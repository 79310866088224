import { Divider } from '@scuf/common';
import { WarrantyConsultModal } from 'components';
import usePreventClickjacking from 'helpers/hooks/usePreventingClickjacking';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'styles/main.scss';

function WarrantyConsultPage() {
    const [width, setWidth] = useState<number>(0);
    const [modalState, setModalState] = useState<boolean>(false);
    const tam = 845;
    const { t } = useTranslation(['common', 'maintenance', 'repaircenterts']);

    function showCancelModal(): void {
        setModalState(true);
    }

    function handleResize() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        setWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
    }, []);

    usePreventClickjacking();

    return (
        <div className="container">
            <div>
                <div className="contentPageContainer">
                    {width > tam ? (
                        <img alt="imagem ilustrativa garantia" src={require('../../assets/advanced_solutions2.jpg')} />
                    ) : (
                        <div />
                    )}

                    <div className="container-med">
                        <div className="form3Columns">
                            <h2>{t('services.WarrantyConsult')}</h2>
                            <Divider />
                            <WarrantyConsultModal />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WarrantyConsultPage;
