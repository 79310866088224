import { Button, Input, Loader, Table } from '@scuf/common';
import { formatDate } from 'helpers';
import useFetch from 'helpers/hooks/useFetch';
import { SerialNumber } from 'models/repaircenter/rmaModel';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { REPAIR_CENTER } from 'resources';
import { consultSerialNumber } from 'resources/api/';
import { notificationAtom } from 'resources/atoms/notificationAtom';

function RmaSummary() {
    var [rmaNumber, setRmaNumber] = useState<any>([]);
    const { t } = useTranslation(['home', 'common', 'repaircenter', 'notification']);
    const [serialNum, setSerialNum] = useState<SerialNumber>();
    const setNotification = useSetRecoilState(notificationAtom);

    const [, consultSerialNumber, loading] = useFetch<{ SerialNumber: string }, SerialNumber>({
        url: REPAIR_CENTER.consultSerialNumber,
        onError: 'SerialNumber',
    });

    const getRmaDetails = async (SerialNumber: string) => {
        try {
            await consultSerialNumber({ SerialNumber }, current => {
                setSerialNum(current.data!);
                if (current.data === null) {
                    setNotification(old => ({ ...old, type: 'critical', message: t('SerialNumber') }));
                }
            });
        } catch (error) {
            setNotification(old => ({ ...old, type: 'critical', message: t('SerialError') }));
        } 
    };

    return (
        <>
            <div>
                {(loading && <Loader text={t('common:actions.Loading')} />) || (
                    <>
                        <div className="form2Columns">
                            <h2>{t('common:actions.Consult')} </h2>
                            <Input
                                placeholder={t('common:translation.SerialNumber')}
                                label={t('common:translation.SerialNumber')}
                                type="text"
                                onChange={value => setRmaNumber(value)}
                                value={rmaNumber}
                            />
                            <div className="buttonsSearch">
                                <Button
                                    type="primary"
                                    icon="search"
                                    disabled={!rmaNumber || rmaNumber == ""}
                                    content={t('common:actions.Consult')}
                                    iconPosition="right"
                                    onClick={() => {
                                        getRmaDetails(rmaNumber);
                                    }}
                                />
                            </div>
                        </div>
                    </>
                )}
            </div>
            {serialNum !== null ? (
                <div>
                    <h4>
                        {t('common:translation.Description')} {serialNum?.description}
                    </h4>
                    <Table>
                        <Table.Header>
                            <Table.HeaderCell content={t('common:translation.SerialNumber')} />
                            <Table.HeaderCell content={t('common:translation.ServiceWarranty')} />
                            <Table.HeaderCell content={t('common:translation.FactoryWarranty')} />
                            <Table.HeaderCell content={t('common:translation.MaintenanceContract')} />
                            <Table.HeaderCell content={t('common:translation.PartNumber')} />
                            <Table.HeaderCell content={t('common:translation.Invoice')} />
                            <Table.HeaderCell content={t('repaircenter:translation.ServiceWarrantyValidity')} />
                            <Table.HeaderCell content={t('repaircenter:translation.FactoryWarrantyValidity')} />
                            <Table.HeaderCell content={t('repaircenter:translation.MaintenanceContractValidity')} />
                        </Table.Header>
                        <Table.Body>

                            <Table.Row>
                                <Table.Cell content={serialNum?.serialNumber} />
                                <Table.Cell content={serialNum?.flagHasServiceWarranty === true ? "SIM" : "NÃO"} />
                                <Table.Cell content={serialNum?.flagHasFactoryWarranty === true ? "SIM" : "NÃO"} />
                                <Table.Cell content={serialNum?.flagHasContract === true ? "SIM" : "NÃO"} />
                                <Table.Cell content={serialNum?.partNumber} />
                                <Table.Cell content={serialNum?.salesInvoice} />
                                <Table.Cell content={formatDate(serialNum?.serviceWarranty)} />
                                <Table.Cell content={formatDate(serialNum?.factoryWarranty)} />
                                <Table.Cell content={formatDate(serialNum?.contractValidity)} />
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </div>
            ) : (
                ''
            )}
        </>
    );
}

export default RmaSummary;