import { OrderDTO } from 'models/spareParts/orderById';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { orderAtom } from 'resources/atoms/spareParts';
import ProductAddedPreApproved from './components/productAddedPreApproved';
import ProductAddedPrice from './components/productAddedPrice';
import ProductAddedWaranty from './components/productAddedWarranty';
import Alert from 'components/alert';

const ProductAdded: React.FC = () => {
    const [orderData] = useRecoilState<OrderDTO>(orderAtom);
    const { t } = useTranslation(['common']);
    useEffect(() => {}, [orderData]);

    return (
        <>
            <div className="div-datatable">
                <div>
                    {orderData !== null ? (
                        orderData?.typeId == 2 ? (
                            <ProductAddedPrice />
                        ) : orderData?.typeId == 3 ? (
                            <ProductAddedPreApproved />
                        ) : orderData?.typeId == 4 ? (
                            <ProductAddedWaranty />
                        ) : (
                            ''
                        )
                    ) : (
                        <Alert text={t('common:translation.RmaNotProcess')} />
                    )}
                </div>
            </div>
        </>
    );
};

export default ProductAdded;
