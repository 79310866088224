import { t } from 'i18next';

export const handleYesOrNo = (cond = false): string => {
    return cond ? t('common:actions.Yes') : t('common:actions.No');
};

export const getCurrency = (value: number): string => {
    switch (value) {
        case 1:
            return 'Real';
        case 2:
            return t('translation.Dollar');

        default:
            return 'Euro';
    }
};
