import axios from 'axios';
import { AxiosHttpGet, AxiosHttpPost } from 'axios-http';
import {
    ItemRmaModel,
    RMAStatus,
    RmaEquipment,
    RmaCustomerCA,
    RmaDetailModel,
    ShipmentShippingModel,
    ShippingAddressModel
} from 'models';
import { CreatNewRmaModel, CreatNewRmaModelCA, ItemsCart, Result, RmaItemsCA } from 'models/repaircenter/detailsRma';
import { ModelEOSEdit, ModelEOSResult, ModelEosXlsx } from 'models/repaircenter/eosModel';
import { INITIAL_PAGE, MAINTENANCES_REPORTS, REPAIR_CENTER, REPAIR_CENTER_CA, SPARE_PARTS } from './links';

const headerNotContentType = {
    Accept: '*/*',
    Authorization: `a/BnOiT72oiKkcO+xlStRbHkstyPgmMT`
};

export async function getAdrresByCEP(cep: string) {
    const cepLength = 8;
    if (cep.length === 9 && cep.charAt(5) === '-') {
        cep = cep.slice(0, 5) + cep.slice(6, 9);
    }
    if (cep.length === cepLength) {
        try {
            const resp = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
            if (resp.data.erro) {
                return { status: 400, data: 'CEP não encontrado' };
            } else {
                return { status: 200, data: resp.data };
            }
        } catch (e) {
            return { status: 500, data: 'Erro ao carregar o CEP' };
        }
    } else {
        return { status: 500, data: 'CEP inválido' };
    }
}

export async function getStatebyIbge() {
    try {
        const resp = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados`);
        return {
            status: 200,
            data: resp.data.map((state: any) => {
                return { value: state.nome, sigla: state.sigla };
            })
        };
    } catch (e) {
        return { status: 500, data: 'Erro ao carregar os estados' };
    }
}
export async function getStatesAndInitials() {
    try {
        const resp = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados`);
        return {
            status: 200,
            data: resp.data.map((state: any) => {
                return state;
            })
        };
    } catch (e) {
        return { status: 500, data: 'Erro ao carregar os estados' };
    }
}

export function postSearchAssistance(product: string, state: string) {
    const data = {
        Product: product,
        State: state
    };
    return AxiosHttpPost({ url: INITIAL_PAGE.searchAssistance, data: data });
}

export function getAssistencs(id: any) {
    return AxiosHttpPost({ url: INITIAL_PAGE.getModelsByUser, data: id });
}

export function getRmaDetails(rmaNumber: number) {
    return AxiosHttpGet({ url: REPAIR_CENTER.getRmaDetails, data: { Id: rmaNumber } });
}
export function getRmaDetailsCA(rmaNumber: number) {
    return AxiosHttpPost({ url: REPAIR_CENTER_CA.getRmaDetailsCA, data: { RmaId: rmaNumber } });
}

export function deleteFile(id: string) {
    let body = { id };
    return AxiosHttpPost({ url: REPAIR_CENTER.removeFile, headers: headerNotContentType, data: body });
}

export function uploadFile(file: any) {
    return AxiosHttpPost({ url: REPAIR_CENTER.uploadFile, headers: headerNotContentType, data: file });
}

export function postRequest(RmaId: string, Type: string, Description: string) {
    let body = {
        RmaId,
        Type,
        Description
    };
    return AxiosHttpPost({ url: REPAIR_CENTER.requestCreate, data: body });
}
export function deleteRequest(id: string) {
    return AxiosHttpPost({ url: REPAIR_CENTER.requestDelete, data: id });
}

export function createRma(newRma: CreatNewRmaModel) {
    return AxiosHttpPost({ url: REPAIR_CENTER.createRma, data: newRma });
}

export function getRma(intemPerPage: number, page: number = 1) {
    const data = {
        RmaId: '',
        SearchInCustomer: '',
        DateStart: null,
        DateEnd: null,
        NumberNFe: '',
        AutorizeType: null,
        StatusId: '',
        SerialNumber: '',
        TypeId: '',
        OsNumber: '',
        ShipmentRequested: '',
        ItemsByPage: 1000,
        Indice: 1,
        MaxIndice: 100,
        TotalResults: '',
        Result: []
    };
    return AxiosHttpPost({ url: REPAIR_CENTER.getRmas, data: data });
}

export function getRmaFilter(
    typeIdSet?: any,
    rmaNumber?: any,
    numberSerie?: any,
    date?: any,
    serviceOrder?: any,
    stOptions?: any,
    itemByPage?: any
) {
    const data = {
        RmaId: rmaNumber,
        SearchInCustomer: '',
        DateStart: null,
        DateEnd: null,
        NumberNFe: '',
        AutorizeType: null,
        StatusId: stOptions,
        SerialNumber: numberSerie,
        TypeId: typeIdSet,
        OsNumber: '',
        ShipmentRequested: '',
        ItemsByPage: 1000,
        Indice: 1,
        MaxIndice: 100,
        TotalResults: '',
        Result: []
    };
    return AxiosHttpPost({ url: REPAIR_CENTER.getRmas, data: data });
}

export function postChat(Message: any, RmaId: any) {
    let body = {
        Message,
        RmaId
    };
    return AxiosHttpPost({ url: REPAIR_CENTER.chatAddMessage, data: body });
}

export function getChat(RmaId: any) {
    let body = {
        Id: 19,
        RmaId
    };
    return AxiosHttpPost({ url: REPAIR_CENTER.chatMessageRead, data: body });
}

export function getSerialNumber(SerialNumber: any) {
    let body = { SerialNumber };
    return AxiosHttpPost({ url: REPAIR_CENTER.consultSerialNumber, data: body });
}

// export function getProduct() {
//     return AxiosHttpPost({ url: INITIAL_PAGE.getProducts });
// }

export function postNotes(message: any, rmaId: any) {
    let body = {
        Message: message,
        RmaId: rmaId
    };
    return AxiosHttpPost({ url: REPAIR_CENTER.addNote, data: body });
}

export function getEquipamentProbelms() {
    let body = {};
    return AxiosHttpPost({ url: REPAIR_CENTER.getEquipmentProblems, data: body });
}

export function getItemsInCart() {
    let body = {};
    return AxiosHttpPost({ url: REPAIR_CENTER.getItemsInCart, data: body });
}

export function addItemCart(AddItem: ItemsCart) {
    return AxiosHttpPost({ url: REPAIR_CENTER.addItemInCart, data: AddItem });
}

export function addItemCartCA(AddItem: Result) {
    return AxiosHttpPost({ url: REPAIR_CENTER_CA.addItemInCartCA, data: AddItem });
}

export function itemCartDelete(id: number[]) {
    return AxiosHttpPost({ url: REPAIR_CENTER.removeItemsFromCart, data: id });
}

export function changeStatusRmaByAdmin(data: RmaDetailModel) {
    return AxiosHttpPost({
        url: REPAIR_CENTER.changeStatusRmaByAdmin,
        data: data
    });
}

export function processingRmaByAdmin(newRma: RmaDetailModel) {
    return AxiosHttpPost({
        url: REPAIR_CENTER.changeStatusRmaByAdmin,
        data: newRma
    });
}

export function changeRmaModality(data: RmaDetailModel) {
    return AxiosHttpPost({
        url: REPAIR_CENTER.alterRmaModality,
        data: data
    });
}

export function removeEquipmentByAdmin(data: ItemRmaModel[]) {
    return AxiosHttpPost({
        url: REPAIR_CENTER.removeEquipmentByAdmin,
        data: data
    });
}

export function alterItemsInRma(data: RmaDetailModel) {
    return AxiosHttpPost({
        url: REPAIR_CENTER.alterItemsInRma,
        data: data
    });
}

export function changeAddressData(data: ShippingAddressModel) {
    return AxiosHttpPost({
        url: REPAIR_CENTER.alterAddressInRma,
        data: data
    });
}

export function changeShippingData(data: ShipmentShippingModel) {
    return AxiosHttpPost({
        url: REPAIR_CENTER.updatePackage,
        data: data
    });
}

export function deleteRma(rma: RmaDetailModel) {
    return AxiosHttpPost({
        url: REPAIR_CENTER.deleteRma,
        data: rma
    });
}

export function deleteEOS(eos: number) {
    let body = {
        Id: eos
    };

    return AxiosHttpPost({
        url: REPAIR_CENTER.deleteEOS,
        data: body
    });
}

export function removePN(id: number) {
    return AxiosHttpGet({
        url: SPARE_PARTS.removePn,
        data: { id }
    });
}

export function getModelsEOS(eosNumber: number) {
    return AxiosHttpPost({ url: REPAIR_CENTER.getModelsEOS, data: { RmaId: eosNumber } });
}

export function saveEOS(eos: ModelEOSEdit[]) {
    return AxiosHttpPost({
        url: REPAIR_CENTER.editEOS,
        data: eos
    });
}

export function UploadModelEOS(Xslx: ModelEosXlsx[]) {
    return AxiosHttpPost({
        url: REPAIR_CENTER.UploadEOS,
        data: Xslx
    });
}
export function UploadStatusRma(Xslx: RMAStatus[]) {
    return AxiosHttpPost({
        url: REPAIR_CENTER_CA.uploadStatusRma,
        data: Xslx
    });
}
export function UploadEquipmentRma(Xslx: RmaEquipment[]) {
    return AxiosHttpPost({
        url: REPAIR_CENTER_CA.uploadListOfStatusEquipmentCA,
        data: Xslx
    });
}
export function UploadCustomerCA(Xslx: RmaCustomerCA[]) {
    return AxiosHttpPost({
        url: REPAIR_CENTER_CA.uploadCustomerCA,
        data: Xslx
    });
}
// export function RmaItemCA(AddItem: RmaItemsCA) {
//     return AxiosHttpPost({ url: REPAIR_CENTER_CA.addItemInCartCA, data: AddItem });
// }
export function RmaItemCA(newRma: Result) {
    return AxiosHttpPost({ url: REPAIR_CENTER_CA.getRmaDetailsCA, data: newRma });
}

export function UploadStatusEquipmentCA(upload: RmaItemsCA) {
    return AxiosHttpPost({ url: REPAIR_CENTER_CA.uploadStatusEquipmentCA, data: upload });
}
export function createRmaCA(newRma: CreatNewRmaModelCA) {
    return AxiosHttpPost({ url: REPAIR_CENTER_CA.createRmaCA, data: newRma });
}
