import { Report } from 'models/maintenanceReport/allMaintenances';
import { atom } from 'recoil';

export const resultModelState = atom({
    key: 'resultModelState',
    default: null as unknown as Report[]
});

export const modelState = atom({
    key: 'modelState',
    default: null as unknown as Report
});
