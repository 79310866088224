import { Button, Icon, Select } from '@scuf/common';
import { DataTable } from '@scuf/datatable';
import useFetch from 'helpers/hooks/useFetch';
import { Report } from 'models/maintenanceReport/allMaintenances';
import { OrderItemsDTO } from 'models/spareParts/orderById';
import { SearchLob } from 'models/userManager/addCustomer';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { SPARE_PARTS } from 'resources';
import { modelState } from 'resources/atoms/maintenanceReportAtom';
import 'styles/maintenanceReport.scss';

interface NewMaintenanceReportItemsProps {
    lobs?: any;
}

function NewMaintenanceReportAddItemsDefault({ lobs }: NewMaintenanceReportItemsProps) {
    const { t } = useTranslation(['maintenanceReport', 'common']);
    const [selectedItem, setSelectedItem] = useState<{ value: number; text: string }>({ value: -1, text: '' });
    const [items, setItems] = useState<any>();
    const [model, setModel] = useRecoilState<Report>(modelState);

    const [, getAllPartnumberByLOB, loading] = useFetch<{ lobs: string; product?: string }, any[]>({
        url: SPARE_PARTS.getAllPartNumbersByLOB,
        onError: 'LoadInfo'
    });

    const [, SearchPartNumbers, loadAction, updatePn] = useFetch<SearchLob, OrderItemsDTO[]>({
        url: SPARE_PARTS.searchPartNumbersByLOB,
        onError: 'LoadInfo',
        method: 'POST'
    });

    useEffect(() => {
        const lobCodes = lobs?.data?.map((item: any) => item.lob.lobCode).join(',');
        if (lobCodes && model?.ProductName) {
            getAllPartnumberByLOB({ lobs: lobCodes, product: model?.ProductName }, resp => {
                setItems(
                    resp.data?.map((e: any, index: number) => ({
                        value: index,
                        text: e.code
                    }))
                );
                setModel({ ...model, ReportItems: [] });
                setSelectedItem({ value: -1, text: '' });
            });
        }
    }, [lobs, model?.ProductName]);

    const addPartNumber = async () => {
        const params: SearchLob = {
            filter: selectedItem?.text ?? '',
            filterType: '1',
            lobs: lobs.data.map((item: any) => item.lob)
        };

        await SearchPartNumbers(params, resp => {
            const newItem = resp.data
                ? [
                      {
                          Description: resp.data[0].description,
                          PartNumber: resp.data[0].code,
                          Product: model?.ProductName,
                          SerialNumber: model?.SerialNumber
                      }
                  ]
                : [];
            setModel(prevModel => ({
                ...prevModel,
                ReportItems: [...(prevModel.ReportItems ?? []), ...newItem]
            }));
        });
    };

    const deleteRenderer = (index: number) => {
        return (
            <Icon
                className="clickable"
                root="common"
                name="delete"
                size="medium"
                onClick={() => {
                    setModel(prevModel => ({
                        ...prevModel,
                        ReportItems: prevModel.ReportItems.filter((_, i) => i !== index)
                    }));
                }}
            />
        );
    };

    return (
        <>
            <h1>
                <Icon root="common" name="entity-details" size="large" /> {t('maintenancereport:translation.ItemsUsedInRepair')}
            </h1>
            <div className="container-general-items">
                <div className="search-items">
                    <Select
                        indicator={'required'}
                        placeholder={t('common:translation.Item')}
                        disabled={loading || !model?.ProductName}
                        options={items ?? []}
                        value={selectedItem?.value}
                        onChange={e => setSelectedItem({ value: e, text: items[e].text })}
                    ></Select>
                    <Button
                        className="button-add"
                        type="primary"
                        icon="slidercontrols-plus"
                        disabled={loading || !model?.ProductName || loadAction || selectedItem?.value === -1}
                        loading={loadAction}
                        content={t('maintenancereport:translation.AddPN')}
                        iconPosition="right"
                        onClick={() => addPartNumber()}
                    />
                </div>
                <div className="table-items">
                    <DataTable
                        data={model?.ReportItems ?? []}
                        rows={10}
                        scrollable={true}
                        virtualScroll={true}
                        scrollHeight="800px"
                    >
                        <DataTable.Column field="PartNumber" header={t('common:translation.PartNumber')} />
                        <DataTable.Column field="Description" header={t('common:translation.Description')} />
                        <DataTable.Column field="" header="" renderer={e => deleteRenderer(e.rowIndex)} />
                    </DataTable>
                </div>
            </div>
        </>
    );
}

export default NewMaintenanceReportAddItemsDefault;
