import { t } from "i18next";
import { CustomErrorBoundary } from "models/error/errorModel";

export class NotFoundError extends Error implements CustomErrorBoundary {
    code:string = '404'
    showMessage: string = t('errors.Return');
    onClick: () => Promise<void> = async ()=>{
        window.location.replace(process.env.REACT_APP_BASENAME_URL!)
    }
   
    constructor(){
        super(t('errors.PageNotFound'))
    }
    
}