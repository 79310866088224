import { Button, Card, Checkbox, Divider, Icon, Input, Modal, Select, Tab, Table, TextArea } from '@scuf/common';
import Alert from 'components/alert';
import { handleYesOrNo } from 'helpers';
import useFetch from 'helpers/hooks/useFetch';
import useUser from 'helpers/hooks/useUser';
import { NewEquipment, RmaModel, RmaModelLite } from 'models';
import { rmaItems, rmaItemsAccessoriesModel, rmaItemsEquipmentModel } from 'models/repaircenter/detailsRma';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { REPAIR_CENTER, Rma, notificationAtom } from 'resources';
import { alterItemsInRma, deleteItemsInRma, insertItemsInRma } from 'resources/api/repaircenter_api/administrative';

interface ChangeStatusProps {
    rmaState: RmaModelLite;
    setRmaState: React.Dispatch<React.SetStateAction<RmaModelLite>>;
}

function AdmEquipmentsAccordion({ rmaState, setRmaState }: ChangeStatusProps): JSX.Element {
    const PROBLEMA_OUTRO = 42;
    const headers = ['Action', 'Number', 'Problem', 'Description', 'SerialNumber', 'Approval'];
    const [change, setChange] = useState<boolean>(false);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [checked, setChecked] = useState<number[]>([]);
    const [, setRemovedItems] = useState<rmaItems[]>([]);
    const { t } = useTranslation(['repaircenter', 'common', 'user']);
    const [items, setItems] = useState<rmaItems[]>(rmaState.rmaItems);
    const [tab, setTab] = useState<number>(0);
    const [originalItems, setOriginalItems] = useState<rmaItems[]>([]);
    const [newEquipment, setNewEquipment] = useState<NewEquipment>(new NewEquipment());
    const [problemTypes, setProblemTypes] = useState<[]>([]);
    const { user } = useUser();
    const [finalEquipment, setFinalEquipment] = useState<NewEquipment>(new NewEquipment());
    const setNotification = useSetRecoilState(notificationAtom);

    const [models, getPartNumberList, loadPartNumbers, setModels] = useFetch({ url: REPAIR_CENTER.getPartNumberModels });
    const [, getRmaDetails] = useFetch<{ Id: number }>({ url: REPAIR_CENTER.getRmaDetails, redirectOnError: true });
    const [problemLocationsAndTypes, getProblemLocationsAndTypes] = useFetch<null, any[]>({
        url: REPAIR_CENTER.getProblemLocationsAndTypes
    });

    async function getPartNumberModels() {
        await getPartNumberList(undefined, current => {
            let listModels: any = [];
            for (let i = 0; i < current.data.length; i++) {
                let objetoTeste = { text: '', value: 0 };
                objetoTeste.text = current.data[i];
                objetoTeste.value = current.data[i];
                listModels[i] = objetoTeste;
            }
            setModels(listModels);
        });
    }

    function changeTab(i: number) {
        setNewEquipment(current => {
            if (i === 0) {
                const { rmaItemsAccessories, ...rest } = current;
                return { ...rest, model: null, rmaItemsEquipment: new rmaItemsEquipmentModel() };
            } else {
                const { rmaItemsEquipment, ...rest } = current;
                return { ...rest, model: null, rmaItemsAccessories: new rmaItemsAccessoriesModel() };
            }
        });
    }

    const handleChange = (field: string, value: any, item: rmaItems): void => {
        setChange(true);
        const updatedItems = items.map(e => (e.id === item.id ? { ...e, [field]: value } : e));
        setItems(updatedItems);
    };

    const handleRemove = async (): Promise<void> => {
        for (const item of items.filter(e => checked.includes(e.id))) {
            const resp = await deleteItemsInRma(item.id, item.rmaId!, rmaState.ssoId, rmaState.justify!);
            if (!resp.error) {
                setItems(old => old.filter(e => !checked.includes(e.id)));
                setChecked([]);
                setChange(true);
                setNotification(old => ({ ...old, type: 'success', message: 'Update' }));
            }
            setChange(false);
            setLoading(false);
        }
    };

    const handleSave = async (): Promise<void> => {
        setLoading(true);

        const resp = await alterItemsInRma({ ...rmaState, rmaItems: items, workOrders: [] });
        if (!resp.error) {
            setRmaState(old => ({ ...old, rmaItems: items }));
            setRemovedItems([]);
            setOriginalItems([...items]);
            setNotification(old => ({ ...old, type: 'success', message: 'Update' }));
            setRmaState(old => ({ ...old, justify: '' }));
        } else if (resp.error) {
            setNotification(old => ({ ...old, type: 'critical', message: 'Change' }));
        }
        setChange(false);
        setLoading(false);
    };

    const handleInsert = async (): Promise<void> => {
        setLoading(true);
        const resp = await insertItemsInRma(finalEquipment);
        if (!resp.error) {
            await getRmaDetails({ Id: rmaState.id }, current => {
                setRmaState(current.data);
                setItems(current.data.rmaItems);
            });
            setNotification(old => ({ ...old, type: 'success', message: 'Update' }));
            setModalOpen(false);
        }
        setChange(false);
        setLoading(false);
    };

    const handleCancel = (): void => {
        setItems([...originalItems]);
        setChange(false);
        setChecked([]);
    };

    function buildSelect(list: any[] | undefined) {
        if (!list) return [];
        let listResult = [];
        for (var i in list) {
            let d: any = { value: list[i].id, text: list[i].name, problemTypes: list[i].problemTypes };
            listResult.push(d);
        }
        return listResult;
    }

    async function Add(rmaID: number) {
        setFinalEquipment({
            ...newEquipment,
            ssoId: user?.SSOID,
            rmaId: rmaID
        });
        sendAddItemCart();
    }

    async function sendAddItemCart() {
        setNewEquipment(current => {
            if (current && tab === 1) {
                const { rmaItemsAccessories, ...rest } = current;
                return rest;
            } else {
                const { rmaItemsEquipment, ...rest } = current;
                return rest;
            }
        });
    }

    useEffect(() => {
        if (finalEquipment.ssoId == undefined || finalEquipment.rmaId == undefined) return;
        handleInsert();
    }, [finalEquipment]);

    useEffect(() => {
        getPartNumberModels();
        changeTab(tab);
        setOriginalItems([...items]);
        getProblemLocationsAndTypes(undefined, (curr: any) => {
            setProblemTypes(curr?.data?.map((e: any) => e.problemTypes));
        });
    }, []);
    return (
        <>
            <Modal
                closeIcon={true}
                className="modalConsultWaranty"
                onClose={() => setModalOpen(false)}
                open={modalOpen}
                size="fullscreen"
            >
                <Modal.Content>
                    <div className="container">
                        <span>
                            <Card>
                                <Card.Content>
                                    <h1>
                                        <Icon root="common" name="device-add" size="large" /> {t('common:actions.Add')}
                                    </h1>
                                    <Alert text={t('translation.TextNotificationAcessory')} />
                                    <div>
                                        <Tab
                                            defaultActiveIndex={0}
                                            className="tab-with-divider"
                                            onTabChange={(index: number) => {
                                                setTab(index);
                                                changeTab(index);
                                            }}
                                        >
                                            <Tab.Pane title={t('common:translation.Equipments')}>
                                                <div className="form3Columns">
                                                    <Select
                                                        indicator={'required'}
                                                        label={t('common:translation.Model')}
                                                        placeholder={t('common:translation.Model')}
                                                        search={true}
                                                        options={models?.data ?? []}
                                                        value={newEquipment.model}
                                                        disabled={loadPartNumbers}
                                                        onChange={(value: any) =>
                                                            setNewEquipment({ ...newEquipment, model: value, ssoId: user?.SSOID })
                                                        }
                                                    />
                                                    <Input
                                                        indicator={'required'}
                                                        placeholder={t('common:translation.SerialNumber')}
                                                        label={t('common:translation.SerialNumber')}
                                                        value={newEquipment.rmaItemsEquipment?.serialNumber ?? ''}
                                                        onChange={(value: any) =>
                                                            setNewEquipment({
                                                                ...newEquipment,
                                                                rmaItemsEquipment: {
                                                                    ...newEquipment.rmaItemsEquipment,
                                                                    serialNumber: value
                                                                }
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div className="form2Columns">
                                                    <Select
                                                        indicator={'required'}
                                                        className="SelectStyle"
                                                        label={t('manage_eos.translation.ProblemLocation')}
                                                        placeholder={t('manage_eos.translation.ProblemLocation')}
                                                        search={true}
                                                        value={newEquipment.rmaItemsEquipment?.problemLocationId}
                                                        options={buildSelect(problemLocationsAndTypes?.data)}
                                                        onChange={(value: any) => {
                                                            setNewEquipment({
                                                                ...newEquipment,
                                                                rmaItemsEquipment: {
                                                                    ...newEquipment.rmaItemsEquipment,
                                                                    problemLocationId: value
                                                                }
                                                            });
                                                            setProblemTypes(
                                                                problemLocationsAndTypes?.data?.at(value - 1).problemTypes
                                                            );
                                                        }}
                                                    />
                                                    {
                                                        //Se o problemLocationId for "outro" deve habilitar campo para descrição
                                                        newEquipment.rmaItemsEquipment?.problemLocationId != PROBLEMA_OUTRO ? (
                                                            <Select
                                                                indicator={'required'}
                                                                className="SelectStyle"
                                                                label={t('common:translation.Problem')}
                                                                search={true}
                                                                disabled={!newEquipment?.rmaItemsEquipment?.problemLocationId}
                                                                placeholder={t('common:translation.Problem')}
                                                                value={newEquipment.rmaItemsEquipment?.problemTypeId}
                                                                options={buildSelect(problemTypes)}
                                                                onChange={(value: any) =>
                                                                    setNewEquipment({
                                                                        ...newEquipment,
                                                                        rmaItemsEquipment: {
                                                                            ...newEquipment.rmaItemsEquipment,
                                                                            problemTypeId: value,
                                                                            problemTypeDescription: null
                                                                        }
                                                                    })
                                                                }
                                                            />
                                                        ) : (
                                                            <Input
                                                                indicator={'required'}
                                                                placeholder={t('common:translation.ProblemDescription')}
                                                                label={t('common:translation.ProblemDescription')}
                                                                value={
                                                                    newEquipment.rmaItemsEquipment?.problemTypeDescription ?? ''
                                                                }
                                                                onChange={(value: any) =>
                                                                    setNewEquipment({
                                                                        ...newEquipment,
                                                                        rmaItemsEquipment: {
                                                                            ...newEquipment.rmaItemsEquipment,
                                                                            problemTypeDescription: value,
                                                                            problemTypeId: null
                                                                        }
                                                                    })
                                                                }
                                                            />
                                                        )
                                                    }
                                                </div>
                                                <div className="buttonsSearch">
                                                    <Button
                                                        type="secondary"
                                                        content={t('common:actions.Cancel')}
                                                        onClick={() => setModalOpen(false)}
                                                    />
                                                    <Button
                                                        type="primary"
                                                        icon="slidercontrols-plus"
                                                        content={t('common:actions.Add')}
                                                        iconPosition="right"
                                                        loading={loading}
                                                        disabled={
                                                            newEquipment.model == null ||
                                                            newEquipment.rmaItemsEquipment?.serialNumber == null ||
                                                            (newEquipment.rmaItemsEquipment?.problemTypeId == null &&
                                                                newEquipment.rmaItemsEquipment?.problemTypeDescription == null) ||
                                                            newEquipment.rmaItemsEquipment?.problemLocationId == null
                                                                ? true
                                                                : false
                                                        }
                                                        onClick={() => Add(rmaState.id)}
                                                    />
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane title={t('common:translation.Accessory')}>
                                                <div className="form3Columns">
                                                    <Select
                                                        indicator={'required'}
                                                        label={t('common:translation.Model')}
                                                        placeholder={t('common:translation.Model')}
                                                        options={models?.data}
                                                        disabled={loadPartNumbers}
                                                        value={newEquipment.model}
                                                        onChange={(value: any) =>
                                                            setNewEquipment({ ...newEquipment, model: value, ssoId: user?.SSOID })
                                                        }
                                                    />
                                                    <Input
                                                        indicator={'required'}
                                                        placeholder={t('common:translation.Problem')}
                                                        label={t('common:translation.Problem')}
                                                        value={newEquipment.rmaItemsAccessories?.problemDescription ?? ''}
                                                        onChange={(value: any) =>
                                                            setNewEquipment({
                                                                ...newEquipment,
                                                                rmaItemsAccessories: {
                                                                    ...newEquipment.rmaItemsAccessories,
                                                                    problemDescription: value
                                                                }
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div className="buttonsSearch">
                                                    <Button
                                                        type="secondary"
                                                        content={t('common:actions.Cancel')}
                                                        onClick={() => setModalOpen(false)}
                                                    />
                                                    <Button
                                                        type="primary"
                                                        icon="slidercontrols-plus"
                                                        content={t('common:actions.Add')}
                                                        iconPosition="right"
                                                        loading={loading}
                                                        disabled={
                                                            newEquipment.model == null ||
                                                            newEquipment.rmaItemsAccessories?.problemDescription == null //||
                                                                ? //newEquipment.serviceTypeId == null
                                                                  true
                                                                : false
                                                        }
                                                        onClick={() => Add(rmaState.id)}
                                                    />
                                                </div>
                                            </Tab.Pane>
                                        </Tab>
                                    </div>
                                </Card.Content>
                            </Card>
                        </span>
                    </div>
                </Modal.Content>
            </Modal>
            <Table>
                <Table.Header>
                    {headers.map((e: string) => (
                        <Table.HeaderCell key={e} content={t(`common:translation.${e}`)}></Table.HeaderCell>
                    ))}
                </Table.Header>
                <Table.Body>
                    {items.map((value: rmaItems) => (
                        <Table.Row key={value.id}>
                            <Table.Cell>
                                <Checkbox
                                    checked={checked.includes(value.id)}
                                    onChange={(checked: boolean) => {
                                        setChange(true);
                                        if (checked) setChecked(old => [...old, value.id]);
                                        else {
                                            setChecked(old => old.filter(id => id !== value.id));
                                            setChange(false);
                                        }
                                    }}
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <Select
                                    search={true}
                                    options={models?.data ?? []}
                                    value={value.model}
                                    disabled={loadPartNumbers}
                                    onChange={newValue => handleChange('model', newValue, value)}
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <Input
                                    disabled={true}
                                    value={value.rmaItemsEquipment?.problemLocation?.name ?? ''}
                                    onChange={e =>
                                        handleChange(
                                            'rmaItemsAccessories',
                                            { ...value.rmaItemsAccessories, problemDescription: e },
                                            value
                                        )
                                    }
                                />
                            </Table.Cell>
                            <Table.Cell>
                                {value.rmaItemsAccessories != null ? (
                                    <Input
                                        value={
                                            value.rmaItemsAccessories?.problemDescription ??
                                            value.rmaItemsEquipment?.problemType?.name ??
                                            value.rmaItemsEquipment?.problemTypeDescription
                                        }
                                        onChange={e =>
                                            handleChange(
                                                'rmaItemsAccessories',
                                                { ...value.rmaItemsAccessories, problemDescription: e },
                                                value
                                            )
                                        }
                                    />
                                ) : (
                                    <Input
                                        disabled={true}
                                        value={
                                            value.rmaItemsEquipment?.problemType?.name ??
                                            value.rmaItemsEquipment?.problemTypeDescription ??
                                            ''
                                        }
                                        onChange={e =>
                                            handleChange(
                                                'rmaItemsEquipment',
                                                { ...value.rmaItemsEquipment, problemTypeDescription: e },
                                                value
                                            )
                                        }
                                    />
                                )}
                            </Table.Cell>
                            <Table.Cell>
                                <Input
                                    disabled={value.rmaItemsAccessories?.problemDescription != null}
                                    value={value.rmaItemsEquipment?.serialNumber || value.rmaItemsAccessories?.serialNumber}
                                    onChange={e =>
                                        handleChange('rmaItemsEquipment', { ...value.rmaItemsEquipment, serialNumber: e }, value)
                                    }
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <Input disabled value={handleYesOrNo(value.approved)} />
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            {change == true ? (
                <>
                    <Divider>{t('common:actions.Reason')}</Divider>
                    <TextArea
                        style={{ resize: 'none !important' }}
                        indicator="required"
                        label={t('common:actions.Reason')}
                        onChange={newValue => {
                            setRmaState(old => ({ ...old, justify: newValue }));
                        }}
                        fluid
                        placeholder={t('common:actions.Reason')}
                    ></TextArea>
                </>
            ) : (
                ''
            )}
            <div className="buttonsSearch">
                {checked.length != 0 ? (
                    <>
                        <Button
                            content={t('common:actions.Delete')}
                            disabled={(checked.length === 0 && rmaState.justify === undefined) || rmaState.justify === ''}
                            type="secondary"
                            onClick={handleRemove}
                        />
                    </>
                ) : (
                    <>
                        <Button
                            type="primary"
                            icon="slidercontrols-plus"
                            content={t('common:actions.Add')}
                            iconPosition="right"
                            loading={loading}
                            onClick={() => setModalOpen(true)}
                        />
                        <Button
                            iconPosition="right"
                            icon={<Icon root="common" name="save" />}
                            content={t('common:actions.Save')}
                            disabled={!change || (change && rmaState.justify === null) || (change && rmaState.justify === '')}
                            loading={loading}
                            onClick={handleSave}
                        />
                    </>
                )}
                {change && <Button content={t('common:actions.Cancel')} type="secondary" onClick={handleCancel} />}
            </div>
        </>
    );
}

export default AdmEquipmentsAccordion;
