import { PartnerKeyModel, PartnerModuleModel, PartnersModel, PartnerUserModel, UserModel } from 'models/spareParts';
import { GetGroups } from 'models/user/ModulePermissions';
import { atom } from 'recoil';
import { persistAtom } from './persistanceAtom';
import { AddAdmin, AddCompany, CompanyDTO } from 'models/userManager/addCustomer';
import { NotesUser } from 'models/userManager/notes';
import { GetUserEvents, UserManagerPendencies } from 'models/userManager/pendencies';

export const clientsAtom = atom({
    key: 'clientAtom',
    default: null as unknown as UserModel[]
});
export const UserAtom = atom({
    key: 'UserAtom',
    default: null as unknown as UserModel
});

export const companiesAtom = atom({
    key: 'companiesAtom',
    default: null as unknown as CompanyDTO[]
});

export const adminAtom = atom({
    key: 'adminAtom',
    default: null as unknown as PartnerUserModel[]
});

export const companyAtom = atom({
    key: 'companyAtom',
    default: null as unknown as CompanyDTO,
    effects_UNSTABLE: [persistAtom]
});

export const getGroupsAtom = atom({
    key: 'getGroupsAtom',
    default: null as unknown as GetGroups[],
    effects_UNSTABLE: [persistAtom]
});

export const modulesAtom = atom({
    key: 'modulesAtom',
    default: null as unknown as PartnerModuleModel[],
    effects_UNSTABLE: [persistAtom]
});
export const keysAtom = atom({
    key: 'keysAtom',
    default: null as unknown as PartnerKeyModel[],
    effects_UNSTABLE: [persistAtom]
});
export const userManagerPendencies = atom({
    key: 'userManagerPendencies',
    default: null as unknown as UserManagerPendencies[]
});

export const GetUserEventsAtom = atom({
    key: 'GetUserEventsAtom',
    default: null as unknown as GetUserEvents[]
});

export const GetUserNotesAtom = atom({
    key: 'GetUserNotesAtom',
    default: null as unknown as NotesUser[]
});

export const AddCompanyAtom = atom({
    key: 'AddCustomerAtom',
    default: null as unknown as AddCompany
});
export const AddAdminAtom = atom({
    key: 'AddAdminAtom',
    default: null as unknown as AddAdmin
});
