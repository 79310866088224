import axios, { AxiosResponse, RawAxiosRequestHeaders } from "axios";
import { AccessDeniedError, AuthenticationError, UnexpectedError } from "helpers/error";

import { CustomErrorBoundary } from "models/error/errorModel";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useErrorBoundary } from "react-error-boundary";
import { useSetRecoilState } from "recoil";
import { notificationAtom } from "resources";

type HttpResponse<ResType> = {
    status?: number;
    error: boolean;
    message: string;
    data?: ResType;
};

type OptionsParams = {
    headers?: RawAxiosRequestHeaders
}

type FetchParams = {
    url: string,
    method?: "POST" | "GET" | "DELETE",
    onSuccess?: string,
    options?: OptionsParams,
    responseType?: "json" | "blob" | "text" | "arraybuffer" | "document" | "stream" // Adicionando responseType
} & (
    | { redirectOnError?: boolean }
    | { onError?: string }
)

type FetchHookResponse<ReqType, ResType> = [
    response: HttpResponse<ResType> | null,
    fetch: (data?: ReqType, onComplete?: ((currState: HttpResponse<ResType>) => void)) => Promise<void>,
    isLoading: boolean,
    update: (newData: ResType | ((prevState: ResType) => ResType)) => void
]

const useFetch = <ReqType = null, ResType = any>({ url, method = "GET", onSuccess, options, responseType = "json", ...rest }: FetchParams): FetchHookResponse<ReqType, ResType> => {
    //hook response
    const [isLoading, setLoading] = useState<boolean>()
    const [response, setResponse] = useState<HttpResponse<ResType> | null>(null)
    //hook helpers
    const [isUpdating, setUpdating] = useState(false)
    const [callback, setCallback] = useState<((currState: HttpResponse<ResType>) => void)>(() => null)
    const setNotification = useSetRecoilState(notificationAtom);
    const [cookie, , removeCookie] = useCookies(['info']);
    const { showBoundary } = useErrorBoundary<CustomErrorBoundary>()

    //request helpers
    const defaultHeaders: RawAxiosRequestHeaders = {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        Accept: '*/*',
        Authorization: `Bearer ${cookie.info}`,
    };
    const headers: RawAxiosRequestHeaders = {
        ...defaultHeaders,
        ...options?.headers
    };

    const errorHandler = (errorCode: string | number) => {
        if (errorCode == 401 || errorCode == 403 || errorCode == 404) {
            removeCookie('info');
        }
    };

    const source = axios.CancelToken.source();
    const paramsMethods = ["GET", "DELETE"]
    useEffect(() => {
        //If component didUnmount, cancel the current request
        return () => { source.cancel('REQUEST CANCELED BY USER') }
    }, [])

    useEffect(() => {
        if (callback && !isUpdating) {
            if (!response?.error) callback(response!)
        }
    }, [response])

    /**
     *
     * @param data
     * @description
     * Requested data to be passed as body (POST) or params (GET) as ReqType
     * @param onComplete
     * @description
     * Callback function that returns the current state and will execute after the fetch call
     * @example
     * (curr)=>
     */
    const fetch =
        async (data?: ReqType, onComplete?: ((currState: HttpResponse<ResType>) => void)) => {
            // Para utilizar uma FUNÇÃO no useState
            // useState(()=> ()=> func) ou setState(()=> (...args)=> func)
            setCallback(() => onComplete)
            setUpdating(false)
            try {
                setLoading(true);
                let res: AxiosResponse<any, any>;
                res = await axios.request({
                    method: method,
                    url,
                    headers: headers,
                    ...((paramsMethods.includes(method) || !method) ? { params: data } : { data: data }),
                    responseType: responseType, // Adicionando responseType na requisição
                    cancelToken: source.token
                });

                setResponse(responseFactory(res));
                onSuccess && setNotification(old => ({ ...old, message: onSuccess, type: "success" }))
            } catch (e: any) {
                'redirectOnError' in rest && showBoundary(errorFactory(e.response?.status ?? e.code))
                'onError' in rest && setNotification(old => ({ ...old, message: rest.onError, type: "critical" }))
                errorHandler(e.code);
            } finally {
                setLoading(false)
            }
        }

    const update = (newData: ResType | ((prevState: ResType) => ResType)) => {
        setUpdating(true);
        setResponse(old => ({
            ...old!, data:
                typeof newData === 'function' ? (newData as ((prevState: ResType) => ResType))(old!.data!) : newData,
        }))
    }

    return [response, fetch, isLoading!, update];
}

export default useFetch

const isHttpResponse = (response: any): response is HttpResponse<any> => {
    return response.data.hasOwnProperty('data')
}

const errorFactory = (errorCode: string | number): CustomErrorBoundary => {
    switch (errorCode) {
        case 401:
            return new AuthenticationError({ code: errorCode.toString() })
        case 403:
            return new AccessDeniedError()
        default:
            return new UnexpectedError({ code: errorCode.toString() })
    }
}

const responseFactory = (apiResponse: AxiosResponse) => {
    if (isHttpResponse(apiResponse)) {
        return {
            status: apiResponse.data?.status,
            error: apiResponse.data?.data?.error,
            message: apiResponse.data?.message ?? '',
            data: apiResponse.data?.data
        }
    } else {

        return {
            status: apiResponse.data?.Status,
            error: apiResponse.data?.Exception,
            message: apiResponse.data?.Message ?? '',
            data: apiResponse.data?.Result
        }
    }
}
