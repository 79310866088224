import React from 'react';
import { Table } from '@scuf/common';
import { RmaModelLite } from 'models';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { RmaLite } from 'resources';
import { handleYesOrNo } from 'helpers';
import { rmaItems } from 'models/repaircenter/detailsRma';
import Alert from 'components/alert';

const EquipmentsAccordion: React.FC = () => {
    const { t } = useTranslation(['common']);
    const rmaDetail = useRecoilValue<RmaModelLite>(RmaLite);

    return (
        <>
            {rmaDetail?.rmaItems !== null ? (
                <div>
                    <Table>
                        <Table.Header>
                            <Table.HeaderCell content={t('translation.Number')} />
                            <Table.HeaderCell content={t('repaircenter:manage_eos.translation.ProblemLocation')} />
                            <Table.HeaderCell content={t('translation.Description')} />
                            <Table.HeaderCell content={t('translation.SerialNumber')} />
                            <Table.HeaderCell content={t('status.Approved')} />
                        </Table.Header>
                        <Table.Body>
                            {rmaDetail
                                ? [
                                      rmaDetail?.rmaItems?.map((item: rmaItems) => (
                                          <Table.Row>
                                              <Table.Cell>{item?.model} </Table.Cell>
                                              <Table.Cell>{item.rmaItemsEquipment?.problemLocation?.name ?? ''} </Table.Cell>
                                              <Table.Cell>
                                                  {item.rmaItemsAccessories?.problemDescription ??
                                                      item.rmaItemsEquipment?.problemType?.name ??
                                                      item.rmaItemsEquipment?.problemTypeDescription ??
                                                      ''}
                                              </Table.Cell>
                                              <Table.Cell>{item.rmaItemsEquipment?.serialNumber ?? ''}</Table.Cell>
                                              <Table.Cell>{handleYesOrNo(item.approved)} </Table.Cell>
                                          </Table.Row>
                                      ))
                                  ]
                                : '--'}
                        </Table.Body>
                    </Table>
                </div>
            ) : (
                <Alert text={t('empty.NoShipping')}></Alert>
            )}
        </>
    );
};

export default EquipmentsAccordion;
