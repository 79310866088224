import { Button, Divider, Header, Icon, Loader } from '@scuf/common';
import Alert from 'components/alert';
import useUser from 'helpers/hooks/useUser';
import { ChatTable } from 'models/repaircenter/detailsRma';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'styles/repaircenter.scss';
import moment from 'moment';
import LabelDescription from './labelDescription';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { RmaFilterModel, RmaModelLite } from 'models';
import { useRecoilState } from 'recoil';
import { FilterAtom, REPAIR_CENTER, RmaLite } from 'resources';
import useFetch from 'helpers/hooks/useFetch';
import { CompanyDTO } from 'models/userManager/addCustomer';

type ChatProps = {
    messages: ChatTable[];
    loading: boolean;
    onSend: (message: string) => Promise<void>;
    companies?: CompanyDTO[];
};

type SparePartsChatType = {
    orderId?: number;
};

const Chat: React.FC<ChatProps> = ({ onSend, loading, messages, companies }: ChatProps) => {
    const { t } = useTranslation(['repaircenter', 'common']);
    const navigate: NavigateFunction = useNavigate();
    const [messageToSend, setMessageToSend] = useState<string>('');
    const [sending, setSending] = useState<boolean>(false);
    const chatRef = useRef<HTMLDivElement>(null);
    const { user } = useUser();
    const [rmaState, setRmaState] = useRecoilState<RmaModelLite>(RmaLite);
    const [rmaFilter, setRmaFilter] = useRecoilState<RmaFilterModel>(FilterAtom);
    const [loadingChat, setLoadingChat] = useState<boolean>(false);
    const [dataRma, getRmaFilter, loadingRmas] = useFetch<RmaFilterModel, RmaFilterModel>({
        url: REPAIR_CENTER.getRmas,
        method: 'POST',
        redirectOnError: true
    });

    useEffect(() => {
        if (dataRma) {
            setRmaState({ ...rmaState, id: dataRma.data?.rmas?.[0].id! });
            navigate('rma-detail');
        }
    }, [dataRma]);

    useEffect(() => {
        if (messageToSend.length > 200) {
            setMessageToSend(messageToSend.slice(0, 200));
        }
    }, [messageToSend]);

    useEffect(() => {
        if (chatRef.current) {
            chatRef.current?.lastElementChild?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
        }
    }, [messages.length]);

    const handleClick = async () => {
        if (messages.length > 0 && messages?.[0]?.orderId != null) {
            navigate('order', { state: { Id: messages?.[0]?.orderId } });
        } else if (messages.length > 0 && messages?.[0]?.rmaId != null) {
            setLoadingChat(true);
            await getRmaFilter({ ...rmaFilter, indice: 1, id: messages?.[0]?.rmaId });
        }
        setLoadingChat(false);
    };

    const sendMessage = async () => {
        setSending(true);
        try {
            await onSend(messageToSend);
            setMessageToSend('');
        } finally {
            setSending(false);
        }
    };

    return (
        <>
            {sending && 'Teste'}
            {loadingChat && loading && loadingRmas ? (
                <Loader text={'Loading'} overlayOpacity={0.5} />
            ) : (
                <div className="container-chat1">
                    <div className="chat-display" ref={chatRef}>
                        <div>
                            <Header
                                icon={<Icon name="sites" root="common" size="large" />}
                                style={{ textAlign: 'center' }}
                                title={companies?.find(x => x.id === messages?.[0]?.companyId)?.name ?? ''}
                                className="header"
                                logo={false}
                                menu={false}
                            >
                                <Header.IconItem
                                    icon={<Icon name="sites" root="building" />}
                                    description={t('')}
                                ></Header.IconItem>
                            </Header>
                        </div>
                        {!window.location.href.includes('rma-detail') && !window.location.href.includes('order') ? (
                            <div
                                className="no-padding blue-text"
                                style={{ margin: '12px', cursor: 'pointer', textAlign: 'center', textDecoration: 'underline' }}
                                onClick={handleClick}
                            >
                                {messages.length > 0 && messages?.[0]?.orderId != null ? (
                                    <LabelDescription title={t('common:translation.Redirect') + ' #' + messages?.[0]?.orderId} />
                                ) : messages.length > 0 && messages?.[0]?.rmaId != null ? (
                                    <LabelDescription title={t('common:translation.Redirect') + ' #' + messages?.[0]?.rmaId} />
                                ) : (
                                    ''
                                )}
                            </div>
                        ) : (
                            ' '
                        )}
                        {messages?.length > 0 ? (
                            messages.map((item: ChatTable) => (
                                <React.Fragment key={item.id}>
                                    <div className={user?.SSOID === item?.ssoId ? 'sended-message' : 'received-message'}>
                                        <h5>
                                            <Icon name="user" size="medium"></Icon>
                                            {item?.userName}
                                            <Divider fitted />
                                        </h5>
                                        <p className="text-message">{item?.message}</p>
                                        <Divider fitted />
                                        <p className="date-message">
                                            <Icon name="calendar" size="medium"></Icon>
                                            {moment(item.createdAt).format('YYYY-MM-DD[,] HH:mm:ss')}
                                        </p>
                                    </div>
                                </React.Fragment>
                            ))
                        ) : (
                            <Alert text={t('common:empty.NoChatAlert')} />
                        )}
                    </div>
                    <div className="container-input">
                        <textarea
                            className="message-input"
                            placeholder={t('common:placeholders.Input')}
                            maxLength={200}
                            value={messageToSend}
                            onChange={e => setMessageToSend(e.target.value)}
                            rows={3}
                        />
                        <Button
                            className="send-button"
                            icon={<Icon name="double-caret-right" />}
                            iconPosition="right"
                            size="medium"
                            loading={sending}
                            disabled={!messageToSend.length || sending}
                            content={t('common:actions.Send')}
                            onClick={sendMessage}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default Chat;
