import { Button, Card, FileDrop, Icon, Input, Modal, Notification, Select, Tab, Loader } from '@scuf/common';
import { DataTable } from '@scuf/datatable';
import { LabelDescription } from 'components';
import Alert from 'components/alert';
import useFetch from 'helpers/hooks/useFetch';
import useUser from 'helpers/hooks/useUser';
import { NewEquipment } from 'models';
import { RmaItems, rmaItemsAccessoriesModel, rmaItemsEquipmentModel } from 'models/repaircenter/detailsRma';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { CreatNewRma, REPAIR_CENTER } from 'resources';
import { downloadFile, itemCartDelete, uploadRmaFile } from 'resources/api/';

function NewRma() {
    const PROBLEMA_OUTRO = 42;
    const { t } = useTranslation(['repaircenter', 'common', 'user']);
    const [loadingDel, setLoadingDel] = useState<boolean>(false);
    const [newEquipment, setNewEquipment] = useState<NewEquipment>(new NewEquipment());
    const [details, setDetails] = useState<any>([]);
    const [problemTypes, setProblemTypes] = useState<[]>([]);
    const [tab, setTab] = useState<number>(0);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [deletedItem, setDeletedItem] = useState<any>([]);
    const [arquivoXLSX, setArquivoXLSX] = useState<File | undefined>();
    const [data, setData] = useState<any[]>([]);
    const [rmaState, setRmaState] = useRecoilState(CreatNewRma);
    const [uploadXLSX, setUploadXLSX] = useState<boolean>(false);
    const [uploadXLSXResult, setUploadXLSXResult] = useState<string>('');
    const [uploadXLSXSuccess, setUploadXLSXSuccess] = useState<boolean>(true);
    const [fullLoading, setFullLoading] = useState<string>('');

    const { user } = useUser();
    const navigate = useNavigate();

    const [addedItem, addItemCart, loading] = useFetch<NewEquipment>({
        url: REPAIR_CENTER.addItemInCart,
        method: 'POST',
        onError: 'MessageNumSerie'
    });

    const [, getItemsInCart] = useFetch<{ ssoId: string }>({
        url: REPAIR_CENTER.getItemsInCart,
        method: 'GET'
    });

    const [models, getPartNumberList, loadPartNumbers, setModels] = useFetch({
        url: REPAIR_CENTER.getPartNumberModels
    });

    const [problemLocationsAndTypes, getProblemLocationsAndTypes] = useFetch<null, any[]>({
        url: REPAIR_CENTER.getProblemLocationsAndTypes
    });

    const [, getServiceTypes] = useFetch({ url: REPAIR_CENTER.getServiceTypes });

    useEffect(() => {
        findItemInCart();
        changeTab(tab);
        getServiceTypes();
        getProblemLocationsAndTypes(undefined, (curr: any) => {
            setProblemTypes(curr?.data?.map((e: any) => e.problemTypes));
        });
        getPartNumberModels();
    }, []);

    useEffect(() => {
        findItemInCart();
    }, [addedItem]);

    const buildSelect = (list: any[] | undefined) => {
        if (!list) return [];
        let listResult = [];
        for (let i in list) {
            let d: any = { value: list[i].id, text: list[i].name, problemTypes: list[i].problemTypes };
            listResult.push(d);
        }
        return listResult;
    };

    const addEquipment = () => {
        const updatedEquipment = {
            ...newEquipment,
            ssoId: user?.SSOID
        };
        sendAddItemCart(updatedEquipment);
    };

    const sendAddItemCart = async (updatedEquipment: NewEquipment) => {
        setNewEquipment(current => {
            if (current && tab === 1) {
                const { rmaItemsAccessories, ...rest } = updatedEquipment;
                return rest;
            } else {
                const { rmaItemsEquipment, ...rest } = updatedEquipment;
                return rest;
            }
        });

        await addItemCart(newEquipment);
    };

    const findItemInCart = async () => {
        if (user) {
            await getItemsInCart({ ssoId: user.SSOID }, current => {
                fillCartTable(current);
            });
        }
    };

    const fillCartTable = (current: any) => {
        if (!current.data.length) {
            setDetails([]);
            setData([]);
            return;
        }

        const newItems: any[] = [];
        const accessoriesItems: any[] = [];

        current.data.forEach((item: RmaItems) => {
            if (!rmaState?.rmaItems?.some(e => e.id === item.id)) {
                setRmaState(old => ({
                    ...old,
                    rmaItems: [...(old?.rmaItems ?? []), item]
                }));
            }

            const newItem: any = {
                Model: item.model,
                Id: item.id,
                SerialNumber: item?.rmaItemsEquipment?.serialNumber,
                SsoId: user?.SSOID,
                ProblemLocation: item?.rmaItemsEquipment?.problemLocation.name,
                Cell: (
                    <Icon
                        root="common"
                        name="delete"
                        size="medium"
                        loading={loadingDel}
                        onClick={() => {
                            openModal(item?.id);
                        }}
                    />
                )
            };

            if (item.rmaItemsEquipment) {
                newItem.Description =
                    item.rmaItemsEquipment.problemLocationId !== PROBLEMA_OUTRO
                        ? item.rmaItemsEquipment?.problemType?.name
                        : item.rmaItemsEquipment.problemTypeDescription;
                newItems.push(newItem);
            } else {
                newItem.ProblemDescription = item?.rmaItemsAccessories?.problemDescription;
                accessoriesItems.push(newItem);
            }
        });

        setDetails(newItems);
        setData(accessoriesItems);

        setNewEquipment(current => {
            const { rmaItemsEquipment, rmaItemsAccessories, ...rest } = current;
            const newModel = tab === 0 ? new rmaItemsEquipmentModel() : new rmaItemsAccessoriesModel();
            return {
                ...rest,
                model: null,
                ...(tab === 0 ? { rmaItemsEquipment: newModel } : { rmaItemsAccessories: newModel })
            };
        });
    };

    const getPartNumberModels = async () => {
        await getPartNumberList(undefined, current => {
            let listModels: any = [];
            for (let i = 0; i < current.data.length; i++) {
                let objetoTeste = { text: '', value: 0 };
                objetoTeste.text = current.data[i];
                objetoTeste.value = current.data[i];
                listModels[i] = objetoTeste;
            }
            setModels(listModels);
        });
    };

    const deleteItemCart = async (id: number) => {
        let resp: { status: number; data: any; message?: any } = await itemCartDelete(id);
        setLoadingDel(true);
        if (resp.status !== 200) {
            setLoadingDel(false);
            return resp.status;
        } else {
            const index = rmaState?.rmaItems!.findIndex(e => e.id == id);
            const updatedList = [...rmaState.rmaItems!];
            updatedList.splice(index, 1);
            findItemInCart();
            setRmaState(old => ({ ...old, rmaItems: updatedList }));
            setLoadingDel(false);
            setShowModal(false);
        }
    };

    const openModal = (item: any) => {
        setDeletedItem(item);
        setShowModal(true);
    };

    const postXLSX = async () => {
        setFullLoading(t('common:translation.Importing'));
        setUploadXLSX(false);
        if (arquivoXLSX != undefined) {
            let resp: { status: number; data: any; message?: any } = await uploadRmaFile(arquivoXLSX, user?.SSOID);

            setUploadXLSXSuccess(resp.status == 200);
            if (resp.status != 200 && resp.message == undefined) resp.message = t('translation.FileOpened');

            let msg: string = resp.message;
            if (resp.status == 200) {
                let qtd: string[] = resp.data.split('|');
                msg = t('translation.' + resp.message);
                msg = msg.replace('XX', qtd[0]);
                msg = msg.replace('YY', qtd[1]);
            }

            setUploadXLSXResult(msg);
            findItemInCart();
        }

        setFullLoading('');
        setArquivoXLSX(undefined);
    };

    const changeTab = (i: number) => {
        setNewEquipment(current => {
            if (i === 0) {
                const { rmaItemsAccessories, ...rest } = current;
                return { ...rest, model: null, rmaItemsEquipment: new rmaItemsEquipmentModel() };
            } else {
                const { rmaItemsEquipment, ...rest } = current;
                return { ...rest, model: null, rmaItemsAccessories: new rmaItemsAccessoriesModel() };
            }
        });
    };

    const download = async (fileName: string) => {
        await downloadFile(fileName);
    };

    const renderModal = () => {
        return (
            <>
                <Modal
                    closeIcon={true}
                    className="popup-theme-wrap"
                    onClose={() => setUploadXLSX(false)}
                    open={uploadXLSX}
                    size="small"
                >
                    <Modal.Header>{t('translation.UploadSpreadsheet')}</Modal.Header>
                    <Modal.Content>
                        <Alert text={t('translation.UploadXLSXT1')} />
                        <div
                            className="no-padding blue-text"
                            style={{ cursor: 'pointer', textDecoration: 'underline' }}
                            onClick={() => {
                                download('TemplateHoneywell.xlsx');
                            }}
                        >
                            <LabelDescription title="TemplateHoneywell.xlsx" />
                        </div>
                        <FileDrop
                            acceptedTypes={['.xlsx']}
                            resetButtonText={t('common:actions.Clean')}
                            multiple={false}
                            buttonText={t('common:actions.BrowseFiles')}
                            label={t('common:translation.File').toUpperCase()}
                            placeholder={t('common:actions.Draganddropfileshere')}
                            onAccepted={(file: File[]) => setArquivoXLSX(file[0])}
                        ></FileDrop>
                    </Modal.Content>
                    <Modal.Footer>
                        <Button
                            type="secondary"
                            size="small"
                            content={t('common:actions.Cancel')}
                            onClick={() => setUploadXLSX(false)}
                        />
                        <Button type="primary" size="small" content="Ok" loading={loading} onClick={() => postXLSX()} />
                    </Modal.Footer>
                </Modal>
                <Modal
                    className="popup-theme-wrap"
                    onClose={() => setUploadXLSXResult('')}
                    open={uploadXLSXResult != ''}
                    size="small"
                >
                    <Modal.Header>{t('translation.UploadSpreadsheet')}</Modal.Header>
                    <Modal.Content>
                        <Notification hasIcon={true} severity={uploadXLSXSuccess ? 'success' : 'critical'}>
                            {uploadXLSXResult}
                        </Notification>
                    </Modal.Content>
                    <Modal.Footer>
                        <Button
                            type="primary"
                            size="small"
                            content="Ok"
                            loading={loading}
                            onClick={() => setUploadXLSXResult('')}
                        />
                    </Modal.Footer>
                </Modal>
                <Modal className="popup-theme-wrap" open={fullLoading != ''} size="small">
                    <Modal.Header></Modal.Header>
                    <Modal.Content>
                        <Loader overlayColor="#202020" text={fullLoading} inverted />
                    </Modal.Content>
                    <Modal.Footer></Modal.Footer>
                </Modal>
            </>
        );
    };

    return (
        <>
            {(loadPartNumbers && <Loader text={t('common:actions.Loading')} />) || (
                <div>
                    {renderModal()}
                    <div className="container">
                        <span>
                            <Card>
                                <Card.Content>
                                    <h1>
                                        <Icon root="common" name="device-add" size="large" /> {t('common:actions.Add')}
                                    </h1>
                                    <Alert text={t('translation.TextNotificationAcessory')} />
                                    <div>
                                        <Tab
                                            defaultActiveIndex={0}
                                            className="tab-with-divider"
                                            onTabChange={(index: number) => {
                                                setTab(index);
                                                changeTab(index);
                                            }}
                                        >
                                            <Tab.Pane title={t('common:translation.Equipments')}>
                                                <div className="form3Columns">
                                                    <Select
                                                        indicator={'required'}
                                                        label={t('common:translation.Model')}
                                                        placeholder={t('common:translation.Model')}
                                                        search={true}
                                                        options={models?.data ?? []}
                                                        value={newEquipment.model}
                                                        disabled={loadPartNumbers}
                                                        onChange={(value: any) =>
                                                            setNewEquipment({ ...newEquipment, model: value, ssoId: user?.SSOID })
                                                        }
                                                    />
                                                    <Input
                                                        indicator={'required'}
                                                        placeholder={t('common:translation.SerialNumber')}
                                                        label={t('common:translation.SerialNumber')}
                                                        value={newEquipment.rmaItemsEquipment?.serialNumber ?? ''}
                                                        onChange={(value: any) =>
                                                            setNewEquipment({
                                                                ...newEquipment,
                                                                rmaItemsEquipment: {
                                                                    ...newEquipment.rmaItemsEquipment,
                                                                    serialNumber: value
                                                                }
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div className="form2Columns">
                                                    <Select
                                                        indicator={'required'}
                                                        className="SelectStyle"
                                                        label={t('manage_eos.translation.ProblemLocation')}
                                                        placeholder={t('manage_eos.translation.ProblemLocation')}
                                                        search={true}
                                                        value={newEquipment.rmaItemsEquipment?.problemLocationId}
                                                        options={buildSelect(problemLocationsAndTypes?.data)}
                                                        onChange={(value: any) => {
                                                            setNewEquipment({
                                                                ...newEquipment,
                                                                rmaItemsEquipment: {
                                                                    ...newEquipment.rmaItemsEquipment,
                                                                    problemLocationId: value
                                                                }
                                                            });
                                                            setProblemTypes(
                                                                problemLocationsAndTypes?.data?.at(value - 1).problemTypes
                                                            );
                                                        }}
                                                    />
                                                    {
                                                        //Se o problemLocationId for "outro" deve habilitar campo para descrição
                                                        newEquipment.rmaItemsEquipment?.problemLocationId != PROBLEMA_OUTRO ? (
                                                            <Select
                                                                indicator={'required'}
                                                                className="SelectStyle"
                                                                label={t('common:translation.Problem')}
                                                                search={true}
                                                                disabled={!newEquipment?.rmaItemsEquipment?.problemLocationId}
                                                                placeholder={t('common:translation.Problem')}
                                                                value={newEquipment.rmaItemsEquipment?.problemTypeId}
                                                                options={buildSelect(problemTypes)}
                                                                onChange={(value: any) =>
                                                                    setNewEquipment({
                                                                        ...newEquipment,
                                                                        rmaItemsEquipment: {
                                                                            ...newEquipment.rmaItemsEquipment,
                                                                            problemTypeId: value
                                                                        }
                                                                    })
                                                                }
                                                            />
                                                        ) : (
                                                            <Input
                                                                indicator={'required'}
                                                                placeholder={t('common:translation.ProblemDescription')}
                                                                label={t('common:translation.ProblemDescription')}
                                                                value={
                                                                    newEquipment.rmaItemsEquipment?.problemTypeDescription ?? ''
                                                                }
                                                                onChange={(value: any) =>
                                                                    setNewEquipment({
                                                                        ...newEquipment,
                                                                        rmaItemsEquipment: {
                                                                            ...newEquipment.rmaItemsEquipment,
                                                                            problemTypeDescription: value
                                                                        }
                                                                    })
                                                                }
                                                            />
                                                        )
                                                    }
                                                </div>
                                                <div className="buttonsSearch">
                                                    <Button
                                                        type="primary"
                                                        icon="slidercontrols-plus"
                                                        content={t('common:actions.XLSXUpload')}
                                                        iconPosition="right"
                                                        loading={loading}
                                                        onClick={() => setUploadXLSX(true)}
                                                    />

                                                    <Button
                                                        type="primary"
                                                        icon="slidercontrols-plus"
                                                        content={t('common:actions.Add')}
                                                        iconPosition="right"
                                                        loading={loading}
                                                        disabled={
                                                            newEquipment.model == null ||
                                                            newEquipment.rmaItemsEquipment?.serialNumber == null ||
                                                            (newEquipment.rmaItemsEquipment?.problemTypeId == null &&
                                                                newEquipment.rmaItemsEquipment?.problemTypeDescription == null) ||
                                                            newEquipment.rmaItemsEquipment?.problemLocationId == null
                                                                ? true
                                                                : false
                                                        }
                                                        onClick={() => addEquipment()}
                                                    />
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane title={t('common:translation.Accessory')}>
                                                <div className="form3Columns">
                                                    <Select
                                                        indicator={'required'}
                                                        label={t('common:translation.Model')}
                                                        placeholder={t('common:translation.Model')}
                                                        options={models?.data}
                                                        disabled={loadPartNumbers}
                                                        value={newEquipment.model}
                                                        onChange={(value: any) =>
                                                            setNewEquipment({ ...newEquipment, model: value, ssoId: user?.SSOID })
                                                        }
                                                    />
                                                    <Input
                                                        indicator={'required'}
                                                        placeholder={t('common:translation.Problem')}
                                                        label={t('common:translation.Problem')}
                                                        value={newEquipment.rmaItemsAccessories?.problemDescription ?? ''}
                                                        onChange={(value: any) =>
                                                            setNewEquipment({
                                                                ...newEquipment,
                                                                rmaItemsAccessories: {
                                                                    ...newEquipment.rmaItemsAccessories,
                                                                    problemDescription: value
                                                                }
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div className="buttonsSearch">
                                                    <Button
                                                        type="primary"
                                                        icon="slidercontrols-plus"
                                                        content={t('common:actions.XLSXUpload')}
                                                        iconPosition="right"
                                                        loading={loading}
                                                        onClick={() => setUploadXLSX(true)}
                                                    />
                                                    <Button
                                                        type="primary"
                                                        icon="slidercontrols-plus"
                                                        content={t('common:actions.Add')}
                                                        iconPosition="right"
                                                        loading={loading}
                                                        disabled={
                                                            newEquipment.model == null ||
                                                            newEquipment.rmaItemsAccessories?.problemDescription == null //||
                                                                ? //newEquipment.serviceTypeId == null
                                                                  true
                                                                : false
                                                        }
                                                        onClick={() => addEquipment()}
                                                    />
                                                </div>
                                            </Tab.Pane>
                                        </Tab>
                                    </div>
                                </Card.Content>
                            </Card>
                        </span>
                    </div>
                    <div className="container">
                        <Card>
                            <Card.Content>
                                <Modal
                                    className="popup-theme-wrap"
                                    onClose={() => setShowModal(false)}
                                    open={showModal}
                                    size="small"
                                >
                                    <Modal.Content>{t('rma.order.DeleteItem')}</Modal.Content>
                                    <Modal.Footer>
                                        <Button
                                            type="secondary"
                                            size="small"
                                            content="Cancel"
                                            onClick={() => setShowModal(false)}
                                        />
                                        <Button
                                            type="primary"
                                            size="small"
                                            content="Delete"
                                            loading={loading}
                                            onClick={() => deleteItemCart(deletedItem)}
                                        />
                                    </Modal.Footer>
                                </Modal>
                                <h1>
                                    <Icon root="common" name="device-add" size="large" /> {t('translation.ServiceBag')}
                                </h1>
                                <br />
                                <div className="div-datatable2">
                                    <div style={{ marginRight: '15px' }}>
                                        <h3>{t('common:translation.Equipments')}</h3>
                                        <DataTable
                                            data={details}
                                            rows={10}
                                            scrollable={true}
                                            virtualScroll={true}
                                            scrollHeight="800px"
                                        >
                                            <DataTable.Column field="Model" header={t('Model')} sortable={true} />
                                            <DataTable.Column
                                                field="ProblemLocation"
                                                header={t('common:translation.Problem')}
                                            ></DataTable.Column>
                                            <DataTable.Column
                                                field="Description"
                                                header={t('common:translation.Description')}
                                                sortable={true}
                                            />
                                            <DataTable.Column
                                                field="SerialNumber"
                                                header={t('common:translation.SerialNumber')}
                                                sortable={true}
                                            />
                                            <DataTable.Column field="Cell" header={t('common:actions.Delete')} />
                                        </DataTable>
                                    </div>
                                    <div>
                                        <h3>{t('common:translation.Accessories')}</h3>
                                        <DataTable
                                            data={data}
                                            rows={10}
                                            scrollable={true}
                                            virtualScroll={true}
                                            scrollHeight="800px"
                                        >
                                            <DataTable.Column field="Model" header={t('Model')} sortable={true} />
                                            <DataTable.Column
                                                field="ProblemDescription"
                                                header={t('common:translation.Description')}
                                                sortable={true}
                                            />
                                            <DataTable.Column field="Cell" header={t('common:actions.Delete')} />
                                        </DataTable>
                                    </div>
                                </div>
                                <div className="buttonsSearch">
                                    <Button
                                        type="primary"
                                        icon="slidercontrols-plus"
                                        content={t('common:translation.Next')}
                                        loading={loading}
                                        iconPosition="right"
                                        disabled={!details.length && !data.length}
                                        onClick={() => navigate('details')}
                                    />
                                </div>
                            </Card.Content>
                        </Card>
                    </div>
                </div>
            )}
        </>
    );
}
export default NewRma;
