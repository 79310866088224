import { atom } from 'recoil';
type notificationModel = {
    type?: 'critical' | 'important' | 'information' | 'success';
    message?: string | null;
    // details: string | null
};

export const notificationAtom = atom({
    key: 'notificationAtom',
    default: {
        message: null,
        type: 'critical',
        // details: 'Erro da exceção'
    } as notificationModel
});

