import { Notification } from '@scuf/common';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toast, ToastContainer } from 'react-toastify';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { notificationAtom } from 'resources';
type Props = {
    children: React.ReactNode;
};

function NotificationToast(message: string, type: any, close: CallableFunction) {
    toast(
        <Notification severity={type} hasIcon={true}>
            {/* {details} */}
            {message}
        </Notification>,
        {
            position: 'bottom-right',
            draggable: true,
            // autoClose: 5000,
            hideProgressBar: true,
            pauseOnHover: true,
            className: 'notificationError',
            icon: false,
            closeButton: false,
            onClose: () => close()
        }
    );

    return <ToastContainer />;
}
const NotificationWrapper: React.FC<Props> = ({ children }) => {
    const notification = useRecoilValue(notificationAtom);
    const { t } = useTranslation(['notification']);
    const reset = useResetRecoilState(notificationAtom);
    useEffect(() => {
        reset();
    }, []);

    const toast = useMemo(() => {
        if (!!notification.message)
            return NotificationToast(
                t(`${notification.type}.${notification.message}`),
                notification.type,
                // notification.details,
                reset
            );
    }, [notification]);
    return (
        <>
            {children}
            {toast}
        </>
    );
};

export default NotificationWrapper;
