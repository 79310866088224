import React, { useState, useEffect } from 'react';
import { Icon } from '@scuf/common';
import '../styles/ratingComponent.scss';

interface RatingComponentProps {
  icon?: string;
  totalStars: number;
  onChange?: (rating: string) => void;
  disabled?: boolean;
  value?: string;
}

const RatingComponent: React.FC<RatingComponentProps> = ({
  icon = 'favorite',
  totalStars,
  onChange,
  disabled = false,
  value = '0',
}) => {
  const [rating, setRating] = useState(value);

  const descriptions = [
    "Extremamente Insatisfeito",
    "Insatisfeito",
    "Parcialmente Satisfeito",
    "Satisfeito",
    "Extremamente Satisfeito"
  ];

  useEffect(() => {
    setRating(value);
  }, [value]);

  const handleClick = (index: number) => {
    if (!disabled) {
      const newRating = (index + 1).toString();
      setRating(newRating);
      if (onChange) {
        onChange(newRating);
      }
    }
  };

  return (
    <div className="rating-component">
      <div className="stars">
        {Array.from({ length: totalStars }, (_, index) => (
          <Icon
            className='icon'
            name={icon}
            root="common"
            key={index}
            size="medium"
            color={index < parseInt(rating) ? '#ffc627' : '#303030'}
            onClick={() => handleClick(index)}
            style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
          />
        ))}
      </div>
      {parseInt(rating) > 0 && (
        <p>Nota: {`${rating} - ${descriptions[parseInt(rating) - 1]}`}</p>
      )}
    </div>
  );
};

export default RatingComponent;
