import { Grid } from '@scuf/common';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { RmaLite } from 'resources';
import LabelDescription from '../../../../components/labelDescription';
import { RmaModelLite } from 'models';

const AddressAccordion: React.FC = () => {
    const { t } = useTranslation(['user']);
    const rmaDetail = useRecoilValue<RmaModelLite>(RmaLite);
    return (
        <>
            <Grid>
                <Grid.Row>
                    <Grid.Column className="grid-column" width={6} sWidth={12}>
                        <LabelDescription value={rmaDetail?.address?.zipCode} title={t('address.ZipCode')}></LabelDescription>
                        <LabelDescription value={rmaDetail?.address?.street} title={t('address.Street')}></LabelDescription>
                        <LabelDescription value={rmaDetail?.address?.state} title={t('address.State')}></LabelDescription>
                        <LabelDescription value={rmaDetail?.addressNumber} title={t('address.Number')}></LabelDescription>
                    </Grid.Column>
                    <Grid.Column className="grid-column" width={6} sWidth={12}>
                        <LabelDescription value={rmaDetail?.address?.city} title={t('address.City')}></LabelDescription>
                        <LabelDescription value={rmaDetail?.addressComplement} title={t('address.Complement')}></LabelDescription>
                        <LabelDescription
                            value={rmaDetail?.address?.neighborhood}
                            title={t('address.Neighborhood')}
                        ></LabelDescription>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    );
};

export default AddressAccordion;
