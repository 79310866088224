import { t } from "i18next";
import { CustomErrorArgs, CustomErrorBoundary } from "models/error/errorModel";

export class AuthenticationError extends Error implements CustomErrorBoundary {
    code: string ;
    showMessage: string = t('errors.User'); 
    onClick = async () => {
        window.location.replace(process.env.REACT_APP_SSO_LOGIN!)
    };
    constructor(params?: CustomErrorArgs){
        super(t('errors.UnauthorizedUser'))
        this.code = params?.code ?? '401';
    }
}