import IPermissionValidator from 'helpers/validators/IPermissionValidator';
import useUser from 'helpers/hooks/useUser';
import React from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import UserAuthenticationValidator from 'helpers/validators/userAuthenticationValidator';

type Props = {
    children: React.ReactNode;
    permissions?: IPermissionValidator[];
};
const ProtectedRoute: React.FC<Props> = ({ children, permissions = [] }: Props) => {
    const { showBoundary } = useErrorBoundary();
    const { user } = useUser();
    const hasError = () => {
        const p = [new UserAuthenticationValidator(), ...(permissions ?? [])];
        return p?.find(permission => !permission.validate(user));
    };

    return (!hasError() && <>{children}</>) || <>{showBoundary(hasError()?.trhow())}</>;
};

export default ProtectedRoute;
