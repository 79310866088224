import { BadgeColors } from "models/repaircenter/detailsRma";

export const getColors = (id: number, list: BadgeColors): keyof BadgeColors | undefined => {
    let color = undefined;
    const currentColor = Object.entries(list);
    currentColor.forEach(e => {
        const idsColor = e[1];
        if (idsColor.includes(id)) color = e[0];
    });
    return color;
};