import { Grid, Icon } from '@scuf/common';
import { formatDate } from 'helpers';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { RmaLite } from 'resources';
import LabelDescription from '../../../../components/labelDescription';
import '../../../../styles/repaircenter.scss';
import { RmaModelLite, attendanceTime } from 'models';
import Alert from 'components/alert';

const ShippingAccordion: React.FC = () => {
    const { t } = useTranslation(['repaircenter', 'common']);
    const rmapackage = useRecoilValue<RmaModelLite>(RmaLite)?.package;

    return (
        <>
            {rmapackage !== null ? (
                <div>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column className="grid-column" width={6} sWidth={12}>
                                <LabelDescription value={rmapackage?.width} title={t('rma.shipping.Width')}></LabelDescription>
                                <LabelDescription value={rmapackage?.height} title={t('rma.shipping.Height')}></LabelDescription>
                                <LabelDescription value={rmapackage?.length} title={t('rma.shipping.Length')}></LabelDescription>
                            </Grid.Column>
                            <Grid.Column className="grid-column" width={6} sWidth={12}>
                                <LabelDescription value={rmapackage?.weight} title={t('rma.shipping.Weight')}></LabelDescription>
                                <LabelDescription
                                    value={rmapackage?.date ? formatDate(rmapackage?.date.toString()) : ''}
                                    title={t('rma.shipping.ShipmentSentDate')}
                                ></LabelDescription>
                                <LabelDescription
                                    value={attendanceTime.at(parseInt(rmapackage?.time) - 1)?.text}
                                    title={t('rma.shipping.BusinessHour')}
                                ></LabelDescription>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            ) : (
                <Alert text={t('common:empty.NotRemessa')}></Alert>
            )}
        </>
    );
};

export default ShippingAccordion;
