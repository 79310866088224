import { Accordion, Tab } from '@scuf/common';
import { useTranslation } from 'react-i18next';
import PriceReview from './acordion/priceReview';
import Result from './acordion/result';
import UserManagementManual from './acordion/userManagement';
import ManualRma from './manualRma';
import usePreventClickjacking from 'helpers/hooks/usePreventingClickjacking';

function TesteManual(): JSX.Element {
    const { t } = useTranslation();

    usePreventClickjacking();
    return (
        <div className="container">
            <Tab>
                <Tab.Pane title="PriceReview">
                    <Accordion>
                        <Accordion.Content title={t('repaircenterdash:Overview')}>
                            <PriceReview />
                        </Accordion.Content>
                        <Accordion.Content title={t('repaircenterdash:RespondingRequest')}>
                            <Result />
                        </Accordion.Content>
                    </Accordion>
                </Tab.Pane>
                <Tab.Pane title="UserManagement">
                    <Accordion>
                        <Accordion.Content title={t('repaircenterdash:Overview')}>
                            <UserManagementManual />
                        </Accordion.Content>
                    </Accordion>
                </Tab.Pane>
                <Tab.Pane title="RMA">
                    <ManualRma />
                </Tab.Pane>
            </Tab>
        </div>
    );
}

export default TesteManual;
