import { AccessDeniedError } from "helpers/error";
import { UserCookie } from "models";
import { CustomErrorBoundary } from "models/error/errorModel";
import IPermissionValidator from "./IPermissionValidator";
class ModulePermissionValidator implements IPermissionValidator {
    constructor(private readonly modulePermission: number){
    }
    validate(user: UserCookie | null): boolean {
        return !!user?.MODULE.includes(this.modulePermission.toString());
    }
    trhow(): CustomErrorBoundary {
        throw new AccessDeniedError()
    }

}

export default ModulePermissionValidator;