import { Button, Card, FileDrop, Icon, Loader, Modal, Select, Table } from '@scuf/common';
import Alert from 'components/alert';
import { formatDate } from 'helpers/dateFormatMicrosft';
import { RmaFileModel, RmaModelLite, typeFile } from 'models';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { RmaLite, notificationAtom } from 'resources';
import { removeFile, uploadFile, downloadFile } from 'resources/api/';
import 'styles/repaircenter.scss';
import useUser from 'helpers/hooks/useUser';

function FileTab() {
    const ld = require('lodash');
    const { t } = useTranslation(['spareparts', 'common']);
    const [rmaState, setRmaState] = useRecoilState<RmaModelLite>(RmaLite);
    const [modalState, setModalState] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const setNotification = useSetRecoilState(notificationAtom);
    const { user: userDetails } = useUser();
    const [fileToSend, setFileToSend] = useState<{
        formFile: any;
        rmaId: any;
        ssoId: any;
        type: any;
    } | null>(null);
    const [disableButton, setDisableButton] = useState<boolean>(false);

    useEffect(() => {
        if (fileToSend?.type == null || fileToSend?.formFile == null) {
            setDisableButton(true);
        } else {
            setDisableButton(false);
        }
    }, [fileToSend?.type, fileToSend?.formFile]);

    function renderModal(): JSX.Element {
        return (
            <Modal
                className="modal"
                onClose={() => setModalState(false)}
                size="small"
                open={modalState}
                closeOnDimmerClick={true}
                closeOnDocumentClick={true}
            >
                <Select
                    className="select-type"
                    options={typeFile}
                    indicator="required"
                    label={t('common:translation.Type')}
                    value={fileToSend?.type}
                    placeholder={t('common:translation.Type')}
                    onChange={value => {
                        setFileToSend(old => ({
                            ...old!,
                            type: value
                        }));
                    }}
                />
                <br />
                <div className="container-selects">
                    <FileDrop
                        placeholder={t('common:actions.Draganddropfileshere')}
                        resetButtonText={t('common:actions.Delete')}
                        onAccepted={file => {
                            setFileToSend(old => ({
                                ...old!,
                                rmaId: rmaState?.id,
                                formFile: file[0],
                                ssoId: userDetails?.SSOID
                            }));
                        }}
                        onReset={() => setFileToSend(null)}
                    />
                </div>
                <Card.Footer>
                    <div className="buttonModal">
                        <Button
                            content={t('common:actions.Close')}
                            onClick={() => setModalState(false)}
                            size="medium"
                            textTransform={true}
                        />
                        <Button
                            type="primary"
                            icon="slidercontrols-plus"
                            onClick={() => {
                                postFile();
                            }}
                            content={t('common:actions.Create')}
                            disabled={disableButton}
                            iconPosition="right"
                            loading={loading}
                        />
                    </div>
                </Card.Footer>
            </Modal>
        );
    }
    const download = async (fileName: string) => {
        await downloadFile(fileName);
    };

    async function postFile() {
        if (fileToSend != undefined) {
            let filename: string[] = fileToSend.formFile.name.split('.');
            let posfix = `_${Date.parse(Date())}.${filename[filename.length - 1]}`;
            let prefix: string = '';
            for (let i = 0; i < filename.length - 1; i++) prefix += filename[i];
            let newName = prefix + posfix;

            //enviando para o servidor
            let f: File = new File([fileToSend.formFile], newName, { type: fileToSend.type });
            let resp = await uploadFile(f, fileToSend.rmaId, fileToSend.ssoId, fileToSend.type);
            if (resp?.error ?? false) setNotification(old => ({ ...old, message: resp?.message, type: 'important' }));
            else {
                setNotification(old => ({ ...old, message: '', type: 'success' }));
                let newRmaFiles: RmaFileModel[] = ld.cloneDeep(rmaState.rmaFiles);
                newRmaFiles.push(resp.data);
                setRmaState(old => ({ ...old, rmaFiles: newRmaFiles }));
            }

            setModalState(false);
        }
    }

    async function deleteFile(fileId: number) {
        setLoading(true);
        let resp = await removeFile(fileId);

        if (resp.error) {
            setNotification(old => ({ ...old, message: 'Removed' }));
        } else {
            setRmaState(prevState => {
                return {
                    ...prevState,
                    rmaFiles: prevState.rmaFiles.filter(item => item.id !== fileId)
                };
            });
            setNotification(old => ({ ...old, message: 'Removed', type: 'success' }));
        }
        setLoading(false);
    }

    return (
        <>
            <div className="container">
                {renderModal()}
                {loading ? <Loader text={t('common:actions.Loading')} /> : <div />}
                <>
                    <div>
                        <div className="contentPageContainer">
                            <div className="div-datatable">
                                <h1>{t('common:translation.File')}</h1>
                                <br />
                                {rmaState && rmaState?.rmaFiles?.length > 0 ? (
                                    [
                                        <div>
                                            <div className="chat-display">
                                                {rmaState?.rmaFiles?.length !== null ? (
                                                    <div>
                                                        <Table>
                                                            <Table.Header>
                                                                <Table.HeaderCell content={t('common:translation.File')} />
                                                                <Table.HeaderCell content={t('common:translation.About')} />
                                                                <Table.HeaderCell content={t('common:translation.Author')} />
                                                                <Table.HeaderCell content={t('common:translation.Date')} />
                                                                <Table.HeaderCell content={t('common:translation.Download')} />
                                                                <Table.HeaderCell content={t('common:actions.Delete')} />
                                                            </Table.Header>
                                                            <Table.Body>
                                                                {rmaState
                                                                    ? [
                                                                          rmaState.rmaFiles?.map((file: RmaFileModel) => (
                                                                              <Table.Row>
                                                                                  <Table.Cell>{file?.fileName} </Table.Cell>
                                                                                  <Table.Cell>{file?.type} </Table.Cell>
                                                                                  <Table.Cell>{file?.addedBy?.name} </Table.Cell>
                                                                                  <Table.Cell>
                                                                                      {formatDate(file?.createdAt)}
                                                                                  </Table.Cell>

                                                                                  <Table.Cell>
                                                                                      {
                                                                                          <div>
                                                                                              <div>
                                                                                                  <a>
                                                                                                      <Icon
                                                                                                          root="common"
                                                                                                          name="file-download"
                                                                                                          size="medium"
                                                                                                          onClick={() => {
                                                                                                              //   setlink(
                                                                                                              //       file?.fileName
                                                                                                              //   );
                                                                                                              //   setType(file?.type);
                                                                                                              download(
                                                                                                                  file.fileName
                                                                                                              );
                                                                                                          }}
                                                                                                      />
                                                                                                  </a>
                                                                                              </div>
                                                                                          </div>
                                                                                      }
                                                                                  </Table.Cell>
                                                                                  <Table.Cell>
                                                                                      {
                                                                                          <Icon
                                                                                              root="common"
                                                                                              name="delete"
                                                                                              size="medium"
                                                                                              loading={true}
                                                                                              onClick={() => {
                                                                                                  deleteFile(file?.id!);
                                                                                              }}
                                                                                          />
                                                                                      }
                                                                                  </Table.Cell>
                                                                              </Table.Row>
                                                                          ))
                                                                      ]
                                                                    : '--'}
                                                            </Table.Body>
                                                        </Table>
                                                        <br />
                                                        <Card.Content className="buttonsSearch">
                                                            <Button
                                                                type="primary"
                                                                icon="slidercontrols-plus"
                                                                onClick={() => setModalState(true)}
                                                                content={t('common:actions.Add')}
                                                                iconPosition="right"
                                                            />
                                                        </Card.Content>
                                                    </div>
                                                ) : (
                                                    '--'
                                                )}
                                            </div>
                                        </div>
                                    ]
                                ) : (
                                    <div>
                                        <Alert text={t('common:empty.Nofilesadded')} />
                                        <br />
                                        <Card.Content className="buttonsSearch">
                                            <Button
                                                type="primary"
                                                icon="slidercontrols-plus"
                                                onClick={() => setModalState(true)}
                                                content={t('common:actions.Add')}
                                                iconPosition="right"
                                            />
                                        </Card.Content>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            </div>
        </>
    );
}
export default FileTab;
