import { Accordion, List, Table } from '@scuf/common';
import LabelDescription from 'components/labelDescription';
import usePreventClickjacking from 'helpers/hooks/usePreventingClickjacking';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { downloadFile } from 'resources/api/repaircenter_api/file';
import 'styles/main.scss';

function InstructionsProceduresPage() {
    const [width, setWidth] = useState<number>(0);
    const tam = 845;
    const { t } = useTranslation(['repaircenter', 'instructions', 'repaircenterts']);

    function handleResize() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        document.title = 'Centro de Reparos - Portal Honeywell';
        setWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
    }, []);

    const download = async (fileName: string) => {
        await downloadFile(fileName);
    };

    usePreventClickjacking();

    return (
        <div className="container">
            <div>
                <div className="contentPageContainer">
                    <div className="container-grande">
                        <h4>{t('instructions:manual')}</h4>
                        <p>{t('instructions:text1')}</p>
                    </div>
                </div>
                <Table>
                    <Table.Header>
                        <Table.HeaderCell content={t('common:translation.File')} />
                        <Table.HeaderCell content={t('common:translation.Download')} />
                    </Table.Header>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>{'Centro de reparos - Manual'} </Table.Cell>
                            <Table.Cell>
                                {' '}
                                <div
                                    className="no-padding blue-text"
                                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                                    onClick={() => {
                                        download('ManualCentroReparos.pdf');
                                    }}
                                >
                                    <LabelDescription title="Manual-Centro de reparos.pdf" />
                                </div>
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
                <div className="contentPageContainer">
                    <div className="container-grande">
                        <h4>{t('instructions:Instrucoes')}</h4>
                        <p>{t('instructions:text2')}</p>
                        <Accordion>
                            {/* Limpeza de Cookies e cache do navegador */}
                            <Accordion.Content title={t('instructions:tip1')}>
                                <p>{t('instructions:tip1Text')}</p>
                                <List bulleted={true}>
                                    <List.Content>
                                        {' '}
                                        <a href="https://support.google.com/accounts/answer/32050?hl=pt-BR&co=GENIE.Platform%3DDesktop">
                                            {' '}
                                            Google Chrome{' '}
                                        </a>
                                    </List.Content>
                                    <List.Content>
                                        {' '}
                                        <a href="https://support.microsoft.com/pt-br/microsoft-edge/excluir-cookies-no-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09">
                                            {' '}
                                            Microsoft Edge{' '}
                                        </a>
                                    </List.Content>
                                    <List.Content>
                                        {' '}
                                        <a href="https://support.mozilla.org/pt-BR/kb/limpe-cookies-e-dados-de-sites-no-firefox">
                                            {' '}
                                            Mozilla Firefox{' '}
                                        </a>
                                    </List.Content>
                                </List>
                            </Accordion.Content>

                            {/* Testar com outros navegadores */}
                            <Accordion.Content title={t('instructions:tip2')}>
                                <p>{t('instructions:tip2Text')}</p>
                            </Accordion.Content>

                            {/* Acessar com janela anônima */}
                            <Accordion.Content title={t('instructions:tip3')}>
                                <p>{t('instructions:tip3Text')}</p>
                            </Accordion.Content>

                            {/* Troca de senha */}
                            <Accordion.Content title={t('instructions:tip4')}>
                                <p>{t('instructions:tip4Text')}</p>
                                <p>{t('instructions:tip4Text1')}</p>
                                {width > tam ? <img src={require('../../assets/password1.png')} /> : <div />}
                                <p>{t('instructions:tip4Text2')}</p>
                                {width > tam ? <img src={require('../../assets/password2.png')} /> : <div />}
                                <p>{t('instructions:tip4Text3')}</p>
                                {width > tam ? <img src={require('../../assets/password3.png')} /> : <div />}
                                <p>{t('instructions:tip4Text5')}</p>
                                {width > tam ? <img src={require('../../assets/password4.png')} /> : <div />}
                                <p>{t('instructions:tip4Text6')}</p>
                                {width > tam ? <img src={require('../../assets/password5.png')} /> : <div />}
                                <p>{t('instructions:tip4Text7')}</p>
                                {width > tam ? <img src={require('../../assets/password6.png')} /> : <div />}
                                <p>{t('instructions:tip4Text8')}</p>
                            </Accordion.Content>
                        </Accordion>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InstructionsProceduresPage;
