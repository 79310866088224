import { Grid } from '@scuf/common';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';
import LabelDescription from '../../../../components/labelDescription';
import '../../../../styles/repaircenter.scss';
import { GetUserById, getCompanyById } from 'resources/api/usermanager_api';
import { UserAtom, cepMask, cnpjMask, companyAtom, notificationAtom } from 'resources';
import { UserModel } from 'models/spareParts/partners';
import useUser from 'helpers/hooks/useUser';
import { OrderDTO } from 'models/spareParts/orderById';
import { orderAtom } from 'resources/atoms/spareParts';
import { getCompanyByCnpj } from 'resources/api/usermanager_api/addCustomer';
import { CompanyDTO } from 'models/userManager/addCustomer';

const Customer: React.FC = () => {
    const { t } = useTranslation(['user']);
    const [loading, setLoading] = useState<boolean>(false);
    const setNotification = useSetRecoilState(notificationAtom);
    const [userData, setUser] = useRecoilState<UserModel>(UserAtom);
    const [companyData, setCompanyData] = useRecoilState<CompanyDTO>(companyAtom);
    const [orderData] = useRecoilState<OrderDTO>(orderAtom);
    const { user } = useUser();

    // const handleData = async (): Promise<void> => {
    //     let companyId = orderData?.companyId;
    //     const resp = await getCompanyById({id : companyId});
    //     if (!resp.error) {
    //         setCompanyData(resp.data);
    //     } else setNotification(old => ({ ...old, message: 'LoadInfo' }));
    // };

    // useEffect(() => {
    //     const f = async () => {
    //         setLoading(true);
    //         await handleData();
    //         setLoading(false);
    //     };
    //     f();
    // }, []);

    return (
        <>
            <Grid>
                <Grid.Row>
                    <Grid.Column className="grid-column" width={3} mWidth={6} sWidth={12}>
                        <LabelDescription title={t('company.Name')} value={companyData?.name} />
                        <LabelDescription title={t('address.State')} value={companyData?.state} />
                        <LabelDescription title={t('address.Number')} value={companyData?.number} />
                    </Grid.Column>
                    <Grid.Column className="grid-column" width={3} mWidth={6} sWidth={12}>
                        <LabelDescription title={t('company.Id')} value={cnpjMask(companyData?.cnpj ?? '')} />
                        <LabelDescription title={t('address.City')} value={companyData?.city} />
                        <LabelDescription title={t('address.Complement')} value={companyData?.complement} />
                    </Grid.Column>
                    <Grid.Column className="grid-column" width={3} mWidth={6} sWidth={12}>
                        <LabelDescription title={t('address.Neighborhood')} value={companyData?.neighborhood} />
                        <LabelDescription title={t('address.ZipCode')} value={cepMask(companyData?.zipCode)} />
                        <LabelDescription title={t('address.Street')} value={companyData?.street} />
                    </Grid.Column>
                    <Grid.Column className="grid-column" width={3} mWidth={6} sWidth={12}>
                        <LabelDescription title={t('address.Phone')} value={companyData?.phone} />
                        <LabelDescription title={t('info.Email')} value={companyData?.email} />
                        <LabelDescription title={t('repaircenter:rma.order.EmailCustomer')} value={orderData?.email} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    );
};

export default Customer;
