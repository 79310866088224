import { Icon, Table } from '@scuf/common';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { SPARE_PARTS } from 'resources';
import { orderAtom } from 'resources/atoms/spareParts';
import ProductInfoModal from './productInfoModal';
import { OrderDTO, OrderItemsDTO } from 'models/spareParts/orderById';
import useFetch from 'helpers/hooks/useFetch';
import Alert from 'components/alert';

const ProductAddedPrice: React.FC = () => {
    const [orderData, setOrderData] = useRecoilState<OrderDTO>(orderAtom);
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const { t } = useTranslation(['spareparts']);
    const canEdit = (): boolean => {
        return orderData.statusId === 2;
    };
    const [, editOrder] = useFetch<OrderDTO>({
        url: SPARE_PARTS.editOrder,
        method: 'POST',
        onError: 'Removed',
        onSuccess: 'Removed'
    });
    const handleDelete = async (itemId: number): Promise<void> => {
        const deleteItem = orderData.orderItems.map((e: OrderItemsDTO) => (itemId === e.id ? { ...e, FlagDeleted: true } : e));
        await editOrder({ ...orderData, orderItems: deleteItem }, current => {
            if (current.data) setOrderData(current.data);
        });
    };
    return (
        <>
            <div className="div-datatable">
                <div>
                    <h2>Produtos Simulados {'(' + orderData?.orderItems.length + ')'} </h2>
                    <Table>
                        {orderData?.orderItems.length > 0 ? (
                            [
                                <>
                                    <Table.Header>
                                        <Table.HeaderCell content={t('common:translation.Item')} />
                                        <Table.HeaderCell content={t('common:translation.Description')} />
                                        <Table.HeaderCell content={t('common:translation.Quantity')} />
                                        <Table.HeaderCell content={t('spareparts:translation.ValueNotDiscount')} />
                                        <Table.HeaderCell content={t('common:translation.Discount')} />
                                        <Table.HeaderCell content={t('spareparts:translation.ValueDiscount')} />
                                        <Table.HeaderCell content={t('common:translation.Total')} />
                                    </Table.Header>
                                    <Table.Body>
                                        {orderData?.orderItems.length > 0 &&
                                            orderData?.orderItems.map((item: any) => (
                                                <>
                                                    <Table.Row
                                                        className="clickable"
                                                        //onClick={() => setModalOpen(true)}
                                                    >
                                                        <Table.Cell>{item?.partNumber} </Table.Cell>
                                                        <Table.Cell width={5}> {item?.description} </Table.Cell>
                                                        <Table.Cell>{item?.quantity} </Table.Cell>
                                                        <Table.Cell>${item?.netPrice?.toFixed(2)} </Table.Cell>
                                                        <Table.Cell>{item?.discount * 100 + "%"} </Table.Cell>
                                                        <Table.Cell>
                                                            ${(item?.netPrice - (item?.netPrice * item.discount)).toFixed(2)}
                                                        </Table.Cell>
                                                        <Table.Cell>
                                                            $
                                                            {
                                                                (item?.quantity * (item?.netPrice - (item?.netPrice * item.discount))).toFixed(2)
                                                                // * (item?.netPrice - item?.netPrice * 0.3)).toFixed(
                                                                //     2
                                                            }
                                                        </Table.Cell>
                                                    </Table.Row>
                                                    {isModalOpen && (
                                                        <ProductInfoModal
                                                            item={item}
                                                            onClose={() => setModalOpen(false)}
                                                            onDelete={(itemId: number) => {
                                                                canEdit() && handleDelete(itemId);
                                                            }}
                                                            open={isModalOpen}
                                                        ></ProductInfoModal>
                                                    )}
                                                </>
                                            ))}
                                    </Table.Body>
                                    {/* <Table.Body>
                                        {orderData.?.Freight > 0 ? (
                                            <Table.Row>
                                                <Table.Cell>{''} </Table.Cell>
                                                <Table.Cell>{''} </Table.Cell>
                                                <Table.Cell>{''} </Table.Cell>
                                                <Table.Cell>{''} </Table.Cell>
                                                <Table.Cell>{''} </Table.Cell>
                                                <Table.Cell>{t('translation.ShippingPrice')} </Table.Cell>
                                                <Table.Cell>${orderData?.Resume?.Freight}</Table.Cell>
                                            </Table.Row>
                                        ) : (
                                            ''
                                        )}
                                    </Table.Body> */}
                                     <Table.Body>
                                        <Table.Row>
                                            <Table.Cell>{''} </Table.Cell>
                                            <Table.Cell>{''} </Table.Cell>
                                            <Table.Cell>{''} </Table.Cell>
                                            <Table.Cell> {} </Table.Cell>
                                            <Table.Cell>{}</Table.Cell>
                                            <Table.Cell>{t('repaircenter:translation.Freight')} </Table.Cell>
                                            <Table.Cell>${(orderData?.priceFreight)?.toFixed(2)}</Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell>{''} </Table.Cell>
                                            <Table.Cell>{''} </Table.Cell>
                                            <Table.Cell>{''} </Table.Cell>
                                            <Table.Cell> {t('translation.NoTaxIncluded')} </Table.Cell>
                                            <Table.Cell>{t('translation.FinalValues')}</Table.Cell>
                                            <Table.Cell>{t('common:translation.Total')} </Table.Cell>
                                            <Table.Cell>${(orderData?.total+orderData.priceFreight)?.toFixed(2)}</Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </>
                            ]
                        ) : (
                            <Alert text={t('common:empty.NotProducts')} />
                        )}
                    </Table>
                </div>
            </div>
        </>
    );
};

export default ProductAddedPrice;
