export function cpfMask(value: string) {
    return value
        .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
        .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1'); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nad
}

export function cnpjMask(value: string) {
    return value
        .replace(/\D+/g, '') // não deixa ser digitado nenhuma letra
        .replace(/(\d{2})(\d)/, '$1.$2') // captura 2 grupos de número o primeiro com 2 digitos e o segundo de com 3 digitos, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de número
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1/$2') // captura 2 grupos de número o primeiro e o segundo com 3 digitos, separados por /
        .replace(/(\d{4})(\d)/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1'); // captura os dois últimos 2 números, com um - antes dos dois números
}

export function telephoneMask(value: string,maxLength: number = 14) {
     // Remove todos os caracteres não numéricos
     let numericValue = value.replace(/\D+/g, '');

     // Aplica a máscara de telefone
     let maskedValue = numericValue
         .replace(/^(\d{2})(\d)/g, '($1) $2')

     // Limita o comprimento máximo, se necessário
     if (maskedValue.length > maxLength) {
         maskedValue = maskedValue.substring(0, maxLength);
     }
     return maskedValue;
}

export const cepMask = (value: string): string => {
    return value?.replace(/\D+/g, '').replace(/(\d{5})(\d)/, '$1-$2');
};

export const moneyMask = (value: string): string => {
    if(value == undefined || value == '')
        return value;

    var valorAlterado: string = value;
	valorAlterado = valorAlterado.replace(/\D/g, ""); // Remove todos os não dígitos
	valorAlterado = valorAlterado.replace(/(\d+)(\d{2})$/, "$1,$2"); // Adiciona a parte de centavos
	valorAlterado = valorAlterado.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1."); // Adiciona pontos a cada três dígitos
	valorAlterado = "US$" + valorAlterado;
	value = valorAlterado;
    return value;
};
