import { AxiosFile, AxiosHttpGet, AxiosHttpPost } from 'axios-http/axiosClient';
import { REPAIR_CENTER } from 'resources/links';

const headerNotContentType = {
    Accept: '*/*',
    Authorization: `a/BnOiT72oiKkcO+xlStRbHkstyPgmMT`
};

export function getRmaDetails(rmaNumber: number) {
    return AxiosHttpPost({ url: REPAIR_CENTER.getRmaDetails, data: { RmaId: rmaNumber } });
}

export function removeFile(fileId: number) {
    return AxiosHttpPost({ url: REPAIR_CENTER.removeFile, data: fileId });
}

export function uploadFile(formFile: any, rmaId: number, ssoId: string, type: string) {
    return AxiosHttpPost({
        url: REPAIR_CENTER.uploadFile,
        headers: { Accept: '*/*', 'Content-Type': 'multipart/form-data' },
        data: { formFile, rmaId, ssoId, type }
    });
}
export function downloadFile(fileName: string) {
    return AxiosFile({ url: REPAIR_CENTER.downloadFile, param: { fileName }, downloadName: fileName });
}
