import { Accordion } from '@scuf/common';
import { useTranslation } from 'react-i18next';
import MicrosigaFunctionalities from '../admAcordion/MicrosigaFunctionalities';
import ChangeProducts from '../admAcordion/changeProducts';
import ChangeStatus from '../admAcordion/changeStatus';


function SparePartsAdministrative(): JSX.Element {
    const { t } = useTranslation(['spareparts', 'common']);
    return (
        <div className="container-administrative">
            <Accordion>
                <Accordion.Content title={t('common:actions.OrderProcessing')}>
                    <ChangeStatus />
                </Accordion.Content>
                <Accordion.Content title={t('Microsiga')}>
                    <MicrosigaFunctionalities />
                </Accordion.Content>
                <Accordion.Content title={t('translation.ChangeProducts')}>
                    <ChangeProducts />
                </Accordion.Content>
            </Accordion>
        </div>
    );
}
export default SparePartsAdministrative;
