import { Icon, Loader, Tab } from '@scuf/common';
import { CompanyInfo, UsersInfo } from 'pages/userManager/components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { COMPANY_MANAGER, LOB, MODULE, MODULE_PERMISSION, USER_MODULE, companyDtoAtom } from 'resources';
import { CompanyDTO, CompanyLOBDTO, CompanyModuleDTO, LobDto, ModuleDTO } from 'models/userManager/addCustomer';
import { insertCompanyLOB, insertUserModule, updateCompanyPermissions } from 'resources/api/';
import { Groups } from 'pages/userManager';
import { GetGroups } from 'models/user/ModulePermissions';
import useFetch from 'helpers/hooks/useFetch';
import { useSetRecoilState } from 'recoil';

const CompanyManager: React.FC = () => {
    const { state } = useLocation();
    const { id } = state;
    const { t } = useTranslation(['usermanager', 'common']);

    const [loading, setLoading] = useState<boolean>(false);
    const setCompany = useSetRecoilState<CompanyDTO>(companyDtoAtom);

    const [company, getCompanyById] = useFetch<{ id: number }, CompanyDTO>({
        url: COMPANY_MANAGER.getCompanyById,
        onError: 'LoadInfo'
    });

    const [companyLOB, getCompanyLOB] = useFetch<{ companyId: number }, CompanyLOBDTO[]>({
        url: USER_MODULE.getCompanyLOBs,
        onError: 'LoadInfo'
    });

    const [modules, getAllModules] = useFetch<never, ModuleDTO[]>({
        url: MODULE.getModules,
        onError: 'LoadInfo'
    });

    const [lobs, getAllLobs] = useFetch<never, LobDto[]>({
        url: LOB.getLOBs,
        onError: 'LoadInfo'
    });

    const [modulePermissions, getModulePermissions] = useFetch<never, GetGroups[]>({
        url: MODULE_PERMISSION.getModulePermissions,
        onError: 'LoadInfo'
    });

    const [companyModules, getCompanyModules, , updateModules] = useFetch<{ companyId: number }, CompanyModuleDTO[] | number[]>({
        url: USER_MODULE.getUserModules,
        onError: 'LoadInfo'
    });

    useEffect(() => {
        const f = async () => {
            setLoading(true);
            await Promise.all([
                getCompanyById({ id }, current => setCompany(current.data!)),
                getAllLobs(),
                getCompanyLOB({ companyId: id }),
                getAllModules(),
                getModulePermissions(),
                getModules()
            ]);
            setLoading(false);
        };
        f();
    }, []);

    const handlePermissions = async (lobs: CompanyLOBDTO[] | undefined) => {
        const permissionIds: number[] = [];

        lobs?.forEach(item => {
            const gbe = item.lob.gbe;
            if (gbe === 'PSS' && !permissionIds.includes(17)) {
                permissionIds.push(17);
            } else if (gbe === 'SST' && !permissionIds.includes(18)) {
                permissionIds.push(18);
            }
        });

        await updateCompanyPermissions({ CompanyId: id, PermissionIds: permissionIds });
    };

    const getModules = async () => {
        await getCompanyModules({ companyId: id }, current => {
            if (current.data) {
                updateModules((current.data as CompanyModuleDTO[])?.map(e => e?.moduleId) as number[]);
            }
        });
    };

    const changeLOB = async (lobId: number): Promise<void> => {
        const resp = await insertCompanyLOB(id, lobId);
        if (!resp.error) {
            getCompanyLOB({ companyId: id }, resp => {
                handlePermissions(resp.data);
            });
        }
    };

    const changeModule = async (moduleId: number, value?: boolean): Promise<void> => {
        const resp = await insertUserModule(id, moduleId);
        if (!resp.error) {
            value
                ? updateModules(old => [...((old as number[]) ?? []), moduleId])
                : updateModules(old => [...((old as number[]) ?? [])].filter(e => e != moduleId));
        }
    };

    return (
        <>
            {(loading && <Loader text={t('common:actions.Loading')} />) || (
                <div className="container wrap-content">
                    <h2 style={{ gap: '20px', display: 'flex' }}>
                        <Icon name="sites" root="common" size="large" />
                        {company?.data?.name} #{company?.data?.id}
                    </h2>
                    <Tab className="tab-with-divider">
                        <Tab.Pane title={t('common:tabs.Customer')}>
                            <CompanyInfo />
                        </Tab.Pane>
                        <Tab.Pane title={t('common:tabs.Users')}>
                            <UsersInfo />
                        </Tab.Pane>
                        <Tab.Pane title={t('common:translation.Permissions')}>
                            <Groups
                                modules={modules?.data ?? []}
                                modulePermissions={modulePermissions?.data ?? []}
                                onChangePermission={changeLOB}
                                lobs={lobs?.data ?? []}
                                userPermissions={(companyLOB?.data as CompanyLOBDTO[]) ?? []}
                                userModules={(companyModules?.data as number[]) ?? []}
                                onChangeModules={changeModule}
                            />
                        </Tab.Pane>
                    </Tab>
                </div>
            )}
        </>
    );
};

export default CompanyManager;
