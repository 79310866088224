import { Button, Grid, Modal } from '@scuf/common';
import { LabelDescription } from 'components';
import { dateFormatFromMicrosft, handleYesOrNo } from 'helpers';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
type Props = {
    open: boolean;
    onClose: VoidFunction;
    onDelete?: CallableFunction;
    item: any;
};
const ProductInfoModal: React.FC<Props> = ({ open, onClose, onDelete, item }: Props) => {
    const { t } = useTranslation(['spareparts', 'common']);
    const {
        Id,
        AliquotIcms,
        AliquotIpi,
        AprovedByClient,
        AprovedByAdmin,
        ProcessedByAdmin,
        FlagDeleted,
        PriceWithICMS,
        PriceWithICMSIPI,
        PriceWithICMSIPIST,
        LastPurchaseInfo,
        ...attr
    } = Object.assign(
        {},
        {
            ...item,
            DateCreation: dateFormatFromMicrosft(item.DateCreation),
            LastUpdate: dateFormatFromMicrosft(item.LastUpdate),
            FlagAllTaxesIncluded: handleYesOrNo(item.FlagAllTaxesIncluded),
            Discount: `${item.Discount * 100}%`
        }
    );
    return (
        <Modal open={open} onClose={onClose} size="fullscreen" className="modalW600">
            <div className="modalConsultWaranty">
                <Modal.Header style={{ 'justify-content': 'space-between' }}>
                    <h1 className="modalHeader">{t('common:translation.Details').toUpperCase()}</h1>
                </Modal.Header>
                <Grid.Row>
                    {Object.entries(attr).map((e: any) => (
                        <Grid.Column width={3}>
                            <LabelDescription
                                title={t(`productinfots.order_data.${e[0]}`)}
                                value={e[1] ?? t('common:status.NotInformed')}
                            />
                        </Grid.Column>
                    ))}
                </Grid.Row>
                <div className="buttonsSearch">
                    <Button size="small" type="secondary" content={t('common:actions.Close')} onClick={onClose}></Button>
                    {onDelete && (
                        <Button size="small" content={t('common:actions.Delete')} onClick={() => onDelete(item.Id)}></Button>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default ProductInfoModal;
