import usePreventClickjacking from 'helpers/hooks/usePreventingClickjacking';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'styles/main.scss';

function SparePartsPage() {
    const [width, setWidth] = useState<number>(0);

    const { t } = useTranslation(['spareParts']);

    function handleResize() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        document.title = 'Centro de Reparos - Portal Honeywell';
        setWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
    }, []);

    usePreventClickjacking();

    return (
        <>
            <div className="container">
                <div>
                    <div className="contentPageContainer">
                        {width > 845 ? (
                            <img alt="imagem ilustrativa homem no mercado" src={require('../../assets/spareparts.png')} />
                        ) : (
                            <div />
                        )}
                        <div className="container-med">
                            <h4>{t('spareparts:translation.PartsAccessories')}</h4>
                            <p>{t('spareparts:translation.text1')}</p>
                            <p>{t('spareparts:translation.text2')}</p>
                            <p>{t('spareparts:translation.text3')}</p>
                            <p>
                                {t('spareparts:translation.Parceiros')}
                                <a
                                    style={{ textTransform: 'lowercase' }}
                                    href="https://service.honeywell.com/info/certified"
                                    target="_blank"
                                >
                                    {' '}
                                    {''}
                                    {t('common:actions.Link')}
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {/* <AboutContainer /> */}
        </>
    );
}

export default SparePartsPage;
