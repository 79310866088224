import jwtDecode from "jwt-decode";
import { UserCookie } from "models";
import { useEffect, useState } from "react";
import { useCookies } from 'react-cookie';

const useUser = () => {
    const [cookie, _, removeCookie] = useCookies(['info']);
    const [user, setUser] = useState<UserCookie | null>(()=>{
        if(cookie.info){
            return jwtDecode(cookie.info);
        } else {
            return null;
        }
    });

    const getUser = () => {
        try {
            const userDetails: any = jwtDecode(cookie.info);
            setUser(userDetails);
        } catch(e){
            setUser(null);
        }        
    }

    useEffect(()=>{
       getUser()
    },[cookie])

    const clear = ()=>{
        removeCookie('info')
        setUser(null)
    }

   return { user, clear }
}

export default useUser;
