import { Icon, Loader, Tab } from '@scuf/common';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { USER_MANAGER } from 'resources';
import { FinalCustomerInfo } from './components';
import useFetch from 'helpers/hooks/useFetch';
import { UserDTO } from 'models/user/sso';

const FinalCustomerManager: React.FC = () => {
    const { state } = useLocation();
    const { id: ssoId } = state;
    const [loading, setLoading] = useState<boolean>(false);
    const { t } = useTranslation(['usermanager', 'common']);
    const [user, getUserById] = useFetch<{ ssoId: any }, UserDTO>({ url: USER_MANAGER.GetUserById, onError: 'LoadInfo' });

    useEffect(() => {
        const f = async () => {
            setLoading(true);

            await Promise.all([
                getUserById({ ssoId: ssoId }, current => {
                    if (current?.data) {
                        // setModules(current.data?.company?.companyModules?.map((e: any) => e.moduleId) as number[]);
                    }
                })
            ]);
            setLoading(false);
        };
        f();
    }, []);
    return (
        <>
            {(loading && <Loader text={t('common:actions.Loading')} />) || (
                <div className="container wrap-content">
                    <h2 style={{ gap: '20px', display: 'flex' }}>
                        <Icon name="user" root="common" size="large" />
                        {user?.data?.name} - {user?.data?.ssoId}
                    </h2>
                    <Tab className="tab-with-divider">
                        <Tab.Pane title={t('common:tabs.Customer')}>
                            <FinalCustomerInfo user={user?.data as UserDTO} />
                        </Tab.Pane>
                    </Tab>
                </div>
            )}
        </>
    );
};

export default FinalCustomerManager;
