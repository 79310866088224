import axios from 'axios';

export async function getAdrresByCEP(cep: string) {
    if (cep.length === 9 && cep.charAt(5) === '-') {
        cep = cep.slice(0, 5) + cep.slice(6, 9);
    }
    if (cep.length === 8) {
        try {
            const resp = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
            if (resp.data.erro) {
                return { status: 400, data: 'CEP não encontrado' };
            } else {
                return { status: 200, data: resp.data };
            }
        } catch (e) {
            return { status: 500, data: 'Erro ao carregar o CEP' };
        }
    } else {
        return { status: 500, data: 'CEP inválido' };
    }
}

export async function getStatebyIbge() {
    try {
        const resp = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados`);
        return {
            status: 200,
            data: resp.data.map((state: any) => {
                return { value: state.nome, sigla: state.sigla };
            })
        };
    } catch (e) {
        return { status: 500, data: 'Erro ao carregar os estados' };
    }
}
export async function getStatesAndInitials() {
    try {
        const resp = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados`);
        return {
            status: 200,
            data: resp.data.map((state: any) => {
                return state;
            })
        };
    } catch (e) {
        return { status: 500, data: 'Erro ao carregar os estados' };
    }
}
