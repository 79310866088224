import { Table } from '@scuf/common';
import { OrderDTO } from 'models/spareParts/orderById';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { orderAtom } from 'resources/atoms/spareParts';

const ProductAddedPreApproved: React.FC = () => {
    const [orderData] = useRecoilState<OrderDTO>(orderAtom);
    const { t } = useTranslation(['spareparts', 'common']);
    return (
        <>
            <div className="div-datatable">
                <div>
                    <h2>
                        {t('translation.SimulatedProducts')} {'(' + orderData?.orderItems.length + ')'}{' '}
                    </h2>
                    <Table>
                        <Table.Header>
                            <Table.HeaderCell content={t('common:translation.Item')} />
                            <Table.HeaderCell content={''} />
                            <Table.HeaderCell content={t('common:translation.Quantity')} />
                            <Table.HeaderCell content={t('spareparts:translation.ValueNotDiscount')} />
                            <Table.HeaderCell content={t('common:translation.Discount')} />
                            <Table.HeaderCell content={t('spareparts:translation.ValueDiscount')} />
                            <Table.HeaderCell content={t('common:translation.Total')} />
                        </Table.Header>
                        <Table.Body>
                            {orderData
                                ? [
                                      orderData?.orderItems?.map((item: any) => (
                                          <Table.Row>
                                              <Table.Cell>{item?.partNumber} </Table.Cell>
                                              <Table.Cell width={5}> {item?.description} </Table.Cell>
                                              <Table.Cell>{item?.quantity} </Table.Cell>
                                              <Table.Cell>${item?.netPrice?.toFixed(2)} </Table.Cell>
                                                <Table.Cell>{item?.discount * 100 + "%"} </Table.Cell>
                                              <Table.Cell>${(item?.netPrice-(item.netPrice*item?.discount)).toFixed(2)} </Table.Cell>
                                              <Table.Cell>${((item?.netPrice-(item.netPrice*item?.discount))*item?.quantity).toFixed(2)} </Table.Cell>
                                          </Table.Row>
                                      ))
                                  ]
                                : ''}
                        </Table.Body>
                        {/* <Table.Body>
                            {orderData.Resume?.Freight > 0 ? (
                                <Table.Row>
                                    <Table.Cell>{''} </Table.Cell>
                                    <Table.Cell>{''} </Table.Cell>
                                    <Table.Cell>{''} </Table.Cell>
                                    <Table.Cell>{''} </Table.Cell>
                                    <Table.Cell>{''} </Table.Cell>
                                    <Table.Cell>{t('translation.ShippingPrice')} </Table.Cell>
                                    <Table.Cell>${orderData?.Resume?.Freight}</Table.Cell>
                                </Table.Row>
                            ) : (
                                ''
                            )}
                        </Table.Body> */}
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>{''} </Table.Cell>
                                <Table.Cell>{''} </Table.Cell>
                                <Table.Cell>{''} </Table.Cell>
                                <Table.Cell>{t('translation.NoTaxIncluded')} </Table.Cell>
                                <Table.Cell>{t('translation.FinalValues')} </Table.Cell>
                                <Table.Cell>{t('common:translation.Total')} </Table.Cell>
                                <Table.Cell>${(orderData?.total+orderData.priceFreight).toFixed(2)}</Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </div>
            </div>
        </>
    );
};

export default ProductAddedPreApproved;
