import { Button, Card, FileDrop, Header, Icon, Input, Loader, Modal, Select } from '@scuf/common';
import { DataTable } from '@scuf/datatable';
import { dateFormatFromMicrosft, formatDate } from 'helpers';
import { ModelEOSResult, ModelEosXlsx } from 'models/repaircenter/eosModel';
import { modelOptions, problems } from 'models/repaircenter/types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { deleteEOS, EosAtom, notificationAtom, saveEOS, UploadModelEOS } from 'resources';
import { addModelEos, getModelsEOS } from 'resources/api/repaircenter_api/searchEOS';
import * as Xlsx from 'xlsx';

function ManageEOSmodels() {
    var [model, setModel] = useState<any>();
    var [lockLevel, setLockLevel] = useState<any>();
    var [typeProblem, setProblem] = useState<any>();
    var [itemPerPage, setItemByPage] = useState<any>();
    var [date, setDate] = useState<any>();
    var fileConvert = new FormData();
    const [reason, setReason] = useState<string>();
    const { t } = useTranslation(['repaircenter', 'common', 'notification']);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingModel, setLoadingModel] = useState<boolean>(false);
    const navigate: NavigateFunction = useNavigate();
    const [modalState, setModalState] = useState<boolean>(false);
    const [modalState2, setState] = useState<boolean>(false);
    const [modalConfigState, setConfig] = useState<boolean>(false);
    const [loadingEOS, setLoadingEOS] = useState<boolean>(false);
    const [resultList, setResultList] = useRecoilState<ModelEOSResult[]>(EosAtom);
    const setNotification = useSetRecoilState(notificationAtom);
    const [set, setConfigModal] = useState<any>();
    const { getJsDateFromExcel } = require('excel-date-to-js');
    //const [filter, setEOSFilter] = useState<RmaFilterModel>({ ssoId: userDetails?.SSOID! } as RmaFilterModel);
    const [fileToSend, setFileToSend] = useState<{
        file: any;
        order_id: any;
        type: string;
    } | null>(null);

    const handleDeleteEOS = async (id: any): Promise<void> => {
        setLoadingEOS(true);
        const resp = await deleteEOS(id);
        if (!resp.error) {
            setNotification(old => ({ ...old, type: 'success', message: 'Removed' }));
            getEOSModels();
            setConfig(false);
            setLoadingEOS(false);
        } else {
            setNotification(old => ({ ...old, message: 'Removed' }));
            setLoadingEOS(false);
        }
    };

    const handleSaveEOS = async (): Promise<void> => {
        setLoadingEOS(true);
        const resp = await saveEOS(set);
        if (!resp.error) {
            setNotification(old => ({ ...old, type: 'success', message: 'Update' }));
            getEOSModels();
            setConfig(false);
            setLoadingEOS(false);
        } else {
            setNotification(old => ({ ...old, type: 'critical', message: 'Update' }));
            setLoadingEOS(false);
        }
    };

    const uploadXslx = async (Xslx: ModelEosXlsx[]): Promise<void> => {
        setLoading(true);
        const resp = await UploadModelEOS(Xslx);
        if (!resp.error) {
            setNotification(old => ({ ...old, type: 'success', message: 'Add' }));
            getEOSModels();
            setModalState(false);
        } else {
            setNotification(old => ({ ...old, message: 'Update' }));
        }
        setLoading(false);
    };

    function set2(e: any) {
        setConfigModal(e);
        setConfig(true);
    }
    function configModal(): JSX.Element {
        return (
            <Modal
                className="modalW600"
                onClose={() => setConfig(false)}
                size="large"
                open={modalConfigState}
                closeOnDimmerClick={true}
                closeOnDocumentClick={true}
            >
                <div className="modalConsultWaranty">
                    <h1 className="modalHeader">{'ID #' + set?.id}</h1>
                    <div className="form3Columns">
                        <Input
                            className="select-type"
                            placeholder={t('manage_eos.translation.Model')}
                            label={t('manage_eos.translation.Model')}
                            type="text"
                            onChange={value => setConfigModal((old: any) => ({ ...old, Model: value }))}
                            defaultValue={set?.model}
                        />

                        <Select
                            className="select-type"
                            size="large"
                            label={t('manage_eos.translation.LockLevel')}
                            options={modelOptions}
                            onChange={value =>
                                setConfigModal((old: any) => ({
                                    ...old,
                                    blockingLevel: modelOptions.find((e: any) => e.value == value)?.text
                                }))
                            }
                            defaultValue={modelOptions.findIndex((e: any) => e.text == set?.blockingLevel) + 1}
                        />
                        <Select
                            size="large"
                            className="select-type"
                            label={t('manage_eos.translation.ProblemLocation')}
                            options={problems}
                            onChange={value =>
                                setConfigModal((old: any) => ({
                                    ...old,
                                    problemLocation: problems.find((e: any) => e.value == value)?.text
                                }))
                            }
                            defaultValue={problems.findIndex((e: any) => e.text == set?.problemLocation) + 1}
                        />
                    </div>
                    <Card.Footer>
                        <div className="buttonsSearch">
                            <Button
                                content={t('common:actions.Close')}
                                loading={loadingEOS}
                                type="secondary"
                                onClick={() => setConfig(false)}
                            ></Button>
                            <Button
                                content={t('common:actions.Delete')}
                                loading={loadingEOS}
                                type="secondary"
                                onClick={() => handleDeleteEOS(set?.id)}
                            ></Button>
                            <Button
                                content={t('common:actions.Save')}
                                loading={loadingEOS}
                                onClick={() => handleSaveEOS()}
                            ></Button>
                        </div>
                    </Card.Footer>
                </div>
            </Modal>
        );
    }

    function renderModal(): JSX.Element {
        return (
            <Modal
                className="modalW600"
                onClose={() => setModalState(false)}
                size="small"
                open={modalState}
                closeOnDimmerClick={true}
                closeOnDocumentClick={true}
            >
                <div className="modalConsultWaranty">
                    <div className="modalHeader">
                        <h3>{t('manage_eos.translation.LoadTable').toUpperCase()} </h3>
                    </div>
                    <div className="div-alert-os">
                        <Icon root="common" name="badge-important" color="#f37021" size="xlarge" />
                        <h5>
                            {t('manage_eos.translation.TextNotificationFile')}
                            <a href="https://sites.honeywell.com.br/mshomolog/_Uploads/Template_EOS.xlsx"> {t('common:translation.Here')} </a>{t('common:actions.Download')}
                        </h5>
                    </div>
                    <br />
                    <div className="container-selects">
                        <FileDrop
                            placeholder={t('common:actions.Draganddropfileshere')}
                            resetButtonText={t('common:actions.Delete')}
                            onAccepted={file => {
                                setFileToSend(old => ({
                                    ...old!,
                                    file: file[0]
                                }));
                            }}
                        />
                    </div>
                    <Card.Footer>
                        <div className="buttonModal">
                            <Button
                                type="secondary"
                                content={t('common:actions.Close')}
                                onClick={() => setModalState(false)}
                                size="medium"
                                textTransform={true}
                            />
                            <Button
                                type="primary"
                                loading={loading}
                                icon="slidercontrols-plus"
                                content={t('common:actions.Create')}
                                iconPosition="right"
                                onClick={async () => {
                                    const data = await fileToSend?.file.arrayBuffer();
                                    const read = Xlsx.read(data);
                                    let jsonDataXlsx: ModelEosXlsx[] = Xlsx.utils.sheet_to_json(read.Sheets['itens']);

                                    uploadXslx(
                                        jsonDataXlsx.map((e: ModelEosXlsx) => {
                                            return {
                                                ...e,
                                                Date_EOS: getJsDateFromExcel(e.Date_EOS)
                                            };
                                        })
                                    );
                                }}
                            />
                        </div>
                    </Card.Footer>
                </div>
            </Modal>
        );
    }
    function addModal(): JSX.Element {
        return (
            <Modal
                className="modalW600"
                onClose={() => setState(false)}
                size="large"
                open={modalState2}
                closeOnDimmerClick={true}
                closeOnDocumentClick={true}
            >
                <div className="modalConsultWaranty">
                    <Modal.Header>
                        <div className="modalHeader">
                            <h3>{t('common:actions.Add').toUpperCase()}</h3>
                        </div>
                    </Modal.Header>
                    <div className="form3Columns">
                        <Input
                            placeholder={t('manage_eos.translation.Model')}
                            label={t('manage_eos.translation.Model')}
                            type="text"
                            onChange={value => setModel(value)}
                            value={model}
                        />
                        <Select
                            size="large"
                            label={t('manage_eos.translation.LockLevel')}
                            placeholder={t('manage_eos.translation.LockLevel')}
                            options={modelOptions}
                            onChange={value => setLockLevel(modelOptions[value - 1].text)}
                        />
                        <Select
                            size="large"
                            label={t('manage_eos.translation.ProblemLocation')} 
                            placeholder={t('manage_eos.translation.ProblemLocation')}
                            options={problems}
                            onChange={value => setProblem(problems[value - 1].text)}
                        />
                        <Input
                            placeholder={t('manage_eos.translation.DateEOS')}
                            label={t('manage_eos.translation.DateEOS')}
                            type="date"
                            onChange={value => setDate(value)}
                        />
                        <h1></h1>
                    </div>

                    <Card.Footer>
                        <div className="buttonModal">
                            <Button
                                type="secondary"
                                content={t('common:actions.Close')}
                                onClick={() => setState(false)}
                                size="medium"
                                textTransform={true}
                            />
                            <Button
                                type="primary"
                                icon="slidercontrols-plus"
                                onClick={() => {
                                    addModelEosGet();
                                }}
                                content={t('common:actions.Create')}
                                iconPosition="right"
                                loading={loading}
                            />
                        </div>
                    </Card.Footer>
                </div>
            </Modal>
        );
    }

    async function addModelEosGet() {
        setLoading(true);
        const data = { BlockingLevel: lockLevel, CreatedAt: date, Model: model, ProblemLocation: typeProblem };
        let resp = await addModelEos(data);
        if (resp.status === 200) {
            getEOSModels();
        }
        setLoading(false);
        setState(false);
    }
    function clearFilter() {
        setModel('');
        setLockLevel('');
    }

    async function getEOSModels() {
        setLoadingModel(true);
        let resp = await getModelsEOS();
        if (resp.status != 200) {
            setNotification(old => ({ ...old, message: 'NoResult', type: 'information' }));
            setLoading(false);
        } else {
            setResultList(resp.data.map((e: ModelEOSResult) => ({ ...e })));
        }

        setLoadingModel(false);
    }
    useEffect(() => {
       // getModelsEOS();
        getEosFilterDetails(lockLevel, model);
    }, []);

    async function getEosFilterDetails(blockingLevel: any, model: any) {
        setLoadingModel(true);
        let resp: { status: number; data: any } = await getModelsEOS(blockingLevel, model, resultList);
        if (resp.status != 200) {
            setNotification(old => ({ ...old, message: 'NoResult', type: 'information' }));
            setLoading(false);
        } else {
            setResultList(resp.data.map((e: ModelEOSResult) => ({ ...e })));
        }
        setLoadingModel(false);
    }

    return (
        <>
            {loadingModel && <Loader text={t('common:actions.Loading')} />}
            {addModal()}
            {renderModal()}
            {configModal()}

            {!loadingModel && (
                <>
                    <div>
                        <Header title={t('manage_eos.Name')} logo={false} menu={false}>
                            <Header.IconItem
                                icon={<Icon name="badge-help" size="large" root="building" />}
                                badge="50"
                                description={t('manage_eos.menu.AccessEOSmodelWorks')}
                            ></Header.IconItem>

                            <Header.IconItem
                                icon={<Icon name="table" size="large" root="common" />}
                                badge="20"
                                onClick={() => setModalState(true)}
                                description={t('manage_eos.menu.AddNewWorksheet')}
                            ></Header.IconItem>
                            <Header.IconItem
                                icon={<Icon name="add" size="large" root="building" />}
                                badge="20"
                                onClick={() => setState(true)}
                                description={t('manage_eos.menu.CreateNewEOS')}
                            ></Header.IconItem>
                        </Header>
                        <div className="form3Columns">
                            <Input
                                placeholder={t('manage_eos.translation.Model')}
                                label={t('manage_eos.translation.Model')}
                                type="text"
                                onChange={value => setModel(value)}
                                value={model}
                            />
                            <Select
                                label={t('manage_eos.translation.LockLevel')}
                                placeholder={t('manage_eos.translation.LockLevel')}
                                options={modelOptions}
                                onChange={value => setLockLevel(modelOptions.find(e => e.value == value)?.text)}
                            />
                        </div>
                        <div className="buttonsSearch">
                            <Button
                                type="secondary"
                                icon="badge-delete"
                                content={t('common:actions.ResetFilters')}
                                iconPosition="right"
                                onClick={() => {
                                    clearFilter();
                                    getEosFilterDetails('', '');
                                }}
                            />
                            <Button
                                type="primary"
                                icon="filter1"
                                content={t('common:actions.ApplyFilters')}
                                iconPosition="right"
                                onClick={() => {
                                    getEosFilterDetails(lockLevel, model);
                                    clearFilter();
                                }}
                            />
                        </div>
                    </div>
                    <div className="div-datatable">
                        <DataTable
                            data={resultList}
                            search
                            rows={itemPerPage}
                            reorderableColumns
                            onCellClick={e => set2(e.rowData)}
                        >
                            <DataTable.Column field="id" header={t('ID')} sortable initialWidth={100} />
                            <DataTable.Column field="model" header={t('manage_eos.translation.Model')} sortable />
                            <DataTable.Column
                                field="problemLocation"
                                header={t('manage_eos.translation.ProblemLocation')}
                                sortable
                            />
                            <DataTable.Column field="blockingLevel" header={t('manage_eos.translation.LockLevel')} sortable />
                            {/* <DataTable.Column field="CreatedAt" header={t('common:translation.Date')} sortable /> */}
                            <DataTable.Pagination totalItems={resultList?.length} itemsPerPage={itemPerPage} />
                        </DataTable>
                    </div>
                </>
            )}
        </>
    );
}
export default ManageEOSmodels;
