import { useEffect, useState } from 'react';

function useValidadeEmail(email: string) {
    const [errorMsgEmail, setErrorMsgEmail] = useState<string | undefined>(undefined);
    const regexValidadeEmail =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    useEffect(() => {
        if (!email.match(regexValidadeEmail) && email.length) {
            setErrorMsgEmail('Email não é válido.');
        } else {
            setErrorMsgEmail(undefined);
        }
    }, [email]);
    return errorMsgEmail;
}
export default useValidadeEmail;
