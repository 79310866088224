import { useEffect, useState } from 'react';

function Clock() {
    const [clock, setClock] = useState<string>();
    const date = new Date();

    setTimeout(() => {
        setClock(`${date.toLocaleDateString()}, ${date.toLocaleTimeString()}`);
    }, 1000);

    return <h5>{clock}</h5>;
}
export default Clock;
