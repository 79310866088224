import { Button, Card, Carousel, Icon, Loader } from '@scuf/common';
import { CompanySelectionModal } from 'components';
import { SsoDTO } from 'models/user/sso';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';
import 'styles/main.scss';
import { SSO } from 'resources';
import { UserPortal } from 'models/user/userAccount';
import useFetch from 'helpers/hooks/useFetch';
import usePreventClickjacking from 'helpers/hooks/usePreventingClickjacking';

function Home() {
    const { t } = useTranslation(['home', 'homets', 'common', 'certified']);
    const [modalState, setModalState] = useState<'pending' | 'email' | 'company' | undefined>();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code');
    const [user, setUser] = useState<UserPortal>({} as UserPortal);
    const [, getToken, loading] = useFetch<SsoDTO, any>({ url: SSO.generateToken, method: 'POST' });

    useEffect(() => {
        handleToken();
    }, []);

    async function handleToken() {
        const data: SsoDTO = {
            code: code,
            redirect_uri: process.env.REACT_APP_SSO_REDIRECT_URI!,
            grant_type: 'authorization_code'
        };

        await getToken(data, curr => {
            if (curr.message == '1') {
                setUser({
                    ...user,
                    ssoId: curr.data.sub,
                    name: curr.data.given_Name + ' ' + curr.data.family_Name,
                    email: curr.data.email
                });
                setModalState('company');
            } else if (curr.message == '2') {
                setModalState('pending');
            } else {
                const cookies = new Cookies();
                cookies.set('info', curr.data.value.portal_token);
            }
        });
    }

    usePreventClickjacking();
    return (
        <>
            {(loading && <Loader text={t('common:actions.Loading')} />) || (
                <>
                    <CompanySelectionModal
                        modalState={modalState}
                        onChangeModalState={state => setModalState(state as typeof modalState)}
                        user={user}
                    />
                    <Carousel autoPlay={true} transitionTime={5000} height={450}>
                        <Carousel.Slide
                            className="img-carousel"
                            image={require('../../assets/RepairDepot_APP6_1600x600.jpg')}
                            title={t('welcome.title')}
                            subtitle={t('welcome.info')}
                        />
                        <Carousel.Slide
                            className="img-carousel"
                            image={require('../../assets/hero-industrial-printers.jpg')}
                            title={t('repair.title')}
                            subtitle={t('repair.info')}
                        />
                        <Carousel.Slide
                            className="img-carousel"
                            image={require('../../assets/Repair-Depot_APP11_1600x600.jpg')}
                            title={t('sales.title')}
                            subtitle={t('sales.info')}
                        />
                    </Carousel>
                    {/* <AboutContainer /> */}
                    <div className="container">
                        <h1>{t('common:services.Services')}</h1>
                        <div className="cards-container-2">
                            <Link to="/info/repair-center">
                                <Card>
                                    <Card.Header title={t('common:services.Repairs')}></Card.Header>
                                    <Card.Content>
                                        <Icon className="largeIcon" name="operations" root="building" exactSize={100} />
                                    </Card.Content>
                                    <Card.Footer>
                                        <Button
                                            type="link"
                                            icon={<Icon className="iconArrow" name="caret-right" root="common" />}
                                            iconPosition="right"
                                            content={t('common:actions.LearnMore')}
                                        />
                                    </Card.Footer>
                                </Card>
                            </Link>
                            <Link to="/info/contract">
                                <Card>
                                    <Card.Header title={t('pages:Contracts')}></Card.Header>
                                    <Card.Content>
                                        <Icon className="largeIcon" name="document-certification" root="common" exactSize={100} />
                                    </Card.Content>
                                    <Card.Footer>
                                        <Button
                                            type="link"
                                            icon={<Icon className="iconArrow" name="caret-right" root="common" />}
                                            iconPosition="right"
                                            content={t('common:actions.LearnMore')}
                                        />
                                    </Card.Footer>
                                </Card>
                            </Link>
                            <Link to="/info/certified">
                                <Card className="learn-more-card">
                                    <Card.Header title={t('certified:translation.Certified')}></Card.Header>
                                    <Card.Content>
                                        <Icon className="largeIcon" name="user-operations" root="common" exactSize={100} />
                                    </Card.Content>
                                    <Card.Footer>
                                        <Button
                                            type="link"
                                            icon={<Icon className="iconArrow" name="caret-right" root="common" />}
                                            iconPosition="right"
                                            content={t('common:actions.LearnMore')}
                                        />
                                    </Card.Footer>
                                </Card>
                            </Link>
                        </div>
                        <div className="cards-container-2">
                            <Link to="../spare-parts">
                                <Card>
                                    <Card.Header title={t('pages:spareParts')}></Card.Header>
                                    <Card.Content>
                                        <Icon className="largeIcon" name="briefcase" root="common" exactSize={100} />
                                    </Card.Content>
                                    <Card.Footer>
                                        <Button
                                            type="link"
                                            icon={<Icon className="iconArrow" name="caret-right" root="common" />}
                                            iconPosition="right"
                                            content={t('common:actions.LearnMore')}
                                        />
                                    </Card.Footer>
                                </Card>
                            </Link>
                            <Link to="/info/solutions">
                                <Card>
                                    <Card.Header title={t('common:services.Solutions')}></Card.Header>
                                    <Card.Content>
                                        <Icon className="largeIcon" name="checkbox" root="common" color="blue" exactSize={100} />
                                    </Card.Content>
                                    <Card.Footer>
                                        <Button
                                            type="link"
                                            icon={<Icon className="iconArrow" name="caret-right" root="common" />}
                                            iconPosition="right"
                                            content={t('common:actions.LearnMore')}
                                        />
                                    </Card.Footer>
                                </Card>
                            </Link>
                            <Link to="/info/instructions">
                                <Card>
                                    <Card.Header title={t('common:services.Instructions')}></Card.Header>
                                    <Card.Content>
                                        <Icon
                                            className="largeIcon"
                                            name="menu-physical"
                                            root="common"
                                            color="blue"
                                            exactSize={100}
                                        />
                                    </Card.Content>
                                    <Card.Footer>
                                        <Button
                                            type="link"
                                            icon={<Icon className="iconArrow" name="caret-right" root="common" />}
                                            iconPosition="right"
                                            content={t('common:actions.LearnMore')}
                                        />
                                    </Card.Footer>
                                </Card>
                            </Link>
                        </div>
                    </div>
                    {/* <AboutContainer /> */}
                </>
            )}
        </>
    );
}

export default Home;
