import { Button, Icon } from '@scuf/common';
import { CustomErrorBoundary } from 'models/error/errorModel';
import React, { useEffect, useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import 'styles/app.scss';
type Props = {
    error: CustomErrorBoundary;
};
const ErrorFallback: React.FC<Props> = ({ error }: Props) => {
    const { resetBoundary } = useErrorBoundary();
    const location = useLocation();
    const [currLocationKey] = useState(location.key);
    const { t } = useTranslation(['common']);
    useEffect(() => {
        if (location.key != currLocationKey) resetBoundary();
    }, [location.key]);
    const handleClick = async () => {
        error.onClick ? await error.onClick() : resetBoundary();
    };
    return (
        <>
            <div className="container error-display">
                <div className="error-text">
                    <Icon name="badge-stop" size="xlarge"></Icon>
                    <h1>{error.message}</h1>
                    {error.code && <h3>{t('status.Error')} {error.code}</h3>}
                </div>
                <Button onClick={handleClick} className="button">
                    {error.showMessage ?? t('actions.TryAgain')}
                </Button>
            </div>
        </>
    );
};

export default ErrorFallback;
