import { AxiosHttpPost } from 'axios-http';
import { CreatNewRmaModel, CreatNewRmaModelCA, Result } from 'models/repaircenter/detailsRma';
import { REPAIR_CENTER } from 'resources/links';
import { REPAIR_CENTER_CA } from 'resources/links';
import Cookies from 'universal-cookie';

// export function getDialyReport() {
//     return AxiosHttpPost({
//         url: REPAIR_CENTER.getDialyReport
//     });
// }

export function createRma(newRma: CreatNewRmaModel) {
    const header = { 'Content-Type': 'application/json', Accept: '*/*', Authorization: `Bearer ${new Cookies().get('info')}` };

    return AxiosHttpPost({ url: REPAIR_CENTER.createRma, data: newRma, headers: header });
}
export function getRmaDetailsCA(newRma: Result) {
    return AxiosHttpPost({ url: REPAIR_CENTER_CA.getRmaDetailsCA, data: newRma });
}
export function createRmaCA(newRma: Result) {
    return AxiosHttpPost({ url: REPAIR_CENTER_CA.createRmaCA, data: newRma });
}
export function uploadRmaFile(formFile: File| undefined, ssoId: string| undefined) {
    return AxiosHttpPost({
        url: REPAIR_CENTER.uploadRmaFile,
        headers: { Accept: '*/*', 'Content-Type': 'multipart/form-data' },
        data: { formFile, ssoId }
    });
}
