import { ChatSidebar } from 'components';
import SparePartsChat from './SparePartsChat';
import { useEffect, useState } from 'react';
import { COMPANY_MANAGER, SPARE_PARTS } from 'resources';
import { SparePartsChatTable } from 'models/repaircenter/detailsRma';
import useFetch from 'helpers/hooks/useFetch';
import useUser from 'helpers/hooks/useUser';
import { CompanyFilterDTO } from 'models';
import { useRecoilState } from 'recoil';
import { ChatSpareParts } from 'resources/atoms/spareParts';

const SparePartsAdminChat = () => {
    const [currentChat, setCurrentChat] = useState<number>();
    const { user } = useUser();
    const [chatsSpareParts, setChatsSpareParts] = useRecoilState<SparePartsChatTable[]>(ChatSpareParts)
    const [chats, loadChats, loading, updateChat] = useFetch<{ roleId: number, ssoId: string, companyId: number}, SparePartsChatTable[]>({
        url: SPARE_PARTS.getAllChats
    });

    const [companyFilter, getCompanies, loadingData, setFilter] = useFetch<CompanyFilterDTO, CompanyFilterDTO>({
        url: COMPANY_MANAGER.getCompanies,
        method: 'POST',
        onError: 'LoadInfo'
    });
    const handleData = async (indice = 1): Promise<void> => {
        getCompanies({ ...companyFilter?.data, indice, CompanyId: Array.from(new Set(chatsSpareParts?.map(item => item?.companyId) || [])) });

    };

    useEffect(() => {
        handleData();
       
    }, []);

   
    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            {!loading && (
                <>
                    {<ChatSidebar
                        company = {companyFilter?.data?.companies!}
                        items={chatsSpareParts?.map(e => ({ ...e, id: e.orderId }))}
                        onChange={(id: number) => {
                            setChatsSpareParts(old => [...old].map(e => (e?.orderId === id ? { ...e, itWasReadByAdmin: true } : e)));
                            setCurrentChat(id);    
                        }}
                    />}
                    {currentChat && <SparePartsChat orderId={currentChat} company={companyFilter?.data?.companies!}/>}
                </>
            )}
        </div>
    );
};

export default SparePartsAdminChat;
