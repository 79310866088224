import { Checkbox, Icon, Modal, Button, Divider, Input } from "@scuf/common";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LabelDescription from "./labelDescription";
import { SatisfactionSurvey } from "models/repaircenter/satisfactionSurvey";
import { REPAIR_CENTER, satisfactionSurveyAtom } from "resources";
import useFetch from "helpers/hooks/useFetch";
import useUser from "helpers/hooks/useUser";
import { useRecoilState } from "recoil";
import RatingComponent from "./ratingComponent";

export const SatisfactionSurveyModal: React.FC = () => {
    const { user: userDetails } = useUser();
    const [modalState, setModalState] = useState<boolean>(false);
    const { t } = useTranslation(['repaircenter', 'common']);
    const [satisfactionSurvey] = useRecoilState(satisfactionSurveyAtom);
    const [surveyModel, setSurveyModel] = useState<SatisfactionSurvey>();
    const [editRatings, setEditRatings] = useState<boolean[]>([true, true, true, true, true]);
    const openModal = () => {
        if (satisfactionSurvey && satisfactionSurvey?.length > 0) {
            setModalState(true);
        }
    };
    const [, sendSatisfactionSurvey, sendingSatisfactionSurvey] = useFetch<SatisfactionSurvey>({
        url: REPAIR_CENTER.updateSatisfactionSurvey,
        method: 'POST',
        onError: 'Send',
        onSuccess: 'Send'
    });


    useEffect(() => {
        openModal()
    }, [satisfactionSurvey])
    return (
        <div style={{ display: 'flex', flexDirection: 'row', gap: '12px' }}>
            <Modal className="no-padding" size="large" style={{width:'80%', height:'88%', overflow: 'auto'  }} open={modalState} closeIcon={true} onClose={() => setModalState(false)}>
                <div className="modalConsultWaranty" >
                    <Modal.Header>
                        <div className="modalHeader">
                            <h2> {t('repaircenter:satisfaction_survey.Name') + t(': RMA #') + satisfactionSurvey?.[0]?.rmaId} </h2>
                            <h2> {t('#')} </h2>
                        </div>
                    </Modal.Header>
                    <Modal.Content>
                        <div className="no-padding"  style={{  margin: '12px', marginTop: '-20px', marginBottom:'1200px' }}>
                            <div >
                                <h3> {t('satisfaction_survey.translation.RatingScale')} </h3>
                            </div>
                            <Divider style={{ margin: 'px 0' }}></Divider>
                            <LabelDescription title={t("satisfaction_survey.translation.Question1")}>
                                <div>
                                <RatingComponent
                                        value={surveyModel?.ReturnTime!}
                                        totalStars={5}
                                        onChange={value => setSurveyModel({ ...surveyModel!, ReturnTime: value })}
                                        disabled={!editRatings[0]}
                                    />
                                   <Checkbox 
                                        label="Não se aplica" 
                                        toggle={true} 
                                        onChange={(toggle: boolean) => {
                                            if (toggle) {
                                                setSurveyModel({ ...surveyModel!, ReturnTime: 'n/a' });
                                                setEditRatings([false, true, true, true, true]);
                                            } else {
                                                setSurveyModel({ ...surveyModel!, ReturnTime: undefined });
                                                setEditRatings([true, true, true, true, true]);
                                            }
                                        }} 
                                    />

                                </div>
                            </LabelDescription>
                            <Divider style={{ margin: '7px 0' }}></Divider>
                            <LabelDescription title={t("satisfaction_survey.translation.Question2")}>
                                <div>
                                    <RatingComponent
                                        value={surveyModel?.RepairQuality!}
                                        totalStars={5}
                                        onChange={value => setSurveyModel({ ...surveyModel!, RepairQuality: value })}
                                        disabled={!editRatings[1]}
                                    />
                                       <Checkbox 
                                            label="Não se aplica" 
                                            toggle={true} 
                                            onChange={(toggle: boolean) => {
                                                if (toggle) {
                                                    setSurveyModel({ ...surveyModel!, RepairQuality: 'n/a' });
                                                    setEditRatings([true, false, true, true, true]);
                                                } else {
                                                    setSurveyModel({ ...surveyModel!, RepairQuality: undefined });
                                                    setEditRatings([true, true, true, true, true]);
                                                }
                                            }} 
                                        />
                                </div>
                            </LabelDescription>
                            <Divider style={{ margin: '7px 0' }}></Divider>
                            <LabelDescription title={t("satisfaction_survey.translation.Question3")}>
                                <div>
                                <RatingComponent
                                        value={surveyModel?.ServiceReceived!}
                                        totalStars={5}
                                        onChange={value => setSurveyModel({ ...surveyModel!, ServiceReceived: value })}
                                        disabled={!editRatings[2]}
                                    />
                                       <Checkbox 
                                            label="Não se aplica" 
                                            toggle={true} 
                                            onChange={(toggle: boolean) => {
                                                if (toggle) {
                                                    setSurveyModel({ ...surveyModel!, ServiceReceived: 'n/a' });
                                                    setEditRatings([true, true, false, true, true]);
                                                } else {
                                                    setSurveyModel({ ...surveyModel!, ServiceReceived: undefined });
                                                    setEditRatings([true, true, true, true, true]);
                                                }
                                            }} 
                                        />
                                </div>
                            </LabelDescription>
                            <Divider style={{ margin: '7px 0' }}></Divider>
                            <LabelDescription title={t("satisfaction_survey.translation.Question4")}>
                                <div>
                                <RatingComponent
                                        value={surveyModel?.ProblemSolution!}
                                        totalStars={5}
                                        onChange={value => setSurveyModel({ ...surveyModel!, ProblemSolution: value })}
                                        disabled={!editRatings[3]}
                                    />
                                       <Checkbox 
                                            label="Não se aplica" 
                                            toggle={true} 
                                            onChange={(toggle: boolean) => {
                                                if (toggle) {
                                                    setSurveyModel({ ...surveyModel!, ProblemSolution: 'n/a' });
                                                    setEditRatings([true, true, true, false, true]);
                                                } else {
                                                    setSurveyModel({ ...surveyModel!, ProblemSolution: undefined });
                                                    setEditRatings([true, true, true, true, true]);
                                                }
                                            }} 
                                        />
                                </div>
                            </LabelDescription>
                            <Divider style={{ margin: '7px 0' }}></Divider>
                            <LabelDescription title={t("satisfaction_survey.translation.Question5")}>
                                <div>
                                <RatingComponent
                                        value={surveyModel?.PortalExperience!}
                                        totalStars={5}
                                        onChange={value => setSurveyModel({ ...surveyModel!, PortalExperience: value })}
                                        disabled={!editRatings[4]}
                                    />
                                       <Checkbox 
                                            label="Não se aplica" 
                                            toggle={true} 
                                            onChange={(toggle: boolean) => {
                                                if (toggle) {
                                                    setSurveyModel({ ...surveyModel!, PortalExperience: 'n/a' });
                                                    setEditRatings([true, true, true, true, false]);
                                                } else {
                                                    setSurveyModel({ ...surveyModel!, PortalExperience: undefined });
                                                    setEditRatings([true, true, true, true, true]);
                                                }
                                            }} 
                                        />
                                </div>
                            </LabelDescription>
                            <Divider style={{ margin: '7px 0' }}></Divider>
                            <LabelDescription title={t("satisfaction_survey.translation.Question6")}>
                                <Checkbox
                                    checked={surveyModel?.ContactPhone}
                                    className='modalConsultWaranty'
                                    label={t("common:actions.Yes")}
                                    toggle={true}
                                    onChange={(value: boolean) => setSurveyModel({ ...surveyModel!, ContactPhone: value })}
                                />
                            </LabelDescription >
                            <Divider style={{ margin: '7px 0' }}></Divider>
                            <LabelDescription title={t("common:translation.Comments")}>
                                <Input
                                    className="form3Columns"
                                    placeholder={t('common:placeholders.Input')}
                                    maxLength={200}
                                    onChange={value => setSurveyModel({ ...surveyModel!, Comments: value })}
                                />
                            </LabelDescription>
                            <div className="buttonsSearch" style={{  margin: '12px', marginBottom: '20px'}}>
                                <Button
                                    type="inline-secondary"
                                    content={t('satisfaction_survey.translation.NotAnswer')}
                                    iconPosition="right"
                                    loading={sendingSatisfactionSurvey}
                                    onClick={async () => {
                                        await sendSatisfactionSurvey({
                                            id: satisfactionSurvey?.[0]?.id,
                                            rmaId: satisfactionSurvey?.[0]?.rmaId,
                                            Answered: false,
                                            SsoId: userDetails?.SSOID.toString(),
                                        });
                                        setModalState(false)
                                    }}
                                />
                                <Button
                                    type="primary"
                                    icon={<Icon name="double-caret-right" />}
                                    content={t('common:actions.Send')}
                                    disabled={
                                        surveyModel?.PortalExperience === undefined
                                        || surveyModel?.ProblemSolution === undefined
                                        || surveyModel?.RepairQuality === undefined
                                        || surveyModel?.ReturnTime === undefined
                                        || surveyModel?.ServiceReceived === undefined
                                    }
                                    iconPosition="right"
                                    onClick={async () => {

                                        await sendSatisfactionSurvey({
                                            ...surveyModel,
                                            id: satisfactionSurvey?.[0]?.id,
                                            rmaId: satisfactionSurvey?.[0]?.rmaId,
                                            SsoId: userDetails?.SSOID,
                                            Answered: true
                                        });
                                        setModalState(false)
                                    }}
                                />
                            </div>
                            <Divider style={{ margin: 'px 0' }}></Divider>
                        </div>
                        <div />
                    </Modal.Content>
                </div>
            </Modal>
        </div>
    );
};