import axios from 'axios';
import { RmaFilterModel } from 'models';
import Cookies from 'universal-cookie';

export type HttpParams = {
    url: string;
    headers?: any;
    data?: any;
};
export type HttpResponse = {
    status: number;
    error: boolean;
    message: string;
    data: any;
};

export type ReportHttpResponse = {
    Status: number;
    Exception: string;
    Result: any;
    Message: string;
};

export type HttpResponseArquivo = {
    status: number;
    data: any;
};
export type HttpResponseRmaReport = {
    status: number;
    data: RmaFilterModel[];
};


const header = { 'Content-Type': 'application/json', Accept: '*/*', Authorization: `Bearer ${new Cookies().get("info")}` };

export async function AxiosHttpPost({ url, headers = header, data }: HttpParams): Promise<HttpResponse> {
    try {
        const response = await axios.request({
            method: 'POST',
            url,
            headers:{...headers,"Authorization":`Bearer ${new Cookies().get("info")}`}, 
            data
        });
        return {
            status: response.status,
            error: response.data.error,
            message: response.data.message ?? '',
            data: response.data.data
        };
    } catch (e: any) {
        return {
            status: e?.response?.status,
            error: true,
            message: e?.response?.statusText,
            data: undefined
        };
    }
}


export async function AxiosHttpPostReport({ url, headers = header, data }: HttpParams): Promise<ReportHttpResponse> {
    try {
        const response = await axios.request({
            method: 'POST',
            url,
            headers:{...headers,"Authorization":`Bearer ${new Cookies().get("info")}`}, 
            data
        });
        return {
            Status: response.status,
            Exception: response.data.Exception,
            Message: response.data.Message ?? '',
            Result: response.data.Result
        };
    } catch (e: any) {
        return {
            Status: e?.response?.status,
            Exception: e?.response.Exception,
            Message: e?.response?.statusText,
            Result: undefined
        };
    }
}

export async function AxiosHttpGetReport({ url, headers = header, data }: HttpParams): Promise<ReportHttpResponse> {
    try {
        const response = await axios.request({
            method: 'GET',
            url,
                    headers:{...headers,"Authorization":`Bearer ${new Cookies().get("info")}`}, 

            params: data
        });
        return {
            Status: response.status,
            Exception: '',
            Message: '',
            Result: response.data
        };
    } catch (e: any) {
        return {
            Status: e?.response?.status,
            Exception: e?.response.Exception,
            Message: e?.response?.statusText,
            Result: undefined
        };
    }
}

export async function AxiosHttpPostFile({ url, headers = header, data }: HttpParams): Promise<HttpResponseArquivo> {
    try {
        const response = await axios.request({
            method: 'POST',
            responseType: 'blob',
            url,
            headers:{...headers,"Authorization":`Bearer ${new Cookies().get("info")}`}, 
            data,
        });
        return {
            status: response.status,
            data: response.data
        };
    } catch (e: any) {
        return {
            status: e.response?.status,
            data: undefined
        };
    }
}

export async function AxiosHttpGet({ url, headers = header, data }: HttpParams): Promise<HttpResponse> {
    try {
        const resp = await axios.request({
            method: 'GET',
            url,
            headers:{...headers,"Authorization":`Bearer ${new Cookies().get("info")}`}, 
            params: data
        });
        return {
            status: resp.status,
            error: resp.data.Error,
            message: resp.data.message ?? '',
            data:resp.data.data
        };
    } catch (e: any) {
        throw new Error(e.message);
    }
}

export async function AxiosFile({url, param, downloadName }: {url: string, param: any, downloadName: string}) {
    try {
        const resp = await axios.get(url, {headers: header, params: param, responseType: 'blob'});
        const fileurl = URL.createObjectURL(new Blob([resp.data]));
        // Cria um link âncora invisível
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = fileurl;
        link.setAttribute('download', downloadName); // Define o atributo 'download' para iniciar o download
        // Adiciona o link ao documento e clica nele para iniciar o download
        document.body.appendChild(link);
        link.click();
        // Remove o link do documento
        document.body.removeChild(link);
    }
    catch(error: any){
    }
}

export async function AxiosDownloadFile({ url, param, downloadName }: { url: string, param: any, downloadName: string }) {
    try {
        const resp = await axios.post(url, param, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Accept': '*/*',
                'Authorization': `Bearer ${document.cookie.replace(/(?:(?:^|.*;\s*)info\s*\=\s*([^;]*).*$)|^.*$/, "$1")}`
            },
            responseType: 'blob'
        });

        const fileurl = URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = fileurl;
        link.setAttribute('download', downloadName); // Define o atributo 'download' para iniciar o download
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error: any) {
        console.error("Error downloading the file", error);
    }
}
