import { RmaOption, RmaRequest, RmaStatusCA, TrueFalse, YesNo, modelShippingCondition, statusOptions, statusRmaCA, typeOptions } from 'models';

export const getRmaStatus = (num: number): RmaOption | undefined => {
    return statusOptions.at(num - 1);
};
export const getRmaType = (num: number): RmaOption | undefined => {
    return typeOptions.at(num - 1);
};
export const getRmaOptions = (num: number): TrueFalse | undefined => {
    return YesNo.at(num-1);
}

export const getRmaStatusCA = (num: number): RmaStatusCA | undefined =>{
    return statusRmaCA.at(num-1);
}
export const getShippingCondition = (num:number): RmaRequest| undefined =>{
    return modelShippingCondition.at(num-1);
}

