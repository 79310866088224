import { Icon } from '@scuf/common';
import React, { useEffect } from 'react';
type AlertProps = {
    text: string;
    icon?: 'important' | 'help' | 'info' | 'check' | 'stop';
    className?: string;
};
const Alert: React.FC<AlertProps> = ({ text, icon = 'important', className }: AlertProps) => {
    useEffect(() => {});
    return (
        <div className={[`div-alert-os`, className].join(' ')}>
            <Icon root="common" name={`badge-${icon}`} color="#f37021" size="xlarge" />
            <h5>{text}</h5>
        </div>
    );
};

export default Alert;
