import { AuthenticationError } from "helpers/error";
import { UserCookie } from "models";
import { CustomErrorBoundary } from "models/error/errorModel";
import IPermissionValidator from "./IPermissionValidator";
 
class UserAuthenticationValidator implements IPermissionValidator {
    validate(user: UserCookie | null): boolean {
         return !!user;
    }
    trhow(): CustomErrorBoundary {
        throw new AuthenticationError();
    }
    
}

export default UserAuthenticationValidator