import { Button, Card, Icon } from '@scuf/common';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import 'styles/main.scss';

function AboutContainer() {
    const { t } = useTranslation(['common', 'home']);

    return (
        <div className="container">
            <h1>{t('common:translation.About')}</h1>
            <div className="cards-container-2">
                <Link to="info/spare-parts">
                    <Card>
                        <Card.Header title={t('pages:spareParts')}></Card.Header>
                        <Card.Content>
                            <Icon className="largeIcon" name="briefcase" root="common" exactSize={150} />
                            {/* <p>{t('home:maintenance.info')}</p> */}
                        </Card.Content>
                        <Card.Footer>
                            <Button
                                type="link"
                                icon={<Icon className="iconArrow" name="caret-right" root="common" />}
                                iconPosition="right"
                                content={t('actions.LearnMore')}
                            />
                        </Card.Footer>
                    </Card>
                </Link>



                <Link to="/info/solutions">
                    <Card>
                        <Card.Header title={t('services.Solutions')}></Card.Header>
                        <Card.Content>
                            <Icon className="largeIcon" name="briefcase" root="common" color="blue" exactSize={150} />
                            {/* <p>{t('home:solutions.info')}</p> */}
                        </Card.Content>
                        <Card.Footer>
                            <Button
                                type="link"
                                icon={<Icon className="iconArrow" name="caret-right" root="common" />}
                                iconPosition="right"
                                content={t('actions.LearnMore')}
                            />
                        </Card.Footer>
                    </Card>
                </Link>

            </div>
        </div>
    );
}

export default AboutContainer;
