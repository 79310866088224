import { AxiosHttpGet, AxiosHttpPost } from 'axios-http';
import { REPAIR_CENTER } from 'resources/links';

export function getOsDetails(data: { id: number; rmaId: number }) {
    return AxiosHttpGet({
        url: REPAIR_CENTER.getOsDetails,
        data: data
    });
}

export function updateStatusOS(data: { numberOS: string | number }) {
    return AxiosHttpPost({
        url: REPAIR_CENTER.updateStatusOS,
        data
    });
}
