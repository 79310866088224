import { Divider, List } from '@scuf/common';
import usePreventClickjacking from 'helpers/hooks/usePreventingClickjacking';
import { useTranslation } from 'react-i18next';

const Overview: React.FC = () => {
    const { t } = useTranslation('manual');

    usePreventClickjacking();
    return (
        <div>
            <h3>{t('visao-geral')}</h3>
            <p>
                {t(
                    'uma-requisicao-e-uma-solicitacao-que-envolve-um-requitante-que-faz-a-requisicao-e-um-relator-que-responde-a-requisicao-esse-portal-de-servicos-atualmente-suporta-dois-tipos-de-requisicoes'
                )}
            </p>
            <List ordered>
                <List.Content>
                    <h3>{t('revisao-de-preco-de-cotacao')}</h3>
                    <p>{t('solicitacao-de-revisao-dos-precos-dos-produtos-de-uma-cotacao')}</p>
                </List.Content>
                <List.Content>
                    <h3>{t('solicitacao-de-acesso')}</h3>
                    <p>{t('solicitacao-de-acesso-aos-sistemas-internos-da-companhia')}</p>
                </List.Content>
            </List>
            <p>
                {t(
                    'uma-requisicao-pode-ser-gerada-atraves-do-portal-de-servicos-ou-importada-de-outros-sistemas-internos-da-companhia-sip-e-ou-atendimento-por-exemplo-a-funcao-do-portal-quando-uma-requisicao-e-importada-e-notificar-e-obter-a-resposta-do-relator-e-em-seguida-informar-a-fonte-da-requisicao-que-a-mesma-ja-foi-respondida'
                )}
            </p>
            <p>{t('todas-as-requisicoes-compartilham-um-conjunto-comum-de-propriedades-conforme-listado-abaixo')}</p>
            <List ordered>
                <List.Content>
                    <h3>ID</h3>
                    <p>{t('indentificador-da-requisicao-gerado-no-portal')}</p>
                </List.Content>
                <List.Content>
                    <h3>{t('tipo')}</h3>
                    <p>{t('identifica-o-tipo-da-requisicao')}</p>
                </List.Content>
                <List.Content>
                    <h3>{t('data')}</h3>
                    <p>{t('data-em-que-a-requisicao-foi-criada-ou-importada')}</p>
                </List.Content>
                <List.Content>
                    <h3>{t('status')}</h3>
                    <p>{t('indique-em-que-estagio-do-ciclo-de-vida-a-requisicao-se-encontra')}</p>
                </List.Content>
                <List.Content>
                    <h3>{t('token-de-acesso')}</h3>
                    <p>
                        {t(
                            'identificador-alfanumerico-que-permite-ao-relator-responder-a-uma-requisicao-sem-estar-logado-no-portal'
                        )}
                    </p>
                </List.Content>
                <List.Content>
                    <h3>{t('validade')}</h3>
                    <p>{t('o-periodo-em-que-o-token-de-acesso-se-encontra-ativo')}</p>
                </List.Content>
                <List.Content>
                    <h3>{t('nome-do-requisitante')}</h3>
                    <p>{t('nome-da-pessoa-que-criou-a-requisicao')}</p>
                </List.Content>
                <List.Content>
                    <h3>{t('e-mail-do-requisitante')}</h3>
                    <p>{t('e-mal-da-pessoa-que-criou-a-requisicao')}</p>
                </List.Content>
                <List.Content>
                    <h3>{t('nome-do-relator')}</h3>
                    <p>{t('nome-da-pessoa-que-e-responsavel-por-responder-a-requisicao')}</p>
                </List.Content>
                <List.Content>
                    <h3>{t('e-mail-do-relator-0')}</h3>
                    <p>{t('e-mal-da-pessoa-que-responsavel-por-responder-a-requisicao-0')}</p>
                </List.Content>
            </List>
            <Divider />
            <h3>{t('ciclo-de-vida')}</h3>
            {t(
                'cada-tipo-de-requisicao-possui-um-ciclo-de-vida-independente-no-entanto-todas-as-requisicoes-utilizam-o-mesmos-conjunto-de-status-conforme-apresentado-abaixo'
            )}
            <List ordered>
                <List.Content>
                    <h3>{t('indefinido')}</h3>
                    <p>{t('indica-algum-erro-de-sincronizacao')}</p>
                </List.Content>
                <List.Content>
                    <h3>{t('aguardando-envio-de-notificacao')}</h3>
                    <p>{t('indica-que-o-relator-da-requisicao-sera-notificado-em-breve')}</p>
                </List.Content>
                <List.Content>
                    <h3>{t('aguardando-resposta-do-relator')}</h3>
                    <p>{t('indica-que-a-requisicao-esta-aguardando-a-resposta-do-relator')}</p>
                </List.Content>
                <List.Content>
                    <h3>{t('aguardando-sincronizacao')}</h3>
                    <p>{t('a-requisicao-sera-sincronizada-em-breve-com-algum-outro-sistema-externo')}</p>
                </List.Content>
                <List.Content>
                    <h3>{t('finalizado')}</h3>
                    <p>{t('a-requisicao-foi-finalizada-e-nao-pode-ser-mais-alterada')}</p>
                </List.Content>
            </List>
        </div>
    );
};

export default Overview;
