import { AxiosHttpGet, AxiosHttpPost } from 'axios-http';
import { RmaDetailModel, RmaFilterModel, RmaModel } from 'models';
import { REPAIR_CENTER } from 'resources/links';
import { REPAIR_CENTER_CA } from 'resources/links';

export function getRmas(intemPerPage: number, page: number = 1) {
    // const data = {
    //     RmaId: '',
    //     SearchInCustomer: '',
    //     DateStart: null,
    //     DateEnd: null,
    //     NumberNFe: '',
    //     AutorizeType: null,
    //     StatusId: '',
    //     SerialNumber: '',
    //     TypeId: '',
    //     OsNumber: '',
    //     ShipmentRequested: '',
    //     ItemsByPage: 1000,
    //     Indice: 1,
    //     MaxIndice: 100,
    //     TotalResults: '',
    //     Result: []
    // };
    const data = {
        // Acessorios: '',
        // RmaId:'',
        // CnpjNota: '',
        // Observacao: '',
        // RmaItems: [],
        // UserId: null,
        // ValorInvoice: '',
        // SerialNumber: '',
    };
    let res = AxiosHttpPost({ url: REPAIR_CENTER.getRmas, data: data });
    return res;
}
export function getRmasLite(filter?: RmaFilterModel) {
    return AxiosHttpPost({ url: REPAIR_CENTER.getRmasLite, data: filter });
}
export function getRmasCA(intemPerPage: number, page: number = 1) {
    const data = {
        AutorizeType: null,
        DateEnd: null,
        DateStart: null,
        Indice: 1,
        ItemsByPage: 1000,
        OsNumber: '',
        Result: [],
        RmaId: '',
        SerialNumber: '',
        ShipmentRequested: '',
        StatusId: '',
        TotalResults: '',
        TypeId: ''
    };
    return AxiosHttpPost({ url: REPAIR_CENTER_CA.getRmasCA, data: data });
}
export function getRmaFilter(
    filter?: RmaFilterModel
    // indice: number,
    // rmaId?: number,
    // numberSerie?: string,
    // dateStart?: Date,
    // dateEnd?: Date,
    // statusId?: number,
    // typeId?: number,
    // itemByPage?: number
) {
    // const data = {
    //     RmaId: '',
    //     SearchInCustomer: '',
    //     DateStart: null,
    //     DateEnd: null,
    //     NumberNFe: '',
    //     AutorizeType: null,
    //     StatusId: '',
    //     SerialNumber: '',
    //     TypeId: '',
    //     OsNumber: '',
    //     ShipmentRequested: '',
    //     ItemsByPage: 1000,
    //     Indice: 1,
    //     MaxIndice: 100,
    //     TotalResults: '',
    //     Result: []
    // };

    // const data = {
    //     Indice: indice,
    //     Id: rmaId,
    //     DateStart: dateStart,
    //     DateEnd: dateEnd,
    //     StatusId: statusId,
    //     SerialNumber: numberSerie,
    //     ItemsByPage: itemByPage,
    //     TypeId: typeId
    // };

    let res = AxiosHttpPost({ url: REPAIR_CENTER.getRmas, data: filter });
    return res;
}
export function getRmaFilterCA(
    rmaNumber?: any,
    serialNumber?: any,
    typeIdSet?: any,
    //DateStart: null,
    //DateEnd: null,
    date?: any,
    stOptions?: any,
    serviceOrder?: any,
    itemByPage?: any
) {
    const data = {
        RmaId: rmaNumber,
        SearchInCustomer: '',
        DateStart: null,
        DateEnd: null,
        NumberNFe: '',
        AutorizeType: null,
        StatusId: stOptions,
        SerialNumber: serialNumber,
        TypeId: typeIdSet,
        OsNumber: '',
        ShipmentRequested: '',
        ItemsByPage: 1000,
        Indice: 1,
        MaxIndice: 100,
        TotalResults: '',
        Result: []
    };
    return AxiosHttpPost({ url: REPAIR_CENTER_CA.getRmasCA, data: data });
}

export function updateInvoice(newRma: RmaModel) {
    return AxiosHttpPost({
        url: REPAIR_CENTER.updateInvoice,
        data: newRma
    });
}
