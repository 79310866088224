import { Divider, Table } from '@scuf/common';
import usePreventClickjacking from 'helpers/hooks/usePreventingClickjacking';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'styles/main.scss';

function ContractsPage() {
    const [width, setWidth] = useState<number>(0);

    const { t } = useTranslation(['contracts']);

    function handleResize() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        document.title = 'Centro de Reparos - Portal Honeywell';
        setWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
    }, []);

    usePreventClickjacking();

    return (
        <>
            <div className="container">
                <div>
                    <div className="contentPageContainer">
                        <div className="container-grande">
                            <h4>{t('contracts:translation.title1')}</h4>
                            <p>{t('contracts:translation.text1')}</p>
                            <h4>{t('contracts:translation.title2')}</h4>
                            <p>{t('contracts:translation.text2')}</p>
                            <Divider />
                            <h4>{t('contracts:translation.titleGold1')}</h4>
                            <p>
                                <li>{t('contracts:translation.list1')}</li>
                                <li>{t('contracts:translation.list2')}</li>
                                <li>{t('contracts:translation.list3')}</li>
                                <li>{t('contracts:translation.list4')}</li>
                                <li>{t('contracts:translation.list5')}</li>
                                <li>{t('contracts:translation.list6')}</li>
                                <li>{t('contracts:translation.list7')}</li>
                                <li>{t('contracts:translation.list8')}</li>
                            </p>
                            <Divider />
                            <h4>{t('contracts:translation.Add')}</h4>
                            <div className="column-container">
                                <ul className="column">
                                    <p>
                                        <li>{t('contracts:translation.add1')}</li>
                                        <li>{t('contracts:translation.add2')}</li>
                                        <li>{t('contracts:translation.add3')}</li>
                                        <li>{t('contracts:translation.add4')}</li>
                                        <li>{t('contracts:translation.add5')}</li>
                                        <li>{t('contracts:translation.add6')}</li>
                                    </p>
                                </ul>
                                <ul className="column">
                                    <p>
                                        <li>{t('contracts:translation.add7')}</li>
                                        <li>{t('contracts:translation.add8')}</li>
                                        <li>{t('contracts:translation.add9')}</li>
                                        <li>{t('contracts:translation.add10')}</li>
                                        <li>{t('contracts:translation.add11')}</li>
                                    </p>
                                </ul>
                            </div>
                            <Divider />
                            <h4>{t('contracts:translation.titlePlatinum')}</h4>
                            <div className="column-container">
                                <ul className="column">
                                    <p>
                                        <li>{t('contracts:translation.platinum1')}</li>
                                        <li>{t('contracts:translation.platinum2')}</li>
                                        <li>{t('contracts:translation.platinum3')}</li>
                                        <li>{t('contracts:translation.platinum4')}</li>
                                        <li>{t('contracts:translation.platinum5')}</li>
                                        <li>{t('contracts:translation.platinum6')}</li>
                                        <li>{t('contracts:translation.platinum7')}</li>
                                        <li>{t('contracts:translation.platinum8')}</li>
                                    </p>
                                </ul>
                                <ul className="column">
                                    <p>
                                        <p>{t('contracts:translation.PlatinumAdd')}</p>
                                        <li>{t('contracts:translation.platinumadd1')}</li>
                                        <li>{t('contracts:translation.platinumadd2')}</li>
                                        <li>{t('contracts:translation.platinumadd3')}</li>
                                        <li>{t('contracts:translation.platinumadd4')}</li>
                                        <li>{t('contracts:translation.platinumadd6')}</li>
                                        <li>{t('contracts:translation.platinumadd7')}</li>
                                        <li>{t('contracts:translation.platinumadd8')}</li>
                                    </p>
                                </ul>
                            </div>
                            <Table>
                                <Table.Header>
                                    <Table.HeaderCell content={t('common:translation.Item')} />
                                    <Table.HeaderCell content={''} />
                                    <Table.HeaderCell content={t('common:translation.Description')} />
                                    <Table.HeaderCell content={t('contracts:translation.coberturaP')} />
                                    <Table.HeaderCell content={t('contracts:translation.CoberturaG')} />
                                </Table.Header>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell>{1}</Table.Cell>
                                        <Table.Cell>{''} </Table.Cell>
                                        <Table.Cell>{'SLA de atendimento (ao chegar no laboratório)'} </Table.Cell>
                                        <Table.Cell>{'3 Dias'} </Table.Cell>
                                        <Table.Cell>{'5 Dias'} </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>{2}</Table.Cell>
                                        <Table.Cell>{''} </Table.Cell>
                                        <Table.Cell>{'Cobertura de peças e serviços'} </Table.Cell>
                                        <Table.Cell>{'X'} </Table.Cell>
                                        <Table.Cell>{'X'} </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>{3}</Table.Cell>
                                        <Table.Cell>{''} </Table.Cell>
                                        <Table.Cell>{'Cobertura de acessórios'} </Table.Cell>
                                        <Table.Cell>{'X'} </Table.Cell>
                                        <Table.Cell>{'-'} </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>{4}</Table.Cell>
                                        <Table.Cell>{''} </Table.Cell>
                                        <Table.Cell>{'Substituição de itens de consumo'} </Table.Cell>
                                        <Table.Cell>{'X'} </Table.Cell>
                                        <Table.Cell>{'-'} </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell> </Table.Cell>
                                        <Table.Cell>{'4.1'} </Table.Cell>
                                        <Table.Cell>{'Bateria'} </Table.Cell>
                                        <Table.Cell>{'X'} </Table.Cell>
                                        <Table.Cell>{'-'} </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>{''}</Table.Cell>
                                        <Table.Cell>{'4.2'} </Table.Cell>
                                        <Table.Cell>{'Cabeça de impressão'} </Table.Cell>
                                        <Table.Cell>{'X'} </Table.Cell>
                                        <Table.Cell>{'-'} </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>{''}</Table.Cell>
                                        <Table.Cell>{'4.3'} </Table.Cell>
                                        <Table.Cell>{'Cutter'} </Table.Cell>
                                        <Table.Cell>{'X'} </Table.Cell>
                                        <Table.Cell>{'-'} </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>{5}</Table.Cell>
                                        <Table.Cell>{''} </Table.Cell>
                                        <Table.Cell>{'Frete envio e retorno'} </Table.Cell>
                                        <Table.Cell>{'X'} </Table.Cell>
                                        <Table.Cell>{'X'} </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>{6}</Table.Cell>
                                        <Table.Cell>{''} </Table.Cell>
                                        <Table.Cell>{'Suporte Técnico Telefônico'} </Table.Cell>
                                        <Table.Cell>{'X'} </Table.Cell>
                                        <Table.Cell>{'X'} </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>{7}</Table.Cell>
                                        <Table.Cell>{''} </Table.Cell>
                                        <Table.Cell>{'Acesso ao portal de serviços Honeywell'} </Table.Cell>
                                        <Table.Cell>{'X'} </Table.Cell>
                                        <Table.Cell>{'X'} </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>{8}</Table.Cell>
                                        <Table.Cell>{''} </Table.Cell>
                                        <Table.Cell>{'Substituição de itens de consumo'} </Table.Cell>
                                        <Table.Cell>{'X'} </Table.Cell>
                                        <Table.Cell>{'-'} </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>{9}</Table.Cell>
                                        <Table.Cell>{''} </Table.Cell>
                                        <Table.Cell>{'Cobertura de mau uso*'} </Table.Cell>
                                        <Table.Cell>{'X'} </Table.Cell>
                                        <Table.Cell>{'X'} </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>{''}</Table.Cell>
                                        <Table.Cell>{'9.1'} </Table.Cell>
                                        <Table.Cell>{'Cobertura de uso catastrófico**'} </Table.Cell>
                                        <Table.Cell>{'X'} </Table.Cell>
                                        <Table.Cell>{'-'} </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>{10}</Table.Cell>
                                        <Table.Cell>{''} </Table.Cell>
                                        <Table.Cell>{'Fornecimento de Backup'} </Table.Cell>
                                        <Table.Cell>{'X'} </Table.Cell>
                                        <Table.Cell>{'-'} </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>{11}</Table.Cell>
                                        <Table.Cell>{''} </Table.Cell>
                                        <Table.Cell>{'Serviço Sentinel'} </Table.Cell>
                                        <Table.Cell>{'X'} </Table.Cell>
                                        <Table.Cell>{'-'} </Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>

            {/* <AboutContainer /> */}
        </>
    );
}

export default ContractsPage;
