import { ChatModel, DefaultCart, LastEventModel, ProdutctsModel, SFilesModel } from 'models/spareParts';
import { MicroSigaBillingDTO, OrderCartDTO, OrderDTO, OrderFilterDTO, OrderItemsDTO } from 'models/spareParts/orderById';
import { atom } from 'recoil';
import { persistAtom } from './persistanceAtom';
import { CompanyDTO } from 'models/userManager/addCustomer';
import { ChatTable, SparePartsChatTable } from 'models/repaircenter/detailsRma';

export const eventosModelState = atom({
    key: 'eventosModelState',
    default: null as unknown as LastEventModel
});

export const chatModelState = atom({
    key: 'chatModelState',
    default: null as unknown as ChatModel
});

export const orderByIdState = atom({
    key: 'orderByIdState',
    default: null as unknown as OrderDTO
});

export const ProdutctsModelState = atom({
    key: 'ProdutctsModelState',
    default: null as unknown as ProdutctsModel
});

export const SfilesModelState = atom({
    key: 'FilesModelState',
    default: null as unknown as SFilesModel
});


export const defaultCart = atom({
    key: 'defaultCart',
    default: null as unknown as DefaultCart
});

export const customerCartAtom = atom({
    key: 'cartItems',
    effects_UNSTABLE: [persistAtom],
    default: null as unknown as OrderDTO
})

export const orderAtom = atom({
    key: 'order',
    default: null as unknown as OrderDTO
})

export const orderRevenusState = atom({
    key: 'Revenues',
    default: null as unknown as MicroSigaBillingDTO
});
export const orderCartAtom = atom({
    key: 'orderCartAtom',
    default: null as unknown as OrderCartDTO
});

export const orderWarrantyCartAtom = atom({
    key: 'orderWarrantyCartAtom',
    default: [] as OrderItemsDTO[]
});

export const orderCartFileAtom = atom({
    key: 'orderCartFileAtom',
    default: null as unknown as File|null
});
export const orderFilterAtom = atom({
    key: 'orderFilterAtom',
    default: null as unknown as OrderFilterDTO
});

export const companyAtom = atom({
    key: 'companyAtom',
    default: null as unknown as CompanyDTO
})
export const ChatSpareParts = atom({
    key: 'ChatSpareParts',
    default: null as unknown as SparePartsChatTable[]
})