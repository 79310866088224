import { Tab } from '@scuf/common';
import { useTranslation } from 'react-i18next';
import 'styles/main.scss';
import 'styles/repaircenter.scss';
import MaintenanceReportSearch from './maintenanceReportSearch';

function MaintenanceReportPage() {
    const { t } = useTranslation();

    return (
        <div>
            <div className="container">
                <div>
                    <Tab defaultActiveIndex={0} className="tab-with-divider">
                        <Tab.Pane title={t('maintenancereport:page_name')}>
                            <MaintenanceReportSearch />
                        </Tab.Pane>
                    </Tab>
                </div>
            </div>
        </div>
    );
}

export default MaintenanceReportPage;
