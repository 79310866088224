import { Checkbox, Divider, Table } from '@scuf/common';
import { GetGroups } from 'models/user/ModulePermissions';
import { CompanyLOBDTO, LobDto, ModuleDTO } from 'models/userManager/addCustomer';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const ACTIVE_MODULE = 1;
type PermissionsProps = {
    modules: ModuleDTO[];
    modulePermissions: GetGroups[];
    userModules?: number[];
    lobs?: LobDto[];
    userPermissions: CompanyLOBDTO[];
    onChangeModules?: (id: number, value?: boolean) => void;
    onChangePermission: (id: number, value?: boolean) => Promise<void>;
};
const Permissions: React.FC<PermissionsProps> = ({
    modules,
    modulePermissions,
    onChangePermission,
    userModules,
    lobs,
    userPermissions,
    onChangeModules
}: PermissionsProps) => {
    const { t } = useTranslation(['usermanager', 'common', 'user']);
    const [loading, setLoading] = useState(false);
    let lastGBE: string = '';

    return (
        <>
            {modules.map(m => (
                <div key={m.id}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Checkbox
                            checked={userModules!.includes(m?.id)}
                            toggle={true}
                            onChange={(value: boolean) => {
                                onChangeModules!(m.id, value);
                            }}
                        />
                        <h3 style={{ marginTop: 0 }}>{`${m?.name}`}</h3>
                    </div>
                    {modulePermissions.map(
                        x =>
                            x.module.id == m.id &&
                            userModules?.includes(m.id) && (
                                <>
                                    <Table>
                                        <Table.Header>
                                            <Table.HeaderCell content={t('common:translation.participant')} />
                                            <Table.HeaderCell content={t('common:translation.Description')} />
                                        </Table.Header>
                                        <Table.Body>
                                            {lobs?.map(
                                                p =>
                                                    p.id != ACTIVE_MODULE && (
                                                        <React.Fragment>
                                                            <Table.Row style={{ display: p?.gbe != lastGBE ? 'block' : 'none' }}>
                                                                <Table.Cell>{`${(lastGBE = p?.gbe)}`}</Table.Cell>
                                                            </Table.Row>
                                                            <Table.Row>
                                                                <Table.Cell>
                                                                    <Checkbox
                                                                        checked={
                                                                            userPermissions.find(x => x.lob.id == p.id) !=
                                                                            undefined
                                                                        }
                                                                        toggle={true}
                                                                        disabled={loading}
                                                                        label={p.lobCode}
                                                                        onChange={async (value: boolean) => {
                                                                            setLoading(true);
                                                                            await onChangePermission(p.id, value);
                                                                            setLoading(false);
                                                                        }}
                                                                    />
                                                                </Table.Cell>
                                                                <Table.Cell>{p.descricao}</Table.Cell>
                                                            </Table.Row>
                                                        </React.Fragment>
                                                    )
                                            )}{' '}
                                        </Table.Body>
                                    </Table>
                                    <Divider />
                                </>
                            )
                    )}
                </div>
            ))}
        </>
    );
};

export default Permissions;
