import { Button, Card, Header, Icon, Input, Loader, Modal, Select } from '@scuf/common';
import { DataTable } from '@scuf/datatable';
import { ICellData } from '@scuf/datatable/dist/components/DataTable/IDataTableInterfaces';
import useFetch from 'helpers/hooks/useFetch';
import useUser from 'helpers/hooks/useUser';
import { CompanyFilterDTO } from 'models';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { COMPANY_MANAGER, PERMISSION_MANAGER, cnpjMask, notificationAtom } from 'resources';

const AdminTabManager: React.FC = () => {
    const { t } = useTranslation(['usermanager', 'common']);
    const setNotification = useSetRecoilState(notificationAtom);
    const [modalState, setModalState] = useState<boolean>(false);
    const [NameGrup, setNameGrup] = useState<string>('');
    const [call, setCall] = useState(false);
    const [reset, setReset] = useState(true);
    // const [loading, setLoading] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsByPage, setItemsByPage] = useState(5);
    const [companyFilter, getCompanies, loadingData, setFilter] = useFetch<CompanyFilterDTO, CompanyFilterDTO>({
        url: COMPANY_MANAGER.getCompanies,
        method: 'POST',
        onError: 'LoadInfo'
    });
    const [, registerPermission, loading] = useFetch<{ Name: string }>({
        url: PERMISSION_MANAGER.createPermission,
        method: 'POST',
        onError: 'Create',
        onSuccess: 'Create'
    });
    const navigate = useNavigate();

    const itemsPerPage = ['5', '10', '15', '20'];

    const handleData = async (indice = 1): Promise<void> => {
        
        await getCompanies({ ...companyFilter?.data, indice, itemByPage: itemsByPage });

    };
    useEffect(() => {
        setCurrentPage(companyFilter?.data?.indice ?? 1);
    }, [getCompanies]);

    const createPermission = async (): Promise<void> => {
        await registerPermission({ Name: NameGrup }, current => {
            if (!current.error) {
                setModalState(false);
            }
        });
    };
    useEffect(() => {
        handleData();

    }, [reset, call]);
    // async function reportGet() {
    //     setLoading(true);

    //     const resp = await getReportUserManeger(selectTypeReport);
    //     if (!resp.error) {
    //         window.open('https://services.honeywell.com.br/_Uploads/User/Report/' + resp.data, '_blank');
    //         setNotification(old => ({ ...old, message: 'ReportSuccess', type: 'success' }));
    //         setModelReport(false);
    //     } else {
    //         setNotification(old => ({ ...old, message: 'NoResult', type: 'information' }));
    //         setLoading(false);
    //         return resp.status;
    //     }
    //     setLoading(false);
    // }

    function renderModal(): JSX.Element {
        return (
            <Modal
                onClose={() => setModalState(false)}
                size="small"
                open={modalState}
                closeOnDimmerClick={true}
                closeOnDocumentClick={true}
                className="no-padding"
            >
                <div className="modalConsultWaranty">
                    <Modal.Header>
                        <div className="modalHeader">
                            <h2> {t('usermanager:translation.NewGroup')} </h2>
                        </div>
                    </Modal.Header>
                    <div className="container-selects">
                        <Input
                            max={20}
                            fluid
                            placeholder={t('usermanager:translation.NameGroup')}
                            onChange={e => setNameGrup(e)}
                        />
                        <br />
                    </div>
                    <Modal.Footer></Modal.Footer>
                    <Card.Footer>
                        <div className="buttonModal">
                            <Button
                                content={t('common:actions.Cancel')}
                                onClick={() => setModalState(false)}
                                size="medium"
                                type="secondary"
                                textTransform={true}
                            />
                            <Button
                                type="primary"
                                icon="slidercontrols-plus"
                                content={t('common:actions.Create')}
                                iconPosition="right"
                                disabled={!NameGrup}
                                onClick={() => createPermission()}
                                loading={loading}
                            />
                        </div>
                    </Card.Footer>
                </div>
            </Modal>
        );
    }

    return (
        <>
            {renderModal()}
            {/* {report()} */}
            {(loadingData && <Loader text={t('common:actions.Loading')} />) || (
                <>
                    <Header title={t('dashboard:admin_tools.user_manager.name')} logo={false} menu={false}>
                        {/* <Header.IconItem
                            icon={<Icon name="graph" size="large" root="building" />}
                            badge="20"
                            description={t('common:actions.Reports')}
                        // onClick={() => setModelReport(true)}
                        ></Header.IconItem> */}
                        <Button
                            type="secondary"
                            size='small'
                            iconPosition="right"
                            icon={<Icon name="user-group-add" size="medium" root="common" />}
                            badge="20"
                            content={t('usermanager:translation.NewGroup')}
                            onClick={() => setModalState(true)}

                        /><Button
                            type="primary"
                            size='small'
                            iconPosition="right"
                            icon={<Icon name="add" size="medium" root="building" />}
                            badge="20"
                            onClick={() => navigate('new-company')}
                            content={t('usermanager:translation.NewCompany')}
                        ></Button>
                    </Header>
                    <div className="form4Columns">
                        <Input
                            label={'ID'}
                            placeholder={'ID'}
                            type="number"
                            value={companyFilter?.data?.id?.toString() ?? ''}
                            onChange={(value: string) => setFilter(old => ({ ...old, id: parseInt(value) }))}
                        ></Input>
                        <Input
                            label={t('user:info.Name')}
                            placeholder={t('user:info.Name')}
                            value={companyFilter?.data?.name?.toString() ?? ''}
                            onChange={(value: string) => setFilter(old => ({ ...old, name: value }))}
                        ></Input>
                         <Input
                            label={t('user:info.EmailUser')}
                            placeholder={t('user:info.EmailUser')}
                            value={companyFilter?.data?.emailUser?.toString() ?? ''}
                            onChange={(value: string) => setFilter(old => ({ ...old, emailUser: value }))}
                        ></Input>
                        <Input
                            label={t('user:company.Id')}
                            placeholder={t('user:company.Id')}
                            value={cnpjMask(companyFilter?.data?.cnpj?.toString() ?? '')}
                            onChange={(value: string) => setFilter(old => ({ ...old, cnpj: cnpjMask(value) }))}
                        ></Input>

                        {/* <Input
                                label={t('user:info.Groupe')}
                                placeholder={t('user:info.Groupe')}
                                type="number"
                                value={filter?.permission?.toString() ?? ''}
                                onChange={(value: string) => setFilter(old => ({ ...old!, permission: parseInt(value) }))}
                            ></Input> */}
                        {/* <Select
                                className="SelectStyle"
                                label={t('user:info.Role')}
                                options={[
                                    { text: 'Client', value: 2 },
                                    { text: 'Admin', value: 1 }
                                ]}
                                placeholder={t('user:info.Role')}
                                type="number"
                                value={filter?.role}
                                onChange={(value: number) => setFilter(old => ({ ...old!, role: value }))}
                            ></Select> */}
                        {/* <Select
                                className="SelectStyle"
                                label={t('user:info.Module')}
                                options={[
                                    { text: 'RMA', value: 1 },
                                    { text: 'SpareParts', value: 2 }
                                ]}
                                placeholder={t('user:info.Module')}
                                type="number"
                                value={filter?.module}
                                onChange={(value: number) => setFilter(old => ({ ...old!, module: value }))}
                            ></Select> */}
                        <Select
                            className="SelectStyle"
                            label={t('common:translation.ItemperPage')}
                            placeholder={t('common:translation.ItemperPage')}
                            value={itemsByPage.toString()}
                            options={itemsPerPage.map((e: string) => ({ text: e.toString(), value: e }))}
                            onChange={value => setItemsByPage(parseInt(value))}
                        />
                    </div>
                    <div className="buttonsSearch">
                        <Button
                            type="secondary"
                            icon="badge-delete"
                            content={t('common:actions.ResetFilters')}
                            iconPosition="right"
                            onClick={() => {
                                setFilter({
                                    itemByPage: 5,
                                    indice: 1,
                                    companies: companyFilter?.data?.companies,
                                    totalItems: companyFilter?.data?.totalItems
                                });
                                setReset(old => !old);
                            }}
                        />
                        <Button
                            type="primary"
                            icon="filter1"
                            content={t('common:actions.ApplyFilters')}
                            iconPosition="right"
                            onClick={() => handleData()}
                        />
                    </div>
                    <br />
                    <DataTable
                        data={companyFilter?.data?.companies ?? []}
                        onCellClick={(data: ICellData) => navigate('company', { state: { id: data.rowData.id } })}
                    >
                        <DataTable.Column header={'ID'} field="id"></DataTable.Column>
                        <DataTable.Column header={t('user:info.Name')} field="name"></DataTable.Column>
                        <DataTable.Column header={t('user:company.Id')} field="cnpj"></DataTable.Column>
                        <DataTable.Pagination
                            disabledPages={[currentPage]}
                            activePage={currentPage}
                            itemsPerPage={companyFilter?.data?.itemByPage ?? itemsByPage}
                            totalItems={companyFilter?.data?.totalItems ?? 0}
                            onPageChange={(page: any) => {
                                // setFilter({ ...companyFilter?.data!, indice: parseInt(page) });
                                handleData(page);
                            }}
                        ></DataTable.Pagination>
                    </DataTable>
                </>
            )}
        </>
    );
};

export default AdminTabManager;
