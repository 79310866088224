import { Button, Grid, Input, TextArea } from '@scuf/common';
import { CompanyDTO } from 'models/userManager/addCustomer';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { cnpjMask, companyAtom, companyDtoAtom, telephoneMask } from 'resources';

const CompanyInfo: React.FC = () => {
    const { t } = useTranslation(['usermanager', 'common', 'user']);
    const [company] = useRecoilState<CompanyDTO>(companyDtoAtom);

    return (
        <>
            {company && (
                <div className="form4Columns">
                    <Input label={t('user:info.Name')} disabled value={company.name}></Input>
                    <Input label={t('CNPJ')} value={cnpjMask(company.cnpj || '')} disabled></Input>
                    <Input label={t('user:address.Phone')} disabled={true} value={company.phone}></Input>
                    <Input label={t('user:address.City')} disabled value={company.city}></Input>
                    <Input label={t('user:address.Street')} value={company.street} disabled></Input>
                    <Input label={t('user:address.State')} value={company.state} disabled></Input>
                    <Input label={t('user:address.Neighborhood')} value={company.neighborhood} disabled></Input>
                    <Input label={t('CEP')} disabled value={company.zipCode}></Input>
                    <Input label={t('user:address.Complement')} disabled value={company.complement}></Input>
                    <TextArea label={t('user:info.Email')} disabled={true} value={company?.email} />
                </div>
            )}
            {/* <div className="buttonsSearch">
                <Button
                    onClick={
                        setchange(true)}
                    content={t('common:actions.Change')}
                //loading={loading}
                // disabled={!changed || !validate()}
                ></Button>
            </div> */}
        </>
    );
};

export default CompanyInfo;
