import { CustomErrorBoundary } from "models/error/errorModel";

export class AccessDeniedError extends Error implements CustomErrorBoundary {
    code: string  = '403';
    showMessage: string = 'Retornar a página inicial'
    onClick = async () => {
        window.location.replace(process.env.REACT_APP_BASENAME_URL!)
    };
    constructor(){
        super('Acesso Negado')
    }
}