import usePreventClickjacking from 'helpers/hooks/usePreventingClickjacking';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'styles/main.scss';

function RepairCenterPage() {
    const [width, setWidth] = useState<number>(0);
    const { t } = useTranslation(['repaircenter', 'maintenance', 'repaircenterts']);

    function handleResize() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        document.title = 'Centro de Reparos - Portal Honeywell';
        setWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
    }, []);

    usePreventClickjacking();
    return (
        <>
            <div className="container">
                <div>
                    <div className="contentPageContainer">
                        {width > 845 ? (
                            <img alt="imagem ilustrativa homem no mercado" src={require('../../assets/manutencao.jpg')} />
                        ) : (
                            <div />
                        )}
                        <div className="container-med">
                            <h4>{t('maintenance:contract.title')}</h4>
                            <p>{t('maintenance:contract.PageContent1')}</p>
                            <p>{t('maintenance:contract.PageContent2')}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div>
                    <div className="contentPageContainer">
                        <div className="container-grande">
                            <h4 className="uppercase-title">{t('maintenance:contract.Benefits')}</h4>
                            <ul>
                                <li>{t('maintenance:contract.Benefits1')}</li>
                                <li>{t('maintenance:contract.Benefits2')}</li>
                                <li>{t('maintenance:contract.Benefits3')}</li>
                                <li>{t('maintenance:contract.Benefits4')}</li>
                            </ul>
                            <h4 className="uppercase-title">{t('maintenance:contract.title2')}</h4>
                            <li>{t('maintenance:contract.SlaBenefits')}</li>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default RepairCenterPage;
