import { Button, DatePicker, Grid, Icon, Input, Select } from '@scuf/common';
import useFetch from 'helpers/hooks/useFetch';
import { attendanceTime, materialType, Package, RmaModel, RmaModelLite } from 'models';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { REPAIR_CENTER, Rma } from 'resources';
interface ChangeStatusProps {
    rmaState: RmaModelLite;
    setRmaState: React.Dispatch<React.SetStateAction<RmaModelLite>>;
}
function AdmShippingTab({ rmaState, setRmaState }: ChangeStatusProps): JSX.Element {
    const [fields, setFields] = useState<Package>(rmaState?.package);
    const [change, setChange] = useState<boolean>(false);
    const { t } = useTranslation(['repaircenter', 'common', 'user']);
    const handleChange = (field: string, value: any): void => {
        setChange(true);
        setFields(old => ({ ...old, [field]: value }));
    };
    const [shippingData, changeShippingData, loading] = useFetch<RmaModelLite>({
        url: REPAIR_CENTER.updatePackage,
        method: 'POST',
        onError: 'Change',
        onSuccess: 'Change'
    });
    const handleSave = async (): Promise<void> => {
        await changeShippingData({ ...rmaState, package: fields }, _ => {
            setChange(false);
            setRmaState(old => ({ ...old, package: fields }));
        });
    };
    const handleCancel = (): void => {
        setChange(false);
        setFields(rmaState.package);
    };

    return (
        <>
            <Grid>
                <Grid.Column className="grid-with-gap">
                    <Input
                        label={t('rma.order.Customer.ResponsibleUser')}
                        value={fields?.name}
                        onChange={value => handleChange('name', value)}
                    ></Input>
                    <Input
                        label={t('user:address.Phone')}
                        value={fields?.phone}
                        onChange={value => handleChange('phone', value)}
                    ></Input>
                    <Select
                        options={attendanceTime}
                        label={t('rma.shipping.BusinessHour')}
                        value={fields?.time}
                        onChange={value => handleChange('time', value)}
                    ></Select>
                    <Select
                        options={materialType}
                        label={t('rma.shipping.MaterialType')}
                        value={fields?.materialType}
                        onChange={value => handleChange('materialType', value)}
                    ></Select>

                    <DatePicker
                        type="date"
                        displayFormat="DD/MM/YYYY"
                        label={t('rma.shipping.ShipmentSentDate')}
                        value={new Date(fields?.date)}
                        onChange={value => handleChange('date', value)}
                    ></DatePicker>
                </Grid.Column>
                <Grid.Column className="grid-with-gap">
                    <Input
                        label={t('rma.shipping.Height')}
                        value={fields?.height}
                        onChange={value => handleChange('height', value)}
                    ></Input>
                    <Input
                        label={t('rma.shipping.Width')}
                        value={fields?.width}
                        onChange={value => handleChange('width', value)}
                    ></Input>
                    <Input
                        label={t('rma.shipping.Length')}
                        value={fields?.length}
                        onChange={value => handleChange('length', value)}
                    ></Input>
                    <Input
                        label={t('rma.shipping.Weight')}
                        value={fields?.weight}
                        onChange={value => handleChange('weight', value)}
                    ></Input>
                </Grid.Column>
            </Grid>
            <div className="buttonsSearch">
                {change && (
                    <div>
                        <Button
                            content={t('common:actions.Cancel')}
                            type="secondary"
                            onClick={handleCancel}
                            loading={loading}
                            disabled={loading}
                        ></Button>
                        <Button
                            iconPosition="right"
                            icon={<Icon root="common" name="save" />}
                            content={t('common:actions.Save')}
                            loading={loading}
                            disabled={loading}
                            onClick={handleSave}
                        ></Button>
                    </div>
                )}
            </div>
        </>
    );
}

export default AdmShippingTab;
