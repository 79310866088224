import { Clock, WarrantyConsultModal } from 'components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import 'styles/dashboard.scss';
import MenuItem from './menuItem';
import { useEffect, useState } from 'react';
import useUser from 'helpers/hooks/useUser';
import { CompanyDTO, CompanyFilterDTO } from 'models/userManager/addCustomer';
import { getCompanies } from 'resources/api/usermanager_api/addCustomer';
import { UserPortal } from 'models';
import { useRecoilState } from 'recoil';
import { companyDTO, satisfactionSurveyAtom } from 'resources';
import { UserModule, UserRole } from 'models/user/sso';
import { SatisfactionSurveyModal } from 'components/satisfactionSurveyModal';
import { getSatisfactionSurvey } from 'resources/api/repaircenter_api/getSatisfactionSuveryList';
import usePreventClickjacking from 'helpers/hooks/usePreventingClickjacking';

function DashboardMenu() {
    const navigate = useNavigate();
    const { t } = useTranslation(['dashboard', 'common', 'user']);
    const { user: userDetails } = useUser();
    const [user, setUser] = useState<UserPortal>({} as UserPortal);
    const [customers, setCustomers] = useState<CompanyDTO[]>([]);
    const [company, setCompany] = useRecoilState(companyDTO);
    const [satisfcationSurvey, setSatisfcationSurvey] = useRecoilState(satisfactionSurveyAtom);
    const [companyFilterDTO, setCompanyFilterDTO] = useState<CompanyFilterDTO>({ indice: 1, itemByPage: 10 });
    const [loading, setLoading] = useState<boolean>(false);
    const userRole = UserRole;
    const userModule = UserModule;

    const validate = (): boolean => {
        return !!user?.companyId;
    };

    useEffect(() => {
        validate();
        companysAll();
        satisfactionSurveyAll();
    }, []);

    useEffect(() => {
        const companyUser = customers.find((e: CompanyDTO) => e.id?.toString() === userDetails?.COMPANY);
        setCompany(companyUser ? [companyUser] : []);
    }, [customers]);

    async function satisfactionSurveyAll() {
        setLoading(true);
        let resp = await getSatisfactionSurvey(userDetails?.SSOID);
        if (!resp.error) {
            setSatisfcationSurvey(resp.data);
        }
        setLoading(false);
    }
    async function companysAll() {
        setLoading(true);
        let resp = await getCompanies(companyFilterDTO);
        if (!resp.error) {
            setCustomers(resp?.data.companies);
        }
        setLoading(false);
    }

    return (
        <>
            {<SatisfactionSurveyModal />}
            <div className="container-parent">
                <div className="container-dashboard">
                    <div className="header-menu">
                        <h3>{t('modules.Modules')}</h3>
                        <Clock />
                    </div>
                    <div className="inner-container-dashboard-modules">
                        <MenuItem
                            name={userModule.RMA}
                            buttonContent={<p>{t('modules.repair_center.name')}</p>}
                            tooltipContent={t('modules.repair_center.desc')}
                            iconName="tools"
                            iconRoot="building"
                            onClick={() => navigate('/repair-center')}
                        />
                        <MenuItem
                            name={userModule.SPAREPARTS}
                            buttonContent={t('modules.spare_parts.name')}
                            tooltipContent={t('modules.spare_parts.desc')}
                            iconName="briefcase"
                            iconRoot="common"
                            onClick={() => navigate('/spare-parts')}
                        />

                        <MenuItem
                            name={userModule.MAINTENANCE_REPORT}
                            buttonContent={t('modules.maintenance_report.name')}
                            tooltipContent={t('modules.maintenance_report.desc')}
                            iconName="document-report"
                            iconRoot="common"
                            onClick={() => navigate('/maintenance-report')}
                        />
                    </div>
                    <h3>{t('utilities.Utilities')}</h3>
                    <div className="inner-container-dashboard-utilities">
                        <MenuItem
                            buttonContent={<p>{t('utilities.help.name')}</p>}
                            tooltipContent={t('utilities.help.desc')}
                            iconName="badge-help"
                            iconRoot="common"
                            onClick={() => navigate('/manual')}
                        />
                        <MenuItem
                            buttonContent={t('utilities.certified.name')}
                            tooltipContent={t('utilities.certified.desc')}
                            iconName="buildings"
                            iconRoot="common"
                            onClick={() => navigate('/info/certified')}
                        />
                        <MenuItem
                            buttonContent={t('utilities.warranty.name')}
                            tooltipContent={t('utilities.warranty.desc')}
                            iconName="search"
                            iconRoot="common"
                            onClick={() => navigate('/warranty-consult')}
                        />
                        <MenuItem
                            buttonContent={t('utilities.assistance.name')}
                            tooltipContent={t('utilities.assistance.desc')}
                            iconName="location"
                            iconRoot="common"
                            onClick={() => navigate('/find-assistance')}
                        />
                    </div>

                    <h3>{t('admin_tools.AdminTools')}</h3>
                    <div className="inner-container-dashboard-admintools ">
                        <MenuItem
                            name={userModule.USER}
                            buttonContent={<p>{t('admin_tools.user_manager.name')}</p>}
                            tooltipContent={t('admin_tools.user_manager.desc')}
                            iconName="user-edit"
                            iconRoot="common"
                            onClick={() => navigate('/user')}
                        />
                    </div>
                </div>
                <div className="container2-dashboard">
                    <div className="profile">
                        <div className="info-profile">
                            <h3>{'E-mail'}</h3>
                            <h5>{userDetails?.EMAIL}</h5>

                            <h3>{t('user:address.Company')}</h3>
                            <h5>
                                {customers.map((e: CompanyDTO) => (e.id?.toString() === userDetails?.COMPANY ? e.name : null))}
                            </h5>

                            <h3>{'CNPJ'}</h3>
                            <h5>
                                {customers.map((e: CompanyDTO) => (e.id?.toString() === userDetails?.COMPANY ? e.cnpj : null))}
                            </h5>

                            <h3>{t('register:type.Account')}</h3>
                            <p>
                                {userDetails?.ROLE == userRole.SUPERADMIN.toString() ? (
                                    <h5> Super Admin</h5>
                                ) : userDetails?.ROLE == userRole.ADMIN.toString() ? (
                                    <h5>{t('user:types.Admin')}</h5>
                                ) : userDetails?.ROLE == userRole.CLIENTADMIN.toString() ? (
                                    <h5> {t('user:types.ClientAdmin')}</h5>
                                ) : userDetails?.ROLE == userRole.CLIENT.toString() ? (
                                    <h5>{t('user:types.Client')} </h5>
                                ) : (
                                    <h5>{t('user:types.Account')} </h5>
                                )}
                            </p>
                        </div>
                    </div>
                    <div className="profile-info"></div>
                    <h3>{t('common:actions.Search')}</h3>
                    <WarrantyConsultModal />
                </div>
            </div>
        </>
    );
}
export default DashboardMenu;
