import { rmaItems } from "./detailsRma";

export type RmaModel = {
    id: number;
    companyId: number;
    address: AddressModel;
    valorInvoice: string;
    cnpjNota: string;
    rmaItems: rmaItems[];
    observacao: string;
    acessorios: string;
    ssoId: string;
    shipping: Shipping;
    package: Package;
    statusId: number;
    typeId: number;
    generalMessage?: unknown;
    addressNumber: string;
    invoice: string;
    addressComplement: string;
    user: User,
    justify?: string;
    status: Info,
    type: Info,
    rmaRequests: RmaRequestModel[]
    rmaFiles: RmaFileModel[]
    rmaChats: RmaChatModel[];
    createdAt: Date,
    rmaDeleted: RmaDeleted
    workOrders: WorkOrderModel[];
    Number: number;
};

export type RmaModelLite = {
    id: number;
    companyId: number;
    address: AddressModel;
    valorInvoice: string;
    cnpjNota: string;
    rmaItems: rmaItems[];
    observacao: string;
    acessorios: string;
    ssoId: string;
    shipping: Shipping;
    package: Package;
    statusId: number;
    typeId: number;
    generalMessage?: unknown;
    addressNumber: string;
    invoice: string;
    addressComplement: string;
    user: User,
    justify?: string;
    status: Info,
    type: Info,
    rmaRequests: RmaRequestModel[]
    rmaFiles: RmaFileModel[]
    rmaChats: RmaChatModel[];
    createdAt: Date,
    rmaDeleted: RmaDeleted
    workOrders: WorkOrderRmaLiteDto[];
    Number: number;
};

export type RmaChatModel = {
    rmaId: number;
    userName: string;
    message: string;
    createdAt: string;
};
export type RmaFileModel = {
    id?: number,
    type: string,
    fileName: string,
    rmaId: number,
    addedById: number,
    addedBy: User,
    createdAt: Date,
    
}
export type RmaReportModel = {
    DateEnd?: any,
    DateStart?: any,
    Type?: any,
    SsoId?: number | string
}
export type RmaFilterModel = {
    id?: number,
    dateStart?: Date,
    dateEnd?: Date,
    statusId?: number,
    typeId?: number,
    serialNumber?: string,
    itemsByPage: number,
    indice: number,
    rmas: RmaModel[],
    ssoId?: string,
    listSsoId?: string[],
    totalItems?: number,
    invoice?: string,
    shippingTypeId: number,
    shippingSituation?: string,
    clientEmail?: string,
    order?: number,
    cnpj?: string,
    cnpjNota?: string,
    
}
export type RMAFilterHistory = {
    serialNumber?: string,
}
export type EOSFilterModel = {
    blockingLevel?: string,
    model?: string,
}

export type RmaRequestModel = {
    id?: number,
    rmaId: number,
    description: string,
    typeId: number,
    type: Info,
    createdBy: User,
    ssoId: string,
    finishedById?: number,
    isCompleted?: boolean,
    createdAt?: Date,
}

export type AddressModel = {
    zipCode: string;
    state: string;
    city: string;
    neighborhood: string;
    street: string;
    // number: string;
    // complement: string;
};

export type Info = {
    id: number,
    name: string
}

export type User = {
    id: number,
    name: string,
    email: string,
    phone: string
}

export type Shipping = {
    code: string
    observation: string
    situation: string
    // transport: string,
    requestedByCustomer: boolean,
    typeId: number,
    type: Info
};

export type Package = {
    id: number,
    height: string,
    length: string,
    materialType: string,
    name: string,
    date: Date,
    phone: string,
    time: string,
    weight: string,
    width: string
};

export type RmaMessageModel = {
    readingBy: string;
    message: string;
    readingDate: number;
    date: string;
    userName: string;
};

export type RmaResultModel = {
    number: number;
    typeId: number;
    cnpjInvoice: string;
    statusId: number;
    qtdItems: number;
    persistDate: string;
};

export class RmaResult {
    number!: number;
    typeId!: number;
    cnpjInvoice!: string;
    statusId!: number;
    qtdItems!: number;
    persistDate!: string;
}

export type RmaLastEventsModel = {
    description: string;
    date: string;
    rmaId: number;
};

export type RmaRequestOpenModel = {
    description: string;
    date: string;
    rmaId: number;
};

export type CustomerModel = {
    id: string;
    phone: string;
    email: string;
    company: string;
    cnpj: string;
    user: string;
};

export type EquipmentModel = {
    status: number;
    equipment: string;
    equipmentDesc: string;
};

export type RmaDeleted = {
    RmaId: number;
    Reason?: string;
};


export type RmaDetailModel = {
    id: number,
    cnpjNota: string,
    valorInvoice: string,
    observacao: string,
    acessorios: string,
    addressComplement: string,
    addressNumber: string,
    status: {
        id: number,
        name: string,
    },
    type: {
        id: number,
        name: string
    },
    address: {
        zipCode: string,
        city: string,
        state: string,
        neighborhood: string,
        street: string
    },
    createdAt: Date,
    package: {
        name: string,
        phone: string,
        time: string,
        materialType: string,
        height: string,
        width: string,
        length: string,
        weight: string,
        date: Date
    },
    shipping: {
        code: string,
        observation: string,
        situation: string,
        transport: string
    },
    user: {
        name: string,
        email: string
    }
    rmaItems: [],
    workOrders: WorkOrderModel[];

    //ANTIGO
    Number: number;
    UserId: number;
    UserName: string;
    UserEmail: string;
    UserPhone: string;
    CompanyId: number;
    CompanyName: string;
    CompanyCnpj: string;
    CnpjInvoice: string;
    PersistDate?: string;
    Nfe?: number | string;
    StatusId: number;
    TypeId: number;
    ShipmentRequested?: string;
    ShipmentRequestedCode?: number;
    AutorizeTypeRmaId: number;
    Accessories?: unknown;
    DiscountCouponNumber?: number;
    DiscountCouponId?: number;
    DiscountCouponPerCent?: number;
    Items: ItemRmaModel[];
    QtdItems: number;
    WorkOrderBudget: WorkOrderBudgerModel;
    QtdOSs: number;
    QtdOSWaitingApproval: number;
    ShippingDate: string;
    ShippingAddress: ShippingAddressModel;
    ShipmentShipping: ShipmentShippingModel;
    Files: FilesModel[];
    Events: EventsModel[];
    rmaChats: RmaChatModel[];
    Requests: RequestModel[];
    Notes: NotesModel[];
    SatisfactionSurveyModel: unknown;
    InvoicesValue: number | string;
    AutorizeTypeObs: unknown;
    Comments: unknown;
    LastUpdate: unknown;
    FlagIsPartnersCommissioning: boolean;
    FinalCustomerName?: unknown;
    FinalCustomerEmail?: unknown;
    ChangeToStatusId: number;
    ChangeToTypeId: number;
    GeneralMessage?: unknown;
    ActionObs?: unknown;
    HaveOS: number;
};

export type RequestModel = {
    FlagFinished?: boolean;
    CreatedBy?: string;
    Date?: string;
    Type?: string;
    Description?: string;
    CreatedById?: number;
    FinishedBy?: string;
    FlagViewed?: boolean;
    ViewedBy?: string;
    ViewedDate?: string;
    CompletionDate?: string;
    Id: string;
    RmaId: string;
    GenericId?: any;
};
export type NotesModel = {
    Message: string;
    SendBy: string;
    Date: string;
    Id: string;
};
export type EventsModel = {
    Id: number;
    RmaId: number;
    OsId?: unknown;
    Title: string;
    Type: string;
    Date: string;
    Description: string;
    FlagAdminMonitor?: null;
    AdminMonitorReporter?: null;
    AdminMonitorReporter_value?: null;
    AdminMonitorConfirmedBy?: null;
    AdminMonitorConfirmedBy_value?: null;
    AdminMoniorDateConfirmed?: null;
    RequestId?: null;
};

export type FilesModel = {
    AddedBy: string;
    Author: string;
    Date: string;
    Extension: string;
    Id: number;
    Name: string;
    NickName: string;
    RmaId: number;
    Type: string;
};
export type ItemRmaModel = {
    Id: number;
    RmaId: number;
    PartNumberModel: string;
    PartNumberDescription: any;
    SerialNumber: string;
    ProblemDescription: string;
    Approval: number;
    ApprovalDate: string;
    Observation: string;
    FlagErrorDetected: boolean;
    FlagServiceAuthorized: boolean;
    FlagProductFound: any;
    FlagProductWithoutSerialNumber: boolean;
    ErrorDetails: any;
    PartNumberType: string;
    IsEOS: boolean;
    Files: any[];
};

export type WorkOrderRmaLiteDto = {
    rma: string;
    os: string;
    partnumber: string;
    modalidade: string;
    serial: string;
    status: string;
    dataEntrada: string;
    dataSaida: string;
    codigo: string;
    relatorio: boolean;
    flagApproved: number;
}

export type WorkOrderModel = {
    number: string;
    product: ProductRmaModel;
    issued: string;
    changes: ChangeRmaModel[];
    status: {
        code: string;
        description: string;
    };
    rma: {
        number: string;
        workOrders: string[];
    };
    technicalReport: string;
    accessory?: string;
    failureReported: string;
    factoryWarranty: boolean;
    serviceWarranty: boolean;
    badUse: boolean;
    entryInvoice: string;
    contract: {
        code: string;
    };
    saleDate: string;
    flagApproved: number;
    courierName: string;
    courierRegisterNumber: string;
    invoice: string;
    trackingCode: string;
    invoiceDate: Date;
};

export type ProductRmaModel = {
    code: string;
    number: string;
    description: string;
    serialNumber: string;
    modality: string;
    modalityCode: string;
};

export type ChangeRmaModel = {
    code: string;
    number: string;
    description: string;
    cause: string;
    optional: number;
    serviceType: number;
    NCM: string;
    quantity: number;
    ottp: string;
    reason: {
        code: string;
        description: string;
    };
};

export type WorkOrderBudgerModel = {
    budgetNumber?: number;
    oSNumber?: number;
    items?: BudgetItems[] | any;
    currency: number;
    currencyRate: number;
    amount: number;
    amountTax: number;
    benefitPISCOFINS: boolean;
    amountPISCOF: number;
    benefitIPI: boolean;
    amountIPI: number;
    benefitICMS: boolean;
    amountICMS: number;
    amountISS: number;
    amountST: number;
    entryInvoice?: any;
    saleDate: string;
    paymentTerms: any;
    flagApproved: number;
    approvedUserName?: unknown;
    approvedPhone?: unknown;
    approvedPartsCnpj?: unknown;
    approvedServicesCnpj?: unknown;
    approvedPartsEmail?: unknown;
    approvedServicesEmail?: unknown;
    approvedEmailXml?: unknown;
    approvedObs?: unknown;
    approvedRequireOrderFile?: any | undefined;
    approvedSsoId?: unknown;
    approvedSalesOrder?:  boolean;
};

export type ShippingAddressModel = {
    RmaId: number | string;
    Cep: string;
    State: string;
    City: string;
    Neighborhood: string;
    Street: string;
    Number: string;
    Complement?: string;
    ShippingDate: string;
};

export type ShipmentShippingModel = {
    RmaId: number;
    ResponsibleName: any;
    ResponsiblePhone: any;
    Height: any;
    Width: any;
    Length: any;
    Weight: any;
    Type: any;
    Quantity: any;
    BusinessHours: any;
    CollectionRequestDate: any;
    ShipmentRequestedCode: any;
};
export type RmaOption = {
    value: number;
    text: string;
    color?: 'red' | 'yellow' | 'orange' | 'green' | 'blue' | 'grey';
};
export type WorkOrderOption = {
    value: number[];
    text: string;
    color: 'red' | 'yellow' | 'orange' | 'green' | 'blue' | 'grey';
};

export type RMAreportTypes = {
    text: string;
    value: number;
    restrict: boolean;
};


export type BudgetDetailsModel = {
    id?: number;
    number?: number;
    rmaId?: number;
    statusId?: number;
    partNumberCode?: unknown;
    partNumberDescription?: unknown;
    serialNumber?: unknown;
    dateRegistration?: unknown;
    defectFound?: unknown;
    technicalAdvice?: unknown;
    budgetFileName?: unknown;
    accessories?: unknown;
    lastUpdate?: unknown;
    budgetCurrency?: unknown;
    totalBudget?: unknown;
    flagAvailableBudget?: unknown;
    flagAutomaticUpdate?: false;
    services?: [];
    benefitPISCOFINS?: unknown;
    benefitIPI?: unknown;
    totalBudgetWithoutOptional?: unknown;
    flagPricesAvailable?: false;
    invoiceReturn?: unknown;
    invoiceInput?: unknown;
    priceDollar?: unknown;
    flagErrorDetected?: unknown;
    errorInfo?: unknown;
    reviewDate?: unknown;
    flagBudgetAproved?: unknown;
    totalAproved?: unknown;
    partNumberManufactureDate?: unknown;
    shippingDate?: unknown;
    invoiceIssueDate?: string | undefined;
    shippingCompany?: unknown;
    userId?: number;
    userName?: string;
    companyId?: number;
    companyName?: unknown;
    companyCnpj?: unknown;
    invoiceCnpj?: unknown;

    product?: ProductRmaModel;
    changes?: ChangeRmaModel[];
    status?: {
        code: string;
        description?: string;
    };
    technicalReport?: unknown;
    failureReported?: string;
    factoryWarranty?: boolean;
    serviceWarranty?: boolean;
    badUse?: boolean;
    contract?: {
        code?: number;
    };
    budget?: WorkOrderBudgerModel;
    rma?: {
        number?: number;
    };
    accessory?: string;
    entryInvoice?: number;
    saleDate?: string;
    flagApproved?: number;
    courierName?: unknown;
    courierRegisterNumber?: unknown;
    trackingCode?: unknown;
    invoiceDate?: string;
    issued?: string;

};

export type BudgetItems = {
    amount: number;
    code: string;
    currency: number;
    description: string;
    flagApproved: number;
    ncm: string;
    netValue: number;
    number: string;
    optional: number;
    ottp: string;
    quantity: number;
    reason: {
        code: string;
        description: string;
    };
    serviceType: number;
    tax: TaxModel;
    unitaryValue: number;
};

export type TaxModel = {
    aliquotICMS: number;
    aliquotIPI: number;
    aliquotISS: number;
    aliquotST: number;
    cofins: number;
    icms: number;
    ipi: number;
    iss: number;
    pis: number;
    st: number;
};

export type RMAStatus = {
    RMA_ID: number;
    STATUS: string;
};

export type RmaEquipment = {
    Id: number,
    SerialNumber: number;
    PartNumber: number;
    StatusId: number;
}
export type RmaCustomerCA = {
    Name: string;
    Contact: string;
    City: string;
    State: string;
    Neighborhood: string;
    Street: string;
    Complement: string;
    Country: string;
    ZipCode: string;
    Number: number;
}
export type RmaResultModelCA = {
    rmaId: number;
    suportLevel: number;
    user: string;
    statusId: number;
    qtdItems: number;

}

export type RmaRequest = {
    text: string,
    value: any
}
export type RmaStatusCA = {
    value: number;
    text: string;
    color: 'red' | 'yellow' | 'orange' | 'green' | 'blue' | 'grey';
    toRma: true | false;
    description: String;
};
export type RmaSupportLevelCA = {
    value: number;
    color: 'green'
    text: string;
    support: string[];
}
export type TrueFalse = {
    value: boolean;
    text: string
}

export type SerialNumber = {
    code: string;
    contractNumber: string;
    contractValidity: string;
    description: string;
    factoryWarranty: string;
    flagHasContract: boolean;
    flagHasFactoryWarranty: boolean;
    flagHasServiceWarranty: boolean;
    partNumber: string;
    partNumberModel: string;
    salesInvoice: string;
    serialNumber: string;
    serviceWarranty: string;
}