import { Checkbox, Divider, Table } from '@scuf/common';
import { handleYesOrNo } from 'helpers';
import { BudgetItems, WorkOrderBudgerModel } from 'models';
import React from 'react';
import { useTranslation } from 'react-i18next';
type Props = {
    budget: WorkOrderBudgerModel;
    onCheck: CallableFunction;
    type: 'Required' | 'Optional';
};
const AccessoriesTable: React.FC<Props> = ({ budget, onCheck, type }: Props) => {
    const { t } = useTranslation(['repaircenter']);
    let header: string[] = [
        'PartNumber',
        'Description',
        'NCM',
        'BadUse',
        'ServiceType',
        'Value',
        'Quantity',
        'SubTotal',
        'IPI',
        'ICMSST',
        'Total',
        'Approved'
    ];
    const getCurrentList = (): BudgetItems[] => {
        switch (type) {
            case 'Required':
                return budget.items.filter((e: BudgetItems) => e.ncm !== '0000.00.00' && e.optional === 1);
            case 'Optional':
                return budget.items.filter((e: BudgetItems) => e.ncm !== '0000.00.00' && e.optional === 0);

            default:
                return [];
        }
    };

    const handleBadUse = (item: BudgetItems): boolean => {
        const code = parseInt(item.reason.code, 10);
        return code === 1 || code === 2 || code === 4;
    };
    const handleServType = (item: BudgetItems): boolean => {
        return item.serviceType != 1 && item.serviceType != 5;
    };
    const handleValue = (item: BudgetItems): string => {
        if (handleServType(item)) return '-';
        return (item.currency === 2 ? item.netValue * budget?.currencyRate : item.netValue).toFixed(2);
    };
    const handleSubTotal = (item: BudgetItems): string => {
        if (handleServType(item)) return '-';
        return (
            item.currency === 2 ? item.unitaryValue * item.quantity * budget?.currencyRate : item.unitaryValue * item.quantity
        ).toFixed(2);
    };
    const handleIpi = (item: BudgetItems): string => {
        if (handleServType(item)) return '-';
        return (item.currency === 2 ? item.tax.ipi * budget.currencyRate : item.tax.ipi).toFixed(2);
    };
    const handleIcmsst = (item: BudgetItems): string => {
        if (handleServType(item)) return '-';
        return (item.currency === 2 ? item.tax.st * budget.currencyRate : item.tax.st).toFixed(2);
    };
    const handleTotal = (item: BudgetItems): string => {
        if (handleServType(item)) return '-';
        return (
            item.currency === 2
                ? (Math.round(item.unitaryValue * item.quantity * 100) / 100 +
                      Math.round(item.tax.ipi * 100) / 100 +
                      Math.round(item.tax.st * 100) / 100) *
                  budget.currencyRate
                : Math.round(item.unitaryValue * item.quantity * 100) / 100 +
                  Math.round(item.tax.ipi * 100) / 100 +
                  Math.round(item.tax.st * 100) / 100
        ).toFixed(2);
    };

    return (
        <>
            {getCurrentList().length > 0 && (
                <div>
                    <Divider />

                    <h2>{t(`rma.budget.${type}`)}</h2>

                    <Table className="more-information-table">
                        <Table.Header>
                            {header.map((e: string, index: number) => (
                                <Table.HeaderCell key={index}>{t(e)}</Table.HeaderCell>
                            ))}
                        </Table.Header>
                        <Table.Body>
                            {getCurrentList().map((e: BudgetItems, index: number) => (
                                <Table.Row key={e.code}>
                                    <Table.Cell>{e.number}</Table.Cell>
                                    <Table.Cell width={4}>{e.description}</Table.Cell>
                                    <Table.Cell>{e.ncm}</Table.Cell>
                                    <Table.Cell>{handleYesOrNo(handleBadUse(e))}</Table.Cell>
                                    <Table.Cell>{handleYesOrNo(handleServType(e))}</Table.Cell>
                                    <Table.Cell>{handleValue(e)}</Table.Cell>
                                    {/* <Table.Cell>{handleAliquot(e)}</Table.Cell> */}
                                    <Table.Cell>{e.quantity}</Table.Cell>
                                    <Table.Cell>{handleSubTotal(e)}</Table.Cell>
                                    <Table.Cell>{handleIpi(e)}</Table.Cell>
                                    <Table.Cell>{handleIcmsst(e)}</Table.Cell>
                                    <Table.Cell>{handleTotal(e)}</Table.Cell>
                                    <Table.Cell>
                                        <Checkbox
                                            disabled={e.optional === 1}
                                            checked={e.optional === 1 || e.flagApproved === 1}
                                            onChange={(checked: boolean) => {
                                                onCheck(checked, e);
                                            }}
                                        ></Checkbox>
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </div>
            )}
        </>
    );
};

export default AccessoriesTable;
