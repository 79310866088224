import { Button, Card, Icon, Input, TextArea } from '@scuf/common';
import useFetch from 'helpers/hooks/useFetch';
import { CompanyDTO } from 'models/userManager/addCustomer';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { COMPANY_MANAGER, cepMask, cnpjMask, notificationAtom } from 'resources';
import { getCompanyByCnpj, getCompanyFromMicrosiga } from 'resources/api/usermanager_api/addCustomer';

function NewCompany() {
    const { t } = useTranslation(['usermanager', 'common', 'user']);
    const navigate = useNavigate();

    const setNotification = useSetRecoilState(notificationAtom);
    const [customers, setCustomers] = useState<CompanyDTO>({} as CompanyDTO);
    const [loading, setLoading] = useState<boolean>(false);
    const [newPhone, setNewPhone] = useState<string>('');
    const [userCreated, createCompany] = useFetch<CompanyDTO>({
        url: COMPANY_MANAGER.createCompany,
        method: 'POST',
        onSuccess: 'CustomerAdded',
        onError: 'Error'
    });

    function handleCancel() {
        navigate('/user');
    }

    const checkCompanyFromMicrosiga = async (cnpj: string): Promise<CompanyDTO | null> => {
        setLoading(true);
        const cnpjNew = cnpj.replaceAll('.', '').replace('/', '').replace('-', '');
        const resp = await getCompanyFromMicrosiga(cnpjNew);

        if (resp.data == null) {
            setNotification(old => ({ ...old, message: t('usermanager:translation.CompanyNotFound'), type: 'critical' }));
            setLoading(false);
            return null;
        } else {
            let cpMS: CompanyDTO = resp.data;

            //É necessário pegar email e telefone do banco local.
            const respLocal = await getCompanyByCnpj(cnpj);
            if (respLocal.data != null) {
                let cpLocal: CompanyDTO = respLocal.data;
                cpMS.phone = cpLocal.phone;
                cpMS.email = cpLocal.email;
            }
            createCompany(cpMS);
            setCustomers(cpMS);
            setLoading(false);
            return cpMS;
        }
    };

    const addPhoneString = async (): Promise<boolean> => {
        if (newPhone.length != 11 && newPhone.length != 10) {
            setNotification(old => ({ ...old, message: t('usermanager:translation.PhoneMispelled'), type: 'critical' }));
            return false;
        }

        let formattedPhone = '';
        for (let i = 0; i < newPhone.length; i++) {
            if (i == 2) formattedPhone += ' ';
            if ((newPhone.length == 10 && i == 6) || (newPhone.length == 11 && i == 7)) formattedPhone += '-';
            formattedPhone += newPhone[i];
        }

        setCustomers({
            ...customers,
            phone: `${customers.phone}; ${formattedPhone}`
        });

        setNewPhone('');
        return true;
    };

    async function saveCompany() {
        await createCompany(customers, _ => navigate('/user'));
    }

    return (
        <>
            <div className="container wrap-content">
                <div>
                    <Card>
                        <Card.Content>
                            <div>
                                <h1>
                                    <Icon root="common" name="entity-details" size="large" />{' '}
                                    {t('usermanager:translation.NewCompany')}
                                </h1>
                                <div className="form4Columns">
                                    <div className="form4Columns">
                                        <div>
                                            {loading && (
                                                <Icon name="refresh" className="loadingIcon" loading={true} size="large" />
                                            )}{' '}
                                            <Input
                                                label={t('CNPJ')}
                                                type="text"
                                                iconPosition="right"
                                                value={cnpjMask(customers?.cnpj || '') || ''}
                                                icon={<Icon root="common" name="search" />}
                                                onBlur={() => {
                                                    if (customers?.cnpj.length >= 14) checkCompanyFromMicrosiga(customers?.cnpj);
                                                }}
                                                onChange={value =>
                                                    setCustomers({
                                                        ...customers,
                                                        cnpj: value,
                                                        code: value
                                                    })
                                                }
                                            />
                                            <Input
                                                label={t('user:info.Name')}
                                                placeholder={t('user:info.Name')}
                                                maxLength={5}
                                                value={customers?.name}
                                                onChange={value => {
                                                    setCustomers({ ...customers, name: value });
                                                }}
                                                indicator="required"
                                                disabled
                                            />
                                            <Input
                                                label={t('user:address.Phone')}
                                                placeholder={t('(00) 0000-0000')}
                                                maxLength={15}
                                                type="tel"
                                                value={newPhone}
                                                disabled={customers.cnpj == undefined}
                                                indicator="required"
                                                onChange={value => setNewPhone(value)}
                                            />
                                            <Button
                                                className="inputAlignedDown"
                                                content={'>'}
                                                size="small"
                                                disabled={customers.cnpj == undefined || newPhone == ''}
                                                onClick={() => addPhoneString()}
                                            />
                                        </div>
                                        <Input
                                            className="inputFullLine"
                                            label={t('user:address.Phone')}
                                            maxLength={15}
                                            type="text"
                                            value={customers?.phone}
                                            onChange={value => {
                                                setCustomers({ ...customers, phone: value });
                                            }}
                                        />
                                        <TextArea
                                            placeholder={t('user:info.Email')}
                                            label={t('user:info.Email')}
                                            type="text"
                                            onChange={value => {
                                                setCustomers({ ...customers, email: value });
                                            }}
                                            iconPosition="right"
                                            indicator="required"
                                            value={customers?.email}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Card.Content>
                    </Card>
                    <br />
                    <span>
                        <Card>
                            <Card.Content>
                                <div>
                                    <h1>
                                        <Icon root="common" name="shipping" size="large" />
                                        {t('common:translation.Address')}
                                    </h1>
                                    <div className="form4Columns">
                                        <Input
                                            placeholder={t('user:address.ZipCode')}
                                            label={t('user:address.ZipCode')}
                                            type="text"
                                            iconPosition="right"
                                            indicator="required"
                                            maxLength={9}
                                            value={cepMask(customers?.zipCode || '') || ''}
                                            disabled
                                        />
                                        <Input
                                            placeholder={t('user:address.State')}
                                            label={t('user:address.State')}
                                            indicator="required"
                                            type="text"
                                            value={customers?.state}
                                            disabled
                                        />
                                        <Input
                                            placeholder={t('user:address.City')}
                                            label={t('user:address.City')}
                                            indicator="required"
                                            type="text"
                                            value={customers?.city}
                                            disabled
                                        />
                                        <Input
                                            placeholder={t('user:address.Neighborhood')}
                                            label={t('user:address.Neighborhood')}
                                            indicator="required"
                                            type="text"
                                            value={customers?.neighborhood}
                                            disabled
                                        />
                                        <br />
                                        <Input
                                            className="inputFullLine"
                                            placeholder={t('user:address.Street')}
                                            label={t('user:address.Street')}
                                            indicator="required"
                                            type="text"
                                            value={customers?.street}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </Card.Content>
                        </Card>
                    </span>
                    <br />
                    <span>
                        <Card>
                            <Card.Content>
                                <div className="buttonsSearch">
                                    <Button
                                        content={t('common:actions.Cancel')}
                                        size="small"
                                        textTransform={true}
                                        type="secondary"
                                        className="buttonCancelSecondary"
                                        onClick={() => handleCancel()}
                                    />
                                    <Button
                                        content={t('common:actions.Register')}
                                        size="small"
                                        disabled={customers.cnpj == undefined}
                                        onClick={() => saveCompany()}
                                    />
                                </div>
                            </Card.Content>
                        </Card>
                    </span>
                </div>
            </div>
        </>
    );
}
export default NewCompany;
