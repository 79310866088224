import { Button, Loader, Table } from '@scuf/common';
import Alert from 'components/alert';
import useFetch from 'helpers/hooks/useFetch';
import useUser from 'helpers/hooks/useUser';
import { FilterUserModel } from 'models';
import { UserDTO, UserRole } from 'models/user/sso';
import React, { useEffect } from 'react';
import { USER_MANAGER } from 'resources';
import { useTranslation } from 'react-i18next';

const ApprovalTabManager: React.FC = () => {
    const { user } = useUser();
    const { t } = useTranslation(['user', 'common']);
    const [users, loadUsers, userLoading, setUser] = useFetch<FilterUserModel, FilterUserModel>({
        url: USER_MANAGER.getUsers,
        method: 'POST',
        onError: 'LoadInfo'
    });
    const [, update, loading] = useFetch<UserDTO>({
        url: USER_MANAGER.updateUser,
        method: 'POST',
        onError: 'Update',
        onSuccess: 'Update'
    });
    const handleLoad = async () => {
        if (user?.ROLE == UserRole.SUPERADMIN || UserRole.ADMIN) {
            await loadUsers({ verified: false });
        } else if (user?.ROLE == UserRole.CLIENTADMIN) {
            await loadUsers({ verified: false, companyId: user?.COMPANY });
        }
    };

    const handleApprove = async (user: UserDTO) => {
        await update({ ...user, verified: true, company: null }, _ => {
            setUser(old => ({ ...old, users: [...old?.users!].filter(x => x.ssoId != user.ssoId) }));
        });
    };
    const handleReprove = async (user: UserDTO) => {
        await update({ ...user, verified: false, company: null, companyId: null }, _ => {
            const removeCompanyFromUser = [...users?.data?.users!].map(e => {
                if (e.ssoId === user.ssoId) {
                    return { ...e, company: null, companyId: null, verified: false };
                }
                return e;
            });
            setUser(old => ({ ...old, users: removeCompanyFromUser }));
        });
    };
    useEffect(() => {
        handleLoad();
    }, []);
    return (
        <>
            {userLoading && <Loader />}
            {(users?.data?.users && users?.data?.users.length > 0 && (
                <Table>
                    <Table.Header>
                        <Table.HeaderCell>Id</Table.HeaderCell>
                        <Table.HeaderCell>{t('user:info.Name')}</Table.HeaderCell>
                        <Table.HeaderCell>Email</Table.HeaderCell>
                        <Table.HeaderCell>{t('user:info.Role')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('user:address.Company')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('common:translation.Approval')}</Table.HeaderCell>
                    </Table.Header>
                    <Table.Body>
                        {users?.data?.users.map((e: UserDTO) => (
                            <Table.Row key={e.ssoId}>
                                <Table.Cell>{e.ssoId}</Table.Cell>
                                <Table.Cell>{e.name}</Table.Cell>
                                <Table.Cell>{e.email}</Table.Cell>
                                <Table.Cell>{e.role?.name}</Table.Cell>
                                <Table.Cell>{e.company?.name}</Table.Cell>
                                <Table.Cell>
                                    {e.companyId != null ? (
                                        <React.Fragment key={e.ssoId}>
                                            <Button
                                                loading={loading}
                                                size="small"
                                                onClick={() => {
                                                    handleApprove(e);
                                                }}
                                            >
                                                {t('common:translation.Approve')}
                                            </Button>
                                            <Button
                                                loading={loading}
                                                size="small"
                                                type="secondary"
                                                onClick={() => {
                                                    handleReprove(e);
                                                }}
                                            >
                                                {t('common:translation.Reprove')}
                                            </Button>
                                        </React.Fragment>
                                    ) : (
                                        t('user:info.WaitingBond')
                                    )}
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            )) || <Alert text={t('user:info.Approval')} />}
        </>
    );
};

export default ApprovalTabManager;
