import { List } from '@scuf/common';
import { AxiosHttpGet, AxiosHttpPost, AxiosHttpPostFile } from 'axios-http';
import { REPAIR_CENTER, USER_MANAGER } from 'resources/links';

export function getReport(DateEnd: any, DateStart: any, Type: any, RmaId?: number | string) {
    let body = { DateEnd, DateStart, Type, RmaId };
    return AxiosHttpPost({ url: REPAIR_CENTER.getReport, data: body });
}

export function getReportFile( DateEnd: any, DateStart: any,  Type: any, SsoId?: number | string, Role?: number | string,  CompanyId?: number | string, Users?: any ) {
    let body = { DateStart, DateEnd, Type, SsoId, Role, CompanyId, Users};
    return AxiosHttpPostFile({ url: REPAIR_CENTER.getReport, data: body });
}

export function getSatisfactionSurveyReport( DateEnd: any, DateStart: any) {
    let body = { DateStart, DateEnd};
    return AxiosHttpPostFile({ url: REPAIR_CENTER.getSatisfactionSurveyReport, data: body });
}
export function rmaUser(ssoId: string[]){
    return AxiosHttpPost({url: USER_MANAGER.rmaUser, data: ssoId})
}
