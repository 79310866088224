import { AxiosHttpPost } from 'axios-http';
import { RmaRequestModel } from 'models';
import { REPAIR_CENTER } from 'resources/links';

export function requestCreate(request: RmaRequestModel) {
    return AxiosHttpPost({ url: REPAIR_CENTER.requestCreate, data: request });
}
export function requestDelete(id: any) {
    return AxiosHttpPost({ url: REPAIR_CENTER.requestDelete, data: id });
}
