import { Button } from '@scuf/common';
import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import AddEquipment from './AddEquipment';
import CustomerOptions from './CustomerOptions';
import CartResume from './CartResume';
import CartInfo from './CartInfo';

function ShoppingCart() {
    const { t } = useTranslation(['spareparts', 'common']);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [canSkip, setCanSkip] = useState<boolean>(false);

    const canSkipPage = (goNext: boolean, page: number) => {
        if (page === currentPage) setCanSkip(goNext);
    };

    const pages = [AddEquipment, CustomerOptions, CartInfo, CartResume];

    return (
        <div className="container">
            {pages.map((Page, index) => {
                if (currentPage == index) {
                    return <Page key={index} canSkipPage={(goNext: boolean) => canSkipPage(goNext, index)} />;
                }
            })}
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                {currentPage !== 0 && (
                    <div className="buttonsSearch">
                        <Button
                            type="primary"
                            icon="arrow-left"
                            content={t('common:actions.Back')}
                            onClick={() => setCurrentPage(old => old - 1)}
                        />
                    </div>
                )}
                {pages.length - 1 !== currentPage && (
                    <div className="buttonsSearch" style={{ marginLeft: 'auto' }}>
                        <Button
                            type="primary"
                            icon="arrow-right"
                            iconPosition="right"
                            content={t('common:actions.Continue')}
                            disabled={!canSkip}
                            onClick={() => setCurrentPage(old => old + 1)}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}
export default ShoppingCart;
