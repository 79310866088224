import { RmaOption } from 'models/repaircenter';

export const typeOptionsISl: RmaOption[] = [
    { value: 1, text: '8125025143', color: 'blue' },
    { value: 2, text: '5454545444', color: 'yellow' },
    { value: 3, text: '7854796451', color: 'green' }
];

export const typeOptionState: RmaOption[] = [
    { value: 1, text: '2 esperando por pates', color: 'blue' },
    { value: 2, text: '4 esperando por pates', color: 'yellow' },
    { value: 3, text: '5 esperando por pates', color: 'green' }
];

export const typeOptionProducts: RmaOption[] = [
    { value: 1, text: 'Coletores de dados', },
    { value: 2, text: 'Impressoras Fixas', },
    { value: 3, text: 'Leitores de código de barras',},
    { value: 4, text: 'RFID',  },
    { value: 5, text: 'Acessórios para coletores, scaners e Impressoras',  },
    { value: 6, text: 'Detecção de Gás e Chamas', },
    { value: 7, text: 'Vocollect',  },
    { value: 8, text: 'Impressoras Portáteis', },

];
