import { IOption } from '@scuf/common/dist/components/Select/ISelectProps';
import { RMAreportTypes, RmaOption, RmaStatusCA, RmaSupportLevelCA, TrueFalse, WorkOrderOption } from './rmaModel';
import { RmaOptionCA } from './rmaModelCA';
export const blockedRmaStatus = [1, 2, 3, 10, 11, 12, 13, 14, 15, 16, 20, 21, 22, 23, 24];
// Já tem no Backend

export const statusOptions: RmaOption[] = [
    { value: 1, text: 'Aguardando Processamento', color: 'yellow' },
    { value: 2, text: 'Aguardando Suporte Técnico', color: 'yellow' },
    { value: 3, text: 'Aguardando Recebimento', color: 'yellow' },
    { value: 4, text: 'Em Analise', color: 'blue' },
    { value: 5, text: 'Aguardando Aprovação', color: 'yellow' },
    { value: 6, text: 'Aprovado', color: 'blue' },
    { value: 7, text: 'Equipamento em Manutenção', color: 'orange' },
    { value: 8, text: 'Nota Fiscal Emitida', color: 'blue' },
    { value: 9, text: 'Equipamento Expedido', color: 'blue' },
    { value: 10, text: 'Finalizado', color: 'green' },
    { value: 11, text: 'Finalizado Recusado', color: 'red' },
    { value: 12, text: 'Expirado', color: 'red' },
    { value: 13, text: 'Recusado', color: 'red' },
    { value: 14, text: 'Reprovado', color: 'red' },
    { value: 15, text: 'Indefinido', color: 'orange' },
    { value: 16, text: 'Aberto', color: 'yellow' },
    { value: 17, text: 'Preparando devolução', color: 'red' },
    { value: 18, text: 'Correção', color: 'orange' },
    { value: 19, text: 'Aguardando Peça', color: 'yellow' },
    { value: 20, text: 'Aguardando retorno ao Cliente', color: 'yellow' },
    { value: 21, text: 'Aguardando ficha cadastral', color: 'yellow' },
    { value: 22, text: 'Aguardando pedido de compra', color: 'yellow' },
    { value: 23, text: 'Pedido de compra divergente', color: 'red' },
    { value: 24, text: 'Bloqueio Financeiro', color: 'red' }
];

export const shippingType : IOption[] = [
    { text: 'NÃO SE APLICA', value: 1},
    { text: 'CLIENTE NÃO SOLICITOU', value: 2},
    { text: 'SOLICITADA', value: 3},
    { text: 'EM PROCESSAMENTO', value: 4},

]

export const reportTypes: RMAreportTypes[] = [
    { text: 'RMA', value: 0, restrict: false },
    { text: 'Ordens de Serviço', value: 1, restrict: false },
    // { text: 'Chat', value: 2, restrict: true },
    { text: 'Pesquisa de Satisfação', value: 3, restrict: true },
    // { text: 'Parceiros', value: 4, restrict: true }
];

// Já tem no Backend
export const typeOptions: RmaOption[] = [
    { value: 1, text: 'Garantia de Serviços', color: 'blue' },
    { value: 2, text: 'Garantia de Fábrica', color: 'yellow' },
    { value: 3, text: 'Troca em Garantia', color: 'green' },
    { value: 4, text: 'Contrato de Manutenção', color: 'orange' },
    { value: 5, text: 'Avulso', color: 'blue' },
    { value: 6, text: 'Outros', color: 'red' }
];

export const modelXpto: RmaOption[] = [
    { value: 1, text: 'Model teste 1', color: 'blue' },
    { value: 2, text: 'Model teste 2', color: 'yellow' }
];
// Já tem no Backend

export const localXpto: RmaOption[] = [
    { value: 1, text: 'Garantia de Serviços', color: 'blue' },
    { value: 2, text: 'Garantia de Fábrica', color: 'yellow' },
    { value: 3, text: 'Troca em Garantia', color: 'green' },
    { value: 4, text: 'Contrato de Manutenção', color: 'orange' },
    { value: 5, text: 'Avulso', color: 'blue' },
    { value: 6, text: 'Outros', color: 'red' }
];
// Já tem no Backend

export const problemaXpto: RmaOption[] = [
    { value: 1, text: 'Garantia de Serviços', color: 'blue' },
    { value: 2, text: 'Garantia de Fábrica', color: 'yellow' },
    { value: 3, text: 'Troca em Garantia', color: 'green' },
    { value: 4, text: 'Contrato de Manutenção', color: 'orange' },
    { value: 5, text: 'Avulso', color: 'blue' },
    { value: 6, text: 'Outros', color: 'red' }
]

export const serviceTypeOptions: RmaOptionCA[] = [
    { value: 1, text: 'Repair & Return'},
    { value: 2, text: 'Advanced Exchange'},
];

export const support: RmaOptionCA[] = [
    { value: 1, text: 'Warrantly' },
    { value: 2, text: 'Billable'},
    { value: 3, text: 'Standard Repair'},
    { value: 4, text: 'AE Common Pool'},
    { value: 5, text: 'AE Premier Pool'},
];

export const typeRequest: IOption[] = [
    { value: 1, text: 'Solicitação de arquivo' },
    { value: 2, text: 'Solicitação de Alt. de Enderço' },
    { value: 3, text: 'Solicitação de Alt. de Remessa' },
    { value: 4, text: 'Solicitação de Alt. de Equipamentos' }
];

export const attendanceTime: IOption[] = [
    { value: '1', text: '08:00-12:00 & 13:00-17:30' },
    { value: '2', text: '08:00-12:00 & 13:00-18:00' },
    { value: '3', text: '08:00-12:00 & 13:00-18:30' },
    { value: '4', text: '08:00-17:30' }
];

export const materialType: IOption[] = [
    { value: '1', text: 'Eletrônico' },
    { value: '2', text: 'Outros' }
];
export const typeFile: IOption[] = [
    { value: 'NOTA_FISCAL_COMPRA', text: 'Nota Fiscal de Compra' },
    { value: 'PEDIDO_COMPRA', text: 'Pedido de Compra' },
    { value: 'AUTORIZACAO_POSTAGEM', text: 'Autorização de Postagem' },
    { value: 'OTHER', text: 'Outros' }
];

export const typeSituation: IOption[] = [
    //{ value: 1, text: 'Indefinido' },
    { value: 2, text: 'Logística Reversa Coleta - Colta Domiciliar' },
    { value: 3, text: 'Logística Reversa - Postagem' },
    { value: 4, text: 'Transportadora' },
    { value: 5, text: 'Não se Aplica' },
    { value: 6, text: 'Em Processamento' }
];

export const modelOptions: IOption[] = [
    { value: 1, text: 'N1' },
    { value: 2, text: 'N2' },
    { value: 3, text: 'N3' }
];

export const problems: IOption[] = [
    { value: 1, text: 'Adesivo' },
    { value: 2, text: 'Alça de Mão' },
    { value: 3, text: 'Alto Falante/Microfone' },
    { value: 4, text: 'Antena' },
    { value: 5, text: 'Barra de Corte' },
    { value: 6, text: 'Bateria' },
    { value: 7, text: 'Berço de Comunicação' },
    { value: 8, text: 'Bluetooth' },
    { value: 9, text: 'Borracha Lateral' },
    { value: 10, text: 'Botão' },
    { value: 11, text: 'Botão Lateral' },
    { value: 12, text: 'Botão Liga-Desliga' },
    { value: 13, text: 'Cabeçote' },
    { value: 14, text: 'Cabo Externo' },
    { value: 15, text: 'Cabo Interno' },
    { value: 16, text: 'Câmera' },
    { value: 17, text: 'Carcaça' },
    { value: 18, text: 'Conector' },
    { value: 19, text: 'Contato' },
    { value: 20, text: 'Cutter' },
    { value: 21, text: 'Display' },
    { value: 22, text: 'Fonte de Alimentação' },
    { value: 23, text: 'Gatilho' },
    { value: 24, text: 'GPS' },
    { value: 25, text: 'GSM/GPRS' },
    { value: 26, text: 'Guia' },
    { value: 27, text: 'Janela de Leitura' },
    { value: 28, text: 'Mecanismo' },
    { value: 29, text: 'Memória' },
    { value: 30, text: 'Placa Principal' },
    { value: 31, text: 'RFID' },
    { value: 32, text: 'Rolete' },
    { value: 33, text: 'Scanner' },
    { value: 34, text: 'Sensor' },
    { value: 35, text: 'Software' },
    { value: 36, text: 'Suporte' },
    { value: 37, text: 'Tampa do Chip' },
    { value: 38, text: 'Teclado' },
    { value: 39, text: 'Touch Panel' },
    { value: 40, text: 'Touch Screen' },
    { value: 41, text: 'Wi-Fi' },
    { value: 42, text: 'Outro' }
];

export const workOrderStatus: WorkOrderOption[] = [
    { text: 'Em Análise', value: [1, 2, 3, 4, 5, 6], color: 'yellow' },
    { text: 'Aguardando Aprovação', value: [7], color: 'yellow' },
    { text: 'Aprovado', value: [10], color: 'green' },
    { text: 'Equipamento em Manutenção', value: [11, 12, 13, 14], color: 'yellow' },
    { text: 'Nota Fiscal Emitida', value: [16], color: 'green' },
    { text: 'Equipamento Expedido', value: [17], color: 'green' },
    { text: 'Aguardando Peças', value: [9], color: 'yellow' },
    { text: 'Reprovado', value: [8], color: 'red' },
    { text: 'Preparando para devolução', value: [15], color: 'yellow' },
    { text: 'Em processamento', value: [18], color: 'yellow' },
    { text: 'Bloqueio Financeiro', value: [19], color: 'red' }
];

export const workOrderDefaultStatus: WorkOrderOption = { text: 'Em Análise', value: [], color: 'yellow' };
export const maintenanceStatus: IOption[] = [
    { value: 1, text: 'Aguardando Aprovação' },
    { value: 2, text: 'Aprovado Parcialmente' },
    { value: 3, text: 'Reprovado' },
    { value: 4, text: 'Aprovado' }
];
export const statusRmaCA: RmaStatusCA[] = [
    { text: "Awaiting Processing", value: 0, color: "yellow", toRma: true, description: "Arguardando Avaliação" },
    { text: "Completed", value: 1, color: "green", toRma: true, description: "Completed" },
    { text: "Open", value: 2, color: "blue", toRma: true, description: "Open" }
]
export const supportLevelCA: RmaSupportLevelCA[] = [
    {value: 0, text: "Repair & Return" , support: ["Warranty","Billable","Standard Repair"], color: 'green'},
    {value: 1, text: "Advanced Exchange" , support: ["AE Common Pool","AE Premier Pool"], color: 'green'}

]
export const YesNo: TrueFalse[]=[
    {value: true, text: "Yes"},
    {value: false, text: "No"}
]
export const modelShippingCondition: IOption[] = [
    { value: 1, text: 'Standard' },
    { value: 2, text: 'Overnight' },
];