import React from 'react';
import 'styles/repaircenter.scss';
type Props = {
    title: string;
    value?: string | number | any;
    children?: React.ReactNode;
    className?: string;
};
const LabelDescription: React.FC<Props> = (props: Props) => {
    return (
        <div className={['column', props.className].join(' ')}>
            <span className="info-title">{props.title}</span>
            <span className="info-desc"> {props.children ?? props.value} </span>
        </div>
    );
};

export default LabelDescription;
