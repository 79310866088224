import { Checkbox, Divider, Table } from '@scuf/common';
import { BudgetItems, WorkOrderBudgerModel } from 'models';
import React from 'react';
import { useTranslation } from 'react-i18next';
type Props = {
    budget: WorkOrderBudgerModel;
};
const ServicesTable: React.FC<Props> = ({ budget }: Props) => {
    const { t } = useTranslation(['common']);
    let header: string[] = ['PartNumber', 'Description', 'Value', 'Quantity', 'Total'];
    const getCurrentList = (): BudgetItems[] => {
        return budget.items.filter((e: BudgetItems) => e.ncm === '0000.00.00');
    };

    const handleServType = (item: BudgetItems): boolean => {
        return item.serviceType != 1 && item.serviceType != 5;
    };
    const handleValue = (item: BudgetItems): string => {
        if (handleServType(item)) return '-';
        return (item.currency === 2 ? item.unitaryValue * budget?.currencyRate : item.unitaryValue).toFixed(2);
    };

    const handleTotal = (item: BudgetItems): string => {
        if (handleServType(item)) return '-';
        return (
            item.currency === 2
                ? (Math.round(item.unitaryValue * item.quantity * 100) / 100 +
                      Math.round(item.tax.ipi * 100) / 100 +
                      Math.round(item.tax.st * 100) / 100) *
                  budget.currencyRate
                : Math.round(item.unitaryValue * item.quantity * 100) / 100 +
                  Math.round(item.tax.ipi * 100) / 100 +
                  Math.round(item.tax.st * 100) / 100
        ).toFixed(2);
    };

    return (
        <>
            {getCurrentList().length > 0 && (
                <div>
                    <Divider />

                    <h2>{t('common:services.Services')}</h2>

                    <Table className="more-information-table">
                        <Table.Header>
                            {header.map((e: string, index: number) => (
                                <Table.HeaderCell key={index}>{t(`translation.${e}`)}</Table.HeaderCell>
                            ))}
                        </Table.Header>
                        <Table.Body>
                            {getCurrentList().map((e: BudgetItems, index: number) => (
                                <Table.Row key={e.code}>
                                    <Table.Cell>{e.number}</Table.Cell>
                                    <Table.Cell>{e.description}</Table.Cell>
                                    <Table.Cell>{handleValue(e)}</Table.Cell>
                                    <Table.Cell>{e.quantity}</Table.Cell>
                                    <Table.Cell>{handleTotal(e)}</Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </div>
            )}
        </>
    );
};

export default ServicesTable;
