import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'styles/main.scss';

function ResumeBar(props: any) {

    const { t } = useTranslation(['repaircenter', 'common', 'user']);

    const [stepColors, setStepColors] = useState(['#1976d2', '#4d444461', '#4d444461']);

    useEffect(() => {
        if (props.page == 'newRmaCA') {
            setStepColors(['#1976d2', '#4d444461', '#4d444461']);
        } else if (props.page == 'detailsRmaCA') {
            setStepColors(['#4d444461', '#1976d2', '#4d444461']);
        } else {
            setStepColors(['#4d444461', '#4d444461', '#1976d2']);
        }
    }, [props])

    return (
        <>
            <div className='resumeBarContainer'>
                <div className='space'>
                    <span className='step1' style={{ backgroundColor: stepColors[0] }}>1</span>
                    {t('repaircenter:translation.ServiceBag')}
                </div>
                <div className="line" />
                <div className='space'>
                    <span className='step2' style={{ backgroundColor: stepColors[1] }}>2</span>
                    {t('common:translation.Details')}
                </div>  
                <div className="line" />
                <div className='space'>
                    <span className='step3' style={{ backgroundColor: stepColors[2] }}>3</span>
                    {t('repaircenter:translation.Resume')}
                </div>  
                    
            </div>
        </>
    );
};

export default ResumeBar;
