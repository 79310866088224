import { Button, Grid, Input } from '@scuf/common';
import useFetch from 'helpers/hooks/useFetch';
import { OrderDTO } from 'models/spareParts/orderById';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { SPARE_PARTS } from 'resources';
import { orderAtom } from 'resources/atoms/spareParts';

function MicrosigaFunctionalities(): JSX.Element {
    const [orderData, setorderData] = useRecoilState(orderAtom);
    const [change, setChange] = useState<boolean>(false);
    const [number, setNumber] = useState(orderData?.numberPV);
    const [numberQuotation, setNumberQuotation] = useState(orderData?.numberQuotation);
    const { t } = useTranslation(['spareparts']);
    const [, editOrder, loading] = useFetch<OrderDTO>({ url: SPARE_PARTS.editOrder, method: 'POST' });
    const [, createOrderInMs, loadingMicrosiga] = useFetch<OrderDTO>({ url: SPARE_PARTS.generateInMS, method: 'POST' });

    const handleSave = async (): Promise<void> => {
        await editOrder({ ...orderData, numberPV: number, numberQuotation: numberQuotation }, current =>
            setorderData(old => ({ ...old, numberPV: current.data.numberPV, numberQuotation: current.data.numberQuotation }))
        );
        setChange(false);
    };

    const handleGenerateInMicrosiga = async (): Promise<void> => {
        await createOrderInMs({ ...orderData }, current => {
            setorderData(old => ({ ...old, numberQuotation: current.data?.value?.numberQuotation }));
            setorderData(old => ({ ...old, numberPV: current.data?.value?.numberPV }));
            setNumber(current.data?.value?.numberPV);
        });

        setChange(false);
    };

    return (
        <>
            <Grid>
                <Grid.Column className="grid-with-gap">
                    <Input
                        value={number}
                        label={t('Alterar PV Manualmente')}
                        onChange={value => {
                            setChange(value !== '' ? true : false);
                            setNumber(value);
                        }}
                    ></Input>
                    {orderData.typeId == 2 ? (
                        <Input
                            value={numberQuotation}
                            label={t('Alterar Número Cotação')}
                            onChange={value => {
                                setChange(true);
                                setNumberQuotation(value !== '' ? value : undefined);
                            }}
                        ></Input>
                    ) : (
                        ''
                    )}
                </Grid.Column>
            </Grid>
            <div className="buttonsSearch">
                <Button
                    loading={loadingMicrosiga}
                    disabled={loadingMicrosiga}
                    content={t('Gerar no Microsiga')}
                    onClick={() => {
                        handleGenerateInMicrosiga();
                    }}
                ></Button>
                <Button
                    content={t('common:actions.Cancel')}
                    type="secondary"
                    disabled={!change}
                    onClick={() => {
                        setChange(false);
                        setNumber(orderData?.numberPV);
                        setNumberQuotation(orderData?.numberQuotation);
                    }}
                ></Button>
                <Button disabled={!change} content={t('common:actions.Save')} loading={loading} onClick={handleSave}></Button>
            </div>
        </>
    );
}
export default MicrosigaFunctionalities;
