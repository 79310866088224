import { Accordion } from '@scuf/common';
import { useTranslation } from 'react-i18next';
import {
    AdmAddressAccordion,
    AdmEquipmentsAccordion,
    AdmOthersAccordion,
    AdmShippingTab,
    AdmWorkOrdersAccordion,
    ChangeModality,
    ChangeStatus
} from '../admAccordion';
import Processing from '../admAccordion/processing';
import { useRecoilState } from 'recoil';
import { RmaModelLite } from 'models';
import { RmaLite } from 'resources';

function Administrative(): JSX.Element {
    const { t } = useTranslation(['repaircenter', 'common', 'user']);
    const [rmaState, setRmaState] = useRecoilState<RmaModelLite>(RmaLite);

    return (
        <div className="container-administrative">
            <Accordion>
                <Accordion.Content title={t('common:actions.OrderProcessing')}>
                    <Processing rmaState={rmaState} setRmaState={setRmaState} />
                </Accordion.Content>
                <Accordion.Content title={t('common:translation.Status')}>
                    <ChangeStatus rmaState={rmaState} setRmaState={setRmaState} />
                </Accordion.Content>
                <Accordion.Content title={t('common:translation.Type')}>
                    <ChangeModality rmaState={rmaState} setRmaState={setRmaState} />
                </Accordion.Content>
                <Accordion.Content title={t('common:translation.Equipments')}>
                    <AdmEquipmentsAccordion rmaState={rmaState} setRmaState={setRmaState} />
                </Accordion.Content>
                <Accordion.Content title={t('common:translation.Address')}>
                    <AdmAddressAccordion rmaState={rmaState} setRmaState={setRmaState} />
                </Accordion.Content>
                <Accordion.Content title={t('rma.order.Shipping')}>
                    <AdmShippingTab rmaState={rmaState} setRmaState={setRmaState} />
                </Accordion.Content>
                <Accordion.Content title={t('rma.budget.WorkOrder')}>
                    <AdmWorkOrdersAccordion></AdmWorkOrdersAccordion>
                </Accordion.Content>
                <Accordion.Content title={t('rma.admin.others.Name')}>
                    <AdmOthersAccordion rmaState={rmaState} setRmaState={setRmaState} />
                </Accordion.Content>
            </Accordion>
        </div>
    );
}
export default Administrative;
