import React, { useEffect, useRef } from 'react';
import { useRecoilState } from 'recoil';
import { UserManager } from 'oidc-client-ts';
import { userOIDCState } from 'resources/atoms';
import { storeUser } from './authActions';
import { setAuthHeader } from './axiosHeader';

export function AuthProvider(props: { userMananager: UserManager; children: any }) {
    const [userOIDC, setUserOIDC] = useRecoilState(userOIDCState);
    let userManager = useRef<UserManager>();

    useEffect(() => {
        userManager.current = props.userMananager;

        const onUserLoaded = (user: any) => {
            //
            // ;
            setUserOIDC(storeUser(user));
        };

        const onUserUnloaded = () => {
            setAuthHeader(null);
            //
        };

        const onAccessTokenExpiring = () => {
            //
        };

        const onAccessTokenExpired = () => {
            //
        };

        const onUserSignedOut = () => {
            //
        };

        // events for user
        userManager.current.events.addUserLoaded(onUserLoaded);
        userManager.current.events.addUserUnloaded(onUserUnloaded);
        userManager.current.events.addAccessTokenExpiring(onAccessTokenExpiring);
        userManager.current.events.addAccessTokenExpired(onAccessTokenExpired);
        userManager.current.events.addUserSignedOut(onUserSignedOut);

        // Specify how to clean up after this effect:
        return function cleanup() {
            userManager.current?.events.removeUserLoaded(onUserLoaded);
            userManager.current?.events.removeUserUnloaded(onUserUnloaded);
            userManager.current?.events.removeAccessTokenExpiring(onAccessTokenExpiring);
            userManager.current?.events.removeAccessTokenExpired(onAccessTokenExpired);
            userManager.current?.events.removeUserSignedOut(onUserSignedOut);
        };
    }, [props.userMananager, userOIDC]);

    return React.Children.only(props.children);
}
