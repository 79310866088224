import { Badge, Grid } from '@scuf/common';
import { dateFormatFromMicrosft, formatDate } from 'helpers';
import { statusOpt, typeOpt } from 'models/spareParts';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { orderAtom, orderByIdState } from 'resources/atoms/spareParts';
import LabelDescription from '../../../../components/labelDescription';
import '../../../../styles/repaircenter.scss';
import { OrderDTO } from 'models/spareParts/orderById';

const General: React.FC = () => {
    const [orderData] = useRecoilState<OrderDTO>(orderAtom);
    const { t } = useTranslation(['spareparts', 'common']);


    return (
        <>
            <Grid>
                <Grid.Row>
                    <Grid.Column className="grid-column" width={3} mWidth={6} sWidth={12}>
                        <LabelDescription title={t('common:translation.Id')} value={orderData?.id} />
                        <LabelDescription title={t('common:translation.Date')} value={formatDate(orderData?.createdAt)} />
                        <LabelDescription
                            title={t('spareparts:translation.Destination')}
                            value={
                                orderData?.destination == 1
                                    ? t('common:translation.Resale')
                                    : t('common:translation.UseConsumption')
                            }
                        />
                    </Grid.Column>

                    <Grid.Column className="grid-column" width={3} mWidth={6} sWidth={12}>
                        <LabelDescription
                            title={t('common:translation.Type')}
                            value={
                                <>
                                    <Badge color={typeOpt.find(e => e.value === orderData?.typeId)?.color}>
                                        {typeOpt.find(e => e.value === orderData?.typeId)?.text}
                                    </Badge>
                                </>
                            }
                        />
                        <LabelDescription
                            title={t('translation.InvoicePartial')}
                            value={orderData?.allowPartial == false ? 'Não Permitido' : 'Permitido'}
                        />
                        <LabelDescription title={t('common:translation.LegacyId')} value={orderData?.legacyId}></LabelDescription>
                    </Grid.Column>
                    <Grid.Column className="grid-column" width={3} mWidth={6} sWidth={12}>
                        <LabelDescription
                            title={t('common:translation.Status')}
                            value={
                                <>
                                    <Badge color={statusOpt.find(e => e.value === orderData?.statusId)?.color}>
                                        {statusOpt.find(e => e.value === orderData?.statusId)?.text}
                                    </Badge>
                                </>
                            }
                        />
                        <LabelDescription
                            title={t('translation.Transport')}
                            value={orderData?.shippingTypeId == 1 ? 'Honeywell' : 'Outra'}
                        />
                        <LabelDescription title={t('common:translation.Number')} value={orderData?.number}></LabelDescription>
                    </Grid.Column>
                    <Grid.Column className="grid-column" width={3} mWidth={6} sWidth={12}>
                        <LabelDescription title={t('translation.NumberPV')} value={orderData?.numberPV} />
                        {orderData?.type?.name == "COTAÇÃO" ?
                            <LabelDescription title={t('common:translation.QuotationNumber')} value={orderData?.numberQuotation} /> : ""}
                        <LabelDescription title={t('repaircenter:rma.order.Observations')} value={orderData?.observation}></LabelDescription>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    );
};

export default General;
