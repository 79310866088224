import { Loader } from '@scuf/common';
import { DataTable } from '@scuf/datatable';
import { OrderChatDTO } from 'models/spareParts/orderById';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { getChatWithMessagesNotReadOrLatest } from 'resources/api/spareParts_api/getChatWithMessagesNotReadOrLatest';
import 'styles/repaircenter.scss';

function SummaryChat() {
    const { t } = useTranslation(['spareparts', 'common', 'user']);
    const navigate: NavigateFunction = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [chat, setChat] = useState<OrderChatDTO[]>();
    const [tableChat, setTableChat] = useState<OrderChatDTO[]>([]);
    const [totalMessagesNotRead, setTotalMessagesNotRead] = useState<any[]>([]);

    // async function lastEvents() {
    //     setLoading(true);
    //     const resp = await getChatWithMessagesNotReadOrLatest();
    //     if (resp.error) {
    //         return resp.status;
    //     } else {
    //         let listChat: OrderChatDTO[] = [];
    //         resp?.data.forEach((item: any) => {
    //             listChat.push({
    //                 CompanyCnpj: item?.CompanyCnpj,
    //                 CompanyName: item?.CompanyName,
    //                 Messages: item?.Messages,
    //                 OrderId: item?.OrderId,
    //                 TotalMessages: item?.TotalMessages,
    //                 TotalMessagesNotRead: item?.TotalMessagesNotRead,
    //                 TotalMessagesNotReadByAdmin: item?.TotalMessagesNotReadByAdmin,
    //                 TotalMessagesNotReadByCustomer: item?.TotalMessagesNotReadByCustomer
    //             });
    //             setTotalMessagesNotRead(item?.TotalMessagesNotRead);
    //         });
    //         setTableChat(listChat);
    //         setChat(listChat);
    //     }
    //     setLoading(false);
    // }

    // useEffect(() => {
    //     lastEvents();
    // }, []);

    return (
        <>
            <div>
                {/* {loading ? <Loader text={t('common:actions.Loading')} /> : <div />} */}
                <div className="div-datatable">
                    <h3>{t(`translation.UnreadMessages`) + totalMessagesNotRead}</h3>
                    <DataTable
                        data={tableChat}
                        search
                        rows={tableChat?.length}
                        onCellClick={e => navigate('chat', { state: { id: e.rowData.OrderId } })}
                    >
                        <DataTable.Column field="OrderId" header={t('common:translation.Order')} sortable initialWidth={180} />
                        <DataTable.Column field="CompanyName" header={t('user:company.Name')} sortable />
                        <DataTable.Column field="CompanyCnpj" header={t('user:company.Id')} sortable />
                        <DataTable.Pagination totalItems={tableChat?.length} itemsPerPage={tableChat?.length} />
                    </DataTable>
                </div>
            </div>
        </>
    );
}
export default SummaryChat;
