import { RmaOption, RmaRequest } from 'models/repaircenter';

export const status: RmaOption[] = [
    { value: 0, text: 'Aguardando aprovação', color: 'yellow' },
    {
        value: 1,
        text: 'Reprovado',
        color: 'red'
    },
    {
        value: 2,
        text: 'Aprovado',
        color: 'green'
    },
    {
        value: 3,
        text: 'Aprovado parcialmente',
        color: 'yellow'
    }
];
export const statusReport: RmaRequest[] = [
    { value: 1, text: 'Aguardando aprovação' },
    { value: 2, text: 'Reprovado'},
    { value: 3, text: 'Aprovado'},
];

export const modalitys: RmaRequest[] = [
    {
        value: 1,
        text: 'Contrato'
    },
    {
        value: 2,
        text: 'Garantia'
    },
    {
        value: 3,
        text: 'Avulso'
    },
    {
        value: 4,
        text: 'Visita'
    }
];
