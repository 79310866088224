import { t } from "i18next";
import { CustomErrorArgs, CustomErrorBoundary } from "models/error/errorModel";

export class UnexpectedError extends Error implements CustomErrorBoundary {
    code:string;
    showMessage: string = t('actions.TryAgain');
    // onClick =  async () => {
    //     window.location.replace('/')
    // };
    constructor(params?:CustomErrorArgs){
        super(t('errors.Unexpected'))
        this.code = params?.code ?? '500';
    }
}