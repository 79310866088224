import { RmaChatModel, NewDetailsRmaModel, NewEquipmentModel, RmaDetailModel, RmaModel, RmaResult, RmaFilterModel, RmaModelLite } from 'models';
import { AddItemInCartModel, CreatNewRmaModel, CreatNewRmaModelCA, CreatNewRmaModelCATest, ItemsCart, Result, RmaChatTable } from 'models/repaircenter/detailsRma';
import { atom } from 'recoil';
import { persistAtom } from './persistanceAtom';
import { ModelEOS, ModelEOSEdit, ModelEOSResult } from 'models/repaircenter/eosModel';

export const newEquipamentState = atom({
    key: 'newEquipament',
    default: [new NewEquipmentModel()]
});

export const newDetailsRmaState = atom({
    key: 'newDetailsRma',
    default: [new NewDetailsRmaModel()]
});

export const rmaState = atom({
    key: 'rma',
    default: new RmaResult(),
    effects_UNSTABLE: [persistAtom]
});

export const rmaDetailState = atom({
    key: 'rmaDetailState',
    default: null as unknown as RmaDetailModel
});

export const messageModel = atom({
    key: 'rmaMessageModel',
    default: null as unknown as RmaChatModel
});

export const AddItemCartState = atom({
    key: 'newRmaState',
    default: null as unknown as AddItemInCartModel
});

export const ItemInCart = atom({
    key: 'ItemInCart',
    default: null as unknown as ItemsCart
});

export const ItemsCartAdd = atom({
    key: 'ItemsCartModelAdd',
    default: null as unknown as ItemsCart[]
});

export const CreatNewRma = atom({
    key: 'CreatNewRmas',
    default: null as unknown as CreatNewRmaModel
});
export const ChatRma = atom({
    key: 'ChatRma',
    default: null as unknown as RmaChatTable[]
});

export const CreateNewRmaCA = atom({
    key: 'CreatNewRmaCA',
    default: null as unknown as CreatNewRmaModelCA
});

export const CreateNewRmaCATest = atom({
    key: 'CreatNewRmaCA',
    default: null as unknown as CreatNewRmaModelCATest
});

export const RmaItems = atom({
    key: 'CreatNewRmasCA',
    default: null as unknown as Result
});

export const Rma = atom({
    key: 'RmaModel',
    default: null as unknown as RmaModel
});

export const RmaLite = atom({
    key: 'RmaModelLite',
    default: null as unknown as RmaModelLite
});

export const EosAtom = atom({
    key: 'EosAtom',
    default: null as unknown as ModelEOSResult[]
})
export const EosListAtom = atom({
    key: 'EosAtom',
    default: null as unknown as ModelEOS[]
})
export const FilterAtom = atom({
    key: 'FilterAtom',
    default: null as unknown as RmaFilterModel
});
export const RMAFileAtom = atom({
    key: 'RMAFileAtom',
    default: null as unknown as File|null
});

// export const RmaItemCA = atom({
//     key: 'CreatNewItemCA',
//     default: null as unknown as RmaItemsCA
// })
