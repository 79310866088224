import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'styles/main.scss';
import usePreventClickjacking from 'helpers/hooks/usePreventingClickjacking';

function ContactPage() {
    const tam = 845;
    const [width, setWidth] = useState<number>(0);
    const { t } = useTranslation(['user', 'common', 'spareparts', 'home']);

    function handleResize() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        document.title = 'Contato - Portal Honeywell';
        setWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
    }, []);

    usePreventClickjacking();

    return (
        <div className="container">
            <div>
                <div className="contentPageContainer">
                    {width > tam ? (
                        <img alt="imagem ilustrativa impressoras" src={require('../../assets/IMG_9450.jpg')} />
                    ) : (
                        <div />
                    )}
                    <div className="container-peq">
                        <h3>{t('home:contact.title')}</h3>
                        <a>{t('address.Phone')}</a> +55 (35) 3629-9000 - {t('common:translation.Option2')}
                        <br />
                        <a>{t('info.Email')}: </a>
                        <a href="malito:centrodereparos@honeywell.com">centrodereparos@honeywell.com</a>
                        <h3>{t('home:contact.TechnicalSupport')}</h3>
                        <a>{t('address.Phone')}</a> +55 (35) 3629-9000 - {t('common:translation.Option4')} <br />
                        <a>{t('info.Email')}: </a>
                        <a href="malito:ACSHSMSuporteBrasil@honeywell.com">ACSHSMSuporteBrasil@honeywell.com</a>
                        <br /> <a>{t('Portal')}:</a>{' '}
                        <a href="https://support.honeywellaidc.com/s/">{t('home:contact.GlobalSupport')}</a>{' '}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactPage;
