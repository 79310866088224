import { Button, Select } from '@scuf/common';
import { getRmaStatus } from 'helpers';
import { blockedRmaStatus, statusOptions, RmaModel, RmaModelLite } from 'models';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { Rma, REPAIR_CENTER, RmaLite } from 'resources';
import useFetch from 'helpers/hooks/useFetch';

interface ChangeStatusProps {
    rmaState: RmaModelLite;
    setRmaState: React.Dispatch<React.SetStateAction<RmaModelLite>>;
}

function ChangeStatus({ rmaState, setRmaState }: ChangeStatusProps): JSX.Element {
    const statusList = statusOptions.filter(element => blockedRmaStatus.includes(element.value));
    const [currentStatus, setCurrentStatus] = useState(getRmaStatus(rmaState.statusId));
    const { t } = useTranslation(['common']);

    const [updateStatus, changeStatusRmaByAdmin, loading] = useFetch<RmaModelLite>({
        url: REPAIR_CENTER.changeStatusRmaByAdmin,
        method: 'POST',
        onError: 'Change',
        onSuccess: 'Change'
    });

    const changeRmaStatus = async (newRma: RmaModelLite): Promise<void> => {
        await changeStatusRmaByAdmin(newRma, current => {
            current.error
                ? setRmaState(old => ({ ...old, statusId: currentStatus!.value }))
                : setRmaState({
                      ...rmaState,
                      statusId: current.data.statusId,
                      status: { name: currentStatus?.text ?? '', id: currentStatus?.value ?? 0 }
                  });
        });
    };
    return (
        <div className="container-changes">
            <div className="container-selects">
                <Select
                    className="select"
                    options={statusList}
                    disabled
                    label={t('translation.From')}
                    placeholder={statusList.find(e => e.value === rmaState?.statusId)?.text}
                />
                <Select
                    className="select"
                    indicator="required"
                    placeholder={t('placeholders.Select.NewValue')}
                    options={statusList}
                    label={t('translation.To')}
                    onChange={val => {
                        setCurrentStatus(getRmaStatus(val));
                    }}
                />
            </div>
            <div className="container-button">
                <Button
                    content={t('actions.Change')}
                    disabled={currentStatus?.value === rmaState.statusId}
                    loading={loading}
                    onClick={async () => {
                        await changeRmaStatus({ ...rmaState, statusId: currentStatus!.value });
                    }}
                />
            </div>
        </div>
    );
}
export default ChangeStatus;
