import { ChatSidebar } from 'components';
import useFetch from 'helpers/hooks/useFetch';
import React, { useEffect, useState } from 'react';
import { COMPANY_MANAGER, ChatRma, REPAIR_CENTER } from 'resources';
import RepairCenterChat from './chat';
import { RmaChatTable, SparePartsChatTable } from 'models/repaircenter/detailsRma';
import useUser from 'helpers/hooks/useUser';
import { useRecoilState } from 'recoil';
import { CompanyFilterDTO } from 'models';
type RepairCenterChatType = {
    roleId?: number;
};
const RmaAdminChat: React.FC<RepairCenterChatType> = () => {
    const [currentChat, setCurrentChat] = useState<number>();
    const { user } = useUser();
    const [unreadMessage, setUnreadMessage] = useState<number>(0);
    const [chatsRma, setchatsRma] = useRecoilState<RmaChatTable[]>(ChatRma)
    const [chats, loadChats, loading, updateChat] = useFetch<{ roleId: number, ssoId: string, companyId: number}, RmaChatTable[]>({
        url: REPAIR_CENTER.getAllChats,
    });
    const [companyFilter, getCompanies, loadingData, setFilter] = useFetch<CompanyFilterDTO, CompanyFilterDTO>({
        url: COMPANY_MANAGER.getCompanies,
        method: 'POST',
        onError: 'LoadInfo'
    });
    const handleData = async (indice = 1): Promise<void> => {
        getCompanies({ ...companyFilter?.data, indice, CompanyId: Array.from(new Set(
            (chatsRma?.map(item => item?.companyId) || []).filter(companyId => companyId !== null) as number[]
          ))});

    };

    useEffect(() => {
        handleData();

    }, []);

    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            {!loading && (
                <>
                    <ChatSidebar
                        company = {companyFilter?.data?.companies!}
                        items={chatsRma?.map(e => ({ ...e, id: e.rmaId }))}
                        onChange={(id: number) => {
                            setchatsRma(old => [...old].map(e => (e?.orderId === id ? { ...e, itWasReadByAdmin: true } : e)));
                            setCurrentChat(id);
                        }}
                    />
                    {currentChat && <RepairCenterChat rmaId={currentChat} company={companyFilter?.data?.companies!}/>}
                </>
            )}
        </div>
    );
};

export default RmaAdminChat;
