import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BaseHeader from './baseHeader';
import UserHeader from './userHeader';
import useUser from 'helpers/hooks/useUser';
type Props = {
    title: string;
};
const CustomHeader: React.FC<Props> = ({ title }: Props) => {
    const [message, setMessage] = useState<string>('');
    const [show, setShow] = useState<boolean>(false);
    const { user } = useUser();
    const navigate = useNavigate();
    const userIsLoggedIn = (): boolean => {
        return false;
    };

    return (
        <>
            {!user ? (
                <BaseHeader
                    title={title}
                    messageState={{ message, setMessage }}
                    showState={{ show, setShow }}
                    navigate={navigate}
                />
            ) : (
                <UserHeader title={title} navigate={navigate} />
            )}
        </>
    );
};

export default CustomHeader;
