import { Button, Divider, TextArea } from '@scuf/common';
import useFetch from 'helpers/hooks/useFetch';
import { RmaDeleted, RmaModel, RmaModelLite } from 'models';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { REPAIR_CENTER, Rma } from 'resources';

interface ChangeStatusProps {
    rmaState: RmaModelLite;
    setRmaState: React.Dispatch<React.SetStateAction<RmaModelLite>>;
}

function AdmOthersAccordion({ rmaState, setRmaState }: ChangeStatusProps): JSX.Element {
    const [reason, setReason] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const { t } = useTranslation(['repaircenter', 'common']);
    const [deleted, deactivateRma] = useFetch<RmaDeleted>({
        url: REPAIR_CENTER.deactivateRma,
        method: 'POST',
        onError: 'Removed',
        onSuccess: 'Removed'
    });
    const navigate = useNavigate();

    const handleDelete = async (): Promise<void> => {
        setLoading(true);
        var rmaDeleted = rmaState.rmaDeleted;
        await deactivateRma({ RmaId: rmaState?.id, Reason: reason }, current => {
            navigate('/repair-center');
        });
        setLoading(false);
    };

    return (
        <>
            <Divider>{t('rma.admin.others.DeleteRMA')}</Divider>

            <TextArea
                style={{ resize: 'none !important' }}
                label={t('rma.admin.others.Reason')}
                onChange={value => setReason(value)}
                fluid
                placeholder={t('repaircenter:rma.admin.others.ReasonDeleteRMA')}
            ></TextArea>
            <div className="buttonsSearch">
                <Button
                    content={t('common:actions.Delete')}
                    type="secondary"
                    loading={loading}
                    disabled={!reason || loading}
                    onClick={handleDelete}
                ></Button>
            </div>
        </>
    );
}

export default AdmOthersAccordion;
