import { Button, FileDrop, Modal } from '@scuf/common';
import { useTranslation } from 'react-i18next';

interface ModalFileMaintenanceReportProps {
    open: boolean;
    setSelectedFile: (file: File) => void;
    closeModal: () => void;
}

const ModalFileMaintenanceReport: React.FC<ModalFileMaintenanceReportProps> = ({ open, setSelectedFile, closeModal }) => {
    const { t } = useTranslation(['common', 'spareparts']);

    const modalDropFileUpload = () => {
        return (
            <Modal closeIcon={true} className="popup-theme-wrap" onClose={closeModal} open={open} size="small">
                <Modal.Header>{t('spareparts:productinfots.UploadPurchaseInvoice')}</Modal.Header>
                <Modal.Content>
                    <FileDrop
                        acceptedTypes={['.pdf']}
                        resetButtonText={t('common:actions.Clean')}
                        multiple={false}
                        buttonText={t('common:actions.BrowseFiles')}
                        label={t('common:translation.File').toUpperCase()}
                        placeholder={t('common:actions.Draganddropfileshere')}
                        onAccepted={(event: any) => setSelectedFile(event[0])}
                    ></FileDrop>
                </Modal.Content>
                <Modal.Footer>
                    <Button type="secondary" size="small" content={t('common:actions.Cancel')} onClick={closeModal} />
                    <Button type="primary" size="small" content="Ok" onClick={closeModal} />
                </Modal.Footer>
            </Modal>
        );
    };

    return <>{modalDropFileUpload()}</>;
};

export default ModalFileMaintenanceReport;
