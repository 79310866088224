import { Header, Icon, Popup, VerticalMenu } from '@scuf/common';
import useUser from 'helpers/hooks/useUser';
import i18n from 'i18n';
import { t } from 'i18next';
import React from 'react';
import { NavigateFunction } from 'react-router-dom';
import { signoutPopup } from 'resources';
type Props = {
    title: string;
    navigate: NavigateFunction;
};
const UserHeader: React.FC<Props> = ({ title, navigate }: Props) => {
    const { clear } = useUser();
    function logout(): void {
        signoutPopup()
            .then(res => {})
            .catch(e => {
                console.error(e);
            })
            .finally(() => {
                navigate('/');
                clear();
            });
    }

    function handleChangeLang(_lang: string) {
        i18n.changeLanguage(_lang);
    }
    return (
        <>
            <Header title={t(title)} menu={false} onLogoClick={() => navigate('/')}>
                <Header.Item onClick={() => navigate('/dashboard')}>{t('dashboard:Menu')}</Header.Item>
                <Header.IconItem
                    icon={<Icon name="badge-help" root="building" />}
                    description={t('instructions:Instrucoes')}
                    onClick={() => navigate('/info/instructions')}
                ></Header.IconItem>
                <Popup
                    className="popup-theme-wrap"
                    element={<Header.IconItem icon={<Icon name="globe" root="common" />} description={t('language.Language')} />}
                    on="click"
                >
                    <div translate="no">
                        <VerticalMenu>
                            <VerticalMenu.Header>{t('language.Language')}</VerticalMenu.Header>
                            <VerticalMenu.Item onClick={() => handleChangeLang('pt')}>
                                {t('language.Portuguese')}
                            </VerticalMenu.Item>
                            <VerticalMenu.Item onClick={() => handleChangeLang('en')}>{t('language.English')}</VerticalMenu.Item>
                        </VerticalMenu>
                    </div>
                </Popup>
                <Popup
                    className="popup-theme-wrap"
                    element={
                        <Header.Item>
                            {/* {user.type !== STORE_USER_ERROR ? user.payload.profile.name.split(" ")[0] : "Teste"}{" "} */}
                            {<Icon name="caret-down" size="medium" />}
                        </Header.Item>
                    }
                    on="click"
                >
                    <div translate="no">
                        <VerticalMenu>
                            <VerticalMenu.Item onClick={() => logout()}>{t('actions.Logout')}</VerticalMenu.Item>
                        </VerticalMenu>
                    </div>
                </Popup>
            </Header>
            {/* <SidebarLayout collapsed={collapsed} noIcons={true}>
                <SidebarLayout.Sidebar>
                    <SidebarLayout.Sidebar.Item content={t("SignIn")} className="sidebarItemBold" />
                    <SidebarLayout.Sidebar.Item content={t("SignUp")} className="sidebarItemBold" />
                    <SidebarLayout.Sidebar.Item content={t("Maintenance")} />
                    <SidebarLayout.Sidebar.Item content={t("Certified")} />
                    <SidebarLayout.Sidebar.Item content={t("Solutions")} />
                    <Popup
                        className="popup-theme-wrap"
                        element={<Header.IconItem icon={<Icon name="globe" root="common" />} description="Languages" />}
                        on="click"
                    >
                        <VerticalMenu>
                            <VerticalMenu.Header>{t("Language")}</VerticalMenu.Header>
                            <VerticalMenu.Item onClick={() => handleChangeLang("pt")}>{t("Portuguese")}</VerticalMenu.Item>
                            <VerticalMenu.Item onClick={() => handleChangeLang("en")}>{t("English")}</VerticalMenu.Item>
                        </VerticalMenu>
                    </Popup>
                </SidebarLayout.Sidebar>
                <SidebarLayout.Content>{children}</SidebarLayout.Content>
            </SidebarLayout> */}
        </>
    );
};

export default UserHeader;
