import { Icon, Select } from '@scuf/common';
import { destiny, typeOpt } from 'models/spareParts';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { orderCartAtom } from 'resources/atoms/spareParts';
import { StepperProp } from './AddEquipment';
import { OrderCartDTO } from 'models/spareParts/orderById';

const GARANTIA = 4;
const REVENDA = 1;
const USOCONSUMO = 2;

function CustomerOptions({ canSkipPage }: StepperProp): JSX.Element {
    const { t } = useTranslation(['common', 'spareparts']);
    const [defaultCart, setdefaultCart] = useRecoilState<OrderCartDTO>(orderCartAtom);

    useEffect(() => {
        if (defaultCart.typeId && defaultCart.destination) {
            canSkipPage(true);
        } else {
            canSkipPage(false);
        }
    }, [defaultCart]);

    return (
        <div className="form3Columns">
            <h1>
                <Icon name="shopping-bag" size="large" root="building" /> {t('spareparts:cart.OptionCustomer')}
            </h1>

            <div className="div-datatable">
                <div className="form3Columns">
                    <Select
                        label={t('common:translation.Type')}
                        indicator="required"
                        options={typeOpt}
                        placeholder={t('common:translation.Type')}
                        value={defaultCart.typeId}
                        onChange={value => {
                            setdefaultCart({
                                ...defaultCart,
                                typeId: value,
                                destination: value === GARANTIA ? USOCONSUMO : REVENDA
                            });
                        }}
                    />
                    <Select
                        label={t('spareparts:cart.Destiny')}
                        options={destiny}
                        placeholder={t('spareparts:cart.Destiny')}
                        indicator="required"
                        disabled={defaultCart.typeId === GARANTIA}
                        value={defaultCart.destination}
                        //helperText='Destino deve ser USO/CONSUMO'
                        onChange={value => {
                            setdefaultCart({
                                ...defaultCart,
                                destination: value
                            });
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

export default CustomerOptions;
