import { Button, Icon, Table } from '@scuf/common';
import { LabelDescription } from 'components';
import { handleYesOrNo } from 'helpers';
import { formatDate } from 'helpers/dateFormatMicrosft';
import useUser from 'helpers/hooks/useUser';
import useFetch from 'helpers/hooks/useFetch';
import { attendanceTime, materialType } from 'models';
import { CreatNewRmaModel, rmaItems } from 'models/repaircenter/detailsRma';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { notificationAtom, COMPANY_MANAGER, companyAtom } from 'resources';
import { createRma, uploadFile } from 'resources/api/';
import { CreatNewRma } from 'resources/atoms/rmaAtom';
import { orderCartFileAtom } from 'resources/atoms/spareParts';
import { getCompanyByCnpj } from 'resources/api/usermanager_api/addCustomer';
import { EmailServiceType, ValidateCompanyEmailFields } from 'models/repaircenter/detailsRma';
import { CompanyDTO } from 'models/userManager/addCustomer';

function NewRmaSummary() {
    const navigate = useNavigate();
    const { t } = useTranslation(['repaircenter', 'common', 'user']);
    const [loading, setLoading] = useState<boolean>(false);
    const [details, setDetails] = useRecoilState<CreatNewRmaModel>(CreatNewRma);
    const [company] = useRecoilState<CompanyDTO>(companyAtom);
    const setNotification = useSetRecoilState(notificationAtom);
    const [file, setFile] = useRecoilState<File | null>(orderCartFileAtom);
    const [uploadXLSXSuccess, setUploadXLSXSuccess] = useState<boolean>(true);

    const [, sendEmail, sendingEmail] = useFetch<EmailServiceType<ValidateCompanyEmailFields>>({
        url: COMPANY_MANAGER.sendEmail,
        method: 'POST',
        onError: 'Send',
        onSuccess: 'Send'
    });

    const { user: userDetails } = useUser();

    const getEquipments = (): rmaItems[] => {
        return [...(details?.rmaItems ?? [])].filter(e => !!e.rmaItemsEquipment);
    };
    const getAccessories = (): rmaItems[] => {
        return [...(details?.rmaItems ?? [])].filter(e => !!e.rmaItemsAccessories);
    };

    const postXLSX = async (id: number) => {
        setLoading(true);
        if (file != undefined) {
            let resp: { status: number; data: any; message?: any } = await uploadFile(file, id, userDetails?.SSOID!, 'NFE');
            setUploadXLSXSuccess(resp.status == 200);
            if (resp.status != 200 && resp.message == undefined)
                if (resp.status == 200) {
                    setLoading(false);
                    navigate('/repair-center');
                    setFile(null);
                }
            navigate('/repair-center');
            setLoading(false);
        }
    };

    const getCompany = async () => {
        let resp = await getCompanyByCnpj(details.cnpjNota.replaceAll('.', '').replaceAll('-', '').replaceAll('/', ''));
        if (resp.data === null) {
            sendEmailNewCompany();
            setLoading(false);
        }
        setLoading(false);
    };

    const sendEmailNewCompany = async () => {
        sendEmail({
            NewCompany: true,
            Body: 'Criação de Companhia',
            Subject: 'Criação de Companhia',
            ToEmail: process.env.REACT_APP_REPAIR_CENTER_EMAIL!,
            Fields: { companyName: company?.name!, phone: company?.phone, email: company?.email, cnpj: details.cnpjNota }
        });
    };

    const sendCreatNewRma = async () => {
        setDetails({ ...details, ssoId: userDetails?.SSOID!, typeId: 1 });
        const { rmaItems, ...newRma } = details;
        await getCompany();
        try {
            setLoading(true);
            const resp = await createRma({
                ...newRma,
                serviceTypeId: details.serviceTypeId,
                ssoId: userDetails?.SSOID!,
                typeId: details.serviceTypeId,
                companyId: userDetails?.COMPANY!,
                cnpjNota: newRma.cnpjNota.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '')
            });
            setDetails(resp.data);
            postXLSX(resp.data);
            setFile(null);
            setLoading(false);
        } catch (error: any) {
            setNotification(old => ({ ...old, message: t('error') }));
            setLoading(false);
        }
    };

    const handleCancel = () => {
        navigate('/repair-center');
        setFile(null);
    };

    return (
        <div>
            <div>
                <div className="container">
                    <h1>
                        <Icon root="common" name="entity-details" size="large" /> {t('common:translation.Details')}
                    </h1>
                    <div className="grid-view">
                        <LabelDescription title={t('translation.CNPJintheNote')} value={details?.cnpjNota}></LabelDescription>
                        <LabelDescription
                            title={t('translation.RemittanceNF-enumber')}
                            value={details?.invoice}
                        ></LabelDescription>
                        <LabelDescription
                            title={t('translation.TotalRemittanceNF-e')}
                            value={details?.valorInvoice}
                        ></LabelDescription>
                        <LabelDescription
                            title={t('translation.RequestTransport')}
                            value={handleYesOrNo(details?.shipping?.requestedByCustomer)}
                        ></LabelDescription>
                    </div>
                    <h1>
                        <Icon root="common" name="shipping" size="large" /> {t('rma.order.ObservationsShipmentRequest')}
                    </h1>
                    <div className="grid-view">
                        <LabelDescription
                            title={t('translation.ResponsibleUser')}
                            value={details?.package?.name}
                        ></LabelDescription>
                        <LabelDescription
                            title={t('rma.budget.Approval.Phone')}
                            value={details?.package?.phone}
                        ></LabelDescription>
                        <LabelDescription
                            title={t('rma.shipping.BusinessHour')}
                            value={attendanceTime.find(e => e.value === details?.package?.time)?.text}
                        ></LabelDescription>
                        <LabelDescription
                            title={t('rma.shipping.MaterialType')}
                            value={materialType.find(e => e.value === details?.package?.materialType)?.text}
                        ></LabelDescription>
                        <LabelDescription title={t('rma.shipping.Height')} value={details?.package?.height}></LabelDescription>
                        <LabelDescription title={t('rma.shipping.Width')} value={details?.package?.width}></LabelDescription>
                        <LabelDescription title={t('rma.shipping.Length')} value={details?.package?.length}></LabelDescription>
                        <LabelDescription title={t('rma.shipping.Weight')} value={details?.package?.weight}></LabelDescription>
                        <LabelDescription
                            title={t('rma.shipping.ShipmentSentDate')}
                            value={formatDate(details?.package?.date)}
                        ></LabelDescription>
                    </div>
                    <h1>
                        <Icon root="common" name="home" size="large" /> {t('rma.order.ReceiveAddress')}
                    </h1>
                    <div className="grid-view">
                        <LabelDescription title={t('user:address.ZipCode')} value={details?.address?.zipCode}></LabelDescription>
                        <LabelDescription title={t('user:address.Street')} value={details?.address?.street}></LabelDescription>
                        <LabelDescription title={t('user:address.State')} value={details?.address?.state}></LabelDescription>
                        <LabelDescription title={t('user:address.City')} value={details?.address?.city}></LabelDescription>
                        <LabelDescription
                            title={t('user:address.Neighborhood')}
                            value={details?.address?.neighborhood}
                        ></LabelDescription>
                        <LabelDescription title={t('user:address.Number')} value={details?.addressNumber}></LabelDescription>
                        <LabelDescription
                            title={t('user:address.Complement')}
                            value={details?.addressComplement}
                        ></LabelDescription>
                    </div>

                    <h1>
                        <Icon root="global" name="shopping-bag" size="large" /> {t('repaircenter:translation.ServiceBag')}
                    </h1>
                    {getEquipments().length > 0 && (
                        <LabelDescription title={t('common:translation.Equipments')}>
                            <Table>
                                <Table.Header>
                                    <Table.HeaderCell content={t('common:translation.Model')} />
                                    <Table.HeaderCell content={t('common:translation.SerialNumber')} />
                                    <Table.HeaderCell content={t('repaircenter:manage_eos.translation.ProblemLocation')} />
                                    <Table.HeaderCell content={t('common:translation.Problem')} />
                                </Table.Header>
                                <Table.Body>
                                    {getEquipments().map((item: rmaItems, index: number) => (
                                        <Table.Row key={item.id}>
                                            <Table.Cell>{item?.model} </Table.Cell>
                                            <Table.Cell>{item?.rmaItemsEquipment?.serialNumber} </Table.Cell>
                                            <Table.Cell>{item?.rmaItemsEquipment?.problemLocation?.name}</Table.Cell>
                                            <Table.Cell>
                                                {item?.rmaItemsEquipment?.problemType?.name ??
                                                    item.rmaItemsEquipment?.problemTypeDescription ??
                                                    ''}{' '}
                                            </Table.Cell>
                                            {/* <Table.Cell>{dateFormatFromMicrosft(item?.Date)} </Table.Cell> */}
                                            {/*<Table.Cell>
                                                <Badge color="blue"> {typeOptions[item?.serviceTypeId - 1].text} </Badge>
                                    </Table.Cell>*/}
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                        </LabelDescription>
                    )}
                    {getAccessories().length > 0 && (
                        <LabelDescription title={t('common:translation.Accessories')}>
                            <Table>
                                <Table.Header>
                                    <Table.HeaderCell content={t('common:translation.Model')} />
                                    <Table.HeaderCell content={t('common:translation.Problem')} />
                                </Table.Header>
                                <Table.Body>
                                    {getAccessories().map((item: rmaItems, index: number) => (
                                        <Table.Row key={item.id}>
                                            <Table.Cell>{item?.model} </Table.Cell>
                                            <Table.Cell>{item?.rmaItemsAccessories?.problemDescription} </Table.Cell>
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                        </LabelDescription>
                    )}
                    <h1>
                        <Icon root="common" name="message" size="large" /> {t('common:translation.Comments')}
                    </h1>
                    <LabelDescription title={t('common:translation.Accessories')} value={details?.acessorios}></LabelDescription>
                    <LabelDescription title={t('common:translation.Comments')} value={details?.observacao}></LabelDescription>

                    <div className="buttonsSearch">
                        <Button
                            content={t('common:actions.Cancel')}
                            size="small"
                            textTransform={true}
                            type="secondary"
                            className="buttonCancelSecondary"
                            onClick={() => handleCancel()}
                            loading={loading}
                            disabled={loading}
                        />
                        <Button
                            content={t('common:actions.Create')}
                            size="small"
                            textTransform={true}
                            loading={loading}
                            onClick={() => sendCreatNewRma()}
                            disabled={loading}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NewRmaSummary;
