import { Badge, Button, Card, Icon, Loader, Modal, Select, Table, TextArea } from '@scuf/common';
import Alert from 'components/alert';
import { formatDate } from 'helpers';
import useUser from 'helpers/hooks/useUser';
import { RmaModelLite, RmaRequestModel, typeRequest } from 'models';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { RmaLite, notificationAtom, rmaDetailState } from 'resources';
import { requestCreate, requestDelete } from 'resources/api/';
import 'styles/main.scss';

function Requests() {
    const [width, setWidth] = useState<number>(0);
    const [modalState, setModalState] = useState<boolean>(false);
    const [rmaState, setRmaState] = useRecoilState<RmaModelLite>(RmaLite);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
    const setNotification = useSetRecoilState(notificationAtom);
    const [requestToCreate, setRequest] = useState<RmaRequestModel>({ rmaId: rmaState?.id } as RmaRequestModel);
    const { t } = useTranslation(['repaircenter', 'common']);
    const { user: userDetails } = useUser();

    function handleResize() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        setWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
    }, []);

    function renderModal(): JSX.Element {
        return (
            <Modal
                className="modal"
                onClose={() => setModalState(false)}
                size="small"
                open={modalState}
                closeOnDimmerClick={true}
                closeOnDocumentClick={true}
            >
                <div className="container-selects">
                    <Select
                        className="select-type"
                        options={typeRequest}
                        indicator="required"
                        label={t('common:translation.Type')}
                        placeholder={t('common:translation.Type')}
                        value={requestToCreate?.typeId}
                        onChange={value => setRequest(old => ({ ...old, typeId: value }))}
                    />
                    <TextArea
                        fluid={true}
                        className="select-type"
                        helperText={t('common:translation.Description')}
                        label={t('common:translation.Description')}
                        placeholder={t('common:translation.Description')}
                        value={requestToCreate?.description}
                        onChange={value => setRequest(old => ({ ...old, description: value }))}
                    />
                </div>
                <Card.Footer>
                    <div className="buttonModal">
                        <Button
                            content={t('common:actions.Close')}
                            onClick={() => setModalState(false)}
                            size="medium"
                            textTransform={true}
                            loading={loading}
                            disabled={loading}
                        />
                        <Button
                            type="primary"
                            icon="slidercontrols-plus"
                            onClick={() => requestPost()}
                            content={t('common:actions.Create')}
                            iconPosition="right"
                            disabled={(!requestToCreate.description && !requestToCreate.typeId) || loading}
                            loading={loading}
                        />
                    </div>
                </Card.Footer>
            </Modal>
        );
    }

    async function deleteRequest(id: number) {
        setLoadingDelete(true);
        let resp = await requestDelete(id);
        if (resp.error) {
            setLoadingDelete(false);
            setNotification(old => ({ ...old, message: 'Removed' }));
        } else {
            setRmaState(prevState => {
                return {
                    ...prevState,
                    rmaRequests: prevState.rmaRequests.filter(item => item.id !== id)
                };
            });
            setNotification(old => ({ ...old, message: 'Removed', type: 'success' }));
            setLoadingDelete(false);
        }
    }

    useEffect(() => {}, [requestToCreate]);

    async function requestPost() {
        setLoading(true);
        let resp = await requestCreate({ ...requestToCreate, ssoId: userDetails?.SSOID!, rmaId: rmaState?.id });
        if (resp.error) {
            setLoading(false);
            setNotification(old => ({ ...old, message: 'Create' }));
            return resp.status;
        } else {
            setRmaState(prevState => {
                return {
                    ...prevState,
                    rmaRequests: [...prevState.rmaRequests, resp.data]
                };
            });
            setRequest({} as RmaRequestModel);
            setModalState(false);
            setNotification(old => ({ ...old, message: 'Add', type: 'success' }));
        }
        setLoading(false);
    }

    return (
        <>
            <div className="container">
                {loadingDelete && loading ? (
                    <Loader text={t('common:actions.Loading')} />
                ) : (
                    <>
                        <div>
                            {renderModal()}

                            <div className="contentPageContainer">
                                <div className="div-datatable">
                                    <h1>{t('common:translation.Request')}</h1>
                                    <br />
                                    {rmaState && rmaState?.rmaRequests?.length > 0 ? (
                                        [
                                            <div>
                                                <div className="chat-display">
                                                    <Table>
                                                        <Table.Header>
                                                            <Table.HeaderCell content={t('common:translation.Type')} />
                                                            <Table.HeaderCell content={t('translation.Responsible')} />
                                                            <Table.HeaderCell content={t('common:translation.Description')} />
                                                            <Table.HeaderCell content={t('common:translation.Status')} />
                                                            <Table.HeaderCell content={t('translation.FinishBy')} />
                                                            <Table.HeaderCell content={t('translation.DateCreate')} />
                                                            <Table.HeaderCell content={t('common:actions.Delete')} />
                                                        </Table.Header>
                                                        <Table.Body>
                                                            {rmaState && rmaState?.rmaRequests?.length > 0
                                                                ? [
                                                                      rmaState.rmaRequests.map((request: RmaRequestModel) => (
                                                                          <Table.Row>
                                                                              <Table.Cell>{request.type?.name}</Table.Cell>
                                                                              <Table.Cell>{request.createdBy?.name} </Table.Cell>
                                                                              <Table.Cell>{request.description} </Table.Cell>

                                                                              <Table.Cell>
                                                                                  {request.isCompleted ? (
                                                                                      <Badge
                                                                                          color="green"
                                                                                          content={t('common:Finished')}
                                                                                      />
                                                                                  ) : (
                                                                                      <Badge
                                                                                          color="orange"
                                                                                          content={t('common:Active')}
                                                                                      />
                                                                                  )}
                                                                              </Table.Cell>
                                                                              <Table.Cell>{request.finishedById} </Table.Cell>
                                                                              <Table.Cell>
                                                                                  {formatDate(request.createdAt)}
                                                                              </Table.Cell>
                                                                              <Table.Cell>
                                                                                  {
                                                                                      <Icon
                                                                                          root="common"
                                                                                          name="delete"
                                                                                          size="medium"
                                                                                          onClick={() => {
                                                                                              setLoadingDelete(true);
                                                                                              //   setIdRma(rma.rmaId);
                                                                                              deleteRequest(request.id!);
                                                                                          }}
                                                                                      />
                                                                                  }
                                                                              </Table.Cell>
                                                                          </Table.Row>
                                                                      ))
                                                                  ]
                                                                : '---'}
                                                        </Table.Body>
                                                    </Table>
                                                    <br />
                                                    <Card.Content className="buttonsSearch">
                                                        <Button
                                                            type="primary"
                                                            icon="slidercontrols-plus"
                                                            onClick={() => setModalState(true)}
                                                            content={t('common:actions.Create')}
                                                            iconPosition="right"
                                                        />
                                                    </Card.Content>
                                                </div>
                                            </div>
                                        ]
                                    ) : (
                                        <div>
                                            <Alert text={t('common:empty.NoRequest')} />
                                            <br />
                                            <Card.Content className="buttonsSearch">
                                                <Button
                                                    type="primary"
                                                    icon="slidercontrols-plus"
                                                    onClick={() => setModalState(true)}
                                                    content={t('common:actions.Create')}
                                                    iconPosition="right"
                                                />
                                            </Card.Content>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
}

export default Requests;
