import { AxiosHttpPost, AxiosHttpGet } from 'axios-http';
import { NewEquipment } from 'models';
import { ItemsCart, RmaItemsCA } from 'models/repaircenter/detailsRma';
import { REPAIR_CENTER } from 'resources/links';

export function getItemsInCart(userId: any) {
    let body = {};
    return AxiosHttpPost({
        url: REPAIR_CENTER.getItemsInCart,
        data: userId,
    });
}

export function getServiceTypes() {
    return AxiosHttpGet({ url: REPAIR_CENTER.getServiceTypes });
}

export function getPartNumberList() {
    return AxiosHttpGet({ url: REPAIR_CENTER.getPartNumberModels });
}

export function getProblemLocationsAndTypes() {
    return AxiosHttpGet({ url: REPAIR_CENTER.getProblemLocationsAndTypes });
}

export function getItemsInCartCA() {
    let body = {};
    return AxiosHttpPost({ url: REPAIR_CENTER.getItemsInCartCA, data: body });
}

export function addItemCart(AddItem: NewEquipment) {
    return AxiosHttpPost({ url: REPAIR_CENTER.addItemInCart, data: AddItem });
}

export function addItemCartCA(AddItem: RmaItemsCA) {
    return AxiosHttpPost({ url: REPAIR_CENTER.addItemInCartCA, data: AddItem });
}

export function itemCartDelete(id: any) {

    return AxiosHttpPost({ url: REPAIR_CENTER.removeItemsFromCart, data: id });
}

export function itemCartDeleteCA(id: string[]) {
    return AxiosHttpPost({ url: REPAIR_CENTER.removeItemsFromCartCA, data: id });
}
