import ProtectedRoute from 'components/protectedRoute';
import ErrorFallback from 'pages/error/errorPage';
import { CustomFooter, CustomHeader } from 'components';
import { ErrorBoundary } from 'react-error-boundary';
import { NavigateFunction, Route, Routes, useNavigate } from 'react-router-dom';
import { Breadcrumb, Icon } from '@scuf/common';
import routes, { rootType, leafType } from './routes';
import { NotFoundError } from 'helpers';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

type breadcrumbs = { prevPath?: string; prevName?: string }[];

/**
 *
 * @param route - O elemento de rota do tipo pathType. Para criar um 'subpath' (/sub/route), não adicionar a propriedade 'element'
 * @example {path:'sub',children:[...]}
 * @param breadcrumbs - Itens que serão adicionados no breadcrumbs da rota. Passar o nome e path da rota anterior.
 *
 * @returns Elemento que será renderizado na rota
 */
const createElement = (route: leafType, breadcrumbs: breadcrumbs = [], navigate: NavigateFunction, t: any) => {
    return (
        <>
            <CustomHeader title={t(`pages:${route.name ?? ''}`)}></CustomHeader>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
                {!!route.element ? (
                    <>
                        {breadcrumbs.length > 0 && (
                            <Breadcrumb className="breadcrumbs">
                                {breadcrumbs.map((e, index) => (
                                    <>
                                        {index == 0 && <Icon name="globe"></Icon>}
                                        <Breadcrumb.Item
                                            className="item"
                                            onClick={() => {
                                                navigate(e.prevPath!);
                                            }}
                                        >
                                            {t(`pages:${e.prevName}`)}
                                        </Breadcrumb.Item>
                                    </>
                                ))}
                                <Breadcrumb.Item>{t(`pages:${route.name}`)}</Breadcrumb.Item>
                            </Breadcrumb>
                        )}

                        {!!route?.protected ? (
                            <ProtectedRoute permissions={route?.permissions}>{route.element}</ProtectedRoute>
                        ) : (
                            route?.element
                        )}
                    </>
                ) : (
                    <ErrorFallback error={new NotFoundError()} />
                )}
            </ErrorBoundary>
            <CustomFooter></CustomFooter>
        </>
    );
};
const RenderRoute = (route: rootType | leafType, breadcrumbs: breadcrumbs = []): React.ReactElement => {
    const navigate = useCallback(useNavigate(), [routes]);
    const { t } = useTranslation();
    const isParentComponent = 'children' in route;
    const path = `${breadcrumbs?.at(-1)?.prevPath ?? ''}${
        route.path.startsWith('/') ? route.path.replace('/', '') : `/${route.path}`
    }`;
    const currentBreadcrumb =
        isParentComponent && !!route.name ? [...breadcrumbs, { prevPath: path, prevName: route?.name }] : breadcrumbs;
    return !isParentComponent ? (
        <Route
            key={route.path}
            path={route.path}
            hasErrorBoundary
            element={createElement(route, currentBreadcrumb, navigate, t)}
        />
    ) : (
        <Route key={route.path} path={route.path}>
            <Route
                key={route.path}
                index
                hasErrorBoundary
                element={createElement(route, [...currentBreadcrumb].slice(0, -1), navigate, t)}
            />
            {'children' in route && !!route.children && route.children.map(e => RenderRoute(e, currentBreadcrumb))}
        </Route>
    );
};

function RoutesHelper() {
    return <Routes>{routes.map(e => RenderRoute(e))}</Routes>;
}
export default RoutesHelper;
