import { AddressModel, Info, Package, RmaDeleted, Shipping, User } from 'models';

export class ErrorsMsgDetailsRmaModel {
    shipping: string;
    shippingDate: string;
    problem: string;
    problemDescription: string;
    typeService: string;
    problemDescriptionAcessory: string;
    modelEquipmentAcessory: string;
    typeServiceAcessory: string;

    constructor() {
        this.shipping = '';
        this.shippingDate = '';
        this.problem = '';
        this.problemDescription = '';
        this.typeService = '';
        this.problemDescriptionAcessory = '';
        this.modelEquipmentAcessory = '';
        this.typeServiceAcessory = '';
    }
}

export class ErrorsMsgModel {
    cep: string;
    state: string;
    city: string;
    neighborhood: string;
    street: string;
    number: string;
    InvoiceCnpj: string;
    ShippingDate: string;
    Erro: boolean;
    zipCode: string | undefined;

    constructor() {
        this.cep = '';
        this.state = '';
        this.city = '';
        this.neighborhood = '';
        this.street = '';
        this.number = '';
        this.InvoiceCnpj = '';
        this.ShippingDate = '';
        this.Erro = true;
    }
}

export type ValidateCompanyEmailFields = { companyName: string; phone?: string; email: string, cnpj: string };
export type EmailServiceType<T = any> = {
    Body: string;
    Subject: string;
    ToEmail: string;
    NewCompany: Boolean;
    Fields: T;
};

export type BadgeColors = { [color in 'green' | 'yellow' | 'blue' | 'red' | 'orange' | 'grey']?: number[] };
export const rmaTypeColors: BadgeColors = { green: [1, 2, 3, 5], blue: [4] };
export const rmaStatusColors: BadgeColors = {
    yellow: [1, 2, 5, 20, 23],
    blue: [3, 7, 8, 9, 16, 17, 18, 19, 21, 22],
    red: [11, 12, 13, 14, 24, 25]
};

export type AddItemInCartModel = {
    Id: any;
    PartNumberModel: any;
    SerialNumber: any;
    PartNumberType: any;
    //TypeId: any;
    ProblemLocationId: any;
    ProblemTypeId: any;
    ProblemDescription: any;
    Indice: any;
    Selected: any;
    FlagServiceAuthorized: any;
    IsEOS: any;
};

export type RmaChatTable = ChatTable & { rmaId: number };
export type SparePartsChatTable = ChatTable & { orderId: number }
export type ChatTable = {
    id?: number;
    ssoId: string;
    companyId: number;
    itWasReadByCustomer: boolean;
    itWasReadByAdmin: boolean;
    message: string;
    userName: string;
    createdAt?: Date;
    orderId?:number;
    rmaId?:number;
    sparePartsId?:number;
    roleId?: number
};

export type CreatNewRmaModel = {
    id: number;
    address: AddressModel;
    valorInvoice?: string;
    cnpjNota: string;
    cnpjCompany: string;
    rmaItems?: RmaItems[];
    observacao: string;
    acessorios: string;
    ssoId: string;
    shipping: Shipping;
    package: Package;
    statusId: number;
    typeId: number;
    companyId: number,
    addressNumber: string;
    invoice?: string;
    addressComplement: string;
    user: User,
    status: Info,
    type: Info,
    createdAt: Date,
    rmaDeleted: RmaDeleted,
    serviceTypeId: number,
    nfeFile?: string
};

export class rmaShipment {
    id?: any
    hight?: string
    width?: string
    length?: string
    weight?: string
    shipmentDate?: string
    address?: address
    rmaId?: any
}

export type address = {
    id?: any,
    cep?: string,
    state?: string,
    city?: string,
    neighborhood?: string,
    street?: string,
    number?: any,
    complement?: string
}

export type problemLocation
    = {
        id?: any,
        name?: string
    }

export type problemType = {
    id?: any,
    name?: string
}

export type serviceType = {
    id?: any,
    name?: string
}

export class rmaItems {
    id?: any
    approved?: boolean
    model?: string
    rmaId?: number
    rmaItemsEquipment?: rmaItemsEquipment
    rmaItemsAccessories?: rmaItemsEquipment
    serviceType?: serviceType
    serviceTypeId?: number| null
}

export type rmaItemsEquipment = {
    id?: any
    problemLocation?: problemLocation
    problemLocationId?: any
    problemType?: problemType
    problemTypeId?: any
    problemTypeDescription?: string |null
    problemDescription?:any
    serialNumber?: string
}

export type rmaItemsAccessories = {
    id?: any
    problemDescription?: any
}

export type RmaItems = {
    id: number,
    serviceType: Info,
    model: string,
    rmaItemsEquipment: {
        problemLocation: serviceType,
        problemType: Info,
        serialNumber: string,
        problemLocationId: number,
        problemTypeDescription?: string
    },
    rmaItemsAccessories: {
        problemDescription: string
    },
    approved?: boolean
}
export class rmaItemsEquipmentModel {
    serialNumber?: string | null
    problemLocationId?: number | null
    problemTypeId?: number | null
    problemTypeDescription?: string | null
    problemDescription?: string | null

    constructor() {
        this.serialNumber = null
        this.problemDescription = null
        this.problemLocationId = null
        this.problemTypeId = null
    }
}
export class rmaItemsAccessoriesModel {
    problemDescription?: string | null

    constructor() {
        this.problemDescription = null
    }
}

export type ItemsCart = {
    id?: any,
    partNumber?: string,
    rmaItemsAccessories?: rmaItemsEquipment,
    rmaItemsEquipment?: rmaItemsEquipment,
    serviceType?: serviceType,
    serviceTypeId?: any,
    user?: {},
    ssoId?: string
};

export type CreatNewRmaModelCA = {
    Accessories?: any;
    DiscountCouponId?: any;
    DiscountCouponIsValid?: any;
    DiscountCouponNumber: any;
    DiscountCouponPercent?: any;
    IsEOS: any;
    InvoiceCnpj: any;
    InvoiceValues: any;
    newRmaItemCart: RmaItemsCA[];
    NumberNfe: any;
    Obs?: any;
    ShipmentRequested: number;
    ShipmentShipping: ShipmentShipping;
    ShippingAddress: ShippingAddress;
    ShippingDate?: any;
    TypeId: any;
    UserId: any;
};

export type Result = {
    TypeId?: any;
    Accessories?: any
    BilTo?: BillTo;
    CreatedAt?: any;
    Id?: any;
    Obs?: any;
    QtdItems?: any;
    RmaItems: RmaItemsCA[];
    ShipTo?: Shipto;
    ShippingDate?: any;
    SoldTo?: SoldTo;
    StatusId?: any;
    SupportLevelId?: any;
    UpdatedAt?: any;
    UserId?: any
}
export type RmaItemsCA = {
    CreatedAt?: Date;
    DBR?: boolean;
    DeliveryDate?: Date;
    DockReceiptDate?: Date;
    ErrorDetails?: any;
    Id?: any;
    model?: any;
    NPF?: boolean;
    PartNumber?: any;
    PartNumberModel?: any;
    PcbReplaced?: boolean;
    ProblemDescription?: any;
    ProblemLocationId?: any;
    ProblemTypeId?: any;
    ReplacementSerialNumber?: any;
    ReturnShippingLabel?: any;
    RmaId?: any;
    SerialNumber?: any;
    ServicePerformed?: any;
    Shipment?: any;
    Status?: any;
    StatusId?: any;
    UpdatedAt?: any;

}

//Criei esse modelo de teste para ficar parecido com o que é enviado para o back em Homolog
export type CreatNewRmaModelCATest = {
    Accessories?: any;
    BillTo?: BillTo;
    DiscountCouponId?: any;
    DiscountCouponIsValid?: any;
    DiscountCouponNumber?: any;
    DiscountCouponPercent?: any;
    InvoiceCnpj?: any;
    InvoicesValue?: any;
    ItemsCart?: [];
    NumberNfe?: any;
    Obs?: any;
    ShipTo?: Shipto;
    ShipmentRequested?: any;
    ShippingDate?: any;
    SoldTo?: SoldTo;
    TypeId?: any;
    steppers?: {};
};

export type BillTo = {
    City?: string;
    Complement?: any;
    Contact?: string;
    Country?: string;
    Id?: number;
    Name?: string;
    Neighborhood?: string;
    Number?: string;
    State?: string;
    Street?: string;
    ZipCode?: string
};

export type SoldTo = {
    City?: string;
    Complement?: any;
    Contact?: string;
    Country?: string;
    Id?: number;
    Name?: string;
    Neighborhood?: string;
    Number?: string;
    State?: string;
    Street?: string;
    ZipCode?: string
};

export type Shipto = {
    City?: string;
    Complement?: any;
    Contact?: string;
    Country?: string;
    Id?: number;
    Name?: string;
    Neighborhood?: string;
    Number?: string;
    State?: string;
    Street?: string;
    ZipCode?: string
};

export type ShippingAddress = {
    Cep: any;
    State: any;
    City: any;
    Neighborhood: any;
    Street: any;
    Number: any;
    Complement: any;
    ShippingDate: any;
};

export type ShipmentShipping = {
    ResponsibleName: any;
    ResponsiblePhone: any;
    Height: any;
    Width: any;
    Length: any;
    Weight: any;
    Type: any;
    Quantity: any;
    BusinessHours: any;
    CollectionRequestDate: any;
};
