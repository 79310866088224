import '../../../../styles/repaircenter.scss';

import { Badge, Button, Divider, Grid, Icon, Input } from '@scuf/common';
import { LabelDescription } from 'components';
import { formatDate } from 'helpers';
import { RmaModelLite, statusOptions } from 'models';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { COMPANY_MANAGER, REPAIR_CENTER, Rma, RmaLite, companyDtoAtom, notificationAtom } from 'resources';
import useFetch from 'helpers/hooks/useFetch';
import { CompanyDTO } from 'models/userManager/addCustomer';
import { getColors } from 'helpers/getBadgeColors';
import { rmaStatusColors, rmaTypeColors } from 'models/repaircenter/detailsRma';
import requestFile from 'helpers/requestFile';

const OrderAccordion: React.FC<any> = () => {
    const setNotification = useSetRecoilState(notificationAtom);
    const [rmaState, setRmaState] = useRecoilState<RmaModelLite>(RmaLite);
    const [loadingAction, setLoadingAction] = useState<boolean>(false);
    const [changes, setChanges] = useState({
        Invoice: '',
        InvoiceValue: ''
    });
    const [company, setCompany] = useRecoilState<CompanyDTO>(companyDtoAtom);
    const [_, getCompanyById] = useFetch<{ id: number | undefined }, CompanyDTO>({
        url: COMPANY_MANAGER.getCompanyById
    });
    const { t } = useTranslation(['repaircenter', 'common', 'user']);
    const [, updateInvoice, loader] = useFetch<RmaModelLite>({
        url: REPAIR_CENTER.updateInvoice,
        method: 'POST',
        onError: 'Change',
        onSuccess: 'Change'
    });

    useEffect(() => {
        setChanges({ Invoice: rmaState?.invoice, InvoiceValue: rmaState?.valorInvoice });
        if (rmaState?.companyId !== company?.id) {
            getCompanyInfo();
        }
    }, [rmaState]);

    const handleChanges = async (): Promise<void> => {
        await updateInvoice(
            { ...rmaState, invoice: changes.Invoice, valorInvoice: changes.InvoiceValue ? changes.InvoiceValue : '0' },
            _ => {
                setRmaState(old => ({ ...old, invoice: changes.Invoice, valorInvoice: changes.InvoiceValue }));
            }
        );
    };

    const validate = (): boolean => {
        return (
            (changes.Invoice !== rmaState?.invoice || changes.InvoiceValue !== rmaState?.valorInvoice) &&
            rmaState?.statusId !== 10
        );
    };

    const getCompanyInfo = async (): Promise<void> => {
        await getCompanyById({ id: rmaState?.companyId }, current => {
            setCompany(current.data!);
        });
    };

    const getStatusInfo = (statusId: number) => {
        const status = statusOptions.find(option => option.value === statusId);
        return status ? { name: status.text } : { name: 'Desconhecido' };
    };

    const handlePrint = async () => {
        setLoadingAction(true);
        try {
            await requestFile.print(REPAIR_CENTER.generatePdfFromRma, {
                method: 'GET',
                params: { id: rmaState?.id }
            });
            setLoadingAction(false);
        } catch {
            setNotification(old => ({ ...old, message: 'UploadError' }));
            setLoadingAction(false);
        }
    };

    const getTransportType = (): string => {
        const situation = rmaState?.shipping?.situation;

        switch (situation) {
            case '1':
                return 'Indefinido';
            case '2':
                return 'Logística Reversa Coleta - Coleta Domiciliar';
            case '3':
                return 'Logística Reversa - Postagem';
            case '4':
                return 'Transportadora';
            case '5':
                return 'Não se Aplica';
            case '6':
                return 'Em processamento';
            default:
                return '';
        }
    };

    return (
        <>
            <Grid>
                <h3>{t('common:translation.General')}</h3>
                <Grid.Row>
                    <Grid.Column className="grid-column" width={3} mWidth={6} sWidth={12}>
                        <LabelDescription title={t('rma.order.RmaNumber')} value={rmaState?.id} />
                        <LabelDescription title={t('rma.order.RmaDate')} value={formatDate(rmaState?.createdAt)} />
                        <LabelDescription
                            title={t('common:translation.Status')}
                            value={
                                <Badge color={getColors(rmaState?.statusId, rmaStatusColors)}>
                                    {getStatusInfo(rmaState?.statusId).name}
                                </Badge>
                            }
                        />
                        <LabelDescription
                            title={t('common:translation.Modality')}
                            value={<Badge color={getColors(rmaState?.type?.id, rmaTypeColors)}>{rmaState?.type?.name}</Badge>}
                        />
                    </Grid.Column>
                    <Grid.Column className="grid-column" width={3} mWidth={6} sWidth={12}>
                        <LabelDescription title={t('rma.order.PostCode')} value={rmaState?.shipping?.code} />
                        <LabelDescription title={t('rma.order.Equipments')} value={rmaState?.rmaItems?.length ?? 0} />
                        <LabelDescription title={t('rma.order.Accessories')} value={rmaState?.acessorios} />
                        <LabelDescription title={t('repaircenter:rma.order.TransportType')} value={getTransportType()} />
                    </Grid.Column>
                    <Grid.Column className="grid-column" width={3} mWidth={6} sWidth={12}>
                        <LabelDescription title={t('rma.order.ShipmentRequestResponse')} value={rmaState?.shipping?.type.name} />
                        <LabelDescription title={t('rma.order.Observations')} value={rmaState?.observacao} />
                    </Grid.Column>
                    <Grid.Column className="grid-column" width={3} mWidth={6} sWidth={12}>
                        <LabelDescription title={t('rma.order.InvoiceRemittance')}>
                            <Input
                                disabled={rmaState?.statusId === 10}
                                value={`${changes.Invoice}`}
                                icon={<Icon name="edit" root="common" size="small" />}
                                onChange={Invoice => setChanges({ ...changes, Invoice })}
                            />
                        </LabelDescription>
                        <LabelDescription title={t('rma.order.InvoiceValue')}>
                            <Input
                                disabled={rmaState?.statusId === 10}
                                value={changes?.InvoiceValue}
                                icon={<Icon name="edit" root="common" size="small" />}
                                onChange={InvoiceValue =>
                                    setChanges({
                                        ...changes,
                                        InvoiceValue
                                    })
                                }
                            />
                        </LabelDescription>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <div className="buttonsSearch">
                {validate() && (
                    <Button
                        size="small"
                        icon={<Icon name="save-as" root="common" />}
                        content={t('common:actions.Save')}
                        loading={loader}
                        onClick={handleChanges}
                    ></Button>
                )}
                <Button
                    icon={<Icon name="print" root="common" />}
                    iconPosition="left"
                    loading={loadingAction}
                    size="small"
                    onClick={() => handlePrint()}
                    content={t('common:actions.Print')}
                    type="primary"
                />
            </div>
            <Divider></Divider>
            <Grid>
                <h3>{t('register:type.Company')}</h3>
                <Grid.Row>
                    <Grid.Column className="grid-column" width={6} sWidth={12}>
                        <LabelDescription title={t('CNPJ')} value={company?.cnpj} />
                        <LabelDescription title={t('rma.order.Customer.InvoiceCNPJ')} value={rmaState?.cnpjNota} />
                        <LabelDescription title={t('user:info.Email')} value={company?.email} />
                        <LabelDescription title={t('user:address.Phone')} value={company?.phone} />
                    </Grid.Column>
                    <Grid.Column className="grid-column" width={6} sWidth={12}>
                        <LabelDescription title={t('user:company.Name')} value={company?.name} />
                        <LabelDescription title={t('repaircenter:translation.ResponsibleName')} value={rmaState?.package?.name} />
                        <LabelDescription
                            title={t('repaircenter:translation.ResponsibleEmail')}
                            value={company?.users?.find(x => x.ssoId === rmaState.ssoId)?.email}
                        />
                        <LabelDescription
                            title={t('repaircenter:translation.ResponsiblePhone')}
                            value={rmaState?.package?.phone}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    );
};

export default OrderAccordion;
