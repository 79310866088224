import { AxiosHttpGet, AxiosHttpPost } from 'axios-http';
import { ItemRmaModel, RmaDetailModel, RmaModel, RmaDeleted, NewEquipment, RmaModelLite } from 'models';
import { ModelEOSEdit, ModelEOSResult } from 'models/repaircenter/eosModel';
import { REPAIR_CENTER } from 'resources/links';
import Cookies from 'universal-cookie';

export function changeStatusRmaByAdmin(data: RmaModel) {
    return AxiosHttpPost({
        url: REPAIR_CENTER.changeStatusRmaByAdmin,
        data: data
    });
}

export function processingRmaByAdmin({ file, rmaDTO }: { file: File | null; rmaDTO: RmaModelLite }) {
    return AxiosHttpPost({
        url: REPAIR_CENTER.processRma,
        data: { file: file, rmaDTO: rmaDTO },
        headers: {
            Accept: '*/*',
            'Content-Type': 'multipart/form-data',
            Authorization: new Cookies().get('info')
        }
    });
}

export function changeRmaModality(data: RmaModel) {
    return AxiosHttpPost({
        url: REPAIR_CENTER.alterRmaModality,
        data: data
    });
}

export function removeEquipmentByAdmin(data: ItemRmaModel) {
    return AxiosHttpPost({
        url: REPAIR_CENTER.removeEquipmentByAdmin,
        data: data
    });
}

export function alterItemsInRma(data: RmaModel | RmaModelLite) {
    return AxiosHttpPost({
        url: REPAIR_CENTER.alterItemsInRma,
        data: data
    });
}
export function insertItemsInRma(data: NewEquipment) {
    return AxiosHttpPost({
        url: REPAIR_CENTER.insertItemsInRma,
        data: data
    });
}
export function deleteItemsInRma(rmaItemId: number, rmaId: number, ssoId: string, justify: string) {
    let newData = {
        rmaItemId: rmaItemId,
        ssoId: ssoId,
        justify: justify
    };
    return AxiosHttpGet({
        url: REPAIR_CENTER.deleteItemsInRma,
        data: { rmaItemId, rmaId, ssoId, justify }
    });
}

export function alterItemsInEOS(data: RmaDetailModel) {
    return AxiosHttpPost({
        url: REPAIR_CENTER.alterItemsInRma,
        data: data
    });
}

export function changeAddressData(data: RmaModel) {
    let { id, address, addressComplement, addressNumber } = data;
    let newData = {
        id: id,
        address: address,
        addressComplement: addressComplement,
        addressNumber: addressNumber
    };
    return AxiosHttpPost({
        url: REPAIR_CENTER.alterAddressInRma,
        data: newData
    });
}

export function changeShippingData(data: RmaModel) {
    return AxiosHttpPost({
        url: REPAIR_CENTER.updatePackage,
        data: data
    });
}

export function deleteRma(rma: RmaDetailModel) {
    return AxiosHttpPost({
        url: REPAIR_CENTER.deleteRma,
        data: rma
    });
}

export function deactivateRma(rma: RmaDeleted) {
    return AxiosHttpPost({
        url: REPAIR_CENTER.deactivateRma,
        data: rma
    });
}

export function deleteEOS(eos: ModelEOSResult) {
    return AxiosHttpPost({
        url: REPAIR_CENTER.deleteEOS,
        data: eos
    });
}

export function saveEOS(eos: ModelEOSEdit) {
    return AxiosHttpPost({
        url: REPAIR_CENTER.deleteEOS,
        data: eos
    });
}
