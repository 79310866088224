export class NewDetailsRmaModel {
    shipping?: any;
    shippingDate?: any;
    cep?: string;
    city?: string;
    complement?: string;
    neighborhood?: string;
    number?: string;
    state?: string;
    street?: string;
    nfe?: any;
    accessories?: any;
    comments?: string;

    constructor() {
        this.shipping = '';
        this.shippingDate = '';
        this.cep = '';
        this.city = '';
        this.complement = '';
        this.neighborhood = '';
        this.number = '';
        this.state = '';
        this.street = '';
        this.nfe = '';
        this.accessories = '';
        this.comments = '';
    }
}
