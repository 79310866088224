import { Table } from '@scuf/common';
import { dateFormatFromMicrosft, formatDate } from 'helpers';
import { OrderDTO } from 'models/spareParts/orderById';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { orderAtom } from 'resources/atoms/spareParts';

const ProductAddedWaranty: React.FC = () => {
    const [orderData] = useRecoilState<OrderDTO>(orderAtom);
    const { t } = useTranslation(['spareparts', 'common']);

    return (
        <>
            <div className="div-datatable">
                <div>
                    <h2>
                        {t('translation.AddedProducts')} {'(' + orderData?.orderItems.length + ')'}{' '}
                    </h2>
                    <Table>
                        <Table.Header>
                            <Table.HeaderCell content={t('common:translation.Item')} />
                            <Table.HeaderCell content={''} />
                            <Table.HeaderCell content={t('translation.EquipSerial')} />
                            <Table.HeaderCell content={t('translation.SerialPart')} />
                            <Table.HeaderCell content={t('common:translation.Date')} />
                            <Table.HeaderCell content={t('common:translation.Problem')} />
                        </Table.Header>
                        <Table.Body>
                            {orderData?.orderItems &&
                                orderData?.orderItems?.map((item: any) => (
                                    <Table.Row>
                                        <Table.Cell>{item.partNumber}</Table.Cell>
                                        <Table.Cell>{item.description} </Table.Cell>
                                        <Table.Cell>{item.serialNumberEquipment} </Table.Cell>
                                        <Table.Cell>{item.serialNumberPart} </Table.Cell>
                                        <Table.Cell>{formatDate(item.createdAt)} </Table.Cell>
                                        <Table.Cell>{item.descriptionDefectFound} </Table.Cell>
                                    </Table.Row>
                                ))}
                        </Table.Body>
                    </Table>
                </div>
            </div>
        </>
    );
};

export default ProductAddedWaranty;
