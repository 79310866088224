export type UserModel = {
    ssoId?: string;
    name?: string;
    email?: string;
    phone?: string;
    roleId: number;
    role?: RoleModel;
};

export type RoleModel = {
    id: string;
    name: string;
};

export type UserModuleModel = {
    module: Module;
    permission: Permission;
};

export type Module = {
    id: number;
    name: string;
};

export type Permission = {
    id: number;
    name: string;
};

export class UserAccount {
    cpfCnpj: string;
    name: string;
    email: string;
    password: string;
    telephone1: string;
    ramal1: string;
    telephone2: string;
    ramal2: string;
    cep: string;
    state: string;
    city: string;
    neighborhood: string;
    street: string;
    number: string;
    complement: string;

    constructor() {
        this.cpfCnpj = '';
        this.name = '';
        this.email = '';
        this.password = '';
        this.telephone1 = '';
        this.ramal1 = '';
        this.telephone2 = '';
        this.ramal2 = '';
        this.cep = '';
        this.state = '';
        this.city = '';
        this.neighborhood = '';
        this.street = '';
        this.number = '';
        this.complement = '';
    }
}

export class UserPortal {
    companyId: number;
    name: string;
    email: string;
    roleId: number;
    phone: string;
    ssoId: string;

    constructor(email: string, roleId: number, name: string, companyId: number, phone: string, ssoId: string) {
        this.name = name;
        this.email = email;
        this.roleId = roleId;
        this.companyId = companyId;
        this.phone = phone;
        this.ssoId = ssoId;
    }
}

export type UserResume = {
    CompanyCNPJ: string;
    CompanyName: string;
    Contact: string;
    Email: string;
    Id: number;
};

export type UserCookie = {
    ID: string;
    ROLE: string;
    MODULE: string;
    PERMISSION: string;
    NAME: string;
    EMAIL: string;
    COMPANY: number;
    SSOID: string;
};
