import { accessoryMap, BigHead, bodyMap, clothingMap, hairMap } from '@bigheads/core';
import { colors } from '@bigheads/core/dist/theme';
import { useEffect, useState } from 'react';

function IconUser() {
    const [bigHead, setBigHead] = useState<any>({
        accessory: 0,
        body: 0,
        clothing: 0,
        hair: 0,
        hairColor: 0,
        skinTone: 0
    });

    const _bigHead = new _BigHead();

    function randomNumberInRange(min: number, max: number) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    useEffect(() => {
        let newBigHead = {
            accessory: _bigHead.accessories[randomNumberInRange(0, _bigHead.accessories.length - 1)],
            body: _bigHead.bodies[randomNumberInRange(0, _bigHead.bodies.length - 1)],
            clothing: _bigHead.clothings[randomNumberInRange(0, _bigHead.clothings.length - 1)],
            clothingColor: _bigHead.clothingColors[randomNumberInRange(0, _bigHead.clothingColors.length - 1)],
            hair: _bigHead.hairs[randomNumberInRange(0, _bigHead.hairs.length - 1)],
            hairColor: _bigHead.hairColors[randomNumberInRange(0, _bigHead.hairColors.length - 1)],
            skinTone: _bigHead.skinTones[randomNumberInRange(0, _bigHead.skinTones.length - 1)]
        };
        setBigHead(newBigHead);
    }, []);

    return (
        <BigHead
            accessory={_bigHead.accessories[bigHead.accessory]}
            body={_bigHead.bodies[bigHead.body]}
            circleColor="blue"
            clothing={_bigHead.clothings[bigHead.clothing]}
            clothingColor={_bigHead.clothingColors[bigHead.clothingColor]}
            eyebrows="leftLowered"
            eyes="simple"
            faceMask={false}
            faceMaskColor="white"
            facialHair="none"
            graphic="none"
            hair={_bigHead.hairs[bigHead.hair]}
            hairColor={_bigHead.hairColors[bigHead.hairColor]}
            hat="none"
            hatColor="white"
            lashes
            lipColor="turqoise"
            mask
            mouth="openSmile"
            skinTone={_bigHead.skinTones[bigHead.skinTone]}
        />
    );
}
export default IconUser;

class _BigHead {
    accessories: Array<keyof typeof accessoryMap> = ['none', 'roundGlasses', 'tinyGlasses', 'shades'];
    bodies: Array<keyof typeof bodyMap> = ['chest', 'breasts'];
    clothings: Array<keyof typeof clothingMap> = ['shirt', 'dressShirt', 'vneck', 'dress'];
    clothingColors: Array<keyof typeof colors.clothing> = ['black', 'white', 'blue', 'red', 'green'];
    hairs: Array<keyof typeof hairMap> = ['none', 'long', 'bun', 'short', 'pixie', 'balding', 'buzz', 'afro', 'bob'];
    hairColors: Array<keyof typeof colors.hair> = ['brown', 'black', 'blonde', 'orange', 'white', 'blue', 'pink'];
    skinTones: Array<keyof typeof colors.skin> = ['red', 'black', 'brown', 'light', 'yellow', 'dark'];
}
