const Result: React.FC = () => {
    return (
        <div>
            <p>
                Quando uma Requisição do tipo <i>Revisão de Preço de Cotação</i> é importada para o Portal de Serviços, um Token
                de Acesso é gerado e é enviado para o e-mail do Relator. O Relator, com o Token de Acesso, consegue responder a
                Requisição sem precisar se autenticar no Portal de Serviços.
            </p>
            <p>
                A imagem acima mostra a estrutura do layout do E-mail recebido pelo Relator. O E-mail apresenta um Resumo da
                Requisição e um link que permite acessar a Requisição no Portal de Serviços. Ao cliclar no link, a seguinte tela
                será exibida:
            </p>
            <p>
                A imagem acima apresenta o layout da Tela de Aprovação de Requisição. Cada região marcada em vermelo na imagem,
                possui uma função.
            </p>
            <p>
                A <strong>REGIÃO 1 representa botões de ações</strong>. Para selecionar todos os itens (produtos) clique no
                prmeiro botão (da equerda para a direita). Para desmarcar todos os itens clique no segundo botão. Para APROVAR
                (iguar o <i>P. Original</i> com <i>P. Aprovado</i>) todos os Produtos selecionados, clique no terceiro botão. Para
                REPROVAR (setar 'P. Aprovado' para zero) os produtos selecionados, clique no quarto botão.
            </p>
            <p>
                A <strong>REGIÃO 2 indica os produtos selecionados</strong>. Essa função somente é necessária caso o usuário
                precise executar uma mesma ação (aprovar ou reprovar) vários Produtos simultaneamente.
            </p>
            <p>
                A <strong>REGIÃO 3 é um atalho para detalhes do Produto</strong>. Ao clicar sobre essa região (Part Number) será
                exibido um pop-up com todos os detalhes do Produto em questão.
            </p>
            <p>
                A <strong>REGIÃO 4 e 5 seta a Resposta do Relator para cada Produto</strong>. Se o valor Aprovado for menor que{' '}
                <i>P. Solicitado</i>, a Ação será REPROVAR (Relator não Aprovou Produto). Se o <i>P. Solicitado</i> for igual a{' '}
                <i>P. Aprovado</i>, a ação será APROVAR (Relator Aprovou Produto). Se <i>P. Aprovado</i> for maior que{' '}
                <i>P. Solicitado</i> a ação será REVISAR (Relator indicou outro preço para o Produto).
            </p>
            <p>
                A <strong>REGIÃO 6 é o botão para confirmação da Resposta</strong>. Quando uma Resposta for atribuída a todos os
                Produtos é necessário clicar no botão dessa Resposta para confirmar a resposta da Requisição.
            </p>
        </div>
    );
};
export default Result;
