import { AxiosHttpPost } from 'axios-http';
import { REPAIR_CENTER } from 'resources/links';
import { SatisfactionSurvey } from "models/repaircenter/satisfactionSurvey";

export function getSatisfactionSurveyList(Rma?: any, Customer?: any, stOptions?: any) {
    let body = {
        Customer,
        Indice: 1,
        ItemsByPage: 20,
        MaxIndice: 0,
        NF: '',
        Result: [],
        Rma,
        RmaId: '',
        Status: stOptions,
        TotalResults: '',
        dateEndQuest: null,
        dateEndResp: null,
        dateStartQuest: null,
        dateStartResp: null
    };
    return AxiosHttpPost({ url: REPAIR_CENTER.getSatisfactionSurveyList, data: body });
}
export function addSatisfactionSurvey(surveyModel:SatisfactionSurvey|undefined) {
    return AxiosHttpPost({ url: REPAIR_CENTER.addSatisfactionSurvey, data: surveyModel });
}
export function getSatisfactionSurvey(ssoId:string|undefined) {
    return AxiosHttpPost({ url: REPAIR_CENTER.getSatisfactionSurvey, data: ssoId });
}
export function updateSatisfactionSurvey(surveyModel:SatisfactionSurvey|undefined) {
    return AxiosHttpPost({ url: REPAIR_CENTER.updateSatisfactionSurvey, data: surveyModel });
}