import { AboutContainer } from 'components';
import usePreventClickjacking from 'helpers/hooks/usePreventingClickjacking';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'styles/main.scss';

function PartnersPage() {
    const [width, setWidth] = useState<number>(0);

    const { t } = useTranslation(['certified', 'maintenance']);
    const tam = 845;
    function handleResize() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        document.title = 'Parceiros - Portal Honeywell';
        setWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
    }, []);

    usePreventClickjacking();

    return (
        <>
            <div className="container">
                <div>
                    <h4>{t('maintenance:contract.title')}</h4>
                    <div className="contentPageContainer">
                        {width > tam ? (
                            <img
                                alt="imagem ilustrativa hhomem no mercado"
                                src={require('../../assets/PD43_DC_14_300_450x360.jpg')}
                            />
                        ) : (
                            <div />
                        )}

                        <div className="default-text">
                            <p>{t('translation.CertifiedPageContent1')}</p>
                            <p>{t('translation.CertifiedPageContent2')}</p>
                            <ul className="default-list">
                                <li>{t('translation.CertifiedPageBulletPoint1')}</li>
                                <li>{t('translation.CertifiedPageBulletPoint2')}</li>
                                <li>{t('translation.CertifiedPageBulletPoint3')}</li>
                                <li>{t('translation.CertifiedPageBulletPoint4')}</li>
                                <li>{t('translation.CertifiedPageBulletPoint5')}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <AboutContainer />
        </>
    );
}

export default PartnersPage;
