import { AxiosFile, AxiosHttpGet, AxiosHttpPost } from 'axios-http';
import jwtDecode from 'jwt-decode';
import { OrderBudgetDTO } from 'models/spareParts/orderById';
import { SPARE_PARTS } from 'resources/links';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const headerNotContentType = {
    Accept: '*/*',
    'Content-Type': 'multipart/form-data',
    Authorization: `Bearer ${cookies.get('info')}`
};

export function uploadFile(file: any) {
    return AxiosHttpPost({ 
        url: SPARE_PARTS.uploadFile, 
        headers: headerNotContentType, 
        data: { file } });
}

export function uploadFileWarranty(formFile: any,  orderId: number, email: string, type: string) {
    return AxiosHttpPost({ 
        url: SPARE_PARTS.uploadFile, 
        headers: headerNotContentType, 
        data: { formFile, orderId, email, type } });
}


export function uploadBudget({ file, orderBudgetDTO }: { file: File; orderBudgetDTO: OrderBudgetDTO }) {
    return AxiosHttpPost({
        url: SPARE_PARTS.uploadBudget,
        headers: headerNotContentType,
        data: { file, orderBudgetDTO }
    });
}

export function downloadOrderFile(fileId: number, downloadName: string) {
    return AxiosFile({ url: SPARE_PARTS.downloadOrderFile, param: { fileId }, downloadName });
}
export function deleteFile(Id: number) {
    return AxiosHttpGet({ url: SPARE_PARTS.deleteFile, data: { Id } });
}

export function deleteBudget(id: number) {
    return AxiosHttpGet({ url: SPARE_PARTS.deleteBudget, data: { id } });
}
