import { Button, Input, Select } from '@scuf/common';
import useFetch from 'helpers/hooks/useFetch';
import { UserDTO, UserRole } from 'models/user/sso';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { USER_MANAGER, telephoneMask } from 'resources';

type Props = {
    user: UserDTO;
};

const FinaluserInfo: React.FC<Props> = ({ user }: Props) => {
    const { t } = useTranslation(['usermanager', 'common', 'user']);
    const [changed, setChanged] = useState<number>();
    const [disable, setDisable] = useState<boolean>(false);

    const [, update, loading] = useFetch<{ ssoId: string, roleId: number }>({
        url: USER_MANAGER.updateRole,
        method: 'GET',
        onError: 'Update',
        onSuccess: 'Update'
    });

    useEffect(() => {
        if (changed != null) {
            setDisable(false)
        } else {
            setDisable(true)
        }
    }, [changed])

    const handleEdit = async (ssoId: string, roleId: number) => {
        await update({ ssoId, roleId });
        setDisable(true)
    };
    return (
        <>
            {user && (
                <>
                    <div className="form4Columns">
                        <Input
                            label={t('user:info.Name')}
                            value={user?.name}
                            disabled
                        ></Input>
                        <Input
                            label={t('Email')}
                            value={user?.email}
                            disabled
                        ></Input>
                        <Select
                            className="SelectStyle"
                            label={t('user:info.Role')}
                            options={[
                                { text: 'Super Admin', value: parseInt(UserRole.SUPERADMIN) },
                                { text: 'Admin', value: parseInt(UserRole.ADMIN) },
                                { text: 'Client Admin', value: parseInt(UserRole.CLIENTADMIN) },
                                { text: 'Client', value: parseInt(UserRole.CLIENT) }
                            ]}
                            defaultValue={user.roleId}
                            placeholder={t('user:info.Role')}
                            onChange={value => setChanged(value)}
                        ></Select>
                        <Input label={t('user:company.Name')} disabled value={user?.company?.name}></Input>
                        <Input
                            label={t('user:company.Id')}
                            value={user?.company?.cnpj}
                            disabled
                        ></Input>
                        <Input
                            label={t('user:address.Phone')}
                            disabled
                            value={telephoneMask(user?.company?.phone || ' ')}
                        ></Input>
                        <Input label={t('user:address.City')} disabled value={user?.company?.city}></Input>
                        <Input
                            label={t('user:address.Street')}
                            value={user?.company?.street}
                            disabled
                        ></Input>
                        <Input label={t('user:address.State')} value={user?.company?.state} disabled></Input>
                        <Input label={t('user:address.Neighborhood')} value={user?.company?.neighborhood} disabled></Input>
                        <Input label={t('CEP')} disabled value={user?.company?.zipCode}></Input>
                        <Input label={t('user:address.Complement')} disabled value={user?.company?.complement}></Input>
                    </div>
                </>
            )}
            <div className="buttonsSearch">
                <Button
                    onClick={() => handleEdit(user.ssoId.toString(), changed!)}
                    content={t('common:actions.Change')}
                    loading={loading}
                    disabled={!changed || disable}
                ></Button>
            </div>
        </>
    );
};

export default FinaluserInfo;
