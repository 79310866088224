import { CustomFooter, CustomHeader } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomerTabManager from './tabs/customerManager/customerTabManager';
import { AdminTabManager, ApprovalTabManager } from './tabs';

import { Tab } from '@scuf/common';
import useUser from 'helpers/hooks/useUser';
import { UserRole } from 'models/user/sso';
import usePreventClickjacking from 'helpers/hooks/usePreventingClickjacking';

const UserManager: React.FC = () => {
    const { t } = useTranslation(['repaircenter', 'common']);
    const { user: userDetails } = useUser();

    usePreventClickjacking();

    return (
        <div className="container wrap-content">
            <Tab defaultActiveIndex={0} className="tab-with-divider">
                <Tab.Pane title={t('user:info.User')}>
                    <CustomerTabManager />
                </Tab.Pane>
                {userDetails?.ROLE != UserRole.CLIENTADMIN ? (
                    <Tab.Pane title={t('user:address.Company')}>
                        <AdminTabManager />
                    </Tab.Pane>
                ) : (
                    []
                )}
                <Tab.Pane title={t('user:Approvals')}>
                    <ApprovalTabManager></ApprovalTabManager>
                </Tab.Pane>
            </Tab>
        </div>
    );
};

export default UserManager;
