import { AxiosHttpGet, AxiosHttpPost } from 'axios-http';
import { CompanyFilterDTO, FilterUserModel, UserModel, UserPortal } from 'models';
import { PartnersModel } from 'models/spareParts';
import { UserDTO } from 'models/user/sso';
import { COMPANY_MANAGER, USER_MANAGER } from 'resources/links';

export async function getUsers(data: FilterUserModel) {
    return AxiosHttpPost({ url: USER_MANAGER.getUsers, data });
}
export async function updateUser(data: UserDTO) {
    return AxiosHttpPost({ url: USER_MANAGER.updateUser, data });
}

export async function getCompanies(data: CompanyFilterDTO){
    return AxiosHttpPost({ url: COMPANY_MANAGER.getCompanies, data });

}

export async function GetUserById(data: { ssoId: number | string }) {
        return AxiosHttpGet({
        url: USER_MANAGER.GetUserById,
        data
    });
}

export async function editCustomer(data: PartnersModel) {
    return AxiosHttpPost({
        url: USER_MANAGER.editCustomer,
        data
    });
}
export async function editUserManager(data: UserModel) {
    return AxiosHttpPost({
        url: USER_MANAGER.editUser,
        data
    });
}

export async function deleteUser(data: { id: number }) {
    return AxiosHttpPost({
        url: USER_MANAGER.deleteUser,
        data
    });
}
export async function addUser(userPortal: UserPortal){
    return await AxiosHttpPost({ url: USER_MANAGER.addUser, data: userPortal });
}
export async function removeUser( id: string ) {
    return AxiosHttpGet({
        url: USER_MANAGER.removeUser,
        data: {id}
    })
}

export async function getCompanyById(data: { id: number | string }) {
    return AxiosHttpGet({
        url: COMPANY_MANAGER.getCompanyById,
        data
    });
}