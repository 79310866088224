import { AxiosHttpPost } from 'axios-http';
import { ModelEOSResult } from 'models/repaircenter/eosModel';
import { atom } from 'recoil';
import { REPAIR_CENTER } from 'resources/links';

export function getModelsEOS(BlockingLevel?: string, Model?: String, Result?: any) {
    const data = {
        BlockingLevel,
        Date_EOS: null,
        Indice: 1,
        ItemsByPage: 5,
        MaxIndice: 1,
        Model,
        Result
    };
    return AxiosHttpPost({ url: REPAIR_CENTER.getModelsEOS, data: data });
}

export function addModelEos(ModelEOS: { BlockingLevel: string; CreatedAt: string; Model: string; ProblemLocation: string }) {
    return AxiosHttpPost({ url: REPAIR_CENTER.addModelEos, data: ModelEOS });
}

export const eosDetailState = atom({
    key: 'eosDetailState',
    default: null as unknown as ModelEOSResult
});
