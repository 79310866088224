export class UserAccess {
    name: string;
    surname: string;
    email: string;
    telephone: string;
    ramal: string;
    password: string;
    constructor() {
        this.name = '';
        this.surname = '';
        this.email = '';
        this.telephone = '';
        this.ramal = '';
        this.password = '';
    }
}
