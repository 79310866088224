import { Divider, List } from '@scuf/common';

const PriceReview: React.FC = () => {
    return (
        <>
            <section>
                <h3>Visão Geral</h3>
                <p>
                    Uma Requisição do tipo <i>Revisão de Preço de Cotação</i> é uma solicitação de aprovação dos preços de uma
                    Cotação. Por exemplo, quando um vendedor deseja conceder algum desconto para o Cliente, vendendo os produtos
                    abaixo da margem definida previamente, o vendedor precisa criar uma Requisição de Revisão de Preço para
                    submeter à aprovação/reprovação de um responsável (relator).
                </p>
                <p>
                    Este tipo de Requisição é gerado através do Sistema Interno (SIP) e é importado para o Portal logo em seguida.
                    O Portal então notifica o Relator da existência de uma Requisição aguardando sua resposta e, quando
                    respondido, enviar essas informações novamente para o SIP e notifica as partes envolvidas (requisitante,
                    vendedor e relator) que a operação foi concluída!
                </p>
                <p>
                    Uma Requisição do tipo <i>Revisão de Preço de Cotação</i> possui as seguintes propriedades:
                </p>
                <List ordered>
                    <List.Content>
                        <h3>ID </h3>
                        <h5>Identificador da Requisição </h5>
                    </List.Content>
                    <List.Content>
                        <h3>Número </h3>
                        <h5>Código da Cotação, gerado no Sistema SIP</h5>
                    </List.Content>
                    <List.Content>
                        <h3>Número da Cotação </h3>
                        <h5>Número da Cotação, gerado no Sistema SIP</h5>
                    </List.Content>
                    <List.Content>
                        <h3>Nome do Cliente </h3>
                        <h5>Nome do Cliente parte da Cotação</h5>
                    </List.Content>
                    <List.Content>
                        <h3>Data de Resposta </h3>
                        <h5>Indica a data em que a Requisição foi respondida</h5>
                    </List.Content>
                    <List.Content>
                        <h3>Margem </h3>
                        <h5>Margem da Cotação </h5>
                    </List.Content>
                    <List.Content>
                        <h3>Linha de Negócios </h3>
                        <h5>Indica a Linha de Negócios </h5>
                    </List.Content>
                    <List.Content>
                        <h3>Nome Vendedor </h3>
                        <h5>Nome do Vendedor parte da Cotação</h5>
                    </List.Content>
                    <List.Content>
                        <h3>E-mail do Vendedor </h3>
                        <h5>E-mail do Vendedor parte da Cotação</h5>
                    </List.Content>
                    <List.Content>
                        <h3>Produtos </h3>
                        <h5>Produtos inclusos na Cotação </h5>
                    </List.Content>
                </List>
                <Divider />
                <p>Abaixo, são listadas as propriedades de um Produto de Cotação:</p>
                <Divider />
                <List>
                    <List.Content>
                        <h3>ID </h3>
                        <h5>Identificador interno do Portal</h5>
                    </List.Content>
                    <List.Content>
                        <h3>Número </h3>
                        <h5>Refere-se ao código do Produto no Sistema SIP</h5>
                    </List.Content>
                    <List.Content>
                        <h3>Part Number </h3>
                        <h5>Identificador da peça </h5>
                    </List.Content>
                    <List.Content>
                        <h3>Descrição </h3>
                        <h5>Descrição do Part Number </h5>
                    </List.Content>
                    <List.Content>
                        <h3>Moeda </h3>
                        <h5>Indica a moeda (dólar, real, etc) em que a operação está sendo feita</h5>
                    </List.Content>
                    <List.Content>
                        <h3>Margem </h3>
                        <h5>Margem do Produto </h5>
                    </List.Content>
                    <List.Content>
                        <h3>Margem Geral </h3>
                        <h5>Margem Geral do Produto </h5>
                    </List.Content>
                    <List.Content>
                        <h3>Produtos </h3>
                        <h5>Produtos inclusos na Cotação </h5>
                    </List.Content>
                    <List.Content>
                        <h3>Quantidade </h3>
                        <h5>Indica a quantidade do mesmo tipo de Part Number</h5>
                    </List.Content>
                    <List.Content>
                        <h3>Preço de Lista </h3>
                        <h5>Preço Unitário do Produto previamente definido para venda</h5>
                    </List.Content>
                    <List.Content>
                        <h3>Preço </h3>
                        <h5>Preço Unitário do produto indicado pelo Requisitante</h5>
                    </List.Content>
                    <List.Content>
                        <h3>Preço Total </h3>
                        <h5>O produto entre Preço Unitário e Quandtidade</h5>
                    </List.Content>
                    <List.Content>
                        <h3>Preço Aprovado </h3>
                        <h5>Preço Unitário aprovado pelo Relator</h5>
                    </List.Content>
                    <List.Content>
                        <h3>Preço Total Aprovado </h3>
                        <h5>O produto entre o Preço Unitário Aprovado e a Quantidade</h5>
                    </List.Content>
                    <List.Content>
                        <h3>Resultado </h3>
                        <h5>Indica se o produto foi aprovado/reprovado/revisado</h5>
                    </List.Content>
                </List>
                <Divider />
                <h3>Ciclo de Vida</h3>
                <p>
                    O Ciclo de Vida de uma Requisição do tipo <i>Revisão de Preço de Cotação</i> segue as seguintes etapas:
                </p>
                <List ordered>
                    <List.Content>
                        <h3>Aguardando Resposta do Relator </h3>
                        <h5>A Requisição foi importada, o Relator foi notificado para Responder a Requisição.</h5>
                    </List.Content>
                    <List.Content>
                        <h3>Aguardando Sincronização </h3>
                        <h5>A Requisição foi respondida e será sincronizada com o SIP em breve.</h5>
                    </List.Content>
                    <List.Content>
                        <h3>Finalizado </h3>
                        <h5>A Requisição foi finalizada. </h5>
                    </List.Content>
                </List>
            </section>
        </>
    );
};
export default PriceReview;
