import { setAuthHeader } from './axiosHeader';
import { LOADING_USER, STORE_USER, STORE_USER_ERROR, USER_EXPIRED, USER_SIGNED_OUT } from './types';

export function storeUser(user: any): { type: string; payload: any } {
    setAuthHeader(user.access_token);
    return {
        type: STORE_USER,
        payload: user
    };
}

export function loadingUser(): { type: string } {
    return {
        type: LOADING_USER
    };
}

export function storeUserError(): { type: string } {
    return {
        type: STORE_USER_ERROR
    };
}

export function userExpired(): { type: string } {
    return {
        type: USER_EXPIRED
    };
}

export function userSignedOut(): { type: string } {
    return {
        type: USER_SIGNED_OUT
    };
}
