import { Table } from '@scuf/common';
import { AboutContainer } from 'components';
import usePreventClickjacking from 'helpers/hooks/usePreventingClickjacking';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'styles/main.scss';

function MaintenancePage() {
    const [width, setWidth] = useState<number>(0);

    const { t } = useTranslation(['maintenance', 'repaircenterts', 'certified', 'common']);
    const tam = 845;
    function handleResize() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        document.title = 'Manutenção - Portal Honeywell';
        setWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
    }, []);

    usePreventClickjacking();
    return (
        <>
            <div className="container">
                <div>
                    <div className="contentPageContainer">
                        {width > tam ? (
                            <img
                                alt="imagem ilustrativa hhomem no mercado"
                                src={require('../../assets/PC42t_Retail_147_450x360.jpg')}
                            />
                        ) : (
                            <div />
                        )}
                        <div className="container-med">
                            <h4>{t('contract.title')}</h4>
                            <p>{t('contract.PageContent1')}</p>
                            <p>{t('contract.PageContent2')}</p>
                            <p>{t('contract.PageContent3')}</p>
                            <ul>
                                <li>{t('contract.PageBulletPoint1')}</li>
                                <li>{t('contract.PageBulletPoint2')}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div>
                    <div className="contentPageContainer">
                        <div className="container-med">
                            <h4 className="uppercase-title">{t('service_table.AdvantagesBenefits')}</h4>
                            <p>{t('benefits.PageContent1')}</p>
                            <p>{t('benefits.PageContent2')}</p>
                            <p>{t('benefits.PageContent3')}</p>
                            <p>{t('benefits.PageContent4')}</p>
                            <p>{t('benefits.PageContent5')}</p>
                        </div>
                        {width > 845 ? (
                            <img
                                alt="imagem ilustrativa hhomem no mercado"
                                src={require('../../assets/RepairDepot_APP17_450x360.jpg')}
                            />
                        ) : (
                            <div />
                        )}
                    </div>
                </div>
            </div>
            <div className="container">
                <div>
                    <Table>
                        <Table.Header>
                            <Table.HeaderCell content={t('service_table.AdvantagesBenefits')} />
                            <Table.HeaderCell content={t('service_table.Separate')} />
                            <Table.HeaderCell content={t('common:services.Warranty')} />
                            <Table.HeaderCell content={t('service_table.Contract')} />
                        </Table.Header>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>{t('service_table.Duration')}</Table.Cell>
                                <Table.Cell>{t('service_table.NA')}</Table.Cell>
                                <Table.Cell>{t('service_table.Years1235')}</Table.Cell>
                                <Table.Cell>{t('service_table.Years1235')}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>{t('service_table.Shihpments')}</Table.Cell>
                                <Table.Cell>{t('common:actions.No')}</Table.Cell>
                                <Table.Cell>{t('service_table.ShipmentReturn')}</Table.Cell>
                                <Table.Cell>{t('service_table.ShipmentReturn')}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>{t('service_table.ReturnDeadline')}</Table.Cell>
                                <Table.Cell>{t('service_table.Days30')}</Table.Cell>
                                <Table.Cell>{t('service_table.Days30')}</Table.Cell>
                                <Table.Cell>{t('service_table.Days5')}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>{t('service_table.Misuse')}</Table.Cell>
                                <Table.Cell>{t('common:actions.No')}</Table.Cell>
                                <Table.Cell>{t('common:actions.No')}</Table.Cell>
                                <Table.Cell>{t('service_table.Yes')}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>{t('service_table.Renewal')}</Table.Cell>
                                <Table.Cell>{t('service_table.NA')}</Table.Cell>
                                <Table.Cell>{t('common:actions.No')}</Table.Cell>
                                <Table.Cell>{t('service_table.Yes')}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>{t('service_table.PhoneSupport')}</Table.Cell>
                                <Table.Cell>{t('service_table.Yes')}</Table.Cell>
                                <Table.Cell>{t('service_table.Yes')}</Table.Cell>
                                <Table.Cell>{t('service_table.Yes')}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>{t('service_table.BudgetApproval')}</Table.Cell>
                                <Table.Cell>{t('service_table.Yes')}</Table.Cell>
                                <Table.Cell>{t('common:actions.No')}</Table.Cell>
                                <Table.Cell>{t('common:actions.No')}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>{t('service_table.CoverBatteriesHeads')}</Table.Cell>
                                <Table.Cell>{t('service_table.Yes')}</Table.Cell>
                                <Table.Cell>{t('service_table.Yes')}</Table.Cell>
                                <Table.Cell>{t('common:actions.No')}</Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </div>
            </div>
            <AboutContainer />
        </>
    );
}

export default MaintenancePage;
