import axios, { AxiosRequestConfig } from "axios";
import Cookies from "universal-cookie";

type DownloadProps = {
    method?: "GET" | "POST",
    params: any
}

const header = {
    "Authorization": `Bearer ${new Cookies().get("info")}`
}


// const requestFile = async (url: string,{method = "GET", params}: DownloadProps): Promise<string | null>=>{
//     try{
//         const file = 
//         
//         return fileurl
//     }
//      catch(error: any){
//         return null;
//     }
// }

const transformFileToDownload = async (url:string,downloadName:string,{method,params}:DownloadProps)=>{
    const file = await axios.request({url,method, params: method ==="GET"? params : {data:params}, responseType:'blob',headers:{
        "Authorization": `Bearer ${new Cookies().get("info")}`
    }})
    const fileUrl = URL.createObjectURL(new Blob([file.data],{type:file.data.type}));

    if(!fileUrl) return
    // Cria um link âncora invisível
    const link = document.createElement('a');
    link.style.display = 'none';
    link.href = fileUrl;
    link.setAttribute('download', downloadName); // Define o atributo 'download' para iniciar o download
    // Adiciona o link ao documento e clica nele para iniciar o download
    document.body.appendChild(link);
    link.click();
    // Remove o link do documento
    document.body.removeChild(link);
}

const transformFileToPrint = async (url:string,{method,params}:DownloadProps)=>{
    let config : AxiosRequestConfig = {
        url,
        method, 
        headers: {
        "Authorization": `Bearer ${new Cookies().get("info")}`}
    }

    if (method === 'GET')
        config.params = params

    else 
        config.data = params


    const file = await axios.request(config)
    const fileToPrint = file.data;
    if(!fileToPrint) return
    const WinPrint = window.open('left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
    WinPrint?.document.write(fileToPrint);
    WinPrint?.print();
}


export default {
    download: transformFileToDownload,
    print: transformFileToPrint
}