const UserManagementManual: React.FC = () => {
    return (
        <div>
            <h3>1. Visão Geral</h3>
            <p>Um Cliente é um cadastro de Pessoa Física ou Jurídica e apresenta as seguintes propriedades:</p>
            <ul>
                <li>ID</li>
                <li>Razão Social</li>
                <li>Nome Fantasia</li>
                <li>CPF/CNPJ</li>
                <li>País</li>
                <li>Estado/Província</li>
                <li>Cidade</li>
                <li>CEP</li>
                <li>Bairro</li>
                <li>Logradouro</li>
                <li>Número</li>
                <li>Complemento</li>
                <li>Telefone</li>
                <li>E-mail</li>
            </ul>
            <p>
                Um <strong>Cliente não pode acessar o Portal se não possuir nenhum Usuário</strong> associado e um{' '}
                <strong>Cliente pode ter qualquer quantidade de Usuários</strong>. Um usuário apresenta as seguintes propriedades:
            </p>
            <ul>
                <li>ID</li>
                <li>Nome</li>
                <li>Sobrenome</li>
                <li>Sexo</li>
                <li>Data de Nascimento</li>
                <li>E-mail</li>
                <li>Senha</li>
                <li>Telefone</li>
                <li>Posição (cargo)</li>
                <li>EID</li>
            </ul>
            <p>
                Os Usuário são entidades que acessam e manipulam os dados do Portal de Serviços (ex: criar Pedidos, processar
                requisições, etc). Existem 2 tipos de Usuários:
            </p>
            <ul>
                <li>Não-Administrador</li>
                <li>Administrador</li>
            </ul>
            <p>
                Um Usuário do tipo Não-Administrador obrigatoriamente precisa estar associado a um Cliente. Usuários
                Administradores são os agentes que processam as Requisiçõe dos Usuários Não-Administradores.
            </p>
            <p>
                A manipulação de dados dentro do Portal pelos Usuários é <strong>condicionado aos seguintes privilégios</strong>:
            </p>
            <ul>
                <li>Acesso (definido através de Módulos)</li>
                <li>Ação (definido através de Chaves)</li>
            </ul>
            <p>
                O Privilégio do tipo <strong>Acesso especifica os Módulos</strong> (por exemplo, Centro de Reparos) que podem ser
                acessados. Privilégio do tipo <strong>Ação especifica as Chaves</strong> que o Usuário pode fazer (exemplo, criar
                pedido) dentro dos Módulos.
            </p>
            <p>
                Portanto, o que define os{' '}
                <strong>
                    locais em que o Usuário pode acessar e as ações que ele pode executar são definidos através de Módulos e
                    Chaves
                </strong>
                . Porém, em certas situações o Usuário{' '}
                <strong>pode não conseguir executar uma ação ou acessar uma tela se ele possuir alguma Pendência</strong>. Uma
                Pendência pode ser adicionada manualmente pelo Usuário Administrador ou pode ser automática (por exemplo, ao se
                cadastrar requerer a ativação do acesso através do e-mail).
            </p>
        </div>
    );
};
export default UserManagementManual;
