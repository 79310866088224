import { Button, DatePicker, Select } from '@scuf/common';
import { typeReportOpt } from 'models/spareParts';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { notificationAtom } from 'resources';
import { getReportFile } from 'resources/api/spareParts_api/getReport';

function SparePartsReport() {
    const [rangeValue, setRangeValue] = useState<{ to: any; from: any }>();
    const { t } = useTranslation(['spareparts', 'common']);
    const [loading, setLoading] = useState<boolean>(false);
    const setNotification = useSetRecoilState(notificationAtom);
    const [typeReport, setTypeReport] = useState<number | null>();

    useEffect(() => {
        setEmptyValue();
    }, []);

    useEffect(() => {
        enableButton();
    }, [rangeValue, typeReport]);

    async function handleReport(dateRange: any, typeReport: any) {
        setLoading(true);

        const formatDate = (date: Date) => {
            const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
            const month = date.getMonth() < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
            const year = date.getFullYear();
            const hour = date.getHours();
            const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
            return `${day}-${month}-${year}-${hour}h-${minutes}min`;
        };

        const formatName = (type: number) => {
            switch (type) {
                case 0:
                    return 'COTACAO-PRE-APROVADO';
                case 1:
                    return 'GARANTIA';
                default:
                    return '';
            }
        };

        const dateStart = new Date(dateRange.to);
        const dateEnd = new Date(dateRange.from);
        const resp = await getReportFile(dateStart, dateEnd, typeReport);

        if (resp.status != 200) {
            setNotification(old => ({ ...old, message: 'NoResult', type: 'information' }));
            setLoading(false);
            return;
        }

        const formattedDate = formatDate(new Date());
        const fileName = formatName(typeReport);
        downloadFile(resp.data, `report${fileName}-${formattedDate}.xlsx`);
        setLoading(false);
    }

    function downloadFile(data: any, fileName: string) {
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    }

    function setEmptyValue() {
        setRangeValue({ to: '', from: '' });
        setTypeReport(null);
    }

    const enableButton = () => {
        return (rangeValue?.to ?? '') !== '' && (rangeValue?.from ?? '') !== '' && typeReport !== null;
    };

    return (
        <div className="form4Columns">
            <div>
                <Select
                    indicator="required"
                    className="SelectStyle"
                    label={t('common:translation.ReportType')}
                    placeholder={t('common:translation.ReportType')}
                    options={typeReportOpt}
                    value={typeReport}
                    onChange={e => {
                        setTypeReport(e);
                    }}
                />
                <DatePicker
                    indicator="required"
                    type="daterange"
                    label={t('common:translation.Period')}
                    rangeValue={rangeValue}
                    displayFormat={'DD-MM-YYYY'}
                    closeOnSelection={true}
                    closeOnDocumentClick={true}
                    onRangeSelect={({ to, from }) => {
                        setRangeValue({
                            to,
                            from
                        });
                    }}
                />
            </div>
            <div className="buttonsSearch">
                <Button
                    type="secondary"
                    icon="badge-delete"
                    content={t('common:actions.ResetFilters')}
                    onClick={setEmptyValue}
                    iconPosition="right"
                />
                <Button
                    type="primary"
                    icon="report"
                    content={t('common:actions.Generate')}
                    loading={loading}
                    iconPosition="right"
                    disabled={!enableButton()}
                    onClick={() => handleReport(rangeValue, typeReport)}
                />
            </div>
        </div>
    );
}

export default SparePartsReport;
