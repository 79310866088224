import { IPermissionValidator, ModulePermissionValidator } from 'helpers';
import { UserModule } from 'models/user/sso';
import {
    CertifiedPage,
    CompanyManager,
    ContactPage,
    ContractsPage,
    DashboardMenu,
    DetailsRma,
    FinalCustomerManager,
    FindAnAssistancePage,
    Home,
    InfoAssistancePage,
    MaintenancePage,
    NewRma,
    NewRmaSummary,
    PartnersPage,
    PrintOrder,
    RepairCenter,
    RepairCenterPage,
    RmaDetails,
    SendOrderBudget,
    SolutionsPage,
    SpareParts,
    UserManager,
    WarrantyConsultPage,
    InstructionsProceduresPage
} from 'pages';
import SparePartsPage from 'pages/identity/sparePartsPage';
import MaintenanceReportPage from 'pages/maintenanceReport/maintenanceReportPage';
import NewMaintenanceReport from 'pages/maintenanceReport/newMaintenanceReport/newMaintenanceReport';
import MaintenanceReportDetails from 'pages/maintenanceReport/maintenanceReportDetails';
import Overview from 'pages/manual/acordion/overview';
import TesteManual from 'pages/manual/manual';
import OrdersDetailSelected from 'pages/spareParts/OrdersDetails';
import { SparePartsChat } from 'pages/spareParts/components/tabs';
import ShoppingCart from 'pages/spareParts/components/tabs/shoppingCart/shoppingCart';
import NewCompany from 'pages/userManager/tabs/customerManager/newCompany';
export type rootType = {
    path: string;
    element?: JSX.Element;
    protected?: boolean;
    name?: string;
    permissions?: IPermissionValidator[];
    children?: (rootType | leafType)[];
};

export type leafType = Omit<rootType, 'children'>;

const routes: rootType[] = [
    { path: '*', name: 'error' },
    {
        path: '/',
        element: <Home />,
        protected: false,
        name: 'home',
        children: [
            {
                path: 'info',
                children: [
                    {
                        path: 'repair-center',
                        element: <RepairCenterPage />,
                        protected: false,
                        name: 'repairCenter'
                    },
                    {
                        path: 'spare-parts',
                        element: <SparePartsPage />,
                        protected: false,
                        name: 'spareParts'
                    },
                    {
                        path: 'partners',
                        element: <PartnersPage />,
                        protected: false,
                        name: 'partners'
                    },
                    {
                        path: 'maintenance',
                        element: <MaintenancePage />,
                        protected: false,
                        name: 'maintenance'
                    },
                    {
                        path: 'certified',
                        element: <CertifiedPage />,
                        protected: false,
                        name: 'certified'
                    },
                    {
                        path: 'solutions',
                        element: <SolutionsPage />,
                        protected: false,
                        name: 'solutions'
                    },
                    {
                        path: 'contact',
                        element: <ContactPage />,
                        protected: false,
                        name: 'contact'
                    },
                    {
                        path: 'contract',
                        element: <ContractsPage />,
                        protected: false,
                        name: 'Contracts'
                    },
                    {
                        path: 'instructions',
                        element: <InstructionsProceduresPage />,
                        protected: false,
                        name: 'Instructions'
                    },
                    {
                        path: 'assistance',
                        element: <InfoAssistancePage />,
                        protected: false,
                        name: 'assistance'
                    }
                ]
            },

            {
                path: 'repair-center',
                element: <RepairCenter />,
                protected: true,
                name: 'repairCenter',
                permissions: [new ModulePermissionValidator(UserModule.RMA)],
                children: [
                    {
                        path: 'new-rma',
                        element: <NewRma />,
                        protected: true,
                        name: 'newRma',
                        children: [
                            {
                                path: 'details',
                                element: <DetailsRma />,
                                protected: true,
                                name: 'rmaDetails',
                                children: [
                                    {
                                        path: 'summary',
                                        element: <NewRmaSummary />,
                                        protected: true,
                                        name: 'summary'
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        path: 'rma-detail',
                        element: <RmaDetails />,
                        protected: true,
                        name: 'rmaDetails',
                        children: [
                            {
                                path: 'budget',
                                element: <SendOrderBudget />,
                                protected: true,
                                name: 'budget'
                            }
                        ]
                    }
                ]
            },
            {
                path: 'spare-parts',
                element: <SpareParts />,
                protected: true,
                name: 'spareParts',
                permissions: [new ModulePermissionValidator(UserModule.SPAREPARTS)],
                children: [
                    {
                        path: 'chat',
                        element: <SparePartsChat />,
                        protected: true,
                        name: 'spareParts',
                        permissions: [new ModulePermissionValidator(UserModule.SPAREPARTS)]
                    },
                    {
                        path: 'order',
                        element: <OrdersDetailSelected />,
                        protected: true,
                        name: 'order',
                        permissions: [new ModulePermissionValidator(UserModule.SPAREPARTS)]
                    },
                    {
                        path: 'cart',
                        element: <ShoppingCart />,
                        protected: true,
                        name: 'shipping',
                        permissions: [new ModulePermissionValidator(UserModule.SPAREPARTS)]
                    }
                ]
            },
            {
                path: 'user',
                element: <UserManager />,
                protected: true,
                name: 'userManager',
                permissions: [new ModulePermissionValidator(UserModule.USER)],

                children: [
                    {
                        path: 'company',
                        element: <CompanyManager />,
                        protected: true,
                        name: 'companyManager',
                        permissions: [new ModulePermissionValidator(UserModule.USER)]
                    },
                    {
                        path: 'customer',
                        element: <FinalCustomerManager />,
                        protected: true,
                        name: 'customerManager',
                        permissions: [new ModulePermissionValidator(UserModule.USER)]
                    },
                    {
                        path: 'new-company',
                        element: <NewCompany />,
                        protected: true,
                        name: 'newCompany',
                        permissions: [new ModulePermissionValidator(UserModule.USER)]
                    }
                ]
            },
            {
                path: 'maintenance-report',
                element: <MaintenanceReportPage />,
                protected: true,
                name: 'maintenanceReport',
                permissions: [new ModulePermissionValidator(UserModule.MAINTENANCE_REPORT)],
                children: [
                    {
                        path: 'report',
                        element: <MaintenanceReportDetails />,
                        protected: true,
                        name: 'report',
                        permissions: [new ModulePermissionValidator(UserModule.MAINTENANCE_REPORT)]
                    },
                    {
                        path: 'new-report',
                        element: <NewMaintenanceReport />,
                        protected: true,
                        name: 'report',
                        permissions: [new ModulePermissionValidator(UserModule.MAINTENANCE_REPORT)]
                    }
                ]
            },
            {
                path: 'warranty-consult',
                element: <WarrantyConsultPage />,
                protected: false,
                name: 'warrantyConsult'
            },
            {
                path: 'find-assistance',
                element: <FindAnAssistancePage />,
                protected: false,
                name: 'findAssistance'
            },
            {
                path: 'dashboard',
                element: <DashboardMenu />,
                protected: true,
                name: 'dashboard'
            },
            {
                path: 'manual',
                element: <TesteManual />,
                protected: false,
                name: 'Teste Manual'
            },
            {
                path: 'overview',
                element: <Overview />,
                protected: false,
                name: 'overview'
            }
        ]
    }
];

const routes1: rootType[] = [
    // {
    //     path: '/',
    //     element: <Home />,
    //     protected: false,
    //     name: 'home'
    // },
    // {
    //     path: '/info/repair-center',
    //     element: <RepairCenterPage />,
    //     protected: false,
    //     name: 'repaircenter'
    // },
    // {
    //     path: '/info/spare-parts',
    //     element: <SparePartsPage />,
    //     protected: false,
    //     name: 'spareParts'
    // },
    // {
    //     path: '/info/partners',
    //     element: <PartnersPage />,
    //     protected: false,
    //     name: 'partners'
    // },
    // {
    //     path: '/info/maintenance',
    //     element: <MaintenancePage />,
    //     protected: false,
    //     name: 'maintenance'
    // },
    // {
    //     path: '/info/certified',
    //     element: <CertifiedPage />,
    //     protected: false,
    //     name: 'certified'
    // },
    // {
    //     path: '/info/solutions',
    //     element: <SolutionsPage />,
    //     protected: false,
    //     name: 'solutions'
    // },
    // {
    //     path: '/login/sign-in',
    //     element: <SignInPage />,
    //     protected: false,
    //     name: 'Login'
    // },
    // {
    //     path: '/login/sign-up',
    //     element: <SignUpPage />,
    //     protected: false,
    //     name: 'Login'
    // },
    // {
    //     path: '/info/contact',
    //     element: <ContactPage />,
    //     protected: false,
    //     name: 'contact'
    // },
    // {
    //     path: '/find-assistance',
    //     element: <FindAnAssistancePage />,
    //     protected: false,
    //     name: 'findAssistance'
    // },
    // {
    //     path: '/info/assistance',
    //     element: <InfoAssistancePage />,
    //     protected: false,
    //     name: 'assistance'
    // },
    // {
    //     path: '/warranty-consult',
    //     element: <WarrantyConsultPage />,
    //     protected: false,
    //     name: 'warrantyConsult'
    // },
    // {
    //     path: '/signin-oidc',
    //     element: <SignInOIDC />,
    //     protected: false,
    //     name: 'Signin OIDC'
    // },
    // {
    //     path: '/signout-oidc',
    //     element: <SignOutOIDC />,
    //     protected: false,
    //     name: 'Signout OIDC'
    // },
    // {
    //     path: '/dashboard',
    //     element: <DashboardMenu />,
    //     protected: true,
    //     name: 'dashboard'
    // },

    // {
    //     path: '/module/repair-center',
    //     element: < RepairCenter /> ,
    //     protected: true,
    //     name: 'repairCenter',
    //     permission: [new ModulePermissionValidator('9')]
    // },
    // {
    //     path: '/module/repair-center/newRma',
    //     element: <NewRma />,
    //     protected: false,
    //     name: 'newRma'
    // },
    // {
    //     path: '/module/repair-center/details',
    //     element: <DetailsRma />,
    //     protected: false,
    //     name: 'rmaDetails'
    // },
    // {
    //     path: '/module/repair-center/details/summary',
    //     element: <NewRmaSummary />,
    //     protected: false,
    //     name: 'summary'
    // },
    // {
    //     path: '/module/repair-centerCA',
    //     element: <RepairCenterCA />,
    //     protected: false,
    //     name: 'Dashboard Repair Center CA'
    // },
    // {
    //     path: '/module/repair-centerCA/newRmaCA',
    //     element: <NewRmaCA />,
    //     protected: false,
    //     name: 'Dashboard New Rma CA'
    // },
    // {
    //     path: '/module/repair-centerCA/detailsRmaCA',
    //     element: <DetailsRmaCA />,
    //     protected: false,
    //     name: 'Dashboard Details Rma CA'
    // },
    // {
    //     path: '/module/repair-centerCA/detailsRmaCA/Summary',
    //     element: <NewRmaSummaryCA />,
    //     protected: false,
    //     name: 'Dashboard Summary CA'
    // },
    // {
    //     path: '/module/repair-centerCA/detailsRmaCA/ResumeRmaCA',
    //     element: <ResumeRmaCA />,
    //     protected: false,
    //     name: 'Dashboard Resume Rma CA'
    // },
    {
        path: '/printOrder',
        element: <PrintOrder />,
        protected: false,
        name: 'print'
    },

    // {
    //     path: '/module/repair-center',
    //     element: <RepairCenter />,
    //     protected: true,
    //     name: 'repairCenter',
    //     permissions: [new ModulePermissionValidator(UserModule.RMA)]
    // },
    // {
    //     path: '/repair-center/rma-detail',
    //     element: <RmaDetails />,
    //     protected: true,
    //     name: 'rmaDetails'
    // },
    // {
    //     path: '/dashboard/spare-parts',
    //     element: <SpareParts />,
    //     protected: true,
    //     name: 'spareParts'
    // },
    // {
    //     path: '/dashboard/spare-parts/Chat',
    //     element: <SparePartsChat />,
    //     protected: true,
    //     name: 'spareParts'
    // },
    // {
    //     path: '/dashboard/spare-partsSearch',
    //     element: < SearchSpareParts /> ,
    //     protected: false,
    //     name: 'spareParts'
    // },
    // {
    //     path: '/dashboard/spare-parts/order',
    //     element: <OrdersDetailSelected />,
    //     protected: false,
    //     name: 'spareParts'
    // },
    // {
    //     path: '/budget',
    //     element: <SendOrderBudget />,
    //     protected: false,
    //     name: 'budget'
    // },
    {
        path: 'PRINT',
        protected: false,
        name: 'print'
    }
    // {
    //     path: '/ShippingCart',
    //     element: <ShippingCart />,
    //     protected: false,
    //     name: 'shipping'
    // },
    // {
    //     path: '/MaintenanceReport',
    //     element: <MaintenanceReport />,
    //     protected: false,
    //     name: 'maintenanceReport'
    // },
    // {
    //     path: '/ReportAccordion',
    //     element: <ReportAccordion />,
    //     protected: false,
    //     name: 'report'
    // },
    // {
    //     path: '/NewMaintenanceReport',
    //     element: <NewMaintenanceReport />,
    //     protected: false,
    //     name: 'report'
    // },
    // {
    //     path: '/userManager',
    //     element: <UserManager />,
    //     protected: false,
    //     name: 'userManager'
    // },
    // {
    //     path: '/companyManager',
    //     element: <CompanyManager />,
    //     protected: false,
    //     name: 'companyManager'
    // },
    // {
    //     path: '/finalCustomerManager',
    //     element: <FinalCustomerManager />,
    //     protected: false,
    //     name: 'userManager'
    // },
    // {
    //     path: '/ISLWeb',
    //     element: <ISLWeb />,
    //     protected: false,
    //     name: 'ISL Web'
    // },
    // {
    //     path: '/EditEquipment',
    //     element: <EditEquipment />,
    //     protected: false,
    //     name: 'equipment'
    // },
    // {
    //     path: '/TesteManual',
    //     element: <TesteManual />,
    //     protected: false,
    //     name: 'Teste Manual'
    // },
    // {
    //     path: '/Overview',
    //     element: <Overview />,
    //     protected: false,
    //     name: 'overview'
    // }
    // {
    //     path: '/NewCompany',
    //     element: <NewCompany />,
    //     protected: false,
    //     name: 'newCompany'
    // }

    // {
    //     path: '*',
    //     // element: < ErrorFallback /> error={new NotFoundError()} ,
    //     protected: false,
    //     name: 'error'
    // }
];

export default routes;
