import { Button, Card, DatePicker, FileDrop, Icon, Input, Loader, Modal, Select, Table } from '@scuf/common';
import { StepperProp } from './AddEquipment';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { OrderCartDTO, OrderItemsDTO } from 'models/spareParts/orderById';
import { orderCartAtom, orderCartFileAtom, orderWarrantyCartAtom } from 'resources/atoms/spareParts';
import { listYesNop, shippingType } from 'models/spareParts';
import { useEffect, useState } from 'react';
import Alert from 'components/alert';
import { LabelDescription } from 'components';

const GARANTIA = 4;

function CartInfo({ canSkipPage }: StepperProp): JSX.Element {
    const { t } = useTranslation(['spareparts', 'common']);
    const [defaultCart, setdefaultCart] = useRecoilState<OrderCartDTO>(orderCartAtom);
    const [warrantyCart, setWarrantyCart] = useRecoilState<OrderItemsDTO[]>(orderWarrantyCartAtom);
    const [modalState, setModalState] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [file, setFile] = useRecoilState<File | null>(orderCartFileAtom);

    function renderModal(): JSX.Element {
        return (
            <Modal
                className="modal"
                onClose={() => setModalState(false)}
                size="small"
                open={modalState}
                closeOnDimmerClick={true}
                closeOnDocumentClick={true}
            >
                <Card.Footer>
                    <FileDrop
                        className="file-drop"
                        placeholder={t('common:actions.Draganddropfileshere')}
                        resetButtonText={t('common:actions.Delete')}
                        onReset={() => setFile(null)}
                        onAccepted={value => {
                            setFile(value[0]);
                        }}
                    />
                    <br />
                    <div className="buttonModal">
                        <Button
                            content={file ? t('common:actions.Finish') : t('common:actions.Close')}
                            onClick={() => setModalState(false)}
                            size="medium"
                            textTransform={true}
                        />
                    </div>
                </Card.Footer>
            </Modal>
        );
    }

    useEffect(() => {
        if (defaultCart.typeId === GARANTIA) {
            setWarrantyCart([]);
            separateItemsQuantity();
        }
    }, []);

    useEffect(() => {
        canSkipPage(true);
        const { typeId, allowPartial, shippingTypeId, finalClient } = defaultCart;

        if (typeId === GARANTIA && warrantyCart) {
            if (allowPartial !== undefined && shippingTypeId !== undefined && finalClient?.companyName && finalClient?.email) {
                const hasValidOrderItems = warrantyCart?.every(order => {
                    return (
                        order.descriptionDefectFound &&
                        order.descriptionDefectFound !== '' &&
                        order.serialNumberEquipment &&
                        order.serialNumberPart
                    );
                });

                if (!hasValidOrderItems) {
                    canSkipPage(false);
                }
            } else {
                canSkipPage(false);
            }
        } else if (allowPartial === undefined || shippingTypeId === undefined) {
            canSkipPage(false);
        }
    }, [defaultCart, warrantyCart]);

    const separateItemsQuantity = () => {
        const expandedOrderItems = defaultCart.orderItems.flatMap(item => {
            if (item.quantity > 1) {
                return Array.from({ length: item.quantity }, (_, i) => ({
                    ...item,
                    quantity: 1
                }));
            } else {
                return [item];
            }
        });
        setWarrantyCart(expandedOrderItems);
    };

    const handleInputChange = (index: number, field: string, value: any) => {
        const newOrderItems = [...warrantyCart];
        newOrderItems[index] = {
            ...newOrderItems[index],
            [field]: value
        };
        setWarrantyCart(newOrderItems);
    };

    const renderOrderItems = () => {
        return warrantyCart ? (
            warrantyCart?.map((order, index) => (
                <Table.Row key={`linhaTabela-${order.code}-${index}`}>
                    <Table.Cell>
                        <>
                            <strong>{order.code}</strong>
                            <br />
                            {order.description}
                            <br />
                            {t('common:translation.Model')} {order.model}
                        </>
                    </Table.Cell>
                    <Table.Cell>
                        <div className="div-informacoes" style={{ display: 'flex', flexDirection: 'column' }}>
                            <Input
                                placeholder={t('productinfots.SerialNumberEqp')}
                                indicator="required"
                                value={order.serialNumberEquipment ?? ''}
                                onChange={value => handleInputChange(index, 'serialNumberEquipment', value)}
                            />
                            <Input
                                placeholder={t('productinfots.SerialNumberPart')}
                                value={order.serialNumberPart ?? ''}
                                onChange={value => handleInputChange(index, 'serialNumberPart', value)}
                            />
                            <DatePicker
                                type="date"
                                displayFormat="DD/MM/YYYY"
                                indicator="required"
                                value={order.createdAt ?? ''}
                                onChange={value => handleInputChange(index, 'createdAt', value)}
                            />
                        </div>
                    </Table.Cell>
                    <Table.Cell>
                        <Input
                            placeholder={t('spareparts:productinfots.DefectFound')}
                            value={order.descriptionDefectFound ?? ''}
                            onChange={value => handleInputChange(index, 'descriptionDefectFound', value)}
                        />
                    </Table.Cell>
                </Table.Row>
            ))
        ) : (
            <Table.Row>
                <Table.Cell colSpan={3}>-</Table.Cell>
            </Table.Row>
        );
    };

    const renderFinalClientInputs = (finalClient: any) => {
        return (
            <div className="div-datatable">
                <div className="form3Columns">
                    <Input
                        label={t('user:company.Name')}
                        placeholder={t('user:company.Name')}
                        indicator="required"
                        value={finalClient?.companyName}
                        onChange={value =>
                            setdefaultCart(old => ({
                                ...old,
                                finalClient: {
                                    ...old.finalClient,
                                    companyName: value
                                }
                            }))
                        }
                    />
                    <Input
                        label={t('user:info.Email')}
                        placeholder={t('user:info.Email')}
                        indicator="required"
                        value={finalClient?.email}
                        onChange={value =>
                            setdefaultCart(old => ({
                                ...old,
                                finalClient: {
                                    ...old.finalClient,
                                    email: value
                                }
                            }))
                        }
                    />
                </div>
            </div>
        );
    };

    const renderFileUploadSection = () => {
        return (
            <div>
                {renderModal()}
                {loading ? (
                    <Loader text={t('common:actions.Loading')} />
                ) : (
                    <div
                        className="contentPageContainer"
                        style={{ display: 'flex', flexDirection: 'column', padding: '24px 0px' }}
                    >
                        {file ? (
                            <div className="chat-display">
                                {file && (
                                    <LabelDescription title={t('common:translation.File')}>
                                        <>
                                            {file?.name}{' '}
                                            <Icon
                                                name="delete"
                                                onClick={() => {
                                                    setFile(null);
                                                }}
                                            ></Icon>
                                        </>
                                    </LabelDescription>
                                )}
                            </div>
                        ) : (
                            <div>
                                {' '}
                                <Alert text={t('common:empty.Nofilesadded')} />
                                <br />
                            </div>
                        )}
                        <div>
                            <div className="buttonModal">
                                <Button
                                    type="inline-secondary"
                                    icon="attachment"
                                    onClick={() => setModalState(true)}
                                    content={t('common:actions.AddFile') + ' jpeg, png, pdf'}
                                    iconPosition="right"
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    function renderWarrantyInfoSection() {
        if (defaultCart.typeId === GARANTIA) {
            return (
                <>
                    {renderFinalClientInputs(defaultCart.finalClient)}
                    {renderFileUploadSection()}
                </>
            );
        }
        return null;
    }

    function renderShippingSection() {
        return (
            <div className="div-datatable">
                <div className="form3Columns">
                    <Select
                        label={t('spareparts:translation.Shipping')}
                        placeholder={t('spareparts:translation.Shipping')}
                        indicator="required"
                        options={shippingType}
                        value={defaultCart.shippingTypeId}
                        onChange={value =>
                            setdefaultCart(old => ({
                                ...old,
                                shippingTypeId: value
                            }))
                        }
                    />
                </div>
            </div>
        );
    }

    const renderOptionsSection = () => {
        return (
            <div>
                <h1>
                    <Icon name="shopping-bag" size="large" root="building" /> {t('spareparts:stepper.Options')}
                </h1>
                <div className="div-datatable">
                    <div className="form3Columns">
                        <Input
                            label={t('common:translation.Number')}
                            placeholder={t('common:translation.Number')}
                            value={defaultCart.number}
                            onChange={value => setdefaultCart(old => ({ ...old, number: value }))}
                        />
                        <Select
                            indicator="required"
                            label={t('spareparts:translation.AllowPartialBilling')}
                            placeholder={t('spareparts:translation.AllowPartialBilling')}
                            options={listYesNop}
                            value={defaultCart.allowPartial}
                            onChange={value => {
                                setdefaultCart({
                                    ...defaultCart,
                                    allowPartial: value
                                });
                            }}
                        />
                        <Input
                            label={t('spareparts:cart.Observation')}
                            placeholder={t('spareparts:cart.Observation')}
                            value={defaultCart.observation}
                            onChange={value => setdefaultCart(old => ({ ...old, observation: value }))}
                        />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div>
            {renderOptionsSection()}
            <h1>
                <Icon name="shopping-bag" size="large" root="building" /> {t('spareparts:translation.Transport')}
            </h1>
            {renderShippingSection()}
            {defaultCart.typeId === 4 && (
                <>
                    <h1>
                        <Icon name="shopping-bag" size="large" root="building" /> {t('spareparts:productinfots.WarrantyInfo')}
                    </h1>
                    <div className="div-datatable">
                        <Table>
                            <Table.Header>
                                <Table.HeaderCell content={t('common:translation.Item')} />
                                <Table.HeaderCell content={t('common:translation.Information')} />
                                <Table.HeaderCell content={t('spareparts:productinfots.DefectFound')} />
                            </Table.Header>
                            <Table.Body>{renderOrderItems()}</Table.Body>
                        </Table>
                    </div>
                    {renderWarrantyInfoSection()}
                </>
            )}
        </div>
    );
}
export default CartInfo;
