import { Button, Divider, Icon, Input, Select, Table } from '@scuf/common';
import useFetch from 'helpers/hooks/useFetch';
import useUser from 'helpers/hooks/useUser';
import { OrderItemsDTO } from 'models/spareParts/orderById';
import { CompanyLOBDTO, LobDto, SearchLob } from 'models/userManager/addCustomer';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { LOB, SPARE_PARTS, USER_MODULE, notificationAtom, removePN } from 'resources';
import { orderAtom } from 'resources/atoms/spareParts';

function ChangeProducts(): JSX.Element {
    const [orderData, setorderData] = useRecoilState(orderAtom);
    const { user } = useUser();
    const { t } = useTranslation(['spareparts', 'common']);
    const [selected, setSelected] = useState<{ pn: string; pd: string }>({ pn: '', pd: '' });
    const [loading, setLoading] = useState<boolean>(false);
    const [newQtd, setqtd] = useState<number>();
    const [Nindex, setNIndex] = useState<number>();
    const [NindexItem, setNIndexItem] = useState<number>();
    const [permissions, setPermissions] = useState<string>('');
    const [, setPartnumber] = useState<any>([]);
    const [partnumberNameList, setPartnumberNameList] = useState<any>([]);

    const setNotification = useSetRecoilState(notificationAtom);

    const [resultList, SearchPartNumbers, loadAction] = useFetch<SearchLob, OrderItemsDTO[]>({
        url: SPARE_PARTS.searchPartNumbersByLOB,
        onError: 'LoadInfo',
        method: 'POST'
    });

    const [, adiconaPn] = useFetch<OrderItemsDTO>({
        url: SPARE_PARTS.adiconaPn,
        method: 'POST',
        onError: 'LoadInfo',
        onSuccess: 'Change'
    });

    const [products, getOptions, , updateProducts] = useFetch<never>({
        url: SPARE_PARTS.getOptions,
        onError: 'LoadInfo'
    });

    const [allLobs, getAllLOBS] = useFetch<never, LobDto[]>({
        url: LOB.getLOBs,
        onError: 'LoadInfo'
    });

    const [lobs, getLobs] = useFetch<{ companyId: number }, CompanyLOBDTO[]>({
        url: USER_MODULE.getCompanyLOBs,
        onError: 'LoadInfo'
    });

    const [, getPartnumberName] = useFetch<{ lobs: string; product?: string }, any[]>({
        url: SPARE_PARTS.getAllPartNumbersByLOB,
        onError: 'LoadInfo'
    });

    async function deleteItem(id: number) {
        setLoading(true);
        let resp = await removePN(id);
        if (resp.error) {
            setNotification(old => ({ ...old, message: 'Removed' }));
        } else {
            setorderData(prevState => {
                return {
                    ...prevState,
                    orderItems: prevState.orderItems.filter(item => item.id !== id)
                };
            });
            setNotification(old => ({ ...old, message: 'Removed', type: 'success' }));
        }
        setLoading(false);
    }

    useEffect(() => {
        if (orderData?.companyId != undefined) getLobs({ companyId: orderData.companyId });
    }, [orderData]);

    useEffect(() => {
        if (lobs == null) return;

        if (lobs.data?.length == 0) return;

        let perms: string = '';
        let resp: CompanyLOBDTO[] = lobs.data as CompanyLOBDTO[];
        perms = '0';
        for (let cl of resp) perms += `,${cl.lob.lobCode}`;

        getPartnumberName({ lobs: perms }, x => {
            setPartnumberNameList(
                x.data?.map((e: any, index: number) => ({
                    value: index,
                    text: e.code
                }))
            );
        });
        setPermissions(perms);
    }, [lobs]);

    useEffect(() => {
        if (selected.pd != '' || selected.pn != '') {
            if (selected.pd != '') {
                getPartnumberName({ lobs: permissions, product: selected.pd }, x => {
                    setPartnumberNameList(
                        x.data?.map((e: any, index: number) => ({
                            value: index,
                            text: e.code
                        }))
                    );
                });
            }
            handleSearch();
        }
    }, [selected]);

    useEffect(() => {
        handleGetPartNumbers();
        getAllLOBS();
        getLobs();
    }, []);

    const handleGetPartNumbers = async (): Promise<void> => {
        setLoading(true);
        await Promise.all([
            await getOptions(undefined, current => {
                updateProducts(
                    current.data?.map((e: any, index: number) => ({
                        value: index,
                        text: e.name
                    }))
                );
            })
        ]);
        setLoading(false);
    };

    const validate = (): boolean => {
        return !!selected.pd || !!selected.pn;
    };

    const handleSearch = async () => {
        setLoading(true);
        const listLOBPermitted: string[] = permissions.split(',');
        const selLOBS: LobDto[] = allLobs?.data?.filter(x => listLOBPermitted.find(a => a == `${x.lobCode}`)) ?? [];
        const parms: SearchLob = {
            filter: selected.pn ? selected.pn : selected.pd,
            filterType: selected.pn ? '1' : '3',
            lobs: selLOBS
        };
        await SearchPartNumbers(parms, x => {
            setPartnumber(
                x.data?.map((e: any, index: number) => ({
                    value: index,
                    text: e.code
                }))
            );
        });
        setLoading(false);
        setSelected({ pn: '', pd: '' });
    };

    const addPn = async (item: OrderItemsDTO) => {
        await adiconaPn({ ...item, email: user?.EMAIL!, orderId: orderData.id }, current => {
            const hasItem = orderData?.orderItems?.some(e => e.id == current.data.id);
            let aux = [...(orderData?.orderItems ?? [])];
            if (hasItem) {
                aux = aux.map(e => (e.id == current.data.id ? current.data : e));
            } else {
                aux.push(current.data);
            }
            setorderData(old => ({ ...old, orderItems: aux }));
        });
    };

    const limparFiltro = () => {
        setSelected({ pn: '', pd: '' });
    };

    return (
        <>
            <div className="container-changes">
                <div className="form3Columns">
                    <Select
                        label={t('common:translation.Product')}
                        options={products?.data ?? []}
                        disabled={loading}
                        value={selected.pd && products?.data?.findIndex((x: any) => x.text == selected.pd)}
                        search={true}
                        placeholder={loading ? t('common:actions.Loading') : t('common:translation.Product')}
                        onChange={e => {
                            setSelected({ pn: '', pd: products?.data[e].text });
                        }}
                    />
                    <Select
                        className="select"
                        label={t('common:translation.PartNumber')}
                        loading={loading}
                        disabled={loading}
                        options={partnumberNameList ?? []}
                        value={selected.pn && partnumberNameList?.findIndex((x: any) => x.text == selected.pn)}
                        search={true}
                        placeholder={loading ? t('common:actions.Loading') : t('common:translation.PartNumber')}
                        onChange={e => setSelected({ pd: '', pn: partnumberNameList[e].text })}
                    />
                </div>
                <div className="buttonsSearch">
                    <Button
                        type="secondary"
                        icon="badge-delete"
                        loading={loadAction}
                        content={t('common:actions.ResetFilters')}
                        onClick={() => limparFiltro()}
                    />
                    <Button
                        loading={loadAction}
                        icon="search"
                        disabled={!validate()}
                        content={t('common:actions.Search')}
                        onClick={() => handleSearch()}
                    ></Button>
                </div>

                {!!resultList && (
                    <>
                        <h1>{t('common:translation.Item')}</h1>
                        <Table>
                            <Table.Header>
                                <Table.HeaderCell>{t('common:translation.Item')}</Table.HeaderCell>
                                <Table.HeaderCell>{t('cart.Discount')}</Table.HeaderCell>
                                <Table.HeaderCell>{t('spareparts:productinfots.order_data.NetPrice')}</Table.HeaderCell>
                                <Table.HeaderCell>{t('common:translation.Quantity')}</Table.HeaderCell>
                                <Table.HeaderCell>{''}</Table.HeaderCell>
                            </Table.Header>
                            <Table.Body>
                                {resultList.data
                                    ?.filter(e => !orderData?.orderItems?.some(item => item.msCode === e.msCode))
                                    ?.map((e: OrderItemsDTO, index) => (
                                        <React.Fragment key={e.id}>
                                            <Table.Row className="clickable">
                                                <Table.Cell>
                                                    <h4>{e?.code}</h4>

                                                    {e.description}
                                                </Table.Cell>
                                                <Table.Cell>{e?.discount * 100}%</Table.Cell>
                                                <Table.Cell>{'US$' + ' ' + e.netPrice}</Table.Cell>
                                                <Table.Cell>
                                                    <Input
                                                        helperText="QTD"
                                                        min={1}
                                                        type="number"
                                                        onChange={(value: any) => {
                                                            e.quantity = parseInt(value);
                                                            setNIndexItem(index);
                                                        }}
                                                    ></Input>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <div className="buttonsSearch">
                                                        <Button
                                                            disabled={index !== NindexItem || e.quantity == 0}
                                                            content={t('common:actions.Add')}
                                                            onClick={() => {
                                                                e.partNumber = e.code;
                                                                addPn(e);
                                                            }}
                                                        ></Button>
                                                    </div>
                                                </Table.Cell>
                                            </Table.Row>
                                        </React.Fragment>
                                    ))}
                            </Table.Body>
                        </Table>
                    </>
                )}
                <Divider />
                <h1>{t('common:translation.ItemAdd')}</h1>
                {orderData.orderItems.length > 0 && (
                    <Table>
                        <Table.Header>
                            <Table.HeaderCell content={t('common:actions.Delete')} />
                            <Table.HeaderCell>{t('common:translation.Item')}</Table.HeaderCell>
                            <Table.HeaderCell>{t('common:translation.PartNumber')}</Table.HeaderCell>
                            <Table.HeaderCell>{t('cart.Discount')}</Table.HeaderCell>
                            <Table.HeaderCell>{t('spareparts:productinfots.order_data.NetPrice')}</Table.HeaderCell>
                            <Table.HeaderCell>{t('common:translation.Quantity')}</Table.HeaderCell>
                            <Table.HeaderCell>{''}</Table.HeaderCell>
                            <Table.HeaderCell>{''}</Table.HeaderCell>
                        </Table.Header>
                        <Table.Body>
                            {orderData.orderItems?.map((e: OrderItemsDTO, index) => {
                                return (
                                    <React.Fragment key={e.id}>
                                        <Table.Row className="clickable">
                                            <Table.Cell>
                                                {
                                                    <Icon
                                                        root="common"
                                                        name="delete"
                                                        size="medium"
                                                        loading={true}
                                                        onClick={() => {
                                                            deleteItem(e.id);
                                                        }}
                                                    />
                                                }
                                            </Table.Cell>
                                            <Table.Cell>
                                                <h4>{e?.code}</h4>
                                                {e.description}
                                            </Table.Cell>
                                            <Table.Cell>{e?.partNumber}</Table.Cell>
                                            <Table.Cell>{e?.discount * 100}%</Table.Cell>
                                            <Table.Cell>{'US$' + ' ' + e.netPrice}</Table.Cell>
                                            <Table.Cell>{e.quantity}</Table.Cell>
                                            <Table.Cell>
                                                <Input
                                                    helperText="QTD"
                                                    type="number"
                                                    min={1}
                                                    onChange={(value: any) => {
                                                        setqtd(value);
                                                        setNIndex(index);
                                                    }}
                                                ></Input>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <div className="buttonsSearch">
                                                    <Button
                                                        disabled={index !== Nindex || e.quantity == newQtd || newQtd == 0}
                                                        content={'Alterar'}
                                                        onClick={() => {
                                                            addPn({ ...e, quantity: newQtd! ?? 0 });
                                                        }}
                                                    ></Button>
                                                </div>
                                            </Table.Cell>
                                        </Table.Row>
                                    </React.Fragment>
                                );
                            })}
                        </Table.Body>
                    </Table>
                )}
            </div>
        </>
    );
}
export default ChangeProducts;
