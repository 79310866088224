import axios from 'axios';
import { AxiosHttpGet, AxiosHttpPost } from 'axios-http';
import { CompanyFilterDTO } from 'models';
import { AddAdmin, AddCompany, CompanyDTO } from 'models/userManager/addCustomer';
import { COMPANY_MANAGER, MICROSIGA_API, USER_MANAGER } from 'resources/links';

export async function addCustomer(data: AddCompany) {
    return AxiosHttpPost({
        url: USER_MANAGER.addCustomer,
        data
    });
}

export async function consultaApiCnpj(cnpj: string) {
    try {
        const resp = await axios.get(process.env.REACT_APP_EXTERNAL_CNPJ_API + cnpj);
        return {
            status: 200,
            data: resp.data
        };
    } catch (e) {
        return { status: 500, data: 'Erro ao carregar os estados' };
    }
}

export async function addAdmin(data: AddAdmin) {
    return AxiosHttpPost({
        url: USER_MANAGER.addAdmin,
        data
    });
}

export async function getCompanyByCnpj(companyDto: string) {
    return await AxiosHttpGet({ url: COMPANY_MANAGER.getCompanyByCnpj, data: { companyDto } });
}

export async function getCompanyFromMicrosiga(cnpj: string) {
    return await AxiosHttpGet({ url: COMPANY_MANAGER.getCompanyFromMicrosiga, data: { cnpj } });
}

export async function getCustumer(CNPJ: string) {
    return await AxiosHttpGet({ url: MICROSIGA_API.microssigaCustumer, data: { CNPJ } });
}

export async function getCompanyById(id: number) {
    return await AxiosHttpGet({ url: COMPANY_MANAGER.getCompanyById, data: { id } });
}
export async function createCompany(data: CompanyDTO) {
    return await AxiosHttpPost({ url: COMPANY_MANAGER.createCompany, data: data });
}

export async function getCompanies(data: CompanyFilterDTO) {
    return await AxiosHttpPost({ url: COMPANY_MANAGER.getCompanies, data: data });
}
