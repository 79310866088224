import { rmaItemsAccessories, rmaItemsAccessoriesModel, rmaItemsEquipment, rmaItemsEquipmentModel } from 'models/repaircenter/detailsRma';


export class NewEquipmentModel {
    id?: any
    partNumber?: string
    partNumberModel?: string
    rmaItemsAccessories?: rmaItemsAccessories
    rmaItemsEquipment?: rmaItemsEquipment
    serviceType?: {}
    serviceTypeId?: any
    user?: {}
    userId?: any
}
export class NewEquipment{
    serviceTypeId?: number | null
    ssoId?: string
    model?: string | null
    rmaId?: number | null
    justify?: string
    rmaItemsEquipment?: rmaItemsEquipmentModel | null
    rmaItemsAccessories?: rmaItemsAccessoriesModel | null

    constructor(){
        this.serviceTypeId = null
        this.model = null
        this.rmaItemsEquipment = null;
        this.rmaItemsAccessories = null;
    }
}
