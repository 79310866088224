import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import {
    certified_en,
    common_en,
    dashboard_en,
    home_en,
    maintenance_en,
    maintenancereport_en,
    manual_en,
    notification_en,
    register_en,
    solutions_en,
    spareparts_en,
    user_en,
    usermanager_en,
    repaircenter_en,
    pages_en,
    contracts_en,
    instructions_en,
} from 'translation/en';
import {
    certified_pt,
    common_pt,
    dashboard_pt,
    home_pt,
    maintenance_pt,
    maintenancereport_pt,
    manual_pt,
    notification_pt,
    register_pt,
    solutions_pt,
    spareparts_pt,
    user_pt,
    usermanager_pt,
    contracts_pt,
    repaircenter_pt,
    pages_pt,
    instructions_pt,


} from 'translation/pt';

const resources = {
    en: {
        certified: certified_en,
        common: common_en,
        dashboard: dashboard_en,
        home: home_en,
        maintenance: maintenance_en,
        maintenancereport: maintenancereport_en,
        manual: manual_en,
        notification: notification_en,
        register: register_en,
        solutions: solutions_en,
        spareparts: spareparts_en,
        user: user_en,
        usermanager: usermanager_en,
        repaircenter: repaircenter_en,
        pages: pages_en,
        contracts: contracts_en,
        instructions: instructions_en,
    },
    pt: {
        certified: certified_pt,
        common: common_pt,
        dashboard: dashboard_pt,
        home: home_pt,
        maintenance: maintenance_pt,
        maintenancereport: maintenancereport_pt,
        manual: manual_pt,
        notification: notification_pt,
        register: register_pt,
        solutions: solutions_pt,
        spareparts: spareparts_pt,
        user: user_pt,
        usermanager: usermanager_pt,
        repaircenter: repaircenter_pt,
        pages: pages_pt,
        contracts: contracts_pt,
        instructions: instructions_pt
    }
};

i18n.use(initReactI18next).init({
    resources,
    lng: 'pt',
    ns: [
        'common',
        'maintenancets',
        'homets',
        'repaircenterts',
        'certifiedts',
        'solutionsts',
        'logints',
        'registerts',
        'admtabs',
        'notification',
        'spareParts',
        'detailspareparts',
        'budget',
        'productinfo',
        'partners',
        'maintenanceReport',
        'maintenancereportdash',
        'usermanager',
        'manual',
        'userManager',
        'contracts'
    ]
});

export default i18n;
