import { Button, Card, Divider, FileDrop, Icon, Input, Modal, Table } from '@scuf/common';
import Alert from 'components/alert';
import { formatDate } from 'helpers';
import useFetch from 'helpers/hooks/useFetch';
import useUser from 'helpers/hooks/useUser';
import requestFile from 'helpers/requestFile';
import { OrderBudgetDTO, OrderDTO } from 'models/spareParts/orderById';
import { UserRole } from 'models/user/sso';
import { useEffect, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { useSetRecoilState } from 'recoil';
import { SPARE_PARTS, notificationAtom, user } from 'resources';
import { uploadBudget } from 'resources/api/spareParts_api/File';
import { orderAtom } from 'resources/atoms/spareParts';
interface BudgetProps {
    files: any;
  }
const Budget: React.FC = ( ) => {
    const [orderData, setorderData] = useRecoilState<OrderDTO>(orderAtom);
    const [budget, setBudget] = useState<OrderBudgetDTO | null>(null);
    const { t } = useTranslation(['spareparts', 'common']);
    const [loading, setLoading] = useState<boolean>(false);
    const [modalState, setModalState] = useState<boolean>(false);
    const [file, setFile] = useState<File | null>(null);
    const setNotification = useSetRecoilState(notificationAtom);
    const { user } = useUser();



    const upload = async () => {
        setLoading(true);
        const resp = await uploadBudget({ file: file!, orderBudgetDTO: budget! as OrderBudgetDTO });
        if (resp.error) {
            setNotification(old => ({ ...old, message: 'Upload' }));
        } else {
            closeModal();
            setorderData(old => ({ ...old, budget: resp.data, orderFiles: [...(old.orderFiles ?? []), resp.data.file] }));
            setNotification(old => ({ ...old, message: 'Upload', type: 'success' }));
        }
        setLoading(false);
    };
    const [, deleteBudget] = useFetch<{ id: number }>({
        url: SPARE_PARTS.deleteBudget,
        method: 'GET',
        onError: 'Removed',
        onSuccess: 'Removed'
    });
    const deleteBudgetFile = async (id: number) => {
        await deleteBudget({ id }, _ => {
            setorderData(old => ({ ...old, budget: null }));
        });
    };
    const closeModal = () => {
        setModalState(false);
        setBudget(null);
        setFile(null);
    };
    useEffect(() => {
        setBudget(old => ({ ...old!, orderId: orderData?.id }));
    }, []);
    function renderModal(): JSX.Element {
        return (
            <Modal
                className="modalW600"
                onClose={() => closeModal()}
                size="small"
                open={modalState}
                closeOnDimmerClick={true}
                closeOnDocumentClick={true}
            >
                <div className="modalConsultWaranty">
                    <h3 className="modalHeader">{t('common:actions.Add').toUpperCase()}</h3>
                    <div
                        style={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr',
                            placeItems: 'center'
                        }}
                    >
                        {/* <Input
                            label={t('spareparts:translation.SubTotal')}
                            type="number"
                            indicator="required"
                            onChange={(value: any) => {
                                setBudget(old => ({ ...old!, subTotal: value }));
                            }}
                        />
                        <Input
                            className="container-input-Large"
                            type="number"
                            indicator="required"
                            label={t('common:translation.Total')}
                            onChange={(value: any) => {
                                setBudget(old => ({ ...old!, total: value }));
                            }}
                        />
                        <Input
                            label={t('spareparts:translation.ShippingPrice')}
                            type="number"
                            indicator="required"
                            onChange={(value: any) => {
                                setBudget(old => ({ ...old!, shippingValue: value }));
                            }}
                        />
                        <Input
                            className="container-input-Large"
                            label={t('common:translation.Description')}
                            type="text"
                            onChange={(value: any) => {
                                setBudget(old => ({ ...old!, description: value }));
                            }}
                        /> */}
                    </div>
                    <div className="container-selects">
                        <FileDrop
                            placeholder={t('common:actions.Draganddropfileshere')}
                            resetButtonText={t('common:actions.Delete')}
                            onAccepted={file => {
                                setFile(file[0]);
                            }}
                        />
                    </div>
                    <Card.Footer>
                        <div className="buttonModal">
                            <Button
                                type="secondary"
                                content={t('common:actions.Close')}
                                onClick={() => closeModal()}
                                size="medium"
                                textTransform={true}
                            />
                            <Button
                                type="primary"
                                icon="slidercontrols-plus"
                                disabled={!file}
                                onClick={() => {
                                    upload();
                                }}
                                content={t('common:actions.Create')}
                                iconPosition="right"
                                loading={loading}
                            />
                        </div>
                    </Card.Footer>
                </div>
            </Modal>
        );
    }

    return (
        <div>
            {renderModal()}
            {orderData.typeId == 2 && (
                <>
                    <div>
                        <h3>{t('translation.Budget')}</h3>

                        {orderData && orderData?.budget != null ? (
                            <div>
                                <div className="chat-display">
                                    <div>
                                        <Table>
                                            <Table.Header>
                                                <Table.HeaderCell content={t('common:translation.File')} />
                                                {/* <Table.HeaderCell content={t('common:translation.Author')} /> */}
                                                <Table.HeaderCell content={t('common:translation.About')} />
                                                <Table.HeaderCell content={t('common:translation.Date')} />
                                                <Table.HeaderCell content={t('common:translation.Download')} />
                                                <Table.HeaderCell content={t('common:actions.Delete')} />
                                            </Table.Header>
                                            <Table.Body>
                                                <Table.Row>
                                                    {/* <Table.Cell>{orderData?.budget?.addedById}</Table.Cell> */}
                                                    <Table.Cell>{orderData?.budget?.file?.fileName}</Table.Cell>
                                                    <Table.Cell>{orderData?.budget?.file?.type} </Table.Cell>
                                                    <Table.Cell>{formatDate(orderData?.budget?.file?.createdAt)} </Table.Cell>

                                                    <Table.Cell>
                                                        {[UserRole.CLIENT, UserRole.CLIENTADMIN].includes(user?.ROLE!) != null ? <Icon
                                                            className="clickable"
                                                            root="common"
                                                            name="file-download"
                                                            size="medium"

                                                            onClick={async () => {
                                                                requestFile.download(
                                                                    SPARE_PARTS.downloadOrderFile,
                                                                    orderData?.budget!.file?.fileName,
                                                                    {
                                                                        method: 'GET',
                                                                        params: { fileId: orderData?.budget?.file?.id }
                                                                    }
                                                                );
                                                                // await downloadOrderFile(
                                                                //     orderData?.budget!.file?.id,
                                                                //     orderData?.budget!.file?.fileName
                                                                // );
                                                            }}
                                                        /> : ""}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <Icon
                                                            className="clickable"
                                                            root="common"
                                                            name="delete"
                                                            size="medium"
                                                            onClick={() => {
                                                                deleteBudgetFile(orderData?.budget!.id);
                                                            }}
                                                            loading={true}
                                                        />

                                                    </Table.Cell>
                                                </Table.Row>
                                            </Table.Body>
                                        </Table>
                                        <br />
                                    </div>
                                </div>
                            </div>
                        ) : (

                            <div>
                                <Alert text={t('common:empty.NofilesaddedProcess')} />
                                <div>
                                    {orderData && orderData?.statusId !== 17 && orderData?.statusId !== 3 || [UserRole.CLIENT, UserRole.CLIENTADMIN].includes(user?.ROLE!) ? (
                                        <Card.Content className="buttonsSearch">
                                            <Button
                                                loading={loading}
                                                type="primary"
                                                icon="slidercontrols-plus"
                                                onClick={() => setModalState(true)}
                                                content={t('common:actions.Add')}
                                                iconPosition="right"

                                            />
                                        </Card.Content>
                                    ) : <></>}
                                </div>
                            </div>
                        )}
                    </div>
                    <Divider />
                </>
            )}
        </div>
    );
};

export default Budget;
