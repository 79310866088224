import { Accordion, Divider, Icon } from '@scuf/common';
import { useTranslation } from 'react-i18next';
import 'styles/main.scss';

function ManualRma(): JSX.Element {
    const { t } = useTranslation();
    return (
        <div>
            <Accordion>
                <Accordion.Content title={t('manualRma:Register')}>
                    <div className="alin">
                        <h1>1. {t('manualRma:WhatIsAnRMA')}</h1>
                        <p>{t('manualRma:WhatIsAnRMAText1')}</p>
                        <p>{t('manualRma:WhatIsAnRMAText2')}</p>
                        <p>{t('manualRma:WhatIsAnRMAText3')}</p>
                        <Divider></Divider>
                        <h1>1.1. {t('manualRma:ProceduresForCreatingAnRMA')}</h1>
                        <p>{t('manualRma:ProceduresForCreatingAnRMAtext1')}</p>
                        <p>{t('manualRma:ProceduresForCreatingAnRMAtext2')}</p>
                        <p>{t('manualRma:ProceduresForCreatingAnRMAtext3')}</p>

                        <h2>1.1.1. {t('manualRma:StepOneServiceBag')} </h2>
                        <p>{t('manualRma:StepOneServiceBagText1')}</p>

                        <h3>1.1.1.1. {t('manualRma:AddEquipmentByForm')}</h3>
                        <p>{t('manualRma:AddEquipmentByFormText1')}</p>

                        <div role="list" className="v-list v-sheet theme--ligth ">
                            <div tabIndex={-1} role="listitem" className="v-list-item theme--ligth">
                                <div className="v-list-item__content">
                                    <h4>{t('manualRma:AddEquipmentByFormText2')}</h4>
                                    <p>{t('manualRma:AddEquipmentByFormText3')}</p>
                                    <h4>{t('manualRma:AddEquipmentByFormText4')}</h4>
                                    <p>{t('manualRma:AddEquipmentByFormText5')}</p>
                                    <h4>{t('manualRma:AddEquipmentByFormText6')}</h4>
                                    <p>{t('manualRma:AddEquipmentByFormText7')}</p>
                                    <h4>{t('manualRma:AddEquipmentByFormText8')}</h4>
                                    <p>{t('manualRma:AddEquipmentByFormText9')}</p>
                                    <h4>{t('manualRma:AddEquipmentByFormText10')}</h4>
                                    <p>{t('manualRma:AddEquipmentByFormText11')}</p>
                                    <h4>{t('manualRma:AddEquipmentByFormText12')}</h4>
                                    <p>{t('manualRma:AddEquipmentByFormText13')}</p>
                                    <div className="div-alert-os">
                                        <Icon root="common" name="badge-important" color="#f37021" size="xlarge" />
                                        <h5>{t('manualRma:AddEquipmentByFormText14')}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h3>1.1.1.2. {t('manualRma:AddEquipmentPerSpreadsheet')}</h3>
                        <p>{t('manualRma:AddEquipmentPerSpreadsheetText1')}</p>
                        <p>{t('manualRma:AddEquipmentPerSpreadsheetText2')}</p>
                        <h3>1.1.1.3. {t('manualRma:EquipmentValidation')}</h3>
                        <p>{t('manualRma:EquipmentValidationText1')}</p>

                        <h3>1.1.1.4. {t('manualRma:CreateRMAOfATypeOfService')}</h3>
                        <p>{t('manualRma:CreateRMAOfATypeOfServiceText1')}</p>
                        <h2>1.1.2. {t('manualRma:SecondStepDetails')} </h2>
                        <p>{t('manualRma:SecondStepDetailsText1')}</p>
                        <h3>1.1.2.1. {t('manualRma:FiscalPurchaseNote')}</h3>
                        <p>{t('manualRma:FiscalPurchaseNoteText1')}</p>
                        <p>{t('manualRma:FiscalPurchaseNoteText2')}</p>

                        <h3>1.1.2.2. {t('manualRma:Details')}</h3>
                        <div role="list" className="v-list v-sheet theme--ligth ">
                            <div tabIndex={-1} role="listitem" className="v-list-item theme--ligth">
                                <div className="v-list-item__content"></div>
                                <h4>{t('manualRma:DetailsText1')}</h4>
                                <p>{t('manualRma:DetailsText2')}</p>
                                <h4>{t('manualRma:DetailsText3')}</h4>
                                <p>{t('manualRma:DetailsText4')}</p>
                                <h4>{t('manualRma:DetailsText5')}</h4>
                                <p>{t('manualRma:DetailsText6')}</p>
                            </div>
                        </div>
                        <h3>1.1.2.3. {t('manualRma:Address')}</h3>
                        <p>{t('manualRma:AddressText1')}</p>
                        <div className="div-alert-os">
                            <Icon root="common" name="badge-important" color="#f37021" size="xlarge" />
                            <h5>{t('manualRma:AddressText2')}</h5>
                        </div>
                        <h3>1.1.2.4. {t('manualRma:Shipping')}</h3>
                        <p>{t('manualRma:ShippingText1')}</p>
                        <h4>{t('manualRma:ShippingText2')}</h4>
                        <p>{t('manualRma:ShippingText3')}</p>
                        <h4>{t('manualRma:ShippingText4')}</h4>
                        <p>{t('manualRma:ShippingText5')}</p>
                        <h4>{t('manualRma:ShippingText6')}</h4>
                        <p>{t('manualRma:ShippingText7')}</p>
                        <h4>{t('manualRma:ShippingText8')}</h4>
                        <p>{t('manualRma:ShippingText9')}</p>
                        <h4>{t('manualRma:ShippingText10')}</h4>
                        <p>{t('manualRma:ShippingText11')}</p>
                        <h4>{t('manualRma:ShippingText12')}</h4>
                        <p>{t('manualRma:ShippingText13')}</p>
                        <h4>{t('manualRma:ShippingText14')}</h4>
                        <p>{t('manualRma:ShippingText15')}</p>
                        <h4>{t('manualRma:ShippingText16')}</h4>
                        <p>{t('manualRma:ShippingText17')}</p>
                        <h4>{t('manualRma:ShippingText18')}</h4>
                        <p>{t('manualRma:ShippingText19')}</p>
                        <h3>1.1.2.5. {t('manualRma:DiscountCoupon')}</h3>
                        <p>{t('manualRma:DiscountCouponText1')}</p>
                        <h3>1.1.2.6. {t('manualRma:Comments')}</h3>
                        <h4>{t('manualRma:CommentsText1')}</h4>
                        <p>{t('manualRma:CommentsText2')}</p>
                        <h4>{t('manualRma:CommentsText3')}</h4>
                        <p>{t('manualRma:CommentsText4')}</p>
                        <h2>1.1.3. {t('manualRma:ThirdStepSummary')} </h2>
                        <p>{t('manualRma:ThirdStepSummaryText1')}</p>
                        <p>{t('manualRma:ThirdStepSummaryText2')}</p>
                    </div>
                </Accordion.Content>
                <Accordion.Content title={t('manualRma:Access')}>
                    <div className="alin">
                        <h1>1. {t('manualRma:HowToAccessAnRMA')}</h1>
                        <p>{t('manualRma:HowToAccessAnRMAText1')}</p>
                        <p>{t('manualRma:HowToAccessAnRMAText2')}</p>
                        <p>{t('manualRma:HowToAccessAnRMAText3')}</p>
                        <h4>{t('manualRma:HowToAccessAnRMAText4')}</h4>
                        <p>{t('manualRma:HowToAccessAnRMAText5')}</p>
                        <h4>{t('manualRma:HowToAccessAnRMAText6')}</h4>
                        <p>{t('manualRma:HowToAccessAnRMAText7')}</p>
                        <h4>{t('manualRma:HowToAccessAnRMAText8')}</h4>
                        <p>{t('manualRma:HowToAccessAnRMAText9')}</p>
                        <h4>{t('manualRma:HowToAccessAnRMAText10')}</h4>
                        <p>{t('manualRma:HowToAccessAnRMAText11')}</p>
                        <h4>{t('manualRma:HowToAccessAnRMAText12')}</h4>
                        <p>{t('manualRma:HowToAccessAnRMAText13')}</p>
                        <h4>{t('manualRma:HowToAccessAnRMAText14')}</h4>
                        <p>{t('manualRma:HowToAccessAnRMAText15')}</p>
                        <h4>{t('manualRma:HowToAccessAnRMAText16')}</h4>
                        <p>{t('manualRma:HowToAccessAnRMAText17')}</p>
                        <h1>1.1 {t('manualRma:TheRMA')}</h1>
                        <p>{t('manualRma:TheRMAText1')}</p>
                        <p>{t('manualRma:TheRMAText2')}</p>
                    </div>
                </Accordion.Content>
                <Accordion.Content title={t('manualRma:LifeCycle')}>
                    <div className="alin">
                        <h1>1. {t('manualRma:RMALifecycle')}</h1>
                        <p>{t('manualRma:RMALifecycleText1')}</p>
                        <h4>{t('manualRma:RMALifecycleText2')}</h4>
                        <p>{t('manualRma:RMALifecycleText3')}</p>
                        <h4>{t('manualRma:RMALifecycleText4')}</h4>
                        <p>{t('manualRma:RMALifecycleText5')}</p>
                        <h4>{t('manualRma:RMALifecycleText6')}</h4>
                        <p>{t('manualRma:RMALifecycleText7')}</p>
                        <h4>{t('manualRma:RMALifecycleText8')}</h4>
                        <p>{t('manualRma:RMALifecycleText9')}</p>
                        <h4>{t('manualRma:RMALifecycleText10')}</h4>
                        <p>{t('manualRma:RMALifecycleText11')}</p>
                        <h4>{t('manualRma:RMALifecycleText12')}</h4>
                        <p>{t('manualRma:RMALifecycleText13')}</p>
                        <h4>{t('manualRma:RMALifecycleText14')}</h4>
                        <p>{t('manualRma:RMALifecycleText15')}</p>
                    </div>
                </Accordion.Content>
                <Accordion.Content title={t('manualRma:Events')}>
                    <div className="alin">
                        <h1>1. {t('manualRma:Events2')}</h1>
                        <p>{t('manualRma:Events2Text1')}</p>
                        <p>{t('manualRma:Events2Text2')}</p>
                        <h4>{t('manualRma:Events2Text3')}</h4>
                        <p>{t('manualRma:Events2Text4')}</p>
                        <h4>{t('manualRma:Events2Text5')}</h4>
                        <p>{t('manualRma:Events2Text6')}</p>
                        <h4>{t('manualRma:Events2Text7')}</h4>
                        <p>{t('manualRma:Events2Text8')}</p>
                        <h4>{t('manualRma:Events2Text9')}</h4>
                        <p>{t('manualRma:Events2Text10')}</p>
                        <h4>{t('manualRma:Events2Text11')}</h4>
                        <p>{t('manualRma:Events2Text12')}</p>
                        <h4>{t('manualRma:Events2Text13')}</h4>
                        <p>{t('manualRma:Events2Text14')}</p>
                        <h4>{t('manualRma:Events2Text15')}</h4>
                        <p>{t('manualRma:Events2Text16')}</p>
                        <h4>{t('manualRma:Events2Text17')}</h4>
                        <p>{t('manualRma:Events2Text18')}</p>
                        <h4>{t('manualRma:Events2Text19')}</h4>
                        <p>{t('manualRma:Events2Text20')}</p>
                        <h4>{t('manualRma:Events2Text21')}</h4>
                        <p>{t('manualRma:Events2Text22')}</p>
                        <h4>{t('manualRma:Events2Text23')}</h4>
                        <p>{t('manualRma:Events2Text24')}</p>
                        <h4>{t('manualRma:Events2Text25')}</h4>
                        <p>{t('manualRma:Events2Text26')}</p>
                        <h4>{t('manualRma:Events2Text27')}</h4>
                        <p>{t('manualRma:Events2Text28')}</p>
                        <h4>{t('manualRma:Events2Text29')}</h4>
                        <p>{t('manualRma:Events2Text30')}</p>
                        <h4>{t('manualRma:Events2Text31')}</h4>
                        <p>{t('manualRma:Events2Text32')}</p>
                        <h4>{t('manualRma:Events2Text33')}</h4>
                        <p>{t('manualRma:Events2Text34')}</p>
                    </div>
                </Accordion.Content>
                <Accordion.Content title={t('manualRma:Chat')}>
                    <div className="alin">
                        <h1>1. {t('manualRma:Chat2')}</h1>
                        <p>{t('manualRma:Chat2Text1')}</p>
                        <h1>1.1 {t('manualRma:HowToAccess')}</h1>
                        <p>{t('manualRma:HowToAccessText1')}</p>
                        <h1>1.2 {t('manualRma:NewMessageIndicators')}</h1>
                        <p>{t('manualRma:NewMessageIndicatorsText1')}</p>
                        <p>{t('manualRma:NewMessageIndicatorsText2')}</p>
                        <p>{t('manualRma:NewMessageIndicatorsText3')}</p>
                        <p>{t('manualRma:NewMessageIndicatorsText4')}</p>
                    </div>
                </Accordion.Content>
                <Accordion.Content title={t('manualRma:Requests')}>
                    <div className="alin">
                        <h1>1. {t('manualRma:WhatIsARequisition')}</h1>
                        <p>{t('manualRma:WhatIsARequisitionText1')}</p>
                        <h4>{t('manualRma:WhatIsARequisitionText2')}</h4>
                        <p>{t('manualRma:WhatIsARequisitionText3')}</p>
                        <h4>{t('manualRma:WhatIsARequisitionText4')}</h4>
                        <p>{t('manualRma:WhatIsARequisitionText5')}</p>
                        <h4>{t('manualRma:WhatIsARequisitionText6')}</h4>
                        <p>{t('manualRma:WhatIsARequisitionText7')}</p>
                        <h4>{t('manualRma:WhatIsARequisitionText8')}</h4>
                        <p>{t('manualRma:WhatIsARequisitionText9')}</p>
                        <h4>{t('manualRma:WhatIsARequisitionText10')}</h4>
                        <p>{t('manualRma:WhatIsARequisitionText11')}</p>
                        <h4>{t('manualRma:WhatIsARequisitionText12')}</h4>
                        <p>{t('manualRma:WhatIsARequisitionText13')}</p>
                        <h4>{t('manualRma:WhatIsARequisitionText14')}</h4>
                        <p>{t('manualRma:WhatIsARequisitionText15')}</p>
                        <h1>1.1 {t('manualRma:WhereAreTheRequests')}</h1>
                        <p>{t('manualRma:WhereAreTheRequestsText1')}</p>
                        <h1>1.2 {t('manualRma:PendingOrderIndication')}</h1>
                        <p>{t('manualRma:PendingOrderIndicationText1')}</p>
                        <h1>1.3 {t('manualRma:CreateNewRequisition')}</h1>
                        <p>{t('manualRma:CreateNewRequisitionText1')}</p>
                        <p>{t('manualRma:CreateNewRequisitionText2')}</p>
                        <h4>{t('manualRma:CreateNewRequisitionText3')}</h4>
                        <p>{t('manualRma:CreateNewRequisitionText4')}</p>
                        <h4>{t('manualRma:CreateNewRequisitionText5')}</h4>
                        <p>{t('manualRma:CreateNewRequisitionText6')}</p>
                        <div className="div-alert-os">
                            <Icon root="common" name="badge-important" color="#f37021" size="xlarge" />
                            <h5>{t('manualRma:CreateNewRequisitionText7')}</h5>
                        </div>
                        <h1>1.4 {t('manualRma:HowtToMonitorRequests')}</h1>
                        <p>{t('manualRma:HowtToMonitorRequestsText1')}</p>
                        <p>{t('manualRma:HowtToMonitorRequestsText2')}</p>
                        <p>{t('manualRma:HowtToMonitorRequestsText3')}</p>
                        <p>{t('manualRma:HowtToMonitorRequestsText4')}</p>
                        <h4>{t('manualRma:HowtToMonitorRequestsText5')}</h4>
                        <p>{t('manualRma:HowtToMonitorRequestsText6')}</p>
                        <h4>{t('manualRma:HowtToMonitorRequestsText7')}</h4>
                        <p>{t('manualRma:HowtToMonitorRequestsText8')}</p>
                        <h4>{t('manualRma:HowtToMonitorRequestsText9')}</h4>
                        <p>{t('manualRma:HowtToMonitorRequestsText10')}</p>
                        <h4>{t('manualRma:HowtToMonitorRequestsText11')}</h4>
                        <p>{t('manualRma:HowtToMonitorRequestsText12')}</p>
                        <h4>{t('manualRma:HowtToMonitorRequestsText13')}</h4>
                        <p>{t('manualRma:HowtToMonitorRequestsText14')}</p>
                        <div className="div-alert-os">
                            <Icon root="common" name="badge-important" color="#f37021" size="xlarge" />
                            <h5>{t('manualRma:HowtToMonitorRequestsText15')}</h5>
                        </div>
                    </div>
                </Accordion.Content>
                <Accordion.Content title={t('manualRma:Files')}>
                    <div className="alin">
                        <h1>1. {t('manualRma:Files2')}</h1>
                        <p>{t('manualRma:Files2Text1')}</p>
                        <p>{t('manualRma:Files2Text2')}</p>
                        <h4>{t('manualRma:Files2Text3')}</h4>
                        <p>{t('manualRma:Files2Text4')}</p>
                        <h4>{t('manualRma:Files2Text5')}</h4>
                        <p>{t('manualRma:Files2Text6')}</p>
                        <h4>{t('manualRma:Files2Text7')}</h4>
                        <p>{t('manualRma:Files2Text8')}</p>
                        <h4>{t('manualRma:Files2Text9')}</h4>
                        <p>{t('manualRma:Files2Text10')}</p>
                        <h1>1.1 {t('manualRma:HowToAddFile')}</h1>
                        <p>{t('manualRma:HowToAddFileText1')}</p>
                    </div>
                </Accordion.Content>
                <Accordion.Content title={t('manualRma:OrderOfService')}>
                    <div className="alin">
                        <h1>1. {t('manualRma:ServiceOrders')}</h1>
                        <p>{t('manualRma:ServiceOrdersText1')}</p>
                        <p>{t('manualRma:ServiceOrdersText2')}</p>
                        <h4>{t('manualRma:ServiceOrdersText3')}</h4>
                        <p>{t('manualRma:ServiceOrdersText4')}</p>
                        <h4>{t('manualRma:ServiceOrdersText5')}</h4>
                        <p>{t('manualRma:ServiceOrdersText6')}</p>
                        <h4>{t('manualRma:ServiceOrdersText7')}</h4>
                        <p>{t('manualRma:ServiceOrdersText8')}</p>
                        <h4>{t('manualRma:ServiceOrdersText9')}</h4>
                        <p>{t('manualRma:ServiceOrdersText10')}</p>
                        <h4>{t('manualRma:ServiceOrdersText11')}</h4>
                        <p>{t('manualRma:ServiceOrdersText12')}</p>
                        <h4>{t('manualRma:ServiceOrdersText13')}</h4>
                        <p>{t('manualRma:ServiceOrdersText14')}</p>
                        <h4>{t('manualRma:ServiceOrdersText15')}</h4>
                        <p>{t('manualRma:ServiceOrdersText16')}</p>
                        <h4>{t('manualRma:ServiceOrdersText17')}</h4>
                        <p>{t('manualRma:ServiceOrdersText18')}</p>
                        <h1>1.1 {t('manualRma:SeeApproveFailSO')}</h1>
                        <p>{t('manualRma:SeeApproveFailSOText1')}</p>
                        <p>{t('manualRma:SeeApproveFailSOText2')}</p>
                        <p>{t('manualRma:SeeApproveFailSOText3')}</p>
                        <p>{t('manualRma:SeeApproveFailSOText4')}</p>
                        <h4>{t('manualRma:SeeApproveFailSOText5')}</h4>
                        <p>{t('manualRma:SeeApproveFailSOText6')}</p>
                        <h4>{t('manualRma:SeeApproveFailSOText7')}</h4>
                        <p>{t('manualRma:SeeApproveFailSOText8')}</p>
                        <h4>{t('manualRma:SeeApproveFailSOText9')}</h4>
                        <p>{t('manualRma:SeeApproveFailSOText10')}</p>
                        <h4>{t('manualRma:SeeApproveFailSOText11')}</h4>
                        <p>{t('manualRma:SeeApproveFailSOText12')}</p>
                        <h4>{t('manualRma:SeeApproveFailSOText13')}</h4>
                        <p>{t('manualRma:SeeApproveFailSOText14')}</p>
                        <h4>{t('manualRma:SeeApproveFailSOText15')}</h4>
                        <p>{t('manualRma:SeeApproveFailSOText16')}</p>
                        <h4>{t('manualRma:SeeApproveFailSOText17')}</h4>
                        <p>{t('manualRma:SeeApproveFailSOText18')}</p>
                        <h4>{t('manualRma:SeeApproveFailSOText19')}</h4>
                        <p>{t('manualRma:SeeApproveFailSOText20')}</p>
                        <h4>{t('manualRma:SeeApproveFailSOText21')}</h4>
                        <p>{t('manualRma:SeeApproveFailSOText22')}</p>
                        <h1>1.2. {t('manualRma:HowToManageServiceOrders')}</h1>
                        <p>{t('manualRma:HowToManageServiceOrdersText1')}</p>
                        <p>{t('manualRma:HowToManageServiceOrdersText2')}</p>
                    </div>
                </Accordion.Content>
                <Accordion.Content title={t('manualRma:Administrative')}>
                    <div className="alin">
                        <h1>1. {t('manualRma:AdministrativeActions2')}</h1>
                        <p>{t('manualRma:AdministrativeActions2Text1')}</p>
                        <p>{t('manualRma:AdministrativeActions2Text2')}</p>
                        <h1>1.1. {t('manualRma:Processing2')}</h1>
                        <p>{t('manualRma:Processing2Text1')}</p>
                        <div className="div-alert-os">
                            <Icon root="common" name="badge-important" color="#f37021" size="xlarge" />
                            <h5>{t('manualRma:Processing2Text2')}</h5>
                        </div>
                        <p>{t('manualRma:Processing2Text3')}</p>
                        <p>{t('manualRma:Processing2Text4')}</p>
                        <div className="div-alert-os">
                            <Icon root="common" name="badge-important" color="#f37021" size="xlarge" />
                            <h5>{t('manualRma:Processing2Text5')}</h5>
                        </div>
                        <div className="div-alert-os">
                            <Icon root="common" name="badge-important" color="#f37021" size="xlarge" />
                            <h5>{t('manualRma:Processing2Text6')}</h5>
                        </div>
                        <h1>1.2. {t('manualRma:Status')}</h1>
                        <p>{t('manualRma:StatusText1')}</p>
                        <p>{t('manualRma:StatusText2')}</p>
                        <div className="div-alert-os">
                            <Icon root="common" name="badge-important" color="#f37021" size="xlarge" />
                            <h5>{t('manualRma:StatusText3')}</h5>
                        </div>
                        <h1>1.3. {t('manualRma:Equipments')}</h1>
                        <p>{t('manualRma:EquipmentsText1')}</p>
                        <p>{t('manualRma:EquipmentsText2')}</p>
                        <p>{t('manualRma:EquipmentsText3')}</p>
                        <p>{t('manualRma:EquipmentsText4')}</p>
                        <div className="div-alert-os">
                            <Icon root="common" name="badge-important" color="#f37021" size="xlarge" />
                            <h5>{t('manualRma:EquipmentsText5')}</h5>
                        </div>
                        <h1>1.4. {t('manualRma:Address2')}</h1>
                        <p>{t('manualRma:Address2Text1')}</p>
                        <p>{t('manualRma:Address2Text2')}</p>
                        <div className="div-alert-os">
                            <Icon root="common" name="badge-important" color="#f37021" size="xlarge" />
                            <h5>{t('manualRma:Address2Text3')}</h5>
                        </div>
                        <div className="div-alert-os">
                            <Icon root="common" name="badge-important" color="#f37021" size="xlarge" />
                            <h5>{t('manualRma:Address2Text4')}</h5>
                        </div>
                        <h1>1.5. {t('manualRma:Shipping2')}</h1>
                        <p>{t('manualRma:Shipping2Text1')}</p>
                        <p>{t('manualRma:Shipping2Text2')}</p>
                        <div className="div-alert-os">
                            <Icon root="common" name="badge-important" color="#f37021" size="xlarge" />
                            <h5>{t('manualRma:Shipping2Text3')}</h5>
                        </div>
                    </div>
                </Accordion.Content>
                <Accordion.Content title={t('manualRma:Notes')}>
                    <div className="alin">
                        <h1>1. {t('manualRma:AdministrativeNotes')}</h1>
                        <p>{t('manualRma:AdministrativeNotesText1')}</p>
                        <p>{t('manualRma:AdministrativeNotesText2')}</p>
                    </div>
                </Accordion.Content>
            </Accordion>
        </div>
    );
}
export default ManualRma;
