import { List, Loader, Table } from '@scuf/common';
import { CustomFooter, CustomHeader } from 'components';
import useFetch from 'helpers/hooks/useFetch';
import usePreventClickjacking from 'helpers/hooks/usePreventingClickjacking';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.min.css';
import { useSetRecoilState } from 'recoil';
import { INITIAL_PAGE, notificationAtom } from 'resources';
import { getModelsByUser } from 'resources/api/';
import 'styles/main.scss';

function InfoAssistancePage() {
    const [, setWidth] = useState<number>(0);
    const { t } = useTranslation(['user', 'common', 'dashboard']);
    var [name, setName] = useState<string>('');
    var [email, setEmail] = useState<string>('');
    var [telefone, setTelefone] = useState<string>('');
    var [contato, setContato] = useState<string>('');
    var [cidade, setCidade] = useState<string>('');
    const [loadingState] = useState<boolean>(false);
    const [models, getModelsByUser] = useFetch<any>({ url: INITIAL_PAGE.getModelsByUser, method: 'POST', onError: 'LoadInfo' });
    const [productName, setProductName] = useState<any[]>([]);
    const {
        state: { IdPage }
    } = useLocation();
    function handleResize() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        setWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
    }, []);

    async function loadInfoAssistence(id: any) {
        await getModelsByUser(id);
        if (models && !models?.error) {
            setName(models.data.UserCompany.Name);
            setEmail(models.data.UserCompany.Email);
            setTelefone(models.data.UserCompany.Phone1);
            setContato(models.data.Contact);
            setCidade(models.data.UserCompany.City);
            setProductName(models.data.Product);
        }
    }

    useEffect(() => {
        loadInfoAssistence(IdPage);
        setWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
    }, []);

    usePreventClickjacking();

    return (
        <>
            {loadingState ? <Loader text={t('common:actions.Loading')} /> : <div />}
            <div className="containerFindAssistence">
                <div>
                    <div className="contentPageContainer">
                        <div className="col-sm-12">
                            <h2>{t('dashboard:utilities.assistance.details')}</h2>
                            <Table>
                                <Table.Header className="Tabelas">
                                    <Table.HeaderCell>{t('info.Name')}</Table.HeaderCell>
                                    <Table.HeaderCell>{t('info.Email')}</Table.HeaderCell>
                                    <Table.HeaderCell>{t('address.Phone')}</Table.HeaderCell>
                                    <Table.HeaderCell>{t('common:translation.Contact')}</Table.HeaderCell>
                                    <Table.HeaderCell>{t('address.City')}</Table.HeaderCell>
                                </Table.Header>
                                <Table.Body className="Tabelas">
                                    <Table.Row className="Tabelas">
                                        <Table.Cell>{name}</Table.Cell>
                                        <Table.Cell>{email}</Table.Cell>
                                        <Table.Cell>{telefone}</Table.Cell>
                                        <Table.Cell>{contato}</Table.Cell>
                                        <Table.Cell>{cidade}</Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                            <h2> {t('common:translation.Model')} </h2>
                            <List celled={true} className="Lista">
                                {productName.map((item: any) => {
                                    return <List.Content>{item.Name}</List.Content>;
                                })}
                            </List>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default InfoAssistancePage;
