import { Button, Input, Modal } from '@scuf/common';
import useFetch from 'helpers/hooks/useFetch';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { REPAIR_CENTER } from 'resources';
import LabelDescription from './labelDescription';
import { formatDate, handleYesOrNo } from 'helpers';
type MicrosigaSerialNumber = {
    code: string;
    contractEnabled: boolean;
    description: string;
    factoryWarranty: string;
    serviceWarranty: string;
    contractValidity: string;
    partNumber: string;
    partNumberModel: string;
    salesInvoice: string;
    serialNumber: string;
    flagHasFactoryWarranty: boolean;
    flagHasContract: boolean;
    flagHasServiceWarranty: boolean;
};

export const WarrantyConsultModal: React.FC = () => {
    const [partNumber, setPartNumber] = useState<string>();
    const { t } = useTranslation(['common', 'repaircenter']);
    const [microsigaItem, consultSerial, loading] = useFetch<{ serialNumber: string }, MicrosigaSerialNumber>({
        url: REPAIR_CENTER.consultSerialNumber,
        onError: 'LoadInfo'
    });
    const [isModalOpen, setisModalOpen] = useState<boolean>(false);
    const handleClick = async () => {
        await consultSerial({ serialNumber: partNumber! }, current => {
            setisModalOpen(!!current.data);
        });
    };
    return (
        <div style={{ display: 'flex', flexDirection: 'row', gap: '12px' }}>
            <Modal className="no-padding" size="large" open={isModalOpen} closeIcon={true} onClose={() => setisModalOpen(false)}>
                <div className="modalConsultWaranty">
                    <Modal.Header>
                        <div className="modalHeader">
                            <h2> {t('translation.About')} </h2>
                        </div>
                    </Modal.Header>
                    <div className="grid-view" style={{ margin: '12px' }}>
                        <LabelDescription title={t('translation.SerialNumber')} value={microsigaItem?.data?.serialNumber} />
                        <LabelDescription title={t('translation.PartNumber')} value={microsigaItem?.data?.partNumber} />
                        <LabelDescription title={t('translation.Description')} value={microsigaItem?.data?.description} />
                        <LabelDescription
                            title={t('repaircenter:translation.MaintenanceContractValidity')}
                            value={formatDate(microsigaItem?.data?.contractValidity)}
                        />
                        <LabelDescription
                            title={t('repaircenter:translation.FactoryWarrantyValidity')}
                            value={formatDate(microsigaItem?.data?.factoryWarranty)}
                        />
                        <LabelDescription
                            title={t('repaircenter:translation.ServiceWarrantyValidity')}
                            value={formatDate(microsigaItem?.data?.serviceWarranty)}
                        />

                        <LabelDescription
                            title={t('translation.FactoryWarranty')}
                            value={handleYesOrNo(microsigaItem?.data?.flagHasFactoryWarranty)}
                        />
                        <LabelDescription
                            title={t('translation.MaintenanceContract')}
                            value={handleYesOrNo(microsigaItem?.data?.flagHasContract)}
                        />
                        <LabelDescription
                            title={t('translation.ServiceWarranty')}
                            value={handleYesOrNo(microsigaItem?.data?.flagHasServiceWarranty)}
                        />
                    </div>
                </div>
            </Modal>
            <Input
                placeholder={t('common:services.WarrantyConsult')}
                value={partNumber}
                onChange={pn => setPartNumber(pn)}
            ></Input>
            <Button content={t('common:actions.Search')} loading={loading} onClick={handleClick} disabled={!partNumber} />
        </div>
    );
};
