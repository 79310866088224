import { Button, Divider, Icon, Popup, Table, VerticalMenu } from '@scuf/common';
import '@scuf/datatable/honeywell-compact/theme.css';
import Alert from 'components/alert';
import { formatDate } from 'helpers';
import requestFile from 'helpers/requestFile';
import { RmaModelLite, WorkOrderRmaLiteDto } from 'models';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { REPAIR_CENTER, RmaLite } from 'resources';
import 'styles/repaircenter.scss';

const ServiceOrdersAccordion: React.FC = () => {
    const { t } = useTranslation(['common', 'repaircenter']);
    const navigate = useNavigate();
    const rmaDetail = useRecoilValue<RmaModelLite>(RmaLite);
    const [reportSelected, setReportSelected] = useState<string | null>(null);

    const pendingAprv = (rma: WorkOrderRmaLiteDto): boolean => {
        return (
            rma.status != 'ABERTO' &&
            rma.status != 'EM TRIAGEM' &&
            rma.status != 'EM ANALISE' &&
            rma.status != 'AGUARDANDO CADASTRO' &&
            rma.status != 'EMITIR ORÇAMENTO' &&
            rma.status != 'ORÇAMENTO EMITIDO')
    };

    const handlePrint = async (workOrder: string): Promise<void> => {
        setReportSelected(workOrder);
        await requestFile.print(REPAIR_CENTER.getTechnicalReportHTML, {
            method: 'POST',
            params: { ids: [workOrder] }
        });
        setReportSelected(null);
    };

    const handleExportXlxs = async (): Promise<void> => {
        await requestFile.download(REPAIR_CENTER.getReport, `WO_Report_${rmaDetail.id}`, {
            method: 'POST',
            params: { Type: 1, RmaId: rmaDetail.id }
        });
    };
    
    const handleTechnicalReport = async (): Promise<void> => {
        const allReports = rmaDetail?.workOrders?.filter(e => e.relatorio).map(e => parseInt(e.codigo, 10));
        await requestFile.print(REPAIR_CENTER.getTechnicalReportHTML, {
            method: 'POST',
            params: { ids: allReports }
        });
    };

    return !rmaDetail?.workOrders ? (
        <Alert text={t('notification:information.NoData')} />
    ) : (
        <div>
                <Alert text={t('repaircenter:translation.AlertOS')} />

                {rmaDetail?.workOrders !== null ? (
                    <div className="container-search-os">
                        <Popup
                            className="popup-theme-wrap"
                            element={
                                <Button
                                    type="link"
                                    content={t('actions.Export')}
                                    icon={<Icon name="ellipsis-vertical" root="common" size="large" />}
                                />
                            }
                            on="click"
                        >
                            <VerticalMenu>
                                <VerticalMenu.Item onClick={handleExportXlxs}>{t('actions.ExportXLSX')}</VerticalMenu.Item>
                                {rmaDetail?.workOrders?.find(e => e.relatorio) && (
                                    <VerticalMenu.Item onClick={handleTechnicalReport}>
                                        {t('actions.ExportReport')}
                                    </VerticalMenu.Item>
                                )}
                            </VerticalMenu>
                        </Popup>
                        <div className="div-datatable">
                            <div>
                                <Table>
                                    <Table.Header>
                                        <Table.HeaderCell content="Número" />
                                        <Table.HeaderCell content={t('Modelo')} />
                                        <Table.HeaderCell content={t('Modalidade')} />
                                        <Table.HeaderCell content={t('SN')} />
                                        <Table.HeaderCell content={t('translation.Status')} />
                                        <Table.HeaderCell content={t('status.SituationClient')} />
                                        <Table.HeaderCell content={t('repaircenter:rma.service_orders.DateIn')} />
                                        <Table.HeaderCell content={t('repaircenter:rma.service_orders.DateOut')} />
                                        <Table.HeaderCell content={t('translation.Code')} />
                                        <Table.HeaderCell content={t('translation.Report')} />
                                    </Table.Header>
                                    <Table.Body>
                                        {rmaDetail ? (
                                            [
                                                rmaDetail.workOrders?.map((rma: WorkOrderRmaLiteDto, index) => (
                                                    
                                                    <Table.Row key={index}>
                                                        <Table.Cell
                                                            onClick={() =>
                                                                pendingAprv(rma)
                                                                    ? navigate('budget', {
                                                                          state: {
                                                                            data: { id: rma?.os, rmaId: rma?.rma, flagRma : rma?.flagApproved }
                                                                          }
                                                                      })
                                                                    : null
                                                            }
                                                            className={pendingAprv(rma) ? 'clickable' : ''}
                                                        >
                                                            {rma?.os}
                                                        </Table.Cell>
                                                        <Table.Cell>{rma?.partnumber} </Table.Cell>
                                                        <Table.Cell>{rma?.modalidade} </Table.Cell>
                                                        <Table.Cell>{rma?.serial} </Table.Cell>
                                                        <Table.Cell>{rma?.status}</Table.Cell>
                                                        <Table.Cell>{rma?.flagApproved === 1 ? 'Aprovado' : rma?.flagApproved === 0 ? 'Reprovado' : rma?.status !== 'Aguardando Aprovação' ? '-' : rma?.status}</Table.Cell>
                                                        <Table.Cell>{formatDate(rma?.dataEntrada)} </Table.Cell>
                                                        <Table.Cell>{formatDate(rma?.dataSaida) == "1/1/1" ? "" : formatDate(rma?.dataSaida)}  </Table.Cell>
                                                        <Table.Cell>
                                                            {rma?.codigo ?? t('common:status.NotInformed')}
                                                        </Table.Cell>
                                                        {(rma.relatorio && (
                                                            <Table.Cell>
                                                                {rma?.os == reportSelected ? (
                                                                    <Icon loading={true} name="refresh" />
                                                                ) : (
                                                                    <Icon
                                                                        style={{ cursor: 'pointer' }}
                                                                        root="common"
                                                                        name="document-report"
                                                                        size="medium"
                                                                        onClick={() => handlePrint(rma?.os)}
                                                                    />
                                                                )}
                                                            </Table.Cell>
                                                        )) || <Icon name="unavailable" />}
                                                    </Table.Row>
                                                ))
                                            ]
                                        ) : (
                                            <Table.Row></Table.Row>
                                        )}
                                    </Table.Body>
                                </Table>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div>
                        <Divider />
                        <Alert text={t('repaircenterdash:QuotesAlert')} />
                    </div>
                )}
            </div>
    );
};

export default ServiceOrdersAccordion;
