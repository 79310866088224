import { UserManager } from 'oidc-client-ts';
import { storeUser, storeUserError } from './authActions';

export const config = {
    authority: 'https://localhost:7040',
    client_id: 'movies_mvc_client',
    redirect_uri: 'http://localhost:3000/signin-oidc',
    response_type: 'code',
    scope: 'openid profile address email roles',
    popup_post_logout_redirect_uri: 'http://localhost:3000/signout-oidc',
    loadUserInfo: true
};

const userManager = new UserManager(config);

export async function loadUserFromStorage(_user: any) {
    try {
        let user = await userManager.getUser();
        if (!user) {
            return _user.setUserOIDC(storeUserError());
        }
        _user.setUserOIDC(storeUser(user));
    } catch (e) {
        console.error(`User not found: ${e}`);
        _user.setUserOIDC(storeUserError());
    }
}

export function signinRedirect() {
    return userManager.signinRedirect();
}

export function signinPopup() {
    return userManager.signinPopup();
}

export function signinRedirectCallback() {
    return userManager.signinRedirectCallback();
}

export async function signoutPopup() {
    const userIdToken = await userManager.getUser();
    userManager.clearStaleState();
    userManager.removeUser();
    return userManager.signoutPopup({ id_token_hint: userIdToken?.id_token });
}

export async function signinPopupCallback(url?: string) {
    return userManager.signinPopupCallback(url);
}

export async function signoutPopupCallback() {
    userManager.clearStaleState();
    userManager.removeUser();
    return userManager.signoutPopupCallback();
}

export function signoutRedirect() {
    userManager.clearStaleState();
    userManager.removeUser();
    return userManager.signoutRedirect();
}

export function signoutRedirectCallback() {
    userManager.clearStaleState();
    userManager.removeUser();
    return userManager.signoutRedirectCallback();
}

export default userManager;
