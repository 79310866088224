import { Button, Icon, Tooltip } from '@scuf/common';
import useUser from 'helpers/hooks/useUser';

function MenuItem(props: any) {
    const { user: userDetails } = useUser();

    return (
        <>
            {(!props.name || userDetails?.MODULE.includes(props.name.toString())) && (
                <Tooltip
                    content={props.tooltipContent}
                    position="bottom center"
                    event="hover"
                    size="mini"
                    hoverable={true}
                    element={
                        <Button
                            onClick={props.onClick}
                            className="button-menu"
                            type="inline-secondary"
                            content={props.buttonContent}
                            icon={<Icon root={props.iconRoot} name={props.iconName} size="large" />}
                        />
                    }
                ></Tooltip>
            )}
        </>
    );
}
export default MenuItem;
