import { Accordion, Badge, Loader, Tab } from '@scuf/common';
import { statusOpt, typeOpt } from 'models/spareParts';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { COMPANY_MANAGER, SPARE_PARTS, companyAtom } from 'resources';
import Customer from './components/acordion/customer';
import General from './components/acordion/general';
import Processing from './components/acordion/processing/processing';
import ProductAdded from './components/acordion/productsAdded/productAdded';
import { SparePartsChat, SparePartsInvoicing } from './components/tabs';
import SparePartsAdministrative from './components/tabs/sparePartsAdministrative';
import SparePartsFile from './components/tabs/sparePartsFile';
import { orderAtom } from 'resources/atoms/spareParts';
import { OrderDTO } from 'models/spareParts/orderById';
import useFetch from 'helpers/hooks/useFetch';
import useUser from 'helpers/hooks/useUser';
import { CompanyDTO } from 'models/userManager/addCustomer';
import { SparePartsChatTable } from 'models/repaircenter/detailsRma';

const OrdersDetailSelected: React.FC = () => {
    const { t } = useTranslation(['spareparts', 'common']);
    const { user: userDetails } = useUser();
    const [orderData, setorderData] = useRecoilState<OrderDTO>(orderAtom);
    const [, setCompanyData] = useRecoilState<CompanyDTO>(companyAtom);
    const { state } = useLocation();
    const { Id } = state;
    const [, getOrderById, loading] = useFetch<{ orderId: number }, OrderDTO>({ url: SPARE_PARTS.getById });
    const [, getCompanyByCnpj] = useFetch<{ companyDto: string }, CompanyDTO>({ url: COMPANY_MANAGER.getCompanyByCnpj });
    const { user } = useUser();
    const [unreadMessage, setUnreadMessage] = useState<number>(0);
    const [chats, loadChats] = useFetch<
        { roleId: number; ssoId: string; companyId: number; orderId: number },
        SparePartsChatTable[]
    >({
        url: SPARE_PARTS.getAllChats
    });
    const UnreadMessage = () => {
        chats?.data?.find(e => {
            if ((user?.ROLE == '1' || user?.ROLE == '2') && e.itWasReadByAdmin === false) {
                setUnreadMessage(parseInt(e.message));
            } else if ((user?.ROLE == '3' || user?.ROLE == '4') && e.itWasReadByCustomer === false) {
                setUnreadMessage(parseInt(e.message));
            }
        });
    };

    async function getOrder(orderId: number) {
        await getOrderById({ orderId }, current => {
            if (current.data) setorderData(current.data);
        });
    }

    async function getCompany() {
        await getCompanyByCnpj({ companyDto: orderData.companyCnpj }, current => {
            if (current.data) setCompanyData(current.data);
        });
    }
    useEffect(() => {
        UnreadMessage();
    }, [chats]);

    useEffect(() => {
        loadChats({ roleId: parseInt(user?.ROLE!), ssoId: user?.SSOID!, companyId: user?.COMPANY!, orderId: Id });
        const f = async () => {
            await getOrder(Id);
        };
        f();
    }, []);

    useEffect(() => {
        const f = async () => {
            await getCompany();
        };
        f();
    }, [orderData]);

    return (
        <>
            {loading ? (
                <Loader text={t('common:actions.Loading')} />
            ) : (
                <>
                    <div className="wrap-content">
                        <div className="teste">
                            <h2>
                                {t('common:translation.Order')} {'#'}
                                {orderData?.id}
                            </h2>
                            <Badge color={typeOpt.find(e => e.value === orderData?.typeId)?.color}>
                                {typeOpt.find(e => e.value === orderData?.typeId)?.text}
                            </Badge>
                            <Badge color={statusOpt.find(e => e.value === orderData?.statusId)?.color}>
                                {statusOpt.find(e => e.value === orderData?.statusId)?.text}
                            </Badge>
                        </div>
                        <Tab className="tab-with-divider">
                            <Tab.Pane title={t('common:translation.Summary')}>
                                <Accordion>
                                    <Accordion.Content title={t('common:translation.General')}> {<General />}</Accordion.Content>
                                    <Accordion.Content title={t('user:address.Company')}>{<Customer />}</Accordion.Content>
                                    <Accordion.Content title={t('translation.AddedProducts')}>
                                        {<ProductAdded />}
                                    </Accordion.Content>
                                </Accordion>
                            </Tab.Pane>
                            <Tab.Pane title={t('common:tabs.Processing')}>{<Processing />}</Tab.Pane>
                            <Tab.Pane title={t('spareparts:translation.Billings')}>
                                <SparePartsInvoicing />
                            </Tab.Pane>
                            <Tab.Pane title={t('common:tabs.Chat')} badge={unreadMessage}>
                                <SparePartsChat orderId={Id} />
                            </Tab.Pane>
                            <Tab.Pane title={t('common:tabs.Files')}>{<SparePartsFile />}</Tab.Pane>
                            {userDetails?.ROLE == '1' || userDetails?.ROLE == '2' ? (
                                <Tab.Pane title={t('common:tabs.Administrative')}>
                                    <SparePartsAdministrative />
                                </Tab.Pane>
                            ) : (
                                []
                            )}
                        </Tab>
                    </div>
                </>
            )}
        </>
    );
};

export default OrdersDetailSelected;
