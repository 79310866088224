import { Button, Input, Modal, Radio } from '@scuf/common';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
type Props = {
    open: boolean;
    osId: string | number;
    onChange: CallableFunction;
    onRejects: CallableFunction;
    onClose: VoidFunction;
};
const RejectBudgetModal: React.FC<Props> = ({ open, osId, onChange, onRejects, onClose }: Props) => {
    const { t } = useTranslation(['repaircenter']);
    const [value, setValue] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const options = ['ReproveReason1', 'ReproveReason2', 'ReproveReason3', 'ReproveReason4', 'ReproveReason5', 'Others'];
    useEffect(() => {
        if (message.length > 600) setMessage(message.slice(0, 600));
    }, [message]);
    return (
        <Modal className="modalW600" open={open} onClose={onClose} closeIcon={true}>
            <div className="modalConsultWaranty">
                <Modal.Header>
                    <h2 className="modalHeader">{`${t('rma.budget.Actions.ReproveOS')} #${osId}`}</h2>
                </Modal.Header>
                <h3>{t('rma.budget.Reprove.Reason')}</h3>
                <div className="column" style={{ margin: '12px' }}>
                    {options.map((e: string, index: number) => (
                        <Radio
                            key={index}
                            label={t(`rma.budget.Reprove.${e}`)}
                            value={t(`rma.budget.Reprove.${e}`)}
                            checked={e === value}
                            onChange={() => {
                                setValue(e);
                                onChange(t(`rma.budget.Reprove.${e}`));
                            }}
                        ></Radio>
                    ))}
                    {value === 'Others' && (
                        <Input
                            indicator="required"
                            // label={t('rma.budget.Reprove.ReproveReason')}
                            value={message}
                            helperText={`${message?.length}/600`}
                            onChange={(text: string) => {
                                onChange(text);
                                setMessage(text);
                            }}
                        ></Input>
                    )}
                </div>
                <div className="buttonsSearch">
                    <Button
                        content={t('rma.budget.Actions.ReproveOS')}
                        disabled={!value || (value === 'Others' && !message)}
                        onClick={() => onRejects()}
                    ></Button>
                    <Button content={t('common:actions.Close')} onClick={onClose}></Button>
                </div>
            </div>
        </Modal>
    );
};

export default RejectBudgetModal;
