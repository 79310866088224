import { AxiosHttpGet, AxiosHttpPost } from 'axios-http';
import { CompanyPermissionDTO } from 'models/user/ModulePermissions';
import { MODULE_PERMISSION, USER_MANAGER, USER_MODULE, USER_PERMISSION } from 'resources/links';

export async function getPermission() {
    return AxiosHttpGet({
        url: MODULE_PERMISSION.getModulePermissions
    });
}
export async function addGroupToCustomer(data: { GroupId: number; UserCompanyId: number }) {
    return AxiosHttpPost({
        url: USER_MANAGER.addGroupToCustomer,
        data
    });
}
export async function removeGroupFromUser(data: { GroupId: number; UserCompanyId: number }) {
    return AxiosHttpPost({
        url: USER_MANAGER.removeGroupFromUser,
        data
    });
}

export async function getUserModule(userId: number) {
    return AxiosHttpGet({
        url: USER_MODULE.getUserModules,
        data: { userId }
    });
}
export async function getUserPermission(userId: number) {
    return AxiosHttpGet({
        url: USER_PERMISSION.getUserPermission,
        data: { userId }
    });
}

export async function insertUserModule(CompanyId: number, ModuleId: number) {
    let data = {
        CompanyId,
        ModuleId
    };
    return AxiosHttpPost({
        url: USER_MODULE.insertUserModule,
        data
    });
}

export async function insertCompanyLOB(CompanyId: number, LOBId: number) {
    return AxiosHttpGet({
        url: USER_MODULE.insertCompanyLOB,
        data: {
            companyId: CompanyId,
            lobId: LOBId
        }
    });
}

export async function insertUserPermission(UserId: number, PermissionId: number) {
    let data = {
        UserId,
        PermissionId
    };
    return AxiosHttpPost({
        url: USER_PERMISSION.updateUserPermission,
        data
    });
}

export async function getCompanyPermissionsById(companyId: number) {
    return AxiosHttpGet({
        url: USER_PERMISSION.getCompanyPermissions,
        data: { companyId }
    });
}

export async function updateCompanyPermissions(data: CompanyPermissionDTO) {
    return AxiosHttpPost({
        url: USER_PERMISSION.updateCompanyPermissions,
        data
    });
}
