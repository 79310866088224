import React from 'react';
import { Icon } from '@scuf/common';
import { OrderDTO } from 'models/spareParts/orderById';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { orderAtom } from 'resources/atoms/spareParts';
import Price from './components/orderprocessing';
import Alert from 'components/alert';
import PreApproved from './components/preApproved';
import Warranty from './components/warranty';

const Processing: React.FC = () => {
    const orderData = useRecoilValue<OrderDTO>(orderAtom);
    const { t } = useTranslation();

    return (
        <div className="container">
            <div className="container-changes">
                <div className="container-selects">
                    {orderData !== null ? (
                       // orderData?.typeId == 2 ? (
                        <Price />
                        ) :(
                        <Alert text={t('common:translation.RmaNotProcess')} />
                        )}
                            {/* orderData?.typeId == 3 ? (<PreApproved />) : (orderData?.typeId == 4 ? (<Warranty />) : (
                            <Alert text={t('common:translation.RmaNotProcess')} />)))) : ()} */}
                </div>
            </div>
        </div>
    );
};

export default Processing;
