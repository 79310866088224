import '@scuf/common/honeywell/theme.css';
import 'styles/app.scss';
import { NotificationWrapper } from 'components';
import { useEffect, useState } from 'react';
import { Cookies, CookiesProvider } from 'react-cookie';
import { useRecoilState } from 'recoil';
import { AuthProvider, loadUserFromStorage, RoutesHelper, userManager, userOIDCState } from 'resources';
import { BrowserRouter } from 'react-router-dom';
import { Button, Card, Modal, Table } from '@scuf/common';
import usePreventClickjacking from 'helpers/hooks/usePreventingClickjacking';

function App() {
    const [userOIDC, setUserOIDC] = useRecoilState(userOIDCState);
    const [modalState, setModalState] = useState(false);
    const cookies = new Cookies();

    const checkForInactivity = () => {
        const expireTime = localStorage.getItem('expireTime')!;
        if (parseInt(expireTime) < Date.now()) {
            if (cookies.get('info')) {
                cookies.remove('info');
                setModalState(true);
            }
        }
    };

    const updateExpireTime = () => {
        const expireTime = Date.now() + 9900000;
        localStorage.setItem('expireTime', expireTime.toString());
    };

    useEffect(() => {
        let _userOIDC = {
            userOIDC: userOIDC,
            setUserOIDC: setUserOIDC
        };
        loadUserFromStorage(_userOIDC);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            checkForInactivity();
        }, 10000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        updateExpireTime();

        window.addEventListener('click', updateExpireTime);
        window.addEventListener('keypress', updateExpireTime);
        window.addEventListener('scroll', updateExpireTime);
        window.addEventListener('mousemove', updateExpireTime);

        return () => {
            window.removeEventListener('click', updateExpireTime);
            window.removeEventListener('keypress', updateExpireTime);
            window.removeEventListener('scroll', updateExpireTime);
            window.removeEventListener('mousemove', updateExpireTime);
        };
    }, []);

    function renderModal(): JSX.Element {
        return (
            <Modal
                className="modalW600"
                onClose={() => setModalState(false)}
                size="small"
                open={parseInt(localStorage.getItem('expireTime')!) < Date.now()}
                closeOnDimmerClick={true}
                closeOnDocumentClick={true}
            >
                <div className="modalConsultWaranty">
                    <Modal.Header>
                        <div className="modalHeader">
                            <h2> {'Tempo Expirado'} </h2>
                        </div>
                    </Modal.Header>
                    <Modal.Content>
                        {
                            'Tempo limite de inatividade atingido, por favor faça login novamente para continuar utilizando o portal'
                        }{' '}
                    </Modal.Content>
                    <Card.Footer>
                        <div className="buttonModal">
                            <Button content={'Fechar'} onClick={() => setModalState(false)} size="medium" textTransform={true} />
                        </div>
                    </Card.Footer>
                </div>
            </Modal>
        );
    }

    usePreventClickjacking();
    return (
        <>
            <BrowserRouter basename={process.env.REACT_APP_BASENAME_URL}>
                {renderModal()}
                <AuthProvider userMananager={userManager}>
                    <NotificationWrapper>
                        <CookiesProvider defaultSetOptions={{ path: '/' }}>
                            <div translate="no" className="fixed-body-height">
                                <RoutesHelper />
                            </div>
                        </CookiesProvider>
                    </NotificationWrapper>
                </AuthProvider>
            </BrowserRouter>
        </>
    );
}

export default App;
