import { Button, Select, TextArea } from '@scuf/common';
import { getRmaType } from 'helpers';
import useFetch from 'helpers/hooks/useFetch';
import { RmaModel, RmaModelLite, RmaOption, typeOptions } from 'models';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { REPAIR_CENTER, Rma } from 'resources';

interface ChangeStatusProps {
    rmaState: RmaModelLite;
    setRmaState: React.Dispatch<React.SetStateAction<RmaModelLite>>;
}

function ChangeModality({ rmaState, setRmaState }: ChangeStatusProps): JSX.Element {
    const [currentType, setCurrentType] = useState<RmaOption | undefined>(getRmaType(rmaState?.typeId));
    const { t } = useTranslation(['repaircenter', 'common']);
    const [updatedRma, changeRmaModality, loading] = useFetch<RmaModel>({
        url: REPAIR_CENTER.alterRmaModality,
        method: 'POST',
        onError: 'Change',
        onSuccess: 'Change'
    });
    let observation: string = '';
    //Coloquei o tipo Any abaixo mas o tipo correto serial RmaModel
    const changeRmaType = async (newRma: any): Promise<void> => {
        await changeRmaModality(newRma, current => {
            current.error
                ? setRmaState({ ...rmaState, typeId: currentType!.value })
                : setRmaState({ ...rmaState, type: current.data.type, typeId: current.data.typeId });
        });
    };
    return (
        <div className="container-changes">
            <div className="container-selects">
                <Select
                    className="select"
                    options={typeOptions}
                    disabled
                    label={t('common:translation.From')}
                    placeholder={rmaState?.type?.name}
                />
                <Select
                    className="select"
                    indicator="required"
                    placeholder={t('common:placeholders.Select.NewValue')}
                    options={typeOptions}
                    label={t('common:translation.To')}
                    onChange={val => setCurrentType(getRmaType(val))}
                />
            </div>
            <div className="container-text-area">
                <TextArea
                    placeholder={t('rma.admin.type.ChangeModalityObs')}
                    onChange={value => {
                        observation = value;
                    }}
                    fluid
                ></TextArea>
            </div>
            <div className="container-button">
                <Button
                    content={t('common:actions.Change')}
                    disabled={currentType?.value === rmaState?.typeId}
                    loading={loading}
                    onClick={async () => {
                        await changeRmaType({ ...rmaState, typeId: currentType!.value, generalMessage: observation });
                    }}
                />
            </div>
        </div>
    );
}
export default ChangeModality;
