import { delivery, Option, OptionAproved, TypeOption } from './orderModel';

export const typeOpt: TypeOption[] = [
    //{ value: 1, text: 'Em Escolha', color: 'yellow' },
    { value: 2, text: 'Cotação', color: 'yellow' },
    { value: 3, text: 'Pré-Aprovado', color: 'green' },
    { value: 4, text: 'Garantia', color: 'blue' }
];
export const statusOpt: Option[] = [
    { value: 1, text: 'No Carrinho', color: 'yellow' },
    { value: 2, text: 'Aguardando Processamento', color: 'yellow' },
    { value: 3, text: 'Aguardando Aprovação do Cliente', color: 'yellow' },
    { value: 4, text: 'Rejeitado', color: 'red' },
    { value: 5, text: 'Aprovado Pelo Cliente', color: 'green' },
    { value: 6, text: 'Expirado', color: 'red' },
    { value: 7, text: 'Recusado Pelo Cliente', color: 'red' },
    { value: 8, text: 'Aguardando Peça', color: 'red' },
    { value: 9, text: 'Restrição Financeira', color: 'red' },
    { value: 10, text: 'Faturado', color: 'blue' },
    { value: 11, text: 'Faturado Parcialmente', color: 'yellow' },
    { value: 12, text: 'Nota Fiscal Emitida', color: 'blue' },
    { value: 13, text: 'Produto em Transporte', color: 'green' },
    { value: 14, text: 'Produto Entregue', color: 'green' },
    { value: 15, text: 'Aguardando Devolução Peça', color: 'yellow' },
    { value: 16, text: 'Finalizado Inconformidade', color: 'yellow' },
    { value: 17, text: 'Finalizado', color: 'green' },
    { value: 18, text: 'Em Separação', color: 'yellow' }
];


export const listAproved: OptionAproved[] = [
    { value: true, text: 'Aprovado' },
    { value: false, text: 'Reprovado' }
];

export const listYesNop: OptionAproved[] = [
    { value: true, text: 'Sim' },
    { value: false, text: 'Não' }
];

export const destiny: OptionAproved[] = [
    { value: 1, text: 'REVENDA' },
    { value: 2, text: 'USO/CONSUMO' }
];

export const shippingType: OptionAproved[] = [
    { value: 1, text: 'Por Conta da Honeywell' },
    { value: 2, text: 'Cliente Retira' }
];

export const situation: Option[] = [
    { value: 2, text: 'Aguardando Administrativo', color: 'yellow' },
    { value: 3, text: 'Aguardando Cliente', color: 'yellow' }
];

export const deliveryTimeOptions: delivery[] = [
    { text: 'Pronta Entrega', value: 'Pronta Entrega' },
    { text: '10 dias', value: '10 dias' },
    { text: '30-45 dias', value: '30-45 dias' },
    { text: '45-90 dias', value: '45-90 dias' },
    { text: '60-90 dias', value: '60-90 dias' },
    { text: 'Mais de 90 dias', value: 'Mais de 90 dias' },
    { text: 'Outro', value: 'Outro' }
];

export const typeReportOpt: Option[] = [
    { value: 3, text: 'Cotação/Pré-Aprovado' },
    { value: 4, text: 'Garantia' }
];

export const StatusSatisfactionSurvey: Option[] = [
    { text: 'Em Aberto', value: 'Em Aberto', color: 'yellow' },
    { text: 'Finalizada', value: 'Finalizada', color: 'blue' },
    { text: 'Não respondida', value: 'Não respondida', color: 'red' }
];
