import { Button, Grid, Icon, Input, Select } from '@scuf/common';
import { IOption } from '@scuf/common/dist/components/Select/ISelectProps';
import useFetch from 'helpers/hooks/useFetch';
import { AddressModel, RmaModel, RmaModelLite } from 'models';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { REPAIR_CENTER, Rma, cepMask, notificationAtom } from 'resources';
import { getStatesAndInitials } from 'resources/api/externalApi';

interface ChangeStatusProps {
    rmaState: RmaModelLite;
    setRmaState: React.Dispatch<React.SetStateAction<RmaModelLite>>;
}

function AdmAddressAccordion({ rmaState, setRmaState }: ChangeStatusProps): JSX.Element {
    const { t } = useTranslation(['user', 'common']);
    const [fields, setFields] = useState<AddressModel>(rmaState.address);
    const [states, setStates] = useState<IOption[]>([]);
    const [change, setChange] = useState<boolean>(false);
    const setNotification = useSetRecoilState(notificationAtom);

    const handleChange = (field: string, value: string): void => {
        setChange(true);
        setFields(old => ({ ...old, [field]: value }));
    };

    const handleChangeAddressComplement = (field: string, value: string): void => {
        setChange(true);
        setRmaState(old => ({ ...old, addressComplement: value }));
    };

    const handleAddressNumber = (field: string, value: string): void => {
        setChange(true);
        setRmaState(old => ({ ...old, addressNumber: value }));
    };

    const handleCancel = (): void => {
        setChange(false);
        setFields(rmaState.address);
    };
    const getStates = async (): Promise<IOption[]> => {
        const resp: { status: number; data: any } = await getStatesAndInitials();
        let options: { value: string; text: string }[] = [];
        if (resp.status === 200) {
            options = resp.data.map((e: any) => ({ value: e.sigla, text: e.nome }));
        }
        return options;
    };
    const [, changeAddressData, loading] = useFetch<RmaModelLite>({
        url: REPAIR_CENTER.alterAddressInRma,
        method: 'POST',
        onSuccess: 'Change',
        onError: 'Change'
    });
    const handleSave = async (): Promise<void> => {
        await changeAddressData({ ...rmaState, id: rmaState.id }, current => {
            setRmaState(old => ({ ...old, address: fields }));
            setChange(false);
        });
    };

    useEffect(() => {
        getStates().then(value => setStates(value));
    }, []);

    useEffect(() => {
        setRmaState(old => ({ ...old, address: fields }));
    }, [fields]);
    return (
        <>
            <Grid>
                <Grid.Column className="grid-with-gap">
                    <Input
                        maxLength={9}
                        value={cepMask(fields?.zipCode)}
                        label={t('address.ZipCode')}
                        onChange={value => handleChange('zipCode', value)}
                    ></Input>
                    <Select
                        value={fields?.state}
                        label={t('address.State')}
                        options={states}
                        onChange={value => handleChange('state', value)}
                    ></Select>
                    <Input value={fields?.city} label={t('address.City')} onChange={value => handleChange('city', value)}></Input>
                    <Input
                        value={fields?.neighborhood}
                        label={t('address.Neighborhood')}
                        onChange={value => handleChange('neighborhood', value)}
                    ></Input>
                </Grid.Column>
                <Grid.Column className="grid-with-gap">
                    <Input
                        value={fields?.street}
                        label={t('address.Street')}
                        onChange={value => handleChange('street', value)}
                    ></Input>
                    <Input
                        value={rmaState?.addressNumber}
                        label={t('address.Number')}
                        onChange={value => handleAddressNumber('addressNumber', value)}
                    ></Input>
                    <Input
                        value={rmaState?.addressComplement}
                        label={t('address.Complement')}
                        onChange={value => handleChangeAddressComplement('addressComplement', value)}
                    ></Input>
                </Grid.Column>
            </Grid>
            <>
                {change && (
                    <div className="buttonsSearch">
                        <Button
                            content={t('common:actions.Cancel')}
                            type="secondary"
                            onClick={handleCancel}
                            loading={loading}
                            disabled={loading}
                        ></Button>
                        <Button
                            content={t('common:actions.Save')}
                            iconPosition="right"
                            icon={<Icon root="common" name="save" />}
                            loading={loading}
                            disabled={loading}
                            onClick={handleSave}
                        ></Button>
                    </div>
                )}
            </>
        </>
    );
}

export default AdmAddressAccordion;
