import { Button, Input, Select } from '@scuf/common';
import useFetch from 'helpers/hooks/useFetch';
import { Report } from 'models/maintenanceReport/allMaintenances';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { SPARE_PARTS } from 'resources';
import { modelState } from 'resources/atoms/maintenanceReportAtom';
import 'styles/maintenanceReport.scss';

function NewMaintenanceReportAddItemsVisit() {
    const { t } = useTranslation(['maintenanceReport', 'common']);
    const [productOptions, setProductOptions] = useState<any>();
    const [problemDescription, setProblemDescription] = useState<string>();
    const [model, setModel] = useRecoilState<Report>(modelState);

    const [products, getOptions, isLoading] = useFetch<never>({
        url: SPARE_PARTS.getOptions,
        onError: 'LoadInfo'
    });

    useEffect(() => {
        handleGetProducts();
    }, []);

    const handleGetProducts = async () => {
        if (!products?.data || products?.data.length === 0) {
            await getOptions(undefined, resp => {
                setProductOptions(
                    resp.data?.map((e: any, index: number) => ({
                        value: index,
                        text: e.name
                    }))
                );
            });
        } else {
            setProductOptions(
                products?.data?.map((e: any, index: number) => ({
                    value: index,
                    text: e.name
                }))
            );
        }
    };

    const addProduct = () => {
        const newItem = [
            {
                ProblemDescription: problemDescription,
                Product: model?.ProductName,
                SerialNumber: model?.SerialNumber
            }
        ];
        setModel(prevModel => ({
            ...prevModel,
            ReportItems: [...(prevModel.ReportItems ?? []), ...newItem]
        }));
    };

    return (
        <div className="form-items">
            <Select
                indicator={'required'}
                label={t('common:translation.Product')}
                placeholder={isLoading ? t('common:actions.Loading') : t('common:translation.Product')}
                search={true}
                disabled={isLoading}
                options={productOptions ?? []}
                value={model?.ProductName && productOptions?.findIndex((x: any) => x.text == model?.ProductName)}
                onChange={e => setModel({ ...model, ProductName: productOptions[e].text })}
            />
            <Input
                className="serial"
                label={t('common:translation.SerialNumber')}
                placeholder={t('common:translation.SerialNumber')}
                indicator="required"
                value={model?.SerialNumber || ''}
                onChange={(value: string) => setModel({ ...model, SerialNumber: value })}
            />
            <Input
                className="problem"
                indicator={'required'}
                placeholder={t('common:translation.Problem')}
                label={t('common:translation.Problem')}
                value={problemDescription ?? ''}
                onChange={(value: string) => setProblemDescription(value)}
            />
            <Button
                className="button-add"
                type="primary"
                icon="slidercontrols-plus"
                content={t('common:actions.Add')}
                iconPosition="right"
                disabled={!problemDescription || !model?.SerialNumber || !model?.ProductName}
                onClick={() => addProduct()}
            />
        </div>
    );
}

export default NewMaintenanceReportAddItemsVisit;
