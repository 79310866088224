import { useEffect } from 'react';

function usePreventClickjacking() {
    useEffect(() => {
        const preventClickjacking = () => {
            if (window.top && window !== window.top) {
                window.top.location.href = window.location.href;
            }
        };

        preventClickjacking();

        window.addEventListener('resize', preventClickjacking);

        return () => {
            window.removeEventListener('resize', preventClickjacking);
        };
    }, []);
}

export default usePreventClickjacking;