import { Button, Card, Icon, Input, Modal, Select, Table } from '@scuf/common';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { COMPANY_MANAGER, LOB, SPARE_PARTS, USER_MODULE, companyAtom, companyDtoAtom } from 'resources';
import { orderCartAtom } from 'resources/atoms/spareParts';
import MyCart from './MyCart';
import { OrderCartDTO, OrderItemsDTO, ProductsDTO } from 'models/spareParts/orderById';
import useFetch from 'helpers/hooks/useFetch';
import useUser from 'helpers/hooks/useUser';
import { CompanyDTO, CompanyLOBDTO, LobDto, SearchLob } from 'models/userManager/addCustomer';

export type StepperProp = {
    canSkipPage: (goNext: boolean) => void;
};

function AddEquipment({ canSkipPage }: StepperProp): JSX.Element {
    const { t } = useTranslation(['spareparts', 'common', 'user']);
    const [loading, setLoading] = useState<boolean>(false);
    const [modalState, setModalState] = useState<boolean>(false);
    const [selected, setSelected] = useState<{ pn: string; pd: string }>({ pn: '', pd: '' });
    const [defaultCart, setdefaultCart] = useRecoilState<OrderCartDTO>(orderCartAtom);
    const [, setQtd] = useState<{}>();
    const { user } = useUser();
    const [, setUserCompany] = useRecoilState<CompanyDTO>(companyAtom);
    const [permissions, setPermissions] = useState<string>('');
    const [partNumbers, setPartNumbers] = useState<ProductsDTO[]>();

    const setCompany = useSetRecoilState<CompanyDTO>(companyDtoAtom);

    const [lobs, getLobs] = useFetch<{ companyId: number }, CompanyLOBDTO[]>({
        url: USER_MODULE.getCompanyLOBs,
        onError: 'LoadInfo'
    });

    const [company, getCompanyById] = useFetch<{ id: number }, CompanyDTO>({
        url: COMPANY_MANAGER.getCompanyById,
        onError: 'LoadInfo'
    });

    const [products, getOptions, , updateProducts] = useFetch<never>({
        url: SPARE_PARTS.getOptions
    });

    const [allLobs, getAllLOBS] = useFetch<never, LobDto[]>({
        url: LOB.getLOBs,
        onError: 'LoadInfo'
    });

    const [, getAllPartnumberByLOB] = useFetch<{ lobs: string; product?: string }, any[]>({
        url: SPARE_PARTS.getAllPartNumbersByLOB,
        onError: 'LoadInfo'
    });

    const [resultList, SearchPartNumbers, loadAction, updatePn] = useFetch<SearchLob, OrderItemsDTO[]>({
        url: SPARE_PARTS.searchPartNumbersByLOB,
        onError: 'LoadInfo',
        method: 'POST'
    });

    const [, addItemInCart, loadingAdd] = useFetch<OrderItemsDTO, OrderItemsDTO>({
        url: SPARE_PARTS.addItemInCart,
        method: 'POST',
        onError: 'Add'
    });

    useEffect(() => {
        getCompanyById({ id: user?.COMPANY! }, resp => setCompany(resp.data!));
        getAllLOBS();
        handleGetPartNumbers();
    }, []);

    useEffect(() => {
        setUserCompany(company?.data!);
    }, [company]);

    useEffect(() => {
        if (permissions == '') return;

        getAllPartnumberByLOB({ lobs: permissions }, resp => {
            if (resp.data) {
                setPartNumbers(
                    resp.data?.map((x, index) => ({
                        value: index,
                        text: x.code
                    }))
                );
            }
        });
    }, [permissions]);

    useEffect(() => {
        if ((permissions == undefined || permissions == '') && (selected.pd == undefined || selected.pd == '')) return;

        getAllPartnumberByLOB({ lobs: permissions, product: selected.pd }, resp => {
            if (resp.data) {
                setPartNumbers(
                    resp.data?.map((x, index) => ({
                        value: index,
                        text: x.code
                    }))
                );
            }
        });
    }, [selected]);

    useEffect(() => {
        const total = defaultCart?.orderItems?.reduce(
            (acc, current) => acc + (1 - current.discount) * current.netPrice * current.quantity,
            0
        );
        setdefaultCart(old => ({ ...old, total }));
        canSkipPage(defaultCart?.orderItems?.length > 0);
    }, [defaultCart?.orderItems]);

    useEffect(() => {
        if (lobs == undefined) return;

        let perms: string = '';
        let resp: CompanyLOBDTO[] = lobs.data as CompanyLOBDTO[];
        perms = resp[0].lob.lobCode;
        for (let cl of resp) perms += `,${cl.lob.lobCode}`;
        setPermissions(perms);
    }, [lobs]);

    useEffect(() => {
        if (user) getLobs({ companyId: user.COMPANY });
    }, [user]);

    const renderModal = (): JSX.Element => {
        return (
            <Modal
                onClose={() => setModalState(false)}
                size="large"
                open={modalState}
                closeOnDimmerClick={true}
                closeOnDocumentClick={true}
            >
                <div className="modalConsultWaranty">
                    <Modal.Header>
                        <div className="modalHeader">
                            <h2> {t('productinfots.FreeShipping')} </h2>
                        </div>
                    </Modal.Header>
                    <Modal>
                        <h2> {t('common:translation.Details')} </h2>
                    </Modal>
                    <Modal.Footer>
                        <Table>
                            <Table.Header>
                                <Table.HeaderCell content={t('user:address.State')} />
                                <Table.HeaderCell content={t('productinfots.MinimumPurchase')} />
                                <Table.HeaderCell content={t('productinfots.CostFreight')} />
                            </Table.Header>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell>{'SP/MG'}</Table.Cell>
                                    <Table.Cell>{'USD 49,00'}</Table.Cell>
                                    <Table.Cell>{'USD 9,99'}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>{t('productinfots.RestBrazil')}</Table.Cell>
                                    <Table.Cell>{t('USD 99,00')}</Table.Cell>
                                    <Table.Cell>{t('USD 17,99')}</Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </Modal.Footer>
                    <div>
                        <h4>{t('productinfots.ValidPurchase')}</h4>
                    </div>

                    <Card.Footer>
                        <div className="buttonModal">
                            <Button
                                content={t('common:actions.Close')}
                                onClick={() => setModalState(false)}
                                size="medium"
                                textTransform={true}
                            />
                        </div>
                    </Card.Footer>
                </div>
            </Modal>
        );
    };

    const handleSearch = async () => {
        const listLOBPermitted: string[] = permissions.split(',');
        const selLOBS: LobDto[] = allLobs?.data?.filter(x => listLOBPermitted.find(a => a == `${x.lobCode}`)) ?? [];
        const parms: SearchLob = {
            filter: selected.pn ? selected.pn : selected.pd,
            filterType: selected.pn ? '1' : '3',
            lobs: selLOBS
        };

        await SearchPartNumbers(parms, current => {
            if (current.data) updatePn(current?.data.map((e: OrderItemsDTO) => ({ ...e, quantity: 0 })));
        });
        setSelected({ pn: '', pd: '' });
    };

    const handleGetPartNumbers = async (): Promise<void> => {
        setLoading(true);

        await getOptions(undefined, resp => {
            updateProducts(
                resp.data?.map((e: any, index: number) => ({
                    value: index,
                    text: e.name
                }))
            );
        });
        setLoading(false);
    };

    const validate = (): boolean => {
        return !!selected.pd || !!selected.pn;
    };

    const addItemCart = async (e: OrderItemsDTO) => {
        e.email = user?.EMAIL ? user?.EMAIL : '';
        await addItemInCart(e, current => {
            if (current.data) {
                current.data.partNumber = current.data.code;
                setdefaultCart(old => ({
                    ...old,
                    orderItems: [...(old?.orderItems ?? []), current.data!]
                }));
                e.quantity = 0;
            }
        });
    };

    const limparFiltro = () => {
        setSelected({ pn: '', pd: '' });
    };

    return (
        <>
            {renderModal()}
            <div>
                <span>
                    <h1>
                        <Icon root="common" name="device-add" size="large" /> {t('common:actions.Add')}
                    </h1>
                    <div className="div-shipping-os">
                        <Icon root="common" name="shipping" color="blue" size="xlarge" />

                        <h5>{t('productinfots.FreeShipping')}</h5>
                        <div>
                            <Button type="secondary" onClick={() => setModalState(true)}>
                                {t('productinfots.ConditionsYourRegion')}
                            </Button>
                        </div>
                    </div>

                    <div className="form3Columns">
                        <Select
                            label={t('common:translation.Product')}
                            disabled={loading}
                            options={products?.data ?? []}
                            placeholder={loading ? t('common:actions.Loading') : t('common:translation.Product')}
                            value={selected.pd && products?.data?.findIndex((x: any) => x.text == selected.pd)}
                            search={true}
                            onChange={e => setSelected({ pd: products?.data![e].text, pn: '' })}
                        />

                        <Select
                            label={t('common:translation.PartNumber')}
                            options={partNumbers ?? []}
                            disabled={loading}
                            value={selected.pn && partNumbers?.findIndex((x: ProductsDTO) => x.text == selected.pn)}
                            search={true}
                            placeholder={loading ? t('common:actions.Loading') : t('common:translation.PartNumber')}
                            onChange={e => {
                                setSelected({ pn: partNumbers![e].text, pd: '' });
                            }}
                        />
                    </div>

                    <div className="buttonsSearch">
                        <Button
                            type="secondary"
                            icon="badge-delete"
                            loading={loadAction}
                            disabled={!validate() || loadAction}
                            content={t('common:actions.ResetFilters')}
                            onClick={() => limparFiltro()}
                        />
                        <Button
                            type="primary"
                            icon="search"
                            loading={loadAction}
                            disabled={!validate() || loadAction}
                            content={t('common:actions.Search')}
                            onClick={() => handleSearch()}
                        />
                    </div>

                    <div style={{ maxHeight: '60vh', overflowY: 'scroll' }}>
                        {!!resultList?.data?.length && (
                            <Table>
                                <Table.Header>
                                    <Table.HeaderCell>{t('cart.Item')}</Table.HeaderCell>
                                    <Table.HeaderCell>{t('cart.Discount')}</Table.HeaderCell>
                                    <Table.HeaderCell>{t('cart.NetPrice')}</Table.HeaderCell>
                                    <Table.HeaderCell>{t('cart.Quantity')}</Table.HeaderCell>
                                    <Table.HeaderCell>{''}</Table.HeaderCell>
                                </Table.Header>

                                <Table.Body>
                                    {resultList?.data?.map((e: OrderItemsDTO, index) => (
                                        <React.Fragment key={e.id}>
                                            <Table.Row className="clickable">
                                                <Table.Cell>
                                                    <h4>{e?.code}</h4>
                                                    {e?.description}
                                                </Table.Cell>
                                                <Table.Cell>{e?.discount * 100}%</Table.Cell>
                                                <Table.Cell>{'US$' + ' ' + e.netPrice}</Table.Cell>
                                                <Table.Cell>
                                                    <Input
                                                        helperText="QTD"
                                                        type="number"
                                                        min={1}
                                                        onChange={(value: any) => {
                                                            e.quantity = parseInt(value);
                                                            setQtd(e.quantity);
                                                        }}
                                                        value={e.quantity.toString()}
                                                    ></Input>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <div className="buttonsSearch">
                                                        <Button
                                                            loading={loadingAdd}
                                                            disabled={!e.quantity}
                                                            content={t('common:actions.Add')}
                                                            onClick={() => {
                                                                addItemCart(e);
                                                            }}
                                                        ></Button>
                                                    </div>
                                                </Table.Cell>
                                            </Table.Row>
                                        </React.Fragment>
                                    ))}
                                </Table.Body>
                            </Table>
                        )}
                    </div>
                </span>
                <MyCart />
            </div>
        </>
    );
}

export default AddEquipment;
