import { Button, Icon, Table } from '@scuf/common';
import Alert from 'components/alert';
import { handleYesOrNo } from 'helpers';
import useFetch from 'helpers/hooks/useFetch';
import {
    RmaModelLite,
    workOrderDefaultStatus,
    WorkOrderModel,
    WorkOrderOption,
    WorkOrderRmaLiteDto,
    workOrderStatus
} from 'models';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { REPAIR_CENTER, RmaLite } from 'resources';

function AdmWorkOrdersAccordion(): JSX.Element {
    const [rmaDetail, setRmaDetail] = useRecoilState<RmaModelLite>(RmaLite);
    const { t } = useTranslation(['repaircenter', 'common']);
    const [updatedOS, updateStatusOS, loading] = useFetch<{ workOrderNumber: string | number }>({
        url: REPAIR_CENTER.updateStatusOS,
        method: 'DELETE',
        onError: 'Change',
        onSuccess: 'Change'
    });
    const handleChangeStatus = async (numberOS: string | number): Promise<void> => {
        await updateStatusOS({ workOrderNumber: numberOS }, _ => {
            setRmaDetail(old => ({
                ...old,
                workOrders: old.workOrders.map((e: WorkOrderRmaLiteDto) => (e.os === numberOS ? { ...e, flagApproved: 2 } : e))
            }));
        });
    };

    return (
        (rmaDetail.workOrders?.length > 0 && (
            <Table>
                <Table.Header>
                    <Table.HeaderCell>{t('common:translation.Number')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('common:status.Approved')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('common:translation.Status')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('rma.admin.os.ChangeStatusOS')}</Table.HeaderCell>
                </Table.Header>
                {rmaDetail.workOrders?.map((workOrder: WorkOrderRmaLiteDto) => (
                    <Table.Row>
                        <Table.Cell>{workOrder.os}</Table.Cell>
                        <Table.Cell>
                            {(workOrder.flagApproved !== 2 && handleYesOrNo(!!workOrder.flagApproved)) ||
                                t('common:status.Pending')}
                        </Table.Cell>
                        <Table.Cell>
                            {workOrderStatus.find((e: WorkOrderOption) => e.text.includes(workOrder.status))?.text ??
                                workOrderDefaultStatus.text}
                        </Table.Cell>
                        <Table.Cell>
                            <Button
                                size="small"
                                loading={loading}
                                disabled={workOrder.flagApproved === 2 || loading}
                                onClick={() => handleChangeStatus(workOrder.os)}
                            >
                                {t('common:actions.Change')}
                            </Button>
                        </Table.Cell>
                    </Table.Row>
                ))}
            </Table>
        )) || <Alert text={t('common:empty.NoWorkOrders')} />
    );
}

export default AdmWorkOrdersAccordion;
