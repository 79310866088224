import useUser from 'helpers/hooks/useUser';
import { UserRole } from 'models/user/sso';
import React, { ReactNode } from 'react';

type Props = {
    roles: (keyof typeof UserRole)[];
    children: ReactNode;
};
export const RoleComponent: React.FC<Props> = ({ roles, children }: Props) => {
    const { user } = useUser();
    return <>{roles.some((e: keyof typeof UserRole) => UserRole[e] === user?.ROLE) ? children : null}</>;
};
