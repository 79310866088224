import { Button, Select } from '@scuf/common';
import { dateFormatFromMicrosft, getRmaStatus } from 'helpers';
import useFetch from 'helpers/hooks/useFetch';
import { Stauts } from 'models/spareParts';
import { OrderDTO } from 'models/spareParts/orderById';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { SPARE_PARTS } from 'resources';
import { getOrderById } from 'resources/api/spareParts_api/getOrderById';
import { orderAtom } from 'resources/atoms/spareParts';

function ChangeStatus(): JSX.Element {
    const [orderData, setorderData] = useRecoilState(orderAtom);
    const [currentStatus, setCurrentStatus] = useState(getRmaStatus(orderData?.statusId));
    // const [status, setStatus] = useState<Stauts[]>([]);
    const { t } = useTranslation(['spareparts', 'common']);
    const [, editOrder, loadingEdit] = useFetch<OrderDTO>({
        url: SPARE_PARTS.editOrder,
        method: 'POST',
        onError: 'Change',
        onSuccess: 'Change'
    });
    const changeSparePartsStatus = async (newOrderData: OrderDTO): Promise<void> => {
        await editOrder(newOrderData, current => {
            setorderData({ ...orderData, statusId: current.data?.statusId });
        });
    };

    async function getOrder(id: any) {
        // setLoading(true);
        const resp = await getOrderById(id);

        if (resp.status !== 200) {
            // setLoading(false);

            return resp.status;
        } else {
            setorderData({ ...resp.data, PersistDate: dateFormatFromMicrosft(resp.data.PersistDate) });
        }
        // setLoading(false);
    }
    const [status, getAllStatus] = useFetch<never, Stauts[]>({ url: SPARE_PARTS.getAlltatus, onError: 'LoadInfo' });

    useEffect(() => {
        getAllStatus();
    }, []);
    // async function getStatus() {
    //     // setLoading(true);
    //     await getAllStatus();

    //     // setLoading(false);
    // }

    return (
        <div className="container-changes">
            <div className="container-selects">
                <Select
                    className="select"
                    options={status?.data?.map(e => ({ text: e.name, value: e.id })) ?? []}
                    disabled
                    label={t('common:translation.From')}
                    //placeholder={orderData?.status?.name}
                    value={orderData?.statusId}
                />
                <Select
                    className="select"
                    indicator="required"
                    placeholder={t('common:placeholders.Select.NewValue')}
                    options={status?.data?.map(e => ({ text: e.name, value: e.id })) ?? []}
                    label={t('common:translation.To')}
                    onChange={val => {
                        setCurrentStatus(getRmaStatus(val));
                    }}
                />
            </div>
            <div className="container-button">
                <Button
                    content={t('common:actions.Change')}
                    disabled={currentStatus?.value === orderData?.statusId}
                    loading={loadingEdit}
                    onClick={async () => {
                        await changeSparePartsStatus({ ...orderData, statusId: currentStatus!.value });
                    }}
                />
            </div>
        </div>
    );
}
export default ChangeStatus;
