import { Button, Header, Icon, Input, Loader, Select } from '@scuf/common';
import { DataTable } from '@scuf/datatable';
import { ICellData } from '@scuf/datatable/dist/components/DataTable/IDataTableInterfaces';
import useFetch from 'helpers/hooks/useFetch';
import useUser from 'helpers/hooks/useUser';
import { FilterUserModel } from 'models';
import { UserModule, UserRole } from 'models/user/sso';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { USER_MANAGER, cnpjMask } from 'resources';

const CustomerTabManager: React.FC = () => {
    const { t } = useTranslation(['usermanager', 'common', 'userManegments']);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsByPage, setItemsByPage] = useState(5);
    const [reset, setReset] = useState(true);
    const [call, setCall] = useState(false);
    const { user: userDetails } = useUser();

    const navigate = useNavigate();
    const [filter, getUsers, loadingData, setFilter] = useFetch<FilterUserModel, FilterUserModel>({
        url: USER_MANAGER.getUsers,
        method: 'POST',
        redirectOnError: true
    });
    const itemsPerPage = ['5', '10', '15', '20'];
    const companyString = userDetails?.COMPANY;

    const handleData = async (indice = 1): Promise<void> => {
        await getUsers(
            //            { ...(filter?.data ?? { role: parseInt(UserRole.SUPERADMIN) }), indice, itemByPage: itemsByPage },
            { ...(filter?.data ?? { role: null }), indice, itemByPage: itemsByPage },

            current => {
                //
                setCurrentPage(current?.data?.indice ?? 1);
                if (userDetails?.ROLE == UserRole.CLIENTADMIN) {
                    setFilter(old => ({
                        ...old,
                        users: [...(old.users ?? [])].filter((user: any) => user.companyId == companyString)
                        // .map((e: any,) => {
                        // return {
                        // ...e,
                        // roleName: e.role.name,
                        // id: e.ssoId,
                        // };
                        // })
                    }));
                }
                // else if(userDetails?.ROLE == 6){
                //     setFilter(old=> ({...old,users: [...old.users ?? []].filter(e=> return {...e,roleName: e.role.name})})
                //         [...resp.data.users].map((e: any,) => {
                //             return {
                //                 ...e,
                //                 roleName: e.role.name,
                //                 id: e.ssoId,
                //             };
                //         })
                //     );
                // }
            }
        );
        // setFilter(old => ({ ...old, id: 2 }));
    };

    useEffect(() => {
        handleData();
    }, [reset, call]);

    return (
        <>
            {/* {report()} */}
            {(loadingData && <Loader text={t('common:actions.Loading')} />) || (
                <>
                    <Header title={t('dashboard:admin_tools.user_manager.name')} logo={false} menu={false}>
                        <Header.IconItem
                            icon={<Icon name="graph" size="large" root="building" />}
                            badge="20"
                            description={t('common:actions.Reports')}
                            // onClick={() => setModelReport(true)}
                        ></Header.IconItem>
                    </Header>
                    <div className="form4Columns">
                        <Input
                            label={'SSO ID'}
                            placeholder={'SSO ID'}
                            value={filter?.data?.ssoId?.toString() ?? ''}
                            onChange={(value: string) => setFilter(old => ({ ...old!, ssoId: value }))}
                        ></Input>
                        <Input
                            label={t('user:info.Name')}
                            placeholder={t('user:info.Name')}
                            value={filter?.data?.name?.toString() ?? ''}
                            onChange={(value: string) => setFilter(old => ({ ...old!, name: value }))}
                        ></Input>
                        <Input
                            label={t('user:info.Email')}
                            placeholder={t('user:info.Email')}
                            value={filter?.data?.email?.toString() ?? ''}
                            onChange={(value: string) => setFilter(old => ({ ...old!, email: value }))}
                        ></Input>
                        <Input
                            label={t('CNPJ')}
                            placeholder={t('CNPJ')}
                            value={cnpjMask(filter?.data?.cnpj?.toString() ?? '') ?? ''}
                            onChange={(value: string) => setFilter(old => ({ ...old!, cnpj: cnpjMask(value) }))}
                        ></Input>
                        <Select
                            className="SelectStyle"
                            label={t('user:info.Role')}
                            options={[
                                { text: 'Super Admin', value: parseInt(UserRole.SUPERADMIN) },
                                { text: 'Admin', value: parseInt(UserRole.ADMIN) },
                                { text: 'Client Admin', value: parseInt(UserRole.CLIENTADMIN) },
                                { text: 'Client', value: parseInt(UserRole.CLIENT) }
                            ]}
                            placeholder={t('user:info.Role')}
                            //value={filter?.data?.role}
                            onChange={(value: number) => setFilter(old => ({ ...old!, role: value }))}
                        ></Select>
                        <Select
                            className="SelectStyle"
                            label={t('user:info.Module')}
                            options={[
                                { text: 'RMA', value: UserModule.RMA },
                                { text: 'SpareParts', value: UserModule.SPAREPARTS },
                                { text: 'User', value: UserModule.USER },
                                { text: 'Maintenance Report', value: UserModule.MAINTENANCE_REPORT }
                            ]}
                            placeholder={t('user:info.Module')}
                            type="number"
                            value={filter?.data?.module}
                            onChange={(value: number) => setFilter(old => ({ ...old!, module: value }))}
                        ></Select>
                        <Select
                            className="SelectStyle"
                            label={t('common:translation.ItemperPage')}
                            placeholder={t('common:translation.ItemperPage')}
                            value={itemsByPage?.toString()}
                            options={itemsPerPage.map((e: string) => ({ text: e.toString(), value: e }))}
                            onChange={value => setItemsByPage(parseInt(value))}
                        />
                    </div>
                    <div className="buttonsSearch">
                        <Button
                            type="secondary"
                            icon="badge-delete"
                            content={t('common:actions.ResetFilters')}
                            iconPosition="right"
                            onClick={() => {
                                setFilter(old => ({
                                    itemByPage: 10,
                                    indice: 1,
                                    role: null,
                                    users: old?.users,
                                    totalItems: old?.totalItems
                                }));
                                setReset(old => !old);
                            }}
                        />
                        <Button
                            type="primary"
                            icon="filter1"
                            content={t('common:actions.ApplyFilters')}
                            iconPosition="right"
                            onClick={() => handleData()}
                        />
                    </div>
                    <br />
                    <DataTable
                        data={filter?.data?.users?.map(e => ({ ...e, roleName: e.role?.name })) ?? []}
                        onCellClick={(data: ICellData) => navigate('customer', { state: { id: data.rowData.ssoId } })}
                    >
                        <DataTable.Column header={'ID'} field="ssoId"></DataTable.Column>
                        <DataTable.Column header={t('user:info.Name')} field="name"></DataTable.Column>
                        <DataTable.Column header={t('user:info.Role')} field="roleName"></DataTable.Column>
                        <DataTable.Pagination
                            disabledPages={[currentPage]}
                            activePage={currentPage}
                            itemsPerPage={filter?.data?.itemByPage ?? itemsByPage}
                            totalItems={filter?.data?.totalItems ?? 0}
                            onPageChange={(page: any) => {
                                handleData(parseInt(page));
                            }}
                        ></DataTable.Pagination>
                    </DataTable>
                </>
            )}
        </>
    );
};

export default CustomerTabManager;
